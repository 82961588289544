import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { DoctorType } from '../../../contracts/Doctors';

export function useDoctorsPeers() {
  const request = useRequest<Array<DoctorType>>();
  return useQuery<unknown, unknown, Array<DoctorType>>(['doctors-peers'], () => request('/doctors/peers'), {
    staleTime: 1000 * 30,
  });
}
