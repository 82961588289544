import * as React from 'react';
import { ChangeEvent } from 'react';

export const Checkbox: React.FC<{ isChecked: boolean; onChange: (e: ChangeEvent<HTMLInputElement>) => void }> = ({
  isChecked,
  onChange,
}) => {
  return (
    <input
      type={'checkbox'}
      checked={isChecked}
      onChange={onChange}
      className="h-4 w-4 text-primary border-gray-300 rounded focus:outline-none focus:ring-primary focus:border-primary"
    />
  );
};
