import * as React from 'react';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import AlertListComponent from '../../components/alerts/AlertListComponent';
import { useTranslation } from 'react-i18next';

export const AlertList = () => {
  const { t } = useTranslation('AlertList');
  const breadcrumbPages = [{ name: t('breadcrumb'), href: '/alerts', current: true }];
  return (
    <DashboardLayout>
      <Breadcrumbs pages={breadcrumbPages} />
      <AlertListComponent />
    </DashboardLayout>
  );
};
