import { TFunction } from 'i18next';
import { FullName } from './Generic';

export type DoctorType = BaseDoctorType & {
  email: string;
  clinic?: {
    id: number;
    name: string;
  };
  phone: string;
  role: DoctorRoles;
};

export type DoctorRequestType = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  clinicId?: number;
  speciality: string;
  role: DoctorRoles;
};

export enum DoctorRoles {
  CaseManager = 1,
  FamilyDoctor,
  Physician,
  Expert,
}

export const getDoctorRoleLabelList = (t: TFunction) => ({
  [DoctorRoles.CaseManager]: t('caseManager', { ns: 'common' }),
  [DoctorRoles.FamilyDoctor]: t('familyDoctor', { ns: 'DoctorRoleLabels' }),
  [DoctorRoles.Physician]: t('physician', { ns: 'DoctorRoleLabels' }),
  [DoctorRoles.Expert]: t('expert', { ns: 'DoctorRoleLabels' }),
});

export type BaseDoctorType = FullName & {
  id: number;
  speciality: string;
};
