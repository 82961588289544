import React from 'react';

interface Props {
  title: string;
  count: number;
  isActive: boolean;
  onClick?: () => void;
}

export default function EcgAccidentsGroupNavItem({ title, count, isActive }: Props) {
  return (
    <>
      <span className={isActive ? 'text-primary' : undefined}>{title}</span>
      <span
        className={`px-3 ml-4 py-1 inline-flex rounded-full justify-center text-xs tracking-widest font-semibold bg-gray-100`}
      >
        {count}
      </span>
    </>
  );
}
