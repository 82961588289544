import { useMemo } from 'react';
import { ActiveElement, Chart, ChartEvent, ChartOptions, TooltipItem } from 'chart.js';
import format from 'date-fns/format';

// Used to calculate visual chart division interval so it appears rectangular
const widthHeightMultiplier = 19.7;

export function useEcgChartOptions(
  secondsPerPage: number,
  startTime: number,
  isCommentMode: boolean,
  onChartClicked: (x: number, y: number) => void,
  yZoom: number,
) {
  return useMemo<ChartOptions<'line'>>(
    () => ({
      animation: false,
      normalized: true,
      parsing: false,
      onClick(event: ChartEvent, elements: ActiveElement[], chart: Chart) {
        if (!isCommentMode) {
          return;
        }
        const parsed = chart.scales['x'].getValueForPixel(event!.x!);
        onChartClicked(parsed ?? 0, 0);
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          intersect: !isCommentMode,
          callbacks: {
            title(tooltipItems: TooltipItem<any>[]): string {
              return format(new Date(startTime + (Number(tooltipItems[0].parsed.x) / 128) * 1000), 'HH:mm:ss');
            },
            label(tooltipItem: TooltipItem<any>): string | string[] {
              return `${tooltipItem.parsed.y / 1000} mV`;
            },
          },
        },
      } as any,
      layout: { padding: -12, autoPadding: false },
      scales: {
        x: {
          min: 0,
          max: secondsPerPage * 128,
          grid: {
            drawBorder: false,
            drawTicks: false,
            lineWidth: 0,
          },
          ticks: {
            display: true,
            mirror: true,
            align: 'inner',
            font: { weight: 'medium' },
            z: 10,
            callback: (tickValue, index, ticks) => {
              if (index === 0 || tickValue === ticks[ticks.length - 1].value) {
                return format(new Date(startTime + (Number(tickValue) / 128) * 1000), 'HH:mm:ss');
              } else {
                return '';
              }
            },
          },
          type: 'linear',
        },
        x2: {
          min: 0,
          max: secondsPerPage * 1000,
          grid: { display: false },
          ticks: { display: false },
          type: 'linear',
        },
        y: {
          min: -1 * secondsPerPage * 25 * widthHeightMultiplier * yZoom,
          max: secondsPerPage * 25 * widthHeightMultiplier * yZoom,
          grid: {
            drawBorder: false,
            drawTicks: false,
            lineWidth: 0,
          },
          ticks: {
            display: true,
            mirror: true,
            font: { weight: 'medium' },
            z: 10,
            crossAlign: 'near',
            callback: (tickValue: number | string) => {
              const tv = Number(tickValue);
              if (Number(tv) % 500 === 0) {
                return `${tv / 1000} mV`;
              } else {
                return '';
              }
            },
          },
        },
      },
    }),
    [isCommentMode, secondsPerPage, yZoom, onChartClicked, startTime],
  );
}
