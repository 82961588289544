import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { PatientThreadType, ThreadMessageUpdateRequest } from '../../../contracts/Threads';

export function useThreadMessageUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientThreadType>();
  return useMutation<PatientThreadType, unknown, ThreadMessageUpdateRequest>(
    ({ patientId, threadId, messageId, ...data }) =>
      request(`/patients/${patientId}/threads/${threadId}/messages/${messageId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    {
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(['threads-me']);
        queryClient.setQueryData(['patient-threads', request.patientId], (oldData: Array<PatientThreadType> = []) => {
          const index = oldData.findIndex(o => o.id === data.id);
          if (index !== -1) {
            return [...oldData.slice(0, index), data, ...oldData.slice(index + 1)];
          }
          return oldData;
        });
      },
    },
  );
}
