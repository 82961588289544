import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AlertType } from '../../contracts/Alert';

export enum StatusFiltersEnum {
  Unprocessed = 0,
  Processed = 1,
  All = 2,
}

export interface IAlertsSearchParams {
  filter?: StatusFiltersEnum;
  pageSize?: number;
  pageIndex?: number;
  days?: number;
  patientId?: number;
}

export interface IAlertResponse {
  result: Array<AlertType>;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
}

export function useAlerts(query: IAlertsSearchParams) {
  const request = useRequest<IAlertResponse>();
  const filtered = Object.entries(query).filter(([, value]) => typeof value !== 'undefined');

  const searchParams = new URLSearchParams(Object.fromEntries(filtered));

  return useQuery<unknown, unknown, IAlertResponse>(
    ['alerts', query],
    () => request(`/alerts?${searchParams.toString()}`),
    {
      staleTime: 1000 * 30,
      keepPreviousData: true,
    },
  );
}
