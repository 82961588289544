import Dialog from '../dialog/Dialog';
import { PropsWithChildren } from 'react';
import H3 from '../typography/H3';
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';

interface IDeleteConfirmation {
  id: number;
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (id: number) => void;
  labels?: {
    title?: string;
    yesLabel?: string;
    noLabel?: string;
  };
}

export default function Confirmation(props: PropsWithChildren<IDeleteConfirmation>) {
  const { t } = useTranslation('Confirmation');
  return (
    <Dialog open={props.open} handleCloseClick={props.handleCancel} widthAuto={true}>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <H3>{props.labels?.title ?? t('title')}</H3>
        {props.children && <div className="dark:text-gray-300">{props.children}</div>}
        <div className="mt-6 sm:flex sm:flex-row-reverse">
          <Button type="primary" buttonType="submit" onClick={() => props.handleSubmit(props.id)}>
            {props.labels?.yesLabel ?? t('yes')}
          </Button>
          <Button type="secondary" buttonType="button" onClick={props.handleCancel}>
            {props.labels?.noLabel ?? t('no')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
