import { useMemo } from 'react';
import { DeepPartial } from 'chart.js/types/utils';
import { ChartDataset, ChartOptions, GridLineOptions, ScaleOptions, TooltipItem } from 'chart.js';
import { lt } from 'date-fns/esm/locale';
import format from 'date-fns/format';

import { COLORS } from '../../../../../../utils/color';
import { getDateWithoutTime, getDateWithTime } from '../../../../../../utils/date';
import { MetricHistoryType } from '../../../../../../contracts/Metrics';

import { getDates, getHistoryDataPoint, historyValues } from '../MetricsUtils';
import { addDays } from 'date-fns';

function getSuggestedMinMax(metrics: MetricHistoryType[], secondary: boolean): Partial<ScaleOptions<any>> {
  const metricsForAxis = metrics.filter(m => (secondary ? m.metric.axis === 'right' : m.metric.axis !== 'right'));

  if (!metricsForAxis?.length) {
    return {
      display: false,
    };
  }

  let targetMin: number = Math.min(...metricsForAxis.map(m => m.metric.targetMin as number));
  let targetMax: number = Math.max(...metricsForAxis.map(m => m.metric.targetMax as number));

  const allValues: Array<number> = metricsForAxis.map(m => historyValues(m.history)).flat();
  const factualMin = Math.min.apply(Math, allValues);
  const factualMax = Math.max.apply(Math, allValues);

  return {
    position: secondary ? 'right' : 'left',
    suggestedMin: targetMin && factualMin > targetMin ? targetMin * 0.95 : factualMin * 0.95,
    suggestedMax: targetMax && factualMax < targetMax ? targetMax * 1.05 : factualMax * 1.05,
    title: {
      text: metricsForAxis[0].metric.unit,
      display: true,
      align: 'start',
    },
    grid: {
      display: !secondary,
    } as GridLineOptions,
  } as ScaleOptions<any>;
}

const getSmartDateTimeTooltipStamp = (tooltipItems: TooltipItem<'line' | 'bar'>): string | string[] => {
  const rawValue: { x?: string } = tooltipItems?.raw as any;
  if (!rawValue?.x) {
    return '';
  }
  return rawValue.x.includes('T00:00:00') ? getDateWithoutTime(rawValue.x) : getDateWithTime(rawValue.x);
};

export const useDynamicCharts = (
  metrics: MetricHistoryType[],
  startDate: Date,
  endDate: Date,
): [any, DeepPartial<ChartOptions<'line' | 'bar'>>] =>
  useMemo(() => {
    const leftYAxisConfig = getSuggestedMinMax(metrics, false);
    const rightYAxisConfig = getSuggestedMinMax(metrics, true);

    const scales: DeepPartial<ChartOptions<'line' | 'bar'>> = {
      scales: {
        x: {
          adapters: {
            date: {
              locale: lt,
            },
          },
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
            displayFormats: {
              day: 'MM-dd',
            },
          },
          min: format(startDate, 'yyyy-MM-dd'),
          max: format(addDays(endDate, 1), 'yyyy-MM-dd'),
        },
        y: leftYAxisConfig,
        y2: rightYAxisConfig,
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            filter: (item, data) => data.datasets[item.datasetIndex!].label !== undefined,
          },
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems: TooltipItem<'line' | 'bar'>[]): string | string[] =>
              Array.isArray(tooltipItems)
                ? getSmartDateTimeTooltipStamp(tooltipItems[0])
                : getSmartDateTimeTooltipStamp(tooltipItems),
          },
          filter: p => !!p.dataset.label,
        },
      },
    };

    return [
      {
        labels: getDates(metrics),
        datasets: metrics.map((m, idx) => buildMetricsDatasets(m, getDates(metrics), idx, startDate, endDate)).flat(),
      },
      scales,
    ];
  }, [metrics, startDate, endDate]);

const buildMetricsDatasets = (
  metric: MetricHistoryType,
  labels: any[],
  index: number,
  startDate: Date,
  endDate: Date,
): ChartDataset[] => {
  const axisName = metric.metric?.axis === 'right' ? 'y2' : 'y';
  const mainDataset = {
    label: metric.metric.name,
    yAxisID: axisName,
    data: metric.history.map(getHistoryDataPoint),
    fill: false,
    pointRadius: 4,
    pointBorderWidth: ({ raw }: { raw?: { x: Date | number; y?: number } }) => {
      const y = raw?.y;
      if (!y) {
        return 0;
      }
      if (
        (metric.metric.targetMin !== null && y < metric.metric.targetMin) ||
        (metric.metric.targetMax !== null && y > metric.metric.targetMax)
      ) {
        return 12;
      }
    },
    tension: 0.2,
    ...COLORS[index % COLORS.length],
  };

  const lowBar: ChartDataset<any> =
    typeof metric.metric.targetMin === 'number'
      ? {
          type: 'line',
          yAxisID: metric.metric.axis === 'left' ? 'y' : 'y2',
          label: undefined,
          data: [
            { x: format(addDays(startDate, -1), 'yyyy-MM-dd'), y: metric.metric.targetMin },
            { x: format(addDays(endDate, 2), 'yyyy-MM-dd'), y: metric.metric.targetMin },
          ],
          pointRadius: 0,
          pointHoverRadius: 0,
          pointHitRadius: 0,
          borderWidth: 1,
          backgroundColor: mainDataset.borderColor,
          borderColor: mainDataset.borderColor,
          fill: {
            target: '+1',
            below: mainDataset.rangeColor,
            above: mainDataset.rangeColor,
          },
        }
      : null;

  const highBar: ChartDataset<any> =
    typeof metric.metric.targetMax === 'number'
      ? {
          type: 'line',
          data: [
            { x: format(addDays(startDate, -1), 'yyyy-MM-dd'), y: metric.metric.targetMax },
            { x: format(addDays(endDate, 2), 'yyyy-MM-dd'), y: metric.metric.targetMax },
          ],
          yAxisID: metric.metric.axis === 'left' ? 'y' : 'y2',
          label: undefined,
          pointRadius: 0,
          pointHoverRadius: 0,
          pointHitRadius: 0,
          borderWidth: 1,
          backgroundColor: mainDataset.borderColor,
          borderColor: mainDataset.borderColor,
          fill: {
            target: '-1',
            below: mainDataset.rangeColor,
            above: mainDataset.rangeColor,
          },
        }
      : null;

  const results: ChartDataset<any>[] = [mainDataset];

  if (lowBar) {
    if (!highBar) {
      lowBar.fill = false;
      lowBar.borderColor = mainDataset.borderColor;
    }
    results.push(lowBar);
  }

  if (highBar) {
    if (!lowBar) {
      highBar.fill = false;
      highBar.borderColor = mainDataset.borderColor;
    }
    results.push(highBar);
  }

  return results;
};
