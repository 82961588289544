import { PatientBaseType } from './Patient';
import { TFunction } from 'i18next';

export type AlertType = {
  id: number;
  isProcessed: boolean;
  level: AlertLevel;
  message?: string;
  metric?: string;
  patient: PatientBaseType;
  target?: string;
  targetRange?: string;
  timestamp: Date;
};

export enum AlertLevel {
  Info = 0,
  Warning,
  Danger,
}

export const getAlertLevelLabelArray = (t: TFunction): Array<string> => [
  t('info', { ns: 'AlertLevelValue' }),
  t('warning', { ns: 'AlertLevelValue' }),
  t('danger', { ns: 'AlertLevelValue' }),
];

export const AlertLevelColor: Array<string> = [
  'bg-gray-200 text-gray-900', // Info
  'bg-orange-100 text-yellow-600', // Warning
  'bg-red-100 text-red-800', // Danger
];
