import React, { PropsWithChildren } from 'react';
import { RawMetricDataPointType, SleepMetricsResponse } from '../../../../../../contracts/Metrics';
import { ChartDataset, ChartOptions, ScaleOptions, TooltipItem } from 'chart.js';
import { lt } from 'date-fns/esm/locale';
import { Column } from 'react-table';
import { getFullYear } from '../../../../../../utils/date';
import { isEqual, parseISO } from 'date-fns';
import { TFunction } from 'i18next';

const evenHours = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24];

const getNextEvenHour = (value: number): number => {
  const diffs = evenHours.map(h => h * 60 - value);
  const firstPositiveValueIndex = diffs.findIndex(v => v > 0);
  return evenHours[firstPositiveValueIndex] * 60;
};

const calculateMax = (ctx: any): number => {
  const datasets: ChartDataset[] = ctx.chart.data.datasets;
  const labelCount = ctx.chart.data.labels.length;
  const columns: number[] = [];
  for (let i = 0; i < labelCount; i++) {
    const labelTotal = datasets.reduce(
      (previousValue, currentValue) => previousValue + ((currentValue.data[i] as number) || 0),
      0,
    );
    columns.push(labelTotal);
  }

  const initialMax = Math.max(...columns);

  return getNextEvenHour(initialMax);
};

const getTickValue = (tickValue: number | string): number => Math.ceil(Number(tickValue) / 60);

export const defaultYAxisOptions: ScaleOptions<'linear'> = {
  title: {
    text: 'val.',
    display: true,
    align: 'start',
  },
  stacked: true,
  beginAtZero: true,
  ticks: {
    callback: getTickValue,
    align: 'inner',
    stepSize: 120,
  },
  grid: {
    tickLength: 0,
  },
  max: calculateMax as any,
};

export const getDefaultSleepChartOptions = (t: TFunction): ChartOptions<'bar'> => ({
  scales: {
    x: {
      stacked: true,
      adapters: {
        date: {
          locale: lt,
        },
      },
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'yyyy-MM-dd',
        displayFormats: {
          day: 'MM-dd',
        },
      },
      grid: {
        tickLength: 0,
        lineWidth: 0,
      },
    },
    y: {
      ...defaultYAxisOptions,
      position: 'left',
    },
    y2: {
      ...defaultYAxisOptions,
      position: 'right',
    },
  },
  plugins: {
    legend: { position: 'bottom' },
    tooltip: {
      callbacks: {
        label({ raw }: TooltipItem<'bar'>): string | string[] {
          const value = raw as number;
          if (value > 60) {
            return t('chartTooltipHoursFormat', { hours: Math.floor(value / 60), minutes: value % 60 });
          }

          return t('chartTooltipMinutesFormat', { minute: value % 60 });
        },
      },
    },
  },
});

const mapMetricsDatapointToDatasetPoint = (
  label: string,
  datapointName: keyof typeof sleepChartColors,
  dataPoint: RawMetricDataPointType[],
) => ({
  label: label,
  data: dataPoint.map(({ value }) => value),
  fill: false,
  tension: 0.2,
  ...sleepChartColors[datapointName],
});

const sleepStages: (keyof typeof sleepChartColors)[] = ['wake', 'rem', 'light', 'deep', 'nap'];

const getSleepDates = (data: SleepMetricsResponse): Date[] => {
  const dates = sleepStages.reduce<Date[]>(
    (prev, curr) => [...prev, ...data[curr].map(d => new Date(d.date))],
    [] as Date[],
  );

  return dates
    .filter((value: Date, index: number, self: Date[]) => self.findIndex(iv => isEqual(iv, value)) === index)
    .sort();
};

export const getSleepDataExists = (data: SleepMetricsResponse) =>
  [data.wake, data.nap, data.rem, data.deep, data.light].flat()?.length > 0;

export const getSleepMetrics = (data: SleepMetricsResponse, t: TFunction) => ({
  labels: getSleepDates(data),
  datasets: [
    mapMetricsDatapointToDatasetPoint(t('deep'), 'deep', data.deep),
    mapMetricsDatapointToDatasetPoint(t('light'), 'light', data.light),
    mapMetricsDatapointToDatasetPoint(t('rem'), 'rem', data.rem),
    mapMetricsDatapointToDatasetPoint(t('wake'), 'wake', data.wake),
    mapMetricsDatapointToDatasetPoint(t('nap'), 'nap', data.nap),
  ],
});

const sleepChartColors = {
  wake: { backgroundColor: '#db2777' },
  rem: { backgroundColor: '#38bdf8' },
  light: { backgroundColor: '#0ea5e9' },
  deep: { backgroundColor: '#0284c7' },
  nap: { backgroundColor: '#94a3b8' },
};

const RightAlignedCell = ({ children }: PropsWithChildren<any>) => <div className="text-right">{children}</div>;
const TimeCell = ({ value }: { value: number | string }) => {
  if (typeof value === 'string') {
    return <RightAlignedCell />;
  }

  const hours = Math.floor(value / 60);
  const minutes = Math.floor(value % 60);
  if (hours === 0 && minutes === 0) {
    return null;
  }
  return (
    <RightAlignedCell>
      {hours}:{minutes < 10 ? `0${minutes}` : minutes}
    </RightAlignedCell>
  );
};

// noinspection SuspiciousTypeOfGuard
export const getSleepMetricsTableColumns = (t: TFunction): Column<SleepTableRowData>[] => [
  {
    Header: t('dateHeader'),
    id: 'date',
    accessor: (row): Date => (typeof row.date === 'string' ? parseISO(row.date) : row.date),
    sortType: 'datetime',
    width: 130,
    Cell: ({ value }: { value: Date }) => <>{getFullYear(value)}</>,
  },
  {
    Header: () => <RightAlignedCell>{t('wakeHeader')}</RightAlignedCell>,
    accessor: 'wake',
    width: 'auto',
    Cell: ({ value }) => <TimeCell value={value} />,
  },
  {
    Header: () => <RightAlignedCell>{t('remHeader')}</RightAlignedCell>,
    accessor: 'rem',
    width: 'auto',
    Cell: ({ value }) => <TimeCell value={value} />,
  },
  {
    Header: () => <RightAlignedCell>{t('lightHeader')}</RightAlignedCell>,
    accessor: 'light',
    width: 'auto',
    Cell: ({ value }) => <TimeCell value={value} />,
  },
  {
    Header: () => <RightAlignedCell>{t('deepHeader')}</RightAlignedCell>,
    accessor: 'deep',
    width: 'auto',
    Cell: ({ value }) => <TimeCell value={value} />,
  },
  {
    Header: () => <RightAlignedCell>{t('napHeader')}</RightAlignedCell>,
    accessor: 'nap',
    width: 'auto',
    Cell: ({ value }) => <TimeCell value={value} />,
  },
];

export const getSleepData = (data: SleepMetricsResponse): SleepTableRowData[] =>
  getSleepDates(data).map(date => ({
    date,
    wake: data.wake.find(({ date }) => date.indexOf(date) !== -1)?.value || '',
    rem: data.rem.find(({ date }) => date.indexOf(date) !== -1)?.value || '',
    light: data.light.find(({ date }) => date.indexOf(date) !== -1)?.value || '',
    deep: data.deep.find(({ date }) => date.indexOf(date) !== -1)?.value || '',
    nap: data.nap.find(({ date }) => date.indexOf(date) !== -1)?.value || '',
  }));

export interface SleepTableRowData {
  date: Date;
  wake: number | '';
  rem: number | '';
  light: number | '';
  deep: number | '';
  nap: number | '';
}
