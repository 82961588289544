import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function H2({ children, className = '' }: PropsWithChildren<Props>) {
  return (
    <h2 className={`text-gray-900 dark:text-gray-300 text-xl leading-7 font-medium pb-6 ${className}`}>{children}</h2>
  );
}
