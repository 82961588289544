import { NullableType, PrimitiveType } from './Common';

type QuestionGroup = {
  id: number;
  name: string;
  totalQuestions: number;
  answeredQuestions: number;
};

export type Section = QuestionGroup & {
  imageUrl: string;
};
export type Scale = QuestionGroup & {
  description: string;
  isIncluded: boolean;
  score: NullableType<number>;
  scoreRange: ScoreRange;
};
type InjectedQuestionProps = {
  // groupId: number; // section or scale id
  handleAnswerSubmit: (value: { questionId: number; answer: any }) => void;
};
export type Question = InjectedQuestionProps & {
  id: number;
  sectionId: number;
  scaleIds: Array<number>;
  description: string | null;
  text: string;
  type: QuestionType;
  value: PrimitiveType | null;
  isAnswered: boolean;
  number: string;
  choices: Array<{
    id: number;
    isExclusive: boolean;
    isSelected: boolean;
    text?: string;
  }>;
  rows: [
    {
      id: number;
      text: string;
      description: string;
      choiceId: number;
      number: string;
    },
  ];
};

export type QuestionType =
  | 'ChoiceList'
  | 'ChoiceTable'
  | 'Section'
  | 'YesNo'
  | 'Number'
  | 'MultipleChoice'
  | 'Text'
  | 'TextArea';

export type PollResponse = {
  answeredQuestions: number;
  totalQuestions: number;
  date: Date;
  id: number;
  questions: Array<Question>;
  sections: Array<Section>;
  scales: Array<Scale>;
};

export type ScalesResponse = Array<Scale>;

export type AnswerQuestionRequest = {
  questionId: number;
  answer: object;
};

export type PollScalesRequest = {
  scalesIds: Array<number>;
};

export type ActiveTab = 'sections' | 'scales';

export enum ScoreRange {
  Neutral = 0,
  Normal,
  Warning,
  Danger,
}

export const ScoreRangeClass: Array<string> = [
  'bg-gray-100 text-gray-700', // Neutral
  'bg-green-poll bg-opacity-30 text-green-800', // Normal
  'bg-orange-100 text-orange-700', // Warning
  'bg-red-100 text-red-700', // Danger
];
