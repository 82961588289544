import { getFullYear } from '../../utils/date';
import { isFuture } from 'date-fns';
import add from 'date-fns/add';
import * as React from 'react';
import LeftRightPicker from '../common/LeftRightPicker';

interface IDatePicker {
  handleDateChange: (step: 1 | -1) => void;
  setDate?: (date: Date) => void;
  date: Date;
}

export const DatePicker: React.FunctionComponent<IDatePicker> = ({ handleDateChange, date, setDate }) => {
  return (
    <LeftRightPicker handleStepChange={handleDateChange} isNextVisible={!isFuture(add(date, { days: 1 }))}>
      {typeof setDate === 'function' ? (
        <input
          id="date-input"
          type={'date'}
          max={getFullYear(new Date())}
          value={getFullYear(date)}
          className="font-semibold border-0 p-0 m-0 focus:outline-none focus:shadow-none"
          onChange={e => setDate(new Date(e.target.value))}
          required={true}
        />
      ) : (
        getFullYear(date)
      )}
    </LeftRightPicker>
  );
};
