import DashboardLayout from '../../components/layouts/DashboardLayout';
import H1 from '../../components/typography/H1';
import { FaqSection } from './components/FaqSection';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const Faq = () => {
  const { t } = useTranslation('Faq');
  const questions = useMemo(
    () => [
      {
        question: t('faqSection.q1Question'),
        answer: t('faqSection.q1Answer'),
      },
      {
        question: t('faqSection.q2Question'),
        answer: t('faqSection.q2Answer'),
      },
    ],
    [t],
  );

  return (
    <DashboardLayout>
      <div className="pt-6">
        <div className="mb-12">
          <H1>{t('header')}</H1>
          <p className="text-gray-700">
            {t('subHeader')}{' '}
            <a href={`mailto:${t('inquiryEmail')}`} className="text-primary">
              {t('inquiryEmail')}
            </a>
          </p>
        </div>
        <FaqSection title="Chrodis paskyra" questions={questions} />
      </div>
    </DashboardLayout>
  );
};
