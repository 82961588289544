import * as React from 'react';
import { useMemo, useState } from 'react';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon, EllipsisVerticalIcon, FunnelIcon } from '@heroicons/react/24/solid';

import Confirmation from '../../Confirmation';
import SingleInputConfirmation from '../../SingleInputConfirmation';
import DropDownButton from '../../../buttons/DropDownButton';

import SuperSearchGlobalFilter from './SuperSearchGlobalFilter';
import SuperSearchTags from './SuperSearchTags';
import SuperSearchFilterPopover from './SuperSearchFilterPopover';
import SuperSearchTabs from './SuperSearchTabs';
import { SuperSearchFilters, SuperSearchSettings, SuperSearchTab } from './SuperSearchUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  tabs: SuperSearchTab[];
  activeTab: SuperSearchTab | null;
  filters: SuperSearchFilters[];
  setActiveTabId: (tabId: number) => void;
  setActiveFilters: (columnAccessor: string, values: (string | number)[]) => void;
  deactivateFilter: (columnAccessor: string, value: string | number) => void;

  onTabSaved: (name: string) => void;
  onTabRenamed: (name: string) => void;
  onTabDeleted: () => void;

  settings?: boolean | SuperSearchSettings;

  /** Global filter search text */
  globalFilter: string;
  /** Global filter's setter */
  setGlobalFilter: (predicate: string) => void;
}

const FilterOptionsButtonIcon = <EllipsisVerticalIcon className="w-4" />;

const defaultSettings: SuperSearchSettings = {
  hideSettings: false,
  hideFilters: false,
  hideGlobalFilter: false,
  globalFilterLabel: 'Ieškoti...',
};

export default function SuperSearch({
  tabs,
  activeTab,
  setActiveTabId,
  setActiveFilters,
  filters,
  onTabSaved,
  onTabRenamed,
  onTabDeleted,
  deactivateFilter,
  globalFilter,
  setGlobalFilter,
  settings,
}: Props) {
  const { t } = useTranslation('SuperSearch');
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const displaySettings = useMemo(
    () => (typeof settings === 'object' ? { ...defaultSettings, ...settings } : defaultSettings),
    [settings],
  );

  if (!activeTab) {
    return null;
  }

  const toggleSaveDialogOpen = () => setSaveDialogOpen(!saveDialogOpen);
  const toggleRenameDialogOpen = () => setRenameDialogOpen(!renameDialogOpen);
  const toggleDeleteDialogOpen = () => setDeleteDialogOpen(!deleteDialogOpen);

  const handleSaveConfirmation = (name: string) => {
    onTabSaved(name);
    toggleSaveDialogOpen();
  };

  const handleRenameConfirmation = (name: string) => {
    onTabRenamed(name);
    toggleRenameDialogOpen();
  };

  const handleDeleteConfirmation = () => {
    onTabDeleted();
    toggleDeleteDialogOpen();
  };

  const allFiltersHidden =
    displaySettings.hideSettings && displaySettings.hideFilters && displaySettings.hideGlobalFilter;

  return (
    <div
      className={classNames(
        'bg-gray-50 dark:bg-gray-700 rounded-tl-md rounded-tr-md',
        !allFiltersHidden && 'border-b dark:border-gray-600',
      )}
    >
      <SuperSearchTabs tabs={tabs} activeTabId={activeTab.id} onTabClicked={setActiveTabId} />

      {!allFiltersHidden && (
        <>
          <Popover className="flex align-middle pt-4 px-4">
            {!displaySettings.hideGlobalFilter && (
              <SuperSearchGlobalFilter
                className="flex-1"
                filter={globalFilter}
                setFilter={setGlobalFilter}
                label={displaySettings.globalFilterLabel}
              />
            )}

            {!displaySettings.hideFilters && (
              <Popover.Button className="ml-2 relative inline-flex items-center px-2 py-2 border p-2 text-sm font-medium focus:z-50 focus:outline-none rounded-md bg-white text-gray-700 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 border-gray-300 dark:bg-gray-600 dark:border-gray-500">
                <FunnelIcon className="w-5 h-5" />
                <ChevronDownIcon className={classNames('h-5 w-5 ml-2')} aria-hidden="true" />
              </Popover.Button>
            )}
            {!displaySettings.hideSettings && (
              <DropDownButton
                className="ml-2"
                type="secondary"
                label={FilterOptionsButtonIcon}
                labelAsDropdownTrigger={true}
                items={[
                  {
                    name: t('rename', { ns: 'common' }),
                    onClick: toggleRenameDialogOpen,
                    disabled: !activeTab.persisted,
                  },
                  {
                    name: t('save', { ns: 'common' }),
                    onClick: toggleSaveDialogOpen,
                    disabled: !activeTab.hasChanges,
                  },
                  {
                    name: t('delete', { ns: 'common' }),
                    onClick: toggleDeleteDialogOpen,
                    disabled: !activeTab.persisted,
                  },
                ]}
              />
            )}
            <Popover.Panel className="absolute right-4 top-28 shadow-lg rounded-lg bg-gray-50 dark:border-gray-600 border z-10">
              <SuperSearchFilterPopover setActiveFilters={setActiveFilters} filters={filters} />
            </Popover.Panel>
          </Popover>

          <SuperSearchTags className="mx-4 mt-2 mb-4" filters={filters} onDeleteClick={deactivateFilter} />
        </>
      )}

      <SingleInputConfirmation
        open={saveDialogOpen}
        value={activeTab.name?.toLowerCase() === 'visi' ? '' : activeTab.name}
        onCancel={toggleSaveDialogOpen}
        onSubmit={handleSaveConfirmation}
        labels={{
          title: t('saveFilter'),
          label: t('name'),
          yesLabel: t('save', { ns: 'common' }),
          noLabel: t('cancel', { ns: 'common' }),
        }}
      />

      <SingleInputConfirmation
        open={renameDialogOpen}
        value={activeTab.name}
        onCancel={toggleRenameDialogOpen}
        onSubmit={handleRenameConfirmation}
        labels={{
          title: t('renameFilter'),
          label: t('name'),
          yesLabel: t('save', { ns: 'common' }),
          noLabel: t('cancel', { ns: 'common' }),
        }}
      />

      <Confirmation
        id={Number(deleteDialogOpen)}
        open={deleteDialogOpen}
        handleCancel={toggleDeleteDialogOpen}
        handleSubmit={handleDeleteConfirmation}
        labels={{
          title: t('deleteFilter'),
          yesLabel: t('yes', { ns: 'common' }),
          noLabel: t('no', { ns: 'common' }),
        }}
      >
        <p className="text-sm">{t('deleteFilterInquiry')}</p>
      </Confirmation>
    </div>
  );
}
