import * as React from 'react';
import { Tab } from '@headlessui/react';
import DashboardLayout from '../../../../../components/layouts/DashboardLayout';
import Breadcrumbs, { BreadcrumbItem } from '../../../../../components/common/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { usePatient } from '../../../../../hooks/queries/usePatient';
import { classNames } from '../../../../../utils/classNames';
import GroupCard from '../../../../../components/poll/GroupCard';
import { usePoll } from '../../../../../hooks/queries/usePoll';
import { ActiveTab, Scale, Section } from '../../../../../contracts/Poll';
import { usePollQuestion } from '../../../../../hooks/mutations/usePollQuestion';
import { PERMISSIONS, usePermission } from '../../../../../hooks/usePermission';
import { getFullYear } from '../../../../../utils/date';
import PatientPollQuestions from './PatientPollQuestions';
import { ScalesDialog } from './ScalesDialog';
import Pagination from '../../../../../components/common/Table/Pagination';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../../../../translations/i18n';

export default function PatientPoll() {
  const { t } = useTranslation('PatientPoll');
  const [breadcrumbPages, setBreadcrumbPages] = React.useState<Array<BreadcrumbItem>>([]);
  const { patientId: patientIdString, pollId: pollIdString } = useParams<{ patientId: string; pollId: string }>();
  const [patientId, pollId] = [Number(patientIdString), Number(pollIdString)];
  const { data: patient } = usePatient(patientId);
  const [activeTab, setActiveTab] = React.useState<ActiveTab>('sections');
  const [activeGroup, setActiveGroup] = React.useState<Scale | Section | undefined>(undefined);
  const { data: poll, isLoading, isFetching } = usePoll(patientId, pollId, activeTab, activeGroup?.id);
  const submitAnswerMutation = usePollQuestion(patientId, pollId, activeTab, activeGroup?.id ?? poll?.sections[0].id);

  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  React.useEffect(() => {
    if (patient && canManagePatients) {
      setBreadcrumbPages([
        { name: t('patients', { ns: 'DashboardMainMenu' }), href: '/patients', current: false },
        {
          name: getLocalizedFullName(patient),
          href: `/patients/${patientId}`,
          current: false,
        },
      ]);
    }
  }, [t, patient, canManagePatients, patientId]);

  const currentGroupIndex = React.useMemo(() => {
    if (!poll) {
      return 0;
    }
    if (typeof activeGroup === 'undefined') {
      return 0;
    }
    return poll[activeTab].findIndex(group => group.id === activeGroup.id);
  }, [poll, activeGroup, activeTab]);

  function handleAnswerSubmit(requestBody: { questionId: number; answer: any }) {
    submitAnswerMutation.mutate(requestBody);
  }

  function handleNextPrevClick(step: -1 | 1) {
    if (!poll) {
      return;
    }
    if (activeGroup) {
      const currentSectionIndex = poll[activeTab].findIndex(group => group.id === activeGroup?.id);
      if (currentSectionIndex !== -1) {
        setActiveGroup(poll[activeTab][currentSectionIndex + step]);
      }
    } else {
      setActiveGroup(poll[activeTab][1]);
    }
  }

  return (
    <DashboardLayout isLoading={!poll && isLoading}>
      {poll ? (
        <div>
          <Breadcrumbs
            pages={[
              ...breadcrumbPages,
              {
                name: t('pollsBreadcrumb'),
                href: `/patients/${patientId}?tab=poll`,
                current: false,
              },
              {
                name: getFullYear(poll.date),
                current: true,
              },
            ]}
          />
          <div className="flex">
            <div className="w-full sm:w-1/4">
              <div className="sticky top-0">
                <Tab.Group
                  onChange={index => {
                    setActiveTab(index === 0 ? 'sections' : 'scales');
                    setActiveGroup(index === 0 ? poll.sections[0] : poll.scales[0]);
                  }}
                >
                  <Tab.List
                    className={`space-x-3 border-b border-gray-300 mb-8
                                        ${!canManagePatients ? 'hidden' : ''}
                                    `}
                  >
                    <Tab
                      key="sections"
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-primary text-primary'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-primary',
                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
                        )
                      }
                    >
                      {t('forPatient')}
                    </Tab>

                    <Tab
                      key="scales"
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-primary text-primary'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-primary',
                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
                        )
                      }
                    >
                      {t('forDoctor')}
                    </Tab>
                    {activeTab === 'scales' ? <ScalesDialog pollId={pollId} patientId={patientId} /> : null}
                  </Tab.List>
                  <Tab.Panels className="w-full">
                    <Tab.Panel key="sections">
                      {poll.sections.map((section, index: number) => (
                        <GroupCard
                          progress={(section.answeredQuestions * 100) / section.totalQuestions}
                          key={section.id}
                          title={section.name}
                          questionsProgress={`${section.answeredQuestions} / ${section.totalQuestions}`}
                          handleClick={() => setActiveGroup(section)}
                          isActive={
                            activeGroup?.id === section.id || (typeof activeGroup === 'undefined' && index === 0)
                          }
                        />
                      ))}
                    </Tab.Panel>
                    <Tab.Panel key="scales">
                      {poll.scales
                        .filter(scale => scale.isIncluded)
                        .map(scale => (
                          <GroupCard
                            key={scale.id}
                            progress={(scale.answeredQuestions * 100) / scale.totalQuestions}
                            questionsProgress={`${scale.answeredQuestions} / ${scale.totalQuestions}`}
                            score={{
                              score: scale.score,
                              range: scale.scoreRange,
                            }}
                            title={scale.name}
                            description={scale.description}
                            handleClick={() => setActiveGroup(scale)}
                            isActive={activeGroup?.id === scale.id}
                          />
                        ))}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
            <div className="w-full pb-8 sm:w-3/4 sm:ml-10">
              {!isFetching && (
                <PatientPollQuestions
                  poll={poll}
                  activeTab={activeTab}
                  activeGroup={activeGroup ?? poll.sections[0]}
                  handleAnswerSubmit={handleAnswerSubmit}
                />
              )}
              <Pagination
                previousPage={currentGroupIndex > 0 ? () => handleNextPrevClick(-1) : undefined}
                nextPage={currentGroupIndex + 1 < poll[activeTab].length ? () => handleNextPrevClick(1) : undefined}
                gotoPage={groupIndex => {
                  if (typeof groupIndex === 'number') {
                    setActiveGroup(poll[activeTab][groupIndex]);
                  }
                }}
                pageIndex={currentGroupIndex}
                pageCount={poll[activeTab].length}
                previousText={currentGroupIndex > 0 ? poll[activeTab][currentGroupIndex - 1].name : undefined}
                nextText={
                  currentGroupIndex + 1 < poll[activeTab].length
                    ? poll[activeTab][currentGroupIndex + 1].name
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      ) : null}
    </DashboardLayout>
  );
}
