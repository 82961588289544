import * as React from 'react';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { TextInput } from '../form/TextInput';
import Dialog from '../dialog/Dialog';
import DialogForm from '../layouts/dialog-form/DialogForm';
import { TextArea } from '../form/TextArea';

interface Props {
  open: boolean;
  value: string;
  onCancel: () => void;
  onSubmit: (value: string) => void;
  type?: 'text' | 'textarea';
  labels: {
    title: string;
    label: string;
    yesLabel?: string;
    noLabel?: string;
  };
}

export default function SingleInputConfirmation({ onSubmit, onCancel, value, open, type, labels }: Props) {
  const validator = useMemo(() => Yup.object().shape({ value: Yup.string().required() }), []);

  return (
    <Dialog open={open} handleCloseClick={onCancel} widthAuto={true}>
      <DialogForm
        title={labels.title}
        initialValues={{ value }}
        onSubmit={({ value }) => onSubmit(value)}
        onCancel={onCancel}
        validationSchema={validator}
        labels={{ submit: labels.yesLabel, cancel: labels.noLabel }}
      >
        <div className="sm:flex sm:items-start">
          <div className="flex flex-col space-y-6 w-full">
            <div className="flex space-x-10 w-full">
              <Field component={type !== 'textarea' ? TextInput : TextArea} name="value" label={labels.label} />
            </div>
          </div>
        </div>
      </DialogForm>
    </Dialog>
  );
}
