import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientHcp, PatientHcpResponse } from '../../contracts/Patient';

type PatientHcpUpdateRequest = {
  patientId: number;
  sectionId: number;
  text: string;
};

export function usePatientHcpUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientHcp>();
  return useMutation<PatientHcp, unknown, PatientHcpUpdateRequest>(
    ({ patientId, sectionId, ...data }) =>
      request(`/patients/${patientId}/plan/sections/${sectionId}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData<PatientHcpResponse | undefined>(['patient-hcp', request.patientId], oldData => {
          if (oldData) {
            return {
              isComplete: oldData.isComplete,
              sections: oldData.sections.map(section => (section.id === data.id ? { ...data } : { ...section })),
            };
          } else {
            return undefined;
          }
        });
      },
    },
  );
}
