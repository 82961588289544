import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export function useRequest<RequestResponse>() {
  const { getAccessTokenSilently } = useAuth0();
  const { i18n } = useTranslation();

  function requestFn(
    url: string,
    options: RequestInit = {},
    customHandler?: (res: Response) => boolean,
  ): Promise<RequestResponse> {
    return (async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      const init: RequestInit = {
        ...options,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Ui-Language': i18n.language,
          ...(typeof options.body === 'string' ? { 'Content-Type': 'application/json' } : {}),
          ...(options.headers || {}),
        },
      };

      const res = await fetch(`${process.env.REACT_APP_TEST_API_CALL_URL}${url}`, init);
      if (res.status === 204) {
        return new Promise(resolve => resolve({} as RequestResponse));
      }

      if (typeof customHandler === 'function') {
        const terminate = customHandler(res);
        if (terminate) {
          return;
        }
      }

      if (!res.ok) {
        const errorMessage = await res.text();
        throw new Error(errorMessage);
      }

      const responseContentType = res.headers.get('content-type');
      if (responseContentType && responseContentType.toLowerCase().includes('json')) {
        return res.json();
      } else {
        return res.blob();
      }
    })();
  }
  return requestFn;
}
