import * as React from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlTick from '../common/HtmlTick';

export default function ChoiceTableQuestion(props: Question) {
  const [selected, setSelected] = React.useState<{ [k: number]: number }>({});

  React.useEffect(() => {
    let initState: any = {};
    props.rows.forEach(row => {
      initState[row.id] = row.choiceId;
    });
    setSelected({
      ...initState,
    });
  }, [props.rows]);

  function handleChange(questionId: number, value: number) {
    setSelected({
      ...selected,
      [questionId]: value,
    });
    props.handleAnswerSubmit({
      questionId,
      answer: { value: [value] },
    });
  }

  function isSelected(questionId: number, choiceId: number): boolean {
    return selected[questionId] === choiceId;
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <table className="w-full mt-2">
        <thead>
          <tr className="text-sm text-black align-bottom">
            <th className="py-2 text-left font-medium">{props.description}</th>
            {props.choices.map(choice => {
              return (
                <th className="w-24 px-1 py-2 font-medium" key={choice.id}>
                  {choice.text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.rows.map(row => (
            <tr key={row.id}>
              <td className="p-2 pl-0 text-sm font-normal text-gray-700 border-t">
                {row.text}
                {row.description ? <p className="text-sm mt-1 text-gray-700">{row.description}</p> : ''}
              </td>
              {props.choices.map(choice => (
                <td key={choice.id} className="border-t">
                  <HtmlTick
                    id={`${row.id}`}
                    value={choice.id}
                    isSelected={isSelected(row.id, choice.id)}
                    handleChange={choiceId => handleChange(row.id, choiceId as number)}
                    type="radio"
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
