import * as React from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlTick from '../common/HtmlTick';
import { useCallback } from 'react';
import { debounce } from '../../../utils/debounce';
import QuestionDescription from '../common/QuestionDescription';

export default function SingleChoiceQuestion(props: Question) {
  const [selected, setSelected] = React.useState<number | null>(null);

  React.useEffect(() => {
    const selectedChoice = props.choices.find(choice => choice.isSelected);
    if (selectedChoice && selected !== selectedChoice.id) {
      setSelected(selectedChoice.id);
    }
  }, [props.choices, selected]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeApi = useCallback(debounce(props.handleAnswerSubmit), []);

  function handleChange(questionId: number, value: number) {
    setSelected(value);
    handleChangeApi({
      questionId,
      answer: { value: [value] },
    });
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <QuestionDescription>{props.description}</QuestionDescription>
      <div className="mt-3 space-y-3">
        {props.choices.map(choice => {
          return (
            <HtmlTick
              id={`${choice.id}`}
              type={'radio'}
              value={choice.id}
              isSelected={selected === choice.id}
              handleChange={choiceId => handleChange(props.id, choiceId as number)}
              label={choice.text}
              key={choice.id}
            />
          );
        })}
      </div>
    </div>
  );
}
