import { useQuery } from '@tanstack/react-query';

import { FiltersGetResponse } from '../../contracts/Filters';
import { useRequest } from '../useRequest';

export const useFiltersQuery = (table?: string) => {
  const request = useRequest<FiltersGetResponse>();
  return useQuery<unknown, unknown, FiltersGetResponse>(['filters-table', table], () =>
    // Table name may not be provided, hence query conditionally
    table ? request(`/filters/${table}`) : null,
  );
};
