import Button from '../../../components/buttons/Button';
import React from 'react';
import Dialog from '../../../components/dialog/Dialog';
import { DoctorConsultationForm } from './DoctorConsultationForm';
import { usePatientThreadsCreate } from '../../../hooks/mutations/thread/usePatientThreadsCreate';
import { successToast } from '../../../utils/successToast';
import { DialogFormContainer } from '../../../components/layouts/dialog-form/DialogFormContainer';
import { PatientMiniCard } from '../../../components/patient/PatientMiniCard';
import { Descendant } from 'slate';
import {useTranslation} from "react-i18next";

interface Props {
  patientId: number;
  clinicId: number;
}

export const DoctorConsultationPopup = ({ patientId, clinicId }: Props) => {
    const {t} = useTranslation('DoctorConsultationPopup')
  const [isOpen, setOpen] = React.useState(false);
  const patientThreadsCreateMutation = usePatientThreadsCreate();

  function handlePatientThread(values: { assigneeId: number; text: Descendant[] }) {
    patientThreadsCreateMutation.mutate(
      {
        ...values,
        patientId,
        text: JSON.stringify(values.text),
      },
      {
        onSuccess: () => {
          setOpen(false);
          successToast(t('consultationRequestSent'));
        },
      },
    );
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('consultLabel')}</Button>
      <Dialog open={isOpen} handleCloseClick={() => setOpen(false)} className="w-3/4">
        <DialogFormContainer title={t('dialogTitle')}>
          <PatientMiniCard patientId={patientId} />
          <DoctorConsultationForm
            clinicId={clinicId}
            handleCancel={() => setOpen(false)}
            handleSave={handlePatientThread}
            isLoading={patientThreadsCreateMutation.isLoading}
          />
        </DialogFormContainer>
      </Dialog>
    </>
  );
};
