import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { CreatePatientsThreadRequest } from '../../../contracts/Threads';

export function usePatientThreadsCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<unknown>();
  return useMutation<unknown, unknown, CreatePatientsThreadRequest>(
    ({ patientId, ...data }) =>
      request(`/patients/${patientId}/threads`, {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: (_, { patientId }) => {
        queryClient.invalidateQueries(['threads-me']);
        queryClient.invalidateQueries(['patient-threads', patientId]);
      },
    },
  );
}
