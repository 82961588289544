export default function PaginationButton({
  children,
  handleClick,
  withBorder = true,
}: {
  children: Array<JSX.Element | string> | JSX.Element;
  handleClick: () => void;
  withBorder?: boolean;
}) {
  let cx = 'pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none';
  if (withBorder) {
    cx += ' pt-4 border-t-2 border-transparent hover:border-gray-300 ';
  }
  return (
    <button onClick={handleClick} type="button" className={cx}>
      {children}
    </button>
  );
}
