import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';
import { createEditor, Descendant } from 'slate';
import { Slate, Editable, withReact, RenderLeafProps } from 'slate-react';
import { BlockButton, ClearButton, Leaf, MarkButton, RichTextElement } from '../richText/richTextUtils';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import { useTranslation } from 'react-i18next';

interface IRichTextInput {
  label: string;
  description?: string;
  rows?: number;
  placeholder?: string;
}

export const RichTextInput: React.FunctionComponent<FieldProps & IRichTextInput> = ({
  field,
  form,
  rows = 3,
  ...props
}) => {
  const { t } = useTranslation('RichTextInput');
  const initialValue: Descendant[] = field.value;
  const [value, setValue] = React.useState<Descendant[]>(initialValue);
  const renderElement = React.useCallback((props: RenderElementProps) => <RichTextElement {...props} />, []);
  const renderLeaf = React.useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const editor = React.useMemo(() => withReact(createEditor()), []);
  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <Slate
        editor={editor}
        value={value}
        onChange={value => {
          setValue(value);
          form.setFieldValue(field.name, value);
        }}
      >
        <div className={'space-x-0.5 flex'}>
          <BlockButton format="h1">{t('heading1')}</BlockButton>
          <BlockButton format="h2">{t('heading2')}</BlockButton>
          <ClearButton>{t('normal')}</ClearButton>
          <MarkButton format="bold">
            <img src={'/images/editor/bold.svg'} alt={t('boldAlt')} className={'h-4 w-4'} />
          </MarkButton>
          <MarkButton format="italic">
            <img src={'/images/editor/italic.svg'} alt={t('italicAlt')} className={'h-4 w-4'} />
          </MarkButton>
          <MarkButton format="underline">
            <img src={'/images/editor/underline.svg'} alt={t('underlineAlt')} className={'h-4 w-4'} />
          </MarkButton>
          <BlockButton format="numbered-list">
            <img src={'/images/editor/list-ol.svg'} alt={t('numberedListAlt')} className={'h-4 w-4'} />
          </BlockButton>
          <BlockButton format="bulleted-list">
            <img src={'/images/editor/list.svg'} alt={t('bulletedListAlt')} className={'h-4 w-4'} />
          </BlockButton>
        </div>
        <Editable
          placeholder={props.placeholder ?? ''}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          id={field.name}
          name={field.name}
          style={{ height: '500px', maxHeight: '40vh' }}
          className="mt-1 p-3 shadow-sm focus:ring-blue-poll focus:border-blue-poll block w-full sm:text-sm border border-gray-300 rounded-md overflow-y-auto overflow-x-hidden dark:text-gray-300"
        />
      </Slate>
    </BaseInput>
  );
};
