import * as React from 'react';
import { FieldProps } from 'formik';
import { Switch } from './Switch';

export function FormikSwitch({ field, form }: FieldProps) {
  return (
    <Switch
      checked={field.value.value}
      onChange={value => {
        form.setFieldValue(field.name, {
          id: field.value.id,
          value,
        });
      }}
    />
  );
}
