import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AlertType } from '../../contracts/Alert';

export type AlertsRequest = {
  alertIds: Array<number>;
  comment: string;
};

export function useAlertsUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<Array<AlertType>>();
  return useMutation<Array<AlertType>, unknown, AlertsRequest>(
    body =>
      request(`/alerts`, {
        body: JSON.stringify(body),
        method: 'PUT',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['alerts']);
      },
    },
  );
}
