import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { usePatientEcgPageData } from '../hooks/usePatientEcgPageData';
import { getDateWithoutTime } from '../../../../../../utils/date';
import Breadcrumbs from '../../../../../../components/common/Breadcrumbs';
import LoadingContainer from '../../../../../../components/layouts/LoadingContainer';
import DashboardLayout from '../../../../../../components/layouts/DashboardLayout';
import classNames from 'classnames';
import { getLocalizedFullName } from '../../../../../../translations/i18n';
import { useTranslation } from 'react-i18next';

export default function PatientEcgLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation('PatientEcgLayout');
  const { patient, ecgRecord, isLoading } = usePatientEcgPageData();

  const breadcrumbPages = useMemo(() => {
    if (!patient || !patient) {
      return [];
    }

    return [
      { name: t('breadcrumbPatients'), href: '/patients', current: false },
      { name: getLocalizedFullName(patient), href: `/patients/${patient?.id}`, current: false },
      { name: t('breadcrumbAnalyses'), href: `/patients/${patient.id}?tab=analyses`, current: false },
      { name: t('breadcrumbECG'), current: false },
      { name: ecgRecord?.startDate ? getDateWithoutTime(ecgRecord.startDate) : '', current: true },
    ];
  }, [patient, ecgRecord, t]);

  const links = [
    { title: t('linkDashboard'), link: `/patients/${patient?.id}/ecg/${ecgRecord?.id}/dashboard` },
    { title: t('linkAccidents'), link: `/patients/${patient?.id}/ecg/${ecgRecord?.id}/accidents` },
    { title: t('linkViewer'), link: `/patients/${patient?.id}/ecg/${ecgRecord?.id}/viewer` },
    { title: t('linkIntervals'), link: `/patients/${patient?.id}/ecg/${ecgRecord?.id}/intervals` },
  ];

  return (
    <DashboardLayout>
      {breadcrumbPages && <Breadcrumbs pages={breadcrumbPages} />}
      <LoadingContainer loading={isLoading}>
        <div className="border-b border-gray-200 mb-8">
          <div className="sm:flex sm:items-baseline">
            <div className="mt-4 sm:mt-0">
              <nav className="-mb-px flex space-x-8">
                {links.map(({ title, link }) => (
                  <NavLink
                    key={link}
                    to={link}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'border-primary text-primary'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-2 mr-2 border-b-2 font-medium text-sm',
                      )
                    }
                  >
                    {title}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {children}
      </LoadingContainer>
    </DashboardLayout>
  );
}
