import CircularButton from '../../buttons/CircularButton';
import Pagination from './Pagination';
import * as React from 'react';

interface Props {
  handleAddClick?: () => void;
  pageCount: number;
  pageIndex: number;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
}

export default function TableFooter({ handleAddClick, pageCount, nextPage, previousPage, pageIndex, gotoPage }: Props) {
  return (
    <>
      {typeof handleAddClick === 'function' && (
        <div className={'text-right pr-5 -mt-3 self-end'}>
          <CircularButton onClick={handleAddClick} />
        </div>
      )}

      {pageCount > 1 && (
        <div className={'pt-10'}>
          <Pagination
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </div>
      )}
    </>
  );
}
