import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function H1({ children, className = '' }: PropsWithChildren<Props>) {
  return (
    <h1 className={`text-gray-900 dark:text-gray-300 text-3xl leading-7 font-semibold pb-4 ${className}`}>
      {children}
    </h1>
  );
}
