import format from 'date-fns/format';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import { EcgEvent, EcgEventGroup, getHoursOrder } from '../../utils/types';

interface Props {
  activeGroup: EcgEventGroup;
  activeTimestamp: number;
  isUpdating: boolean;
  onActiveTimestampChanged: (timestamp: number) => void;
  onDeleteClicked: (event: EcgEvent) => void;
}

export default function EcgAccidentsNav({ activeGroup, activeTimestamp, isUpdating, ...handlers }: Props) {
  const activeIndex = activeGroup.events.findIndex(t => t.timestamp === activeTimestamp) ?? 0;
  const activeEvent = activeGroup.events[activeIndex];
  const isPrevDisabled = isUpdating || activeIndex === 0;
  const isNextDisabled = isUpdating || activeIndex + 1 === activeGroup.events.length;

  const setPrevTimestamp = () => {
    if (isPrevDisabled) {
      return;
    }
    const newTimestamp = activeGroup.events[activeIndex - 1].timestamp;
    handlers.onActiveTimestampChanged(newTimestamp);
  };

  const setNextTimestamp = () => {
    if (isNextDisabled) {
      return;
    }
    const newTimestamp = activeGroup.events[activeIndex + 1].timestamp;
    handlers.onActiveTimestampChanged(newTimestamp);
  };

  const eventsGroupedByHour = useMemo(
    () =>
      activeGroup.events
        .map(({ timestamp }) => ({
          value: timestamp,
          parsed: format(new Date(timestamp), 'HH:mm:ss'),
          group: `${format(new Date(timestamp), 'HH')}`,
        }))
        .reduce((previousValue, currentValue) => {
          const val = previousValue?.[currentValue.group];
          if (val) {
            previousValue[currentValue.group].push(currentValue);
          } else {
            previousValue[currentValue.group] = [currentValue];
          }
          return previousValue;
        }, {} as { [key: string]: { value: number; parsed: string; group: string }[] }),
    [activeGroup],
  );

  return (
    <div>
      <div className="flex justify-center">
        <div className={'flex justify-center shadow-sm'}>
          <button
            type={'button'}
            className={classNames(
              'border-t border-l border-b rounded-tl-md rounded-bl-md px-2 border-gray-300',
              isUpdating ? 'cursor-default bg-gray-100 text-gray-300' : 'hover:bg-gray-50 cursor-pointer',
            )}
            onClick={setPrevTimestamp}
            disabled={isPrevDisabled}
          >
            <ChevronLeftIcon className={'w-5 h-5'} />
          </button>

          <Popover className="relative">
            <Popover.Button
              disabled={isUpdating}
              className={classNames(
                'border-t border-b w-24 py-2 px-4 text-sm border-gray-300',
                isUpdating ? 'cursor-default bg-gray-100 text-gray-300' : 'hover:bg-gray-50 cursor-pointer',
              )}
            >
              {format(new Date(activeTimestamp), 'HH:mm:ss')}
            </Popover.Button>

            <Popover.Panel className="absolute z-10">
              <div className="bg-white p-4 rounded-md border mt-2 max-h-96 overflow-y-auto w-[64rem] -ml-[18rem]">
                {getHoursOrder(activeGroup.events[0].timestamp)
                  .filter(v => Object.keys(eventsGroupedByHour).includes(v))
                  .map(groupName => (
                    <div key={groupName}>
                      <div className={'pl-2 mb-4 pb-2 border-b text-sm font-semibold'}>
                        {groupName}
                        <span
                          className={
                            'px-3 ml-4 py-1 inline-flex rounded-full justify-center text-xs tracking-widest font-semibold bg-gray-100 cursor-default'
                          }
                        >
                          {eventsGroupedByHour[groupName]?.length ?? 0}
                        </span>
                      </div>
                      <div className={'grid grid-cols-8 mb-8'}>
                        {eventsGroupedByHour[groupName].map((t: any) => (
                          <Popover.Button
                            as={'button'}
                            key={t.value}
                            type={'button'}
                            onClick={() => handlers.onActiveTimestampChanged(t.value)}
                            className={'py-2'}
                          >
                            <span
                              className={classNames(
                                'px-2 py-1 shadow rounded-md inline-flex justify-center text-xs tracking-widest font-semibold',
                                t.value === activeTimestamp ? 'bg-primary text-white' : 'text-gray-700',
                              )}
                            >
                              {t.parsed}
                            </span>
                          </Popover.Button>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </Popover>

          <button
            type={'button'}
            onClick={setNextTimestamp}
            disabled={isNextDisabled}
            className={classNames(
              'border-t border-r border-b rounded-tr-md rounded-br-md px-2 border-gray-300',
              isUpdating ? 'cursor-default bg-gray-100 text-gray-300' : 'hover:bg-gray-50 cursor-pointer',
            )}
          >
            <ChevronRightIcon className={'w-5 h-5'} />
          </button>
        </div>

        <div className={'ml-4 flex items-center'}>
          <div
            className={
              'w-48 text-center border-l border-t border-b rounded-tl rounded-bl px-4 py-2 text-sm border-gray-300 overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {activeEvent.name}
          </div>

          <button
            className={classNames(
              'border rounded-tr rounded-br text-sm px-4 py-2 border-gray-300',
              !isUpdating && (activeEvent.isDeleted ? 'bg-primary text-white' : 'hover:bg-gray-50 text-gray-600'),
              isUpdating && 'cursor-default bg-gray-100 text-gray-300',
            )}
            onClick={() => (isUpdating ? () => {} : handlers.onDeleteClicked(activeEvent))}
          >
            <TrashIcon className={'w-5 h-5'} />
          </button>
        </div>
      </div>
    </div>
  );
}
