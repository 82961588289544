import React from 'react';
import { Listbox } from '@headlessui/react';
interface IListboxItem {
  value: number | string;
  children: JSX.Element;
}

export const ListboxItem = (props: IListboxItem) => (
  <Listbox.Option
    key={props.value}
    className={({ active }) => {
      return `${active ? 'text-white bg-primary' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9`;
    }}
    value={props.value}
  >
    {({ selected, active }) =>
      // "{ selected, active } as any" is a hack - typescript throws "select does not exist" errors otherwise
      React.isValidElement(props.children)
        ? React.cloneElement(props.children, { selected, active } as any)
        : props.children
    }
  </Listbox.Option>
);
