import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ActiveTab, AnswerQuestionRequest, PollResponse } from '../../contracts/Poll';

export function usePollQuestion(patientId: number, pollId: number, activeTab: ActiveTab, activeGroupId?: number) {
  const queryClient = useQueryClient();
  const request = useRequest<PollResponse>();
  return useMutation<PollResponse, unknown, AnswerQuestionRequest>(
    ['poll', pollId, 'questions'],
    ({ questionId, answer }) =>
      request(`/patients/${patientId}/polls/${pollId}/${activeTab}/${activeGroupId}/questions/${questionId}`, {
        body: JSON.stringify(answer),
        method: 'PUT',
      }),
    {
      onSuccess: data => {
        if (queryClient.isMutating({ mutationKey: ['poll', pollId, 'questions'] }) <= 1) {
          queryClient.setQueriesData(['polls', pollId, activeTab], data);
        }
      },
    },
  );
}
