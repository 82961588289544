import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientPoll } from '../../contracts/Patient';

interface IPollDelete {
  patientId: number;
  pollId: number;
}

export function usePatientPollDelete() {
  const queryClient = useQueryClient();
  const request = useRequest<{}>();
  return useMutation<{}, unknown, IPollDelete>(
    ({ patientId, pollId }) =>
      request(`/patients/${patientId}/polls/${pollId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, request) => {
        queryClient.setQueryData(['patient-polls', request.patientId], (oldData: Array<PatientPoll> = []) =>
          oldData.filter(poll => poll.id !== request.pollId),
        );
      },
    },
  );
}
