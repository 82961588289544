import React, { useCallback, useMemo } from 'react';
import { EcgData, EcgRecordPeakData } from '../../../../../../../contracts/Ecg';
import EcgChart from '../../../../../../../components/common/charts/EcgChart';
import { useEcgChunkSections } from '../../hooks/useEcgChunkSections';
import { AnalysisMode } from '../../utils/types';
import { ChartDataset } from 'chart.js';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { getResolutionAdjustedXValueForSpecialAxis } from '../../utils/utils';

interface Props {
  alignedChunk: EcgData | null;
  denoisedChunk: EcgData | null;
  rawChunk: EcgData | null;

  minute: number;
  second: number;
  secondsPerPage: number;
  peaksData: EcgRecordPeakData[];
  startTime: number;
  analysesOverlayModes: AnalysisMode[];
  highlightValues?: number[];
  isCommentMode: boolean;
  yZoom: number;
  onYZoomChanged: (modifier: number) => void;
  onChartClicked: (timestamp: number) => void;
}

export default function EcgChunkViewer({
  alignedChunk,
  denoisedChunk,
  rawChunk,
  peaksData,
  minute,
  second,
  secondsPerPage,
  startTime,
  highlightValues,
  isCommentMode,
  yZoom,
  onYZoomChanged,
  onChartClicked,
}: Props) {
  const datasetStartTime = useMemo(() => startTime + (minute * 60 + second) * 1000, [startTime, minute, second]);
  const { alignedSignal, rawSignal, deNoisedSignal, peaksSignal } = useEcgChunkSections(
    alignedChunk,
    denoisedChunk,
    rawChunk,
    minute,
    second,
    secondsPerPage,
    peaksData,
    startTime,
  );

  const highlightsDataset = useMemo<ChartDataset<'line'>>(
    () => ({
      label: '',
      data: highlightValues?.length
        ? highlightValues.map(v => ({
            x: getResolutionAdjustedXValueForSpecialAxis(secondsPerPage, datasetStartTime, v),
            y: 0,
          }))
        : [],
      type: 'VerticalLineChartType' as 'line',
      xAxisID: 'x2',
    }),
    [highlightValues, secondsPerPage, datasetStartTime],
  );

  const handleChartClicked = useCallback(
    (x: number) => {
      if (onChartClicked) {
        onChartClicked(Math.floor(datasetStartTime + (Number(x) / 128) * 1000));
      }
    },
    [datasetStartTime, onChartClicked],
  );

  return (
    <div className={'relative'}>
      <div className={'relative w-full group'}>
        <EcgChart
          dataSets={[alignedSignal, deNoisedSignal, rawSignal, peaksSignal, highlightsDataset]}
          startTime={datasetStartTime}
          secondsPerPage={secondsPerPage}
          commentMode={isCommentMode}
          onClick={handleChartClicked}
          yZoom={yZoom}
        />
      </div>

      <div className={'flex justify-center items-center absolute bottom-8 right-2'}>
        {yZoom !== 1 && <div className={'text-xs mr-2'}>y: x{yZoom.toFixed(1)}</div>}
        <div className={'flex flex-col'}>
          <button
            className={'rounded-full shadow-md border p-1 cursor-pointer bg-white mb-2 hover:bg-gray-100'}
            onClick={() => onYZoomChanged(-0.1)}
          >
            <PlusIcon className={'w-2 h-2'} />
          </button>

          <button
            className={'rounded-full shadow-md border p-1 cursor-pointer bg-white hover:bg-gray-100'}
            onClick={() => onYZoomChanged(0.1)}
          >
            <MinusIcon className={'w-2 h-2'} />
          </button>
        </div>
      </div>
    </div>
  );
}
