import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { GadgetType } from '../../contracts/Gadget';

export function useGadgets() {
  const request = useRequest<Array<GadgetType>>();
  return useQuery<unknown, unknown, Array<GadgetType>>(['gadgets'], () => request('/gadgets'), {
    staleTime: 1000 * 30,
  });
}
