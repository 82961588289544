import * as React from 'react';
import { useParams } from 'react-router-dom';
import add from 'date-fns/add';
import { useMetrics } from '../../../../../hooks/queries/useMetrics';
import Loading from '../../../../../components/common/Loading';
import { MetricPanel } from '../../../../../components/patient/MetricPanel';
import { DatePicker } from '../../../../../components/patient/DatePicker';
import { usePatient } from '../../../../../hooks/queries/usePatient';
import LastSync from '../../../../../components/common/LastSync';
import { MetricsHistory } from './MetricsHistory';

interface IPatientMainTab {}

export const PatientMetricsTab: React.FunctionComponent<IPatientMainTab> = () => {
  const [date, setDate] = React.useState<Date>(new Date());
  const { id: patientId } = useParams<{ id: string }>();
  const { data, isLoading } = useMetrics(Number(patientId), date);
  const { data: patient } = usePatient(Number(patientId));

  function handleDateChange(step: 1 | -1) {
    setDate(
      add(date, {
        days: step,
      }),
    );
  }

  return (
    <>
      <div className="flex justify-between mb-8">
        <DatePicker handleDateChange={handleDateChange} setDate={setDate} date={date} />
        <LastSync>{patient?.lastSync}</LastSync>
      </div>
      {data && data?.metrics?.length > 0 && !isLoading && (
        <div className={'grid grid-cols-3 gap-x-8 gap-y-6 mb-8'}>
          {data?.metrics.map((item, index) => (
            <MetricPanel {...item} key={index} />
          ))}
        </div>
      )}
      {!data && isLoading && (
        <div className="w-full flex justify-center">
          <Loading />
        </div>
      )}
      <MetricsHistory patientId={Number(patientId)} date={date} />
    </>
  );
};
