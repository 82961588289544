import classNames from 'classnames';

export type ButtonType = 'primary' | 'secondary';

export const getButtonClassNames = (buttonType: ButtonType) =>
  classNames(
    'w-full relative inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium',
    'focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
    {
      'border-transparent bg-primary text-white hover:bg-pink-700 focus:ring-indigo-500': buttonType === 'primary',
      'border-gray-300 dark:border-gray-500 bg-white dark:bg-gray-600 text-gray-700 dark:text-gray-400 hover:text-gray-500 focus:ring-primary':
        buttonType === 'secondary',
    },
  );

export const disabledButtonClassNames =
  'bg-gray-200 border-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-600 cursor-default';

export const getButtonSpinnerClassNames = (buttonType: ButtonType) =>
  classNames('absolute z-10 w-full top-0 rounded-md bottom-0 flex items-center justify-center bg-opacity-80', {
    'bg-primary': buttonType === 'primary',
    'bg-white': buttonType === 'secondary',
  });
