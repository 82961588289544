import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AnalysisGroupType } from '../../contracts/Analysis';

export function useAnalyses() {
  const request = useRequest<Array<AnalysisGroupType>>();
  return useQuery<unknown, unknown, Array<AnalysisGroupType>>(['analyses'], () => request('/analyses/parameters'), {
    staleTime: 10 * 60 * 1000,
  });
}
