import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { CellProps, Column, Row } from 'react-table';
import Table from '../../../../../components/common/Table/Table';
import { usePatientAnalyses } from '../../../../../hooks/queries/usePatientAnalyses';
import { TableTitle } from '../../../../../components/common/TableTitle';
import LeftRightPicker from '../../../../../components/common/LeftRightPicker';
import { getFullYear } from '../../../../../utils/date';
import { AnalysisType, PatientAnalysesParameter } from '../../../../../contracts/Patient';
import Dialog from '../../../../../components/dialog/Dialog';
import { AddPatientAnalysisForm, AnalysisFormValues } from './AddPatientAnalysisForm';
import { usePatientAnalysis } from '../../../../../hooks/mutations/usePatientAnalysis';
import { successToast } from '../../../../../utils/successToast';
import { ParametersDialog } from './ParametersDialog';
import { PERMISSIONS, usePermission } from '../../../../../hooks/usePermission';
import { NullableType } from '../../../../../contracts/Common';
import { PatientDocuments } from '../documents/PatientDocuments';
import { DocumentTypeEnum } from '../../../../../contracts/Document';
import LineChart from '../../../../../components/common/charts/LineChart';
import { lt } from 'date-fns/esm/locale';
import { getAnnotations } from '../../../../../utils/chart';
import { ScaleChartOptions } from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { EditableCell } from './components/EditableCell';
import { AnalysisParameterType } from '../../../../../contracts/Analysis';
import { MinMaxCell } from '../../../../settings/analyses/components/MinMaxCell';
import { useEcgRecords } from '../../../../../hooks/queries/useEcgRecords';
import { EcgRecord } from '../../../../../contracts/Ecg';
import dateCell from '../../../../../components/common/Table/Builders/DateCell';
import timeCell from '../../../../../components/common/Table/Builders/TimeCell';
import Tag from '../../../../../components/common/Tag';
import H2 from '../../../../../components/typography/H2';
import { useTranslation } from 'react-i18next';

const TOTAL_VISIBLE_COLUMNS = 5;

export type AnalysisUpdateType = {
  value: string;
  date: Date;
  analysisId: NullableType<number>;
  parameterId: NullableType<number>;
};

const defaultEcgTableSort = [{ id: 'date', desc: true }];

export default function PatientAnalysesTab() {
  const { t } = useTranslation('PatientAnalysesTab');
  const navigate = useNavigate();
  const { id: patientIdString } = useParams<{ id: string }>();
  const patientId = Number(patientIdString);
  const [createAnalysesProps, setCreateAnalysesProps] = React.useState<{ isOpen: boolean; analysisGroupId?: number }>({
    isOpen: false,
  });
  const [selectedParameter, setSelectedParameter] = React.useState<
    NullableType<{
      parameter: AnalysisParameterType;
      analyses: Array<AnalysisType>;
    }>
  >(null);
  const [dateIndex, setDateIndex] = React.useState<number>(0);
  const { data } = usePatientAnalyses(patientId);
  const { data: ecgs } = useEcgRecords(patientId);
  const patientAnalysisMutation = usePatientAnalysis();
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const ecgTableColumns: Column<EcgRecord>[] = [
    dateCell(t, t('dateHeader'), 'date'),
    timeCell(t, t('startDateHeader'), 'startDate'),
    timeCell(t, t('endDateHeader'), 'endDate'),
  ];

  React.useEffect(() => {
    if (data && data.dates) {
      setDateIndex(data.dates.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.dates]);

  const [columns, analysesGroupData] = React.useMemo(() => {
    const columns: Array<Column<PatientAnalysesParameter>> = [
      {
        Header: t('parametersHeader'),
        accessor: row => row.parameter.name,
        disableSortBy: true,
        width: 60,
        Cell: ({ value, row }: CellProps<PatientAnalysesParameter, number>) => (
          <div
            title={row.original.parameter.description}
            className={'font-semibold text-gray-900 cursor-pointer'}
            onClick={() =>
              setSelectedParameter({
                parameter: row.original.parameter,
                analyses: row.original.analyses,
              })
            }
          >
            {value}
          </div>
        ),
      },
      {
        Header: t('unitHeader'),
        accessor: row => row.parameter.unit,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: t('minHeader'),
        accessor: row => row.parameter.min,
        width: 60,
        disableSortBy: true,
        Cell: ({ row }: CellProps<PatientAnalysesParameter>) => (
          <MinMaxCell min={row.original.parameter.min} max={row.original.parameter.max} />
        ),
      },
    ];
    if (data && Array.isArray(data?.dates)) {
      let i = dateIndex + 1 - TOTAL_VISIBLE_COLUMNS <= 0 ? 0 : dateIndex + 1 - TOTAL_VISIBLE_COLUMNS;
      for (i; i <= dateIndex; i++) {
        const item = data.dates[i];
        if (item) {
          columns.push({
            Header: () => <div className={'whitespace-nowrap pr-2'}>{format(new Date(item), 'yyyy-MM-dd')}</div>,
            // @ts-ignore
            accessor: `date_${getFullYear(item)}.value`,
            // @ts-ignore
            name: `date_${getFullYear(item)}`,
            disableSortBy: true,
            width: 100,
            Cell: (cellProps: any) => (
              <EditableCell {...cellProps} handleUpdate={handleAnalysisCellChange} allowEdit={canManagePatients} />
            ),
          });
        }
      }
      if (columns.length - 2 < TOTAL_VISIBLE_COLUMNS) {
        const total = TOTAL_VISIBLE_COLUMNS + 2 - columns.length;
        for (let i = 0; i < total; i++) {
          columns.push({
            id: `empty_${i}`,
            disableSortBy: true,
            width: 120,
          });
        }
      }
    }

    let _groups: Array<any> = [];
    if (data && Array.isArray(data.groups)) {
      data.groups.forEach(group => {
        let _data: Array<any> = [];
        if (group && Array.isArray(group.parameters)) {
          group.parameters.forEach((parameter: PatientAnalysesParameter) => {
            if (parameter.parameter.isVisible) {
              let dataObj: { [k: string]: number | string | object } = {
                parameter: parameter.parameter,
                analyses: parameter.analyses,
              };

              parameter.analyses.forEach((analysis: AnalysisType) => {
                dataObj[`date_${getFullYear(analysis.date)}`] = analysis;
              });

              _data.push(dataObj);
            }
          });
        }
        _groups.push({
          id: group.id,
          name: group.name,
          data: _data,
        });
      });
    }

    return [columns, _groups];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dateIndex]);

  const [chartData, scales] = React.useMemo(() => {
    if (selectedParameter === null || !data) {
      return [null, null];
    }

    const nonEmptyValues = selectedParameter.analyses.filter(params => params.value !== null);
    const values = nonEmptyValues.map(d => d.value);
    const min = Math.min.apply(Math, values);
    const max = Math.max.apply(Math, values);

    const scales: DeepPartial<ScaleChartOptions<'line'>> = {
      scales: {
        x: {
          adapters: {
            date: {
              locale: lt,
            },
          },
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'yyyy-MM-dd',
          },
        },
        y: {
          suggestedMin:
            selectedParameter.parameter.min && min > selectedParameter.parameter.min
              ? selectedParameter.parameter.min * 0.95
              : min * 0.95,
          suggestedMax:
            selectedParameter.parameter.max && max < selectedParameter.parameter.max
              ? selectedParameter.parameter.max * 1.05
              : max * 1.05,
        },
      },
    };
    return [
      {
        labels: nonEmptyValues.map(d => d.date),
        datasets: [
          {
            data: values,
            fill: false,
            backgroundColor: 'rgb(219, 39, 119)',
            borderColor: 'rgba(219, 39, 119, .7)',
            tension: 0.2,
          },
        ],
      },
      scales,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter]);

  const minMaxAnnotations = React.useMemo(() => {
    if (selectedParameter === null || !data) {
      return undefined;
    }
    return getAnnotations(selectedParameter.parameter.min, selectedParameter.parameter.max);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter]);

  function handleAnalysisCellChange(data: AnalysisUpdateType) {
    patientAnalysisMutation.mutate({
      ...data,
      value: data.value === '' ? null : Number(data.value),
      patientId: Number(patientId),
    });
  }

  function handleDateChange(step: 1 | -1) {
    setDateIndex(dateIndex + step);
  }

  function handleAnalysisSubmit(values: AnalysisFormValues) {
    patientAnalysisMutation.mutate(
      {
        ...values,
        date: values.date,
        value: Number(values.value),
        patientId: Number(patientId),
        analysisId: null,
      },
      {
        onSuccess: () => {
          if (createAnalysesProps.isOpen) {
            setCreateAnalysesProps({ isOpen: false });
            successToast(t('submitSuccessMessage'));
          }
        },
      },
    );
  }

  const goToEcgPage = React.useCallback(
    (row: Row<EcgRecord>) => navigate(`/patients/${patientId}/ecg/${row.original.id}`),
    // eslint-disable-next-line
    [],
  );

  if (!analysesGroupData) {
    return null;
  }
  return (
    <>
      <H2>{t('header')}</H2>
      <div className="flex justify-between">
        <div className="mb-8">{canManagePatients && <ParametersDialog />}</div>
        {data && data.dates && data.dates.length > TOTAL_VISIBLE_COLUMNS ? (
          <div className="mb-8">
            <LeftRightPicker
              handleStepChange={handleDateChange}
              isNextVisible={dateIndex < data.dates.length - 1}
              isPrevVisible={dateIndex + 1 - TOTAL_VISIBLE_COLUMNS > 0}
            >
              {getFullYear(data.dates[dateIndex])}
            </LeftRightPicker>
          </div>
        ) : null}
      </div>
      {analysesGroupData.map(group => (
        <TableTitle title={group.name} key={group.name} className={'mb-8'}>
          <Table
            data={group.data}
            columns={columns}
            handleAddClick={
              canManagePatients
                ? () =>
                    setCreateAnalysesProps({
                      isOpen: true,
                      analysisGroupId: group.id,
                    })
                : undefined
            }
            pageSize={999}
          />
        </TableTitle>
      ))}
      <Dialog
        open={createAnalysesProps.isOpen}
        handleCloseClick={() => setCreateAnalysesProps({ isOpen: false })}
        widthAuto={true}
      >
        <AddPatientAnalysisForm
          analysisGroupId={createAnalysesProps.analysisGroupId}
          handleCancel={() => setCreateAnalysesProps({ isOpen: false })}
          handleSave={handleAnalysisSubmit}
        />
      </Dialog>
      <Dialog open={selectedParameter !== null && !!chartData} handleCloseClick={() => setSelectedParameter(null)}>
        {chartData && scales && selectedParameter !== null ? (
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-6 flex justify-between pr-6">
              <span>{selectedParameter?.parameter.description}</span>
              <span className={'text-emerald-500'}>
                {selectedParameter?.parameter.min} - {selectedParameter?.parameter.max}{' '}
                {selectedParameter?.parameter.unit}
              </span>
            </h3>
            <LineChart data={chartData} annotation={minMaxAnnotations} scales={scales} />
          </div>
        ) : null}
      </Dialog>

      {!!ecgs?.length && (
        <TableTitle
          title={
            <>
              {t('ekgLabel')} <Tag title={'beta'}>{t('betaFeature', { ns: 'common' })}</Tag>
            </>
          }
          className={'mb-16'}
        >
          <Table
            data={ecgs}
            columns={ecgTableColumns}
            defaultSortBy={defaultEcgTableSort}
            pageSize={10}
            handleRowClick={goToEcgPage}
          />
        </TableTitle>
      )}

      <TableTitle title={t('header')} className={'mb-8'}>
        <PatientDocuments documentType={DocumentTypeEnum.Analysis} />
      </TableTitle>
    </>
  );
}
