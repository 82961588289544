import React from 'react';
import { Menu } from '@headlessui/react';
import { EcgEventGroup } from '../../utils/types';
import EcgAccidentsGroupNavItem from './EcgAccidentsGroupNavItem';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface Props {
  groups: EcgEventGroup[];
  activeEventGroup?: EcgEventGroup;
  onGroupClicked: (group?: string) => void;
}

export default function EcgAccidentsGroupNav({ groups, activeEventGroup, onGroupClicked }: Props) {
  return (
    <Menu>
      <Menu.Button
        className={'border rounded py-2 px-4 outline-0 w-80 flex items-center border-gray-300 text-sm shadow-sm'}
      >
        {activeEventGroup && (
          <span>
            {activeEventGroup.name} ({activeEventGroup.count})
          </span>
        )}
        <div className={'flex-1'} />
        <ChevronDownIcon className={'w-5 h-5'} />
      </Menu.Button>
      <Menu.Items className={'absolute mt-1 z-10 border bg-white outline-0 px-4 py-4 w-80 rounded-md shadow-lg'}>
        {groups
          .filter(({ count }) => count)
          .map(g => (
            <Menu.Item
              as={'button'}
              key={g.id}
              onClick={() => onGroupClicked(g.id)}
              className={
                'py-1 mb-2 cursor-pointerblock text-sm font-medium text-black flex justify-between items-center w-full'
              }
            >
              <EcgAccidentsGroupNavItem title={g.name} count={g.count} isActive={g.id === activeEventGroup?.id} />
            </Menu.Item>
          ))}
      </Menu.Items>
    </Menu>
  );
}
