import { ExtendedColumnProps } from '../../components/common/Table/TableUtilities';
import { PatientType } from '../../contracts/Patient';
import { CellProps, Row } from 'react-table';
import { PatientInline } from '../../components/patient/PatientInline';
import DiagnosisBadge from '../../components/common/DiagnosisBadge';
import { GadgetColorByBrandId } from '../../contracts/Gadget';
import Tag from '../../components/common/Tag';
import { AlertLevel, AlertLevelColor } from '../../contracts/Alert';
import { getFullTime } from '../../utils/date';
import * as React from 'react';
import { KNOWN_DIAGNOSES } from '../../contracts/Diagnoses';
import { ClinicType } from '../../contracts/Clinic';
import { SuperSearchFilterOption } from '../../components/common/Table/SuperSearch/SuperSearchUtils';
import { TFunction } from 'i18next';

export const getPatientListTableColumns = (
  t: TFunction,
  clinics: ClinicType[],
  doctors: SuperSearchFilterOption[],
  goToUrl: (url: string) => void,
): Array<ExtendedColumnProps<PatientType>> => [
  {
    Header: t('table.patientColHeader'),
    id: 'fullName',
    width: 280,
    accessor: row => `${(row as PatientType).firstName} ${(row as PatientType).lastName}`,
    Cell: ({ row }: CellProps<PatientType>) => <PatientInline {...row.original} />,
    className: 'md:py-2',
  },
  {
    Header: t('table.ageColHeader'),
    accessor: 'age',
    width: 140,
    Cell: ({ value }) => <>{value} m.</>,
  },
  {
    Header: t('table.diagnosesColHeader'),
    accessor: 'diagnoses',
    id: 'diagnoses',
    width: 'auto',
    canSuperFilter: true,
    superSearchPredicateType: 'multiselect',
    superSearchPredicateOptions: KNOWN_DIAGNOSES,
    Cell: ({ value }) => (
      <div className="whitespace-normal">
        {value.map(value => (
          <DiagnosisBadge className={'mr-0.5 mb-0.5'} key={value}>
            {value}
          </DiagnosisBadge>
        ))}
      </div>
    ),
  },
  {
    Header: t('table.gadgetsColHeader'),
    accessor: 'gadgets',
    width: 'auto',
    Cell: ({ row, value }) => (
      <div
        className="space-x-1"
        onClick={e => {
          e.stopPropagation();
          goToUrl(`/patients/${row.original.id}?tab=gadget`);
        }}
      >
        {value.map(value => (
          <Tag
            className={GadgetColorByBrandId[value.brandId] ?? GadgetColorByBrandId[0]}
            title={value.modelName}
            key={value.shortName}
          >
            {value.shortName}
          </Tag>
        ))}
      </div>
    ),
  },
  {
    Header: t('table.alarmsColHeader'),
    accessor: row => {
      if ((row as PatientType).alarms > 0) {
        return (row as PatientType).alarms;
      } else {
        return (row as PatientType).warnings;
      }
    },
    id: 'events',
    sortType: (rowA: Row<PatientType>, rowB: Row<PatientType>) => {
      if (rowA.original.alarms > rowB.original.alarms) {
        return 1;
      }
      if (rowA.original.alarms < rowB.original.alarms) {
        return -1;
      }

      if (rowA.original.warnings > rowB.original.warnings) {
        return 1;
      }
      if (rowA.original.warnings < rowB.original.warnings) {
        return -1;
      }
      return 0;
    },
    Cell: ({ row }: CellProps<PatientType>) =>
      row.original.alarms > 0 || row.original.warnings > 0 ? (
        <div
          className="space-x-1"
          onClick={e => {
            e.stopPropagation();
            goToUrl(`/patients/${row.original.id}?tab=alerts`);
          }}
        >
          {row.original.alarms > 0 ? (
            <Tag className={AlertLevelColor[AlertLevel.Danger]}>{row.original.alarms}</Tag>
          ) : null}
          {row.original.warnings > 0 ? (
            <Tag className={AlertLevelColor[AlertLevel.Warning]}>{row.original.warnings}</Tag>
          ) : null}
        </div>
      ) : null,
  },
  {
    Header: t('table.lastSyncColHeader'),
    accessor: 'lastSync',
    width: 140,
    Cell: ({ value }) => (value ? <>{getFullTime(value)}</> : null),
  },
  {
    Header: t('table.clinicColHeader'),
    accessor: 'clinic',
    id: 'clinic',
  },
  {
    Header: t('table.clinicIdColHeader'),
    accessor: 'clinicId',
    id: 'clinicId',
    canSuperFilter: true,
    superSearchPredicateType: 'multiselect',
    superSearchPredicateOptions: clinics.map(c => ({ id: c.id, label: c.name })),
  },
  {
    Header: t('table.familyDoctorIdColHeader'),
    accessor: 'familyDoctorId',
    id: 'familyDoctorId',
    canSuperFilter: true,
    superSearchPredicateType: 'multiselect',
    superSearchPredicateOptions: doctors,
  },
];
