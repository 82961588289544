import * as React from 'react';
import { ChartBarIcon, Bars4Icon } from '@heroicons/react/24/solid';

import H3 from '../../../../../components/typography/H3';
import ButtonGroup from '../../../../../components/buttons/ButtonGroup';

import { ChartView } from './MetricsUtils';

interface MyProps {
  name: string;
  view: ChartView;
  setView: (newValue: ChartView) => void;
}

export const MetricsCellHeader = ({ name, view, setView }: MyProps) => (
  <div className="pb-4">
    <H3>
      <div className="flex items-center justify-between">
        <span>{name}</span>
        <ButtonGroup
          buttons={[
            {
              value: ChartView.Chart,
              text: '',
              icon: <ChartBarIcon className="h-5 w-6" />,
            },
            {
              value: ChartView.Table,
              text: '',
              icon: <Bars4Icon className="h-5 w-6" />,
            },
          ]}
          handleClick={view => setView(view)}
          active={view}
        />
      </div>
    </H3>
  </div>
);
