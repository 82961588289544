import React from 'react';

interface ITightPanel {
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
  onClick?: () => void;
}

export const TightPanel: React.FunctionComponent<ITightPanel> = ({ children, className = '', ...rest }) => {
  return (
    <div className={`shadow rounded-lg ${className}`} {...rest}>
      <div className="pl-4 pr-2 py-2">{children}</div>
    </div>
  );
};
