export class WithingsService {
  static scope = 'user.activity,user.metrics';

  static clientId = process.env.REACT_APP_WITHINGS_APP_ID;

  static url = 'https://account.withings.com/oauth2_user/authorize2';

  static expiration = '604800';

  public static getAuthUrl = (patientId: number, returnUrl = WithingsService.getReturnUrl()): string =>
    `${WithingsService.url}?response_type=code&client_id=${WithingsService.clientId}&redirect_uri=${encodeURI(
      returnUrl,
    )}&scope=${encodeURI(WithingsService.scope)}&state=${patientId}`;

  public static getReturnUrl = (): string => `${window.location.origin}/withings`;
}
