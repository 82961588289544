import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { FeedRecordType } from '../../../contracts/Feed';
import { PatientFeedRecordFormType } from '../../../pages/patients/components/tabs/feed/PatientFeedRecordForm';

type PatientFeedRequest = PatientFeedRecordFormType & {
  patientId: number;
};

export function usePatientFeedUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<FeedRecordType>();
  return useMutation<FeedRecordType, unknown, PatientFeedRequest>(
    ({ patientId, id, ...data }) => {
      let body = new FormData();
      if (data.files) {
        data.files.forEach(file => {
          body.append('files', file);
        });
      }
      if (data.comments) {
        body.append('comments', JSON.stringify(data.comments));
      }
      if (data.removedFileIds) {
        data.removedFileIds.forEach(id => {
          body.append('removedFileIds', String(id));
        });
      }
      return request(`/patients/${patientId}/feed/${id}`, {
        body,
        method: 'PUT',
      });
    },
    {
      onSuccess: (data, { patientId, id }) => {
        queryClient.setQueryData(['patient-feed', patientId], (oldData: Array<FeedRecordType> = []) =>
          oldData.map(item => (item.id === id ? { ...data } : { ...item })),
        );
      },
    },
  );
}
