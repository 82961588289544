import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { FeedRecordType } from '../../../contracts/Feed';
import { PatientFeedRecordFormType } from '../../../pages/patients/components/tabs/feed/PatientFeedRecordForm';

type PatientFeedRequest = PatientFeedRecordFormType & {
  patientId: number;
};

export function usePatientFeedCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<FeedRecordType>();
  return useMutation<FeedRecordType, unknown, PatientFeedRequest>(
    ({ patientId, ...data }) => {
      let body = new FormData();
      if (data.files) {
        data.files.forEach(file => {
          body.append('files', file);
        });
      }
      if (data.comments) {
        body.append('comments', JSON.stringify(data.comments));
      }
      return request(`/patients/${patientId}/feed`, {
        body,
        method: 'POST',
      });
    },
    {
      onSuccess: (data, { patientId }) => {
        queryClient.setQueryData(['patient-feed', patientId], (oldData: Array<FeedRecordType> = []) => [
          data,
          ...oldData,
        ]);
      },
    },
  );
}
