import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { PatientDiagnosisType } from '../../contracts/Patient';

export function usePatientDiagnoses(patientId: number) {
  const request = useRequest<Array<PatientDiagnosisType>>();
  return useQuery<unknown, unknown, Array<PatientDiagnosisType>>(
    ['patient-diagnoses', patientId],
    () => request(`/patients/${patientId}/diagnoses`),
    {
      staleTime: 60 * 1000,
    },
  );
}
