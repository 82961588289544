import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { FeedRecordType } from '../../../contracts/Feed';

type PatientFeedRequest = {
  patientId: number;
  medicalRecordId: number;
};

export function usePatientFeedDelete() {
  const queryClient = useQueryClient();
  const request = useRequest<unknown>();
  return useMutation<unknown, unknown, PatientFeedRequest>(
    ({ patientId, medicalRecordId }) => {
      return request(`/patients/${patientId}/feed/${medicalRecordId}`, {
        method: 'DELETE',
      });
    },
    {
      onSuccess: (_, { patientId, medicalRecordId }) => {
        queryClient.setQueryData(['patient-feed', patientId], (oldData: Array<FeedRecordType> = []) =>
          oldData.filter(item => item.id !== medicalRecordId),
        );
      },
    },
  );
}
