import { useRequest } from '../useRequest';
import { EcgRecordPeaksResponse } from '../../contracts/Ecg';
import { useQuery } from '@tanstack/react-query';

export function useEcgPeaks(patientId: number, recordId: number, enabled?: boolean) {
  const request = useRequest<EcgRecordPeaksResponse>();
  return useQuery<EcgRecordPeaksResponse>(
    ['patient-ecg-peaks', `${patientId}${recordId}`],
    () => request(`/patients/${patientId}/ecgs/${recordId}/peaks`),
    { staleTime: 60 * 1000, enabled: enabled ?? false },
  );
}
