import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ModelType } from '../../contracts/Model';

export function useModels() {
  const request = useRequest<Array<ModelType>>();
  return useQuery<unknown, unknown, Array<ModelType>>(['models'], () => request('/gadgets/models'), {
    staleTime: 10 * 60 * 1000,
  });
}
