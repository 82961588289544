import * as React from 'react';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Table from '../../components/common/Table/Table';
import { useGadgets } from '../../hooks/queries/useGadgets';
import Dialog from '../../components/dialog/Dialog';
import Tag from '../../components/common/Tag';
import { EditGadgetForm } from './components/EditGadgetForm';
import {
  GadgetCreateRequest,
  GadgetState,
  GadgetStateColor,
  getGadgetStateLabels,
  GadgetType,
  GadgetUpdateRequest,
} from '../../contracts/Gadget';
import { useGadgetCreate } from '../../hooks/mutations/useGadgetCreate';
import { useGadgetUpdate } from '../../hooks/mutations/useGadgetUpdate';
import { AddGadgetForm } from './components/AddGadgetForm';
import { Column } from 'react-table';
import { PatientInline } from '../../components/patient/PatientInline';
import EditIcon from '../../components/icons/EditIcon';
import { useTranslation } from 'react-i18next';

const breadcrumbPages = [{ name: 'Įranga', href: '/gadgets', current: true }];

export const GadgetList = () => {
  const { t } = useTranslation('GadgetList');
  const [open, setOpen] = React.useState<'add' | number | false>(false);
  const createGadget = useGadgetCreate();
  const updateGadget = useGadgetUpdate();
  const { data, isLoading } = useGadgets();

  const [columns, tableData] = React.useMemo(() => {
    const columns: Array<Column> = [
      {
        Header: t('table.modelColHeader'),
        accessor: 'model',
      },
      {
        Header: t('table.seriesNoColHeader'),
        accessor: 'seriesNo',
      },
      {
        Header: t('table.clinicColHeader'),
        accessor: 'clinic',
      },
      {
        Header: t('table.patientColHeader'),
        accessor: 'patient',
        Cell: ({ value }) => (value ? <PatientInline {...value} /> : null),
      },
      {
        Header: t('table.stateColHeader'),
        accessor: 'state',
        Cell: ({ value }: { value: GadgetState }) => {
          return <Tag className={GadgetStateColor[value]}>{getGadgetStateLabels(t)[value]}</Tag>;
        },
      },
      {
        Header: t('table.editColHeader'),
        accessor: 'id',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: { value: number }) => <EditIcon onClick={() => setOpen(value)} />,
      },
    ];
    return [columns, data];
  }, [data, t]);

  function handleModalClose() {
    setOpen(false);
  }

  function addGadget({ clinicId = 0, ...requestData }: GadgetCreateRequest) {
    if (Number(clinicId) > 0) {
      (requestData as GadgetCreateRequest).clinicId = Number(clinicId);
    }
    createGadget.mutate(requestData);
    handleModalClose();
  }

  function editGadget({ clinicId = 0, ...requestData }: GadgetUpdateRequest) {
    if (Number(clinicId) > 0) {
      (requestData as GadgetUpdateRequest).clinicId = Number(clinicId);
    }
    requestData.state = Number(requestData.state);
    updateGadget.mutate(requestData);
    handleModalClose();
  }

  function gadgetSelector(gadgetId: number, gadgets: Array<GadgetType> | undefined): GadgetType | undefined {
    if (!gadgets) {
      return undefined;
    }
    if (gadgets.length <= 0) {
      return undefined;
    }
    return gadgets.find(gadget => gadget.id === gadgetId);
  }

  return (
    <DashboardLayout isLoading={isLoading && !tableData}>
      <div>
        <Breadcrumbs pages={breadcrumbPages} />
        {!isLoading && tableData && <Table data={tableData} columns={columns} handleAddClick={() => setOpen('add')} />}
        <Dialog open={!!open} handleCloseClick={handleModalClose} widthAuto={true}>
          {open === 'add' ? <AddGadgetForm handleCancel={handleModalClose} handleSave={addGadget} /> : <div />}
          {typeof open === 'number' ? (
            <EditGadgetForm
              gadgetId={Number(open)}
              gadget={gadgetSelector(Number(open), data)}
              handleCancel={handleModalClose}
              handleSave={editGadget}
            />
          ) : (
            <div />
          )}
        </Dialog>
      </div>
    </DashboardLayout>
  );
};
