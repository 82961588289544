import * as React from 'react';
import { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/common/Loading';

interface Props {
  component: ComponentType;
}

const ProtectedRoute = ({ component }: Props) => {
  if (typeof component === 'undefined') {
    throw new Error('component prop must be defined for ProtectedRoute');
  }
  const LoadingWithWrapper = () => (
    <div className="absolute top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full flex items-center justify-center">
        <Loading />
      </div>
    </div>
  );

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingWithWrapper />,
  });

  return <Component />;
};

export default ProtectedRoute;
