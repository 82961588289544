import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { DocumentType, DocumentTypeEnum } from '../../contracts/Document';

export function usePatientDocuments(patientId: number, documentType: DocumentTypeEnum = DocumentTypeEnum.Generic) {
  const request = useRequest<Array<DocumentType>>();
  return useQuery<unknown, unknown, Array<DocumentType>>(
    ['patient-documents', patientId, documentType],
    () => request(`/patients/${patientId}/documents?documentType=${documentType}`),
    {
      staleTime: 60 * 1000,
    },
  );
}
