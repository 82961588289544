import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { DiagnosisType } from '../../contracts/Classifiers';

export function useDiagnoses(): { [k: string]: DiagnosisType } | undefined {
  const request = useRequest<Array<DiagnosisType>>();
  const queryPayload = useQuery<unknown, unknown, Array<DiagnosisType>>(
    ['diagnoses'],
    () => request(`/classifiers/diagnoses`),
    {
      staleTime: 60 * 1000 * 60,
    },
  );
  let _data;
  if (queryPayload.data) {
    _data = queryPayload.data.reduce(function (result: { [key: string]: DiagnosisType }, item) {
      result[item.code] = item;
      return result;
    }, {});
  }
  return _data ?? undefined;
}
