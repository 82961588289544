import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { CellProps, Renderer } from 'react-table';
import { TFunction } from 'i18next';

export default function dateCellRenderer<TData extends object, TValue extends object | string | null | undefined>(
  t: TFunction,
  fallback: string = '-',
): Renderer<CellProps<TData, TValue>> {
  const dateEl = (date: Date) => <>{format(date, t('dateShort', { ns: 'formats' }))}</>;
  const fallbackEl = <>{fallback}</>;

  return ({ value }: CellProps<TData, TValue>) => {
    switch (typeof value) {
      case 'string':
        return dateEl(parseISO(value));
      case 'object':
        return value instanceof Date ? dateEl(value) : fallbackEl;
      default:
        return <>fallback</>;
    }
  };
}
