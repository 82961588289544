import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientRequest, PatientType } from '../../contracts/Patient';

type PatientCreateRequest = Omit<PatientRequest, 'id'>;

export function usePatientCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientType>();
  return useMutation<PatientType, unknown, PatientCreateRequest>(
    data =>
      request('/patients', {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['patients'], (oldData: Array<PatientType> = []) => [...oldData, data]);
      },
    },
  );
}
