import * as React from 'react';
import { Bar } from 'react-chartjs-2';

import { SleepMetricsResponse } from '../../../../../../contracts/Metrics';
import Table from '../../../../../../components/common/Table/Table';

import {
  getDefaultSleepChartOptions,
  getSleepData,
  getSleepDataExists,
  getSleepMetrics,
  getSleepMetricsTableColumns,
} from './MetricsSleepCellUtils';
import { ChartView } from '../MetricsUtils';
import { MetricsCellHeader } from '../MetricsCellHeader';
import { BarElement, Chart, Legend, LinearScale, LineElement, PointElement, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import NoMetricsData from './NoMetricsData';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface MyProps {
  name: string;
  data: SleepMetricsResponse;
}

Chart.register(TimeScale, LinearScale, PointElement, LineElement, BarElement, annotationPlugin, Legend);

const Component = ({ name, data }: MyProps) => {
  const { t } = useTranslation('MetricsSleepCell');
  const [view, setView] = React.useState<ChartView>(ChartView.Chart);
  const sleepDataExists = getSleepDataExists(data);

  const sleepMetricsTableColumns = useMemo(() => getSleepMetricsTableColumns(t), [t]);
  const sleepMetrics = useMemo(() => getSleepMetrics(data, t), [data, t]);
  const defaultSleepChartOptions = useMemo(() => getDefaultSleepChartOptions(t), [t]);

  if (!sleepDataExists) {
    return <NoMetricsData name={name} view={view} setView={setView} />;
  }

  const sleepData = getSleepData(data);

  return (
    <div className="mb-16 mt-10">
      <MetricsCellHeader name={name} view={view} setView={setView} />

      {view === ChartView.Chart ? (
        <Bar data={sleepMetrics} options={defaultSleepChartOptions} height={80} />
      ) : (
        <Table data={sleepData} columns={sleepMetricsTableColumns} defaultSortBy={[{ id: 'date', desc: true }]} />
      )}
    </div>
  );
};

export const MetricsSleepCell = React.memo(Component);
