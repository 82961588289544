import { Listbox } from '@headlessui/react';
import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { EcgDataLayer } from '../../../../../../../contracts/Ecg';
import { AnalysisMode } from '../../utils/types';
import { Checkbox } from '../../../../../../../components/form/Checkbox';
import { useTranslation } from 'react-i18next';

interface Props {
  modes: EcgDataLayer[];
  analysesOverlayModes: AnalysisMode[];

  onAnalysesOverlayModesChanged: (value: AnalysisMode[]) => void;
  onModesChanged: (mode: EcgDataLayer[]) => void;
}

const allModes: EcgDataLayer[] = ['aligned', 'denoised', 'raw'];
const allAnalysesModes: AnalysisMode[] = ['peaks'];

export default function Layers({ analysesOverlayModes, modes, onAnalysesOverlayModesChanged, onModesChanged }: Props) {
  const { t } = useTranslation('Layers');

  const layerNames = {
    aligned: t('aligned'),
    denoised: t('denoised'),
    raw: t('raw'),
  };

  const analyticsNames = {
    peaks: t('peaks'),
    millimeterPaper: t('millimeterPaper'),
  };

  return (
    <>
      <div className={'mr-4'}>
        <Listbox value={modes} onChange={onModesChanged} multiple>
          <div className="relative mt-1">
            <Listbox.Button className="rounded-md bg-white border py-2 px-2 text-sm">
              <span className={'font-medium mr-2'}>{t('signalsLabel')}</span>
              {modes.map(m => layerNames[m]).join(', ')}
            </Listbox.Button>
            <Listbox.Options className="z-10 flex flex-col cursor-pointer absolute right-0 mt-1 max-h-60 w-48 overflow-auto rounded-md focus:outline-none sm:text-sm bg-white border shadow-lg">
              {allModes.map(m => (
                <Listbox.Option key={m} value={m} className="py-2 px-4 inline-flex items-center">
                  <CheckIcon className={classNames('w-4 h-4 mr-2', !modes.includes(m) && 'text-white')} />
                  {layerNames[m]}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>

      <div className={'flex-1'} />

      <div>
        {allAnalysesModes.map(m => (
          <div className="flex justify-center items-center" key={m}>
            <Checkbox
              isChecked={analysesOverlayModes.includes(m)}
              onChange={e => {
                if (e.target.checked) {
                  onAnalysesOverlayModesChanged([...analysesOverlayModes, m]);
                } else {
                  onAnalysesOverlayModesChanged(analysesOverlayModes.filter(v => v !== m));
                }
              }}
              key={m}
            />
            <span className="pl-2 text-sm">{analyticsNames[m]}</span>
          </div>
        ))}
      </div>
    </>
  );
}
