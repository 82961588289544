export const boxValues = <T extends object>(
  boxCount: number,
  boxSize: number,
  values: T[],
  value: (inputPrev: T, inputCurr: T) => number,
  parsingThreshold: number = 5,
): { boxes: number[]; max: number } => {
  const boxes = Array(boxCount);
  let max = 0;
  for (let i = 1; i < values.length; i++) {
    const val = value(values[i - 1], values[i]);

    let index = Math.floor(val / boxSize);
    if (index < 0) {
      index = 0;
    }
    boxes[index] = isNaN(boxes[index]) ? 1 : boxes[index] + 1;

    if (index > max) {
      max = index;
    }
  }

  for (let i = 0; i < boxes.length; i++) {
    if (boxes[i] >= parsingThreshold) {
      max = i;
    } else {
      boxes[i] = 0;
    }
  }
  return { boxes, max };
};
