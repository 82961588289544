import { setLocale } from 'yup';
import { TOptions } from 'i18next';

export interface ValidationMessage {
  key: string;
  props: TOptions & unknown;
}

export type TranslateValidationMessageFn = (props: any) => ValidationMessage;

export const getValidationMessage =
  (key: string, ns: string) =>
  (props: any): ValidationMessage => ({
    key,
    props: {
      ...props,
      ns,
    },
  });

setLocale({
  mixed: {
    default: getValidationMessage('default', 'validation'),
    required: getValidationMessage('required', 'validation'),
  },
  number: {
    // @ts-ignore
    required: getValidationMessage('required', 'validation'),
  },
  string: {
    email: getValidationMessage('email', 'validation'),
  },
});
