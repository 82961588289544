import * as React from 'react';
import { NullableType } from '../../contracts/Common';
import { PropsWithChildren } from 'react';

interface Props {
  headline?: NullableType<string>;
}

export default function PersonLine({ children, headline }: PropsWithChildren<Props>) {
  return (
    <>
      <div className="text-sm font-semibold text-gray-900 dark:text-gray-300">{children}</div>
      {headline && <div className="text-sm font-normal tracking-wide text-gray-700 dark:text-gray-400">{headline}</div>}
    </>
  );
}
