import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DiagnosisType } from '../../contracts/Classifiers';

export type AnalysesParametersRequest = {
  parameterIds: Array<number>;
};

export function useAnalysesParametersUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<DiagnosisType>();
  return useMutation<unknown, unknown, AnalysesParametersRequest>(
    data =>
      request(`/analyses/parameters/filter`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['analyses']);
        queryClient.invalidateQueries(['analyses-filter']);
        queryClient.invalidateQueries(['patient-analyses']);
      },
    },
  );
}
