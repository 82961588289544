import * as React from 'react';
import { Field } from 'formik';
import { MultiFileInput } from '../../../../../components/form/MultiFileInput';
import { FeedRecordType } from '../../../../../contracts/Feed';
import { Descendant } from 'slate';
import { RichTextInput } from '../../../../../components/form/RichTextInput';
import * as Yup from 'yup';
import { slateNodesHaveText } from '../../../../../utils/text';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { getValidationMessage } from '../../../../../utils/validation';
import { useTranslation } from 'react-i18next';

export type PatientFeedRecordFormType = {
  files: Array<any> | null;
  comments: Descendant[];
  id?: number;
  removedFileIds?: Array<number>;
};

interface IPatientFeedRecordForm {
  handleCancel: () => void;
  handleSave: (values: PatientFeedRecordFormType) => void;
  feedRecord?: FeedRecordType | undefined;
}

const PatientFeedRecordFormSchema = Yup.object().shape({
  comments: Yup.array().test('has-text', getValidationMessage('required', 'validation'), value =>
    (value as Descendant[]).some(slateNodesHaveText),
  ),
});

export const PatientFeedRecordForm: React.FunctionComponent<IPatientFeedRecordForm> = props => {
  const { t } = useTranslation('PatientFeedRecordForm');
  const { feedRecord } = props;
  const initialValues: PatientFeedRecordFormType = {
    comments:
      feedRecord && Array.isArray(feedRecord.comments)
        ? feedRecord.comments
        : [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ],
    files: feedRecord && feedRecord.documents ? feedRecord.documents : null,
    id: feedRecord ? feedRecord.id : undefined,
    removedFileIds: [],
  };

  return (
    <DialogForm
      title={feedRecord ? t('dialogTitleEdit') : t('dialogTitleCreate')}
      initialValues={initialValues}
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
      validationSchema={PatientFeedRecordFormSchema}
      labels={{ submit: feedRecord ? t('save', { ns: 'common' }) : t('createLabel') }}
    >
      <div className="sm:flex sm:items-start max-w-full" style={{ width: '460px' }}>
        <div className="flex flex-col space-y-6 w-full">
          <Field component={RichTextInput} name={'comments'} label={t('commentsLabel')} rows={8} />
          <Field
            component={MultiFileInput}
            name={'files'}
            removedFileIdsFieldName={'removedFileIds'}
            label={t('filesLabel')}
          />
        </div>
      </div>
    </DialogForm>
  );
};
