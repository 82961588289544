import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientMedicamentRequest } from '../../contracts/Patient';
import { PatientMedicamentType } from '../../contracts/Patient';

export function usePatientMedicationCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientMedicamentType>();
  return useMutation<PatientMedicamentType, unknown, PatientMedicamentRequest>(
    ({ patientId, id, name, ...data }) => {
      return request(`/patients/${patientId}/medications`, {
        body: JSON.stringify(id > 0 ? { ...data, id } : { ...data, name }),
        method: 'POST',
      });
    },
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(
          ['patient-medication', request.patientId],
          (oldData: Array<PatientMedicamentType> = []) => [...oldData, data],
        );
      },
    },
  );
}
