import React from 'react';
import { PatientType } from '../../contracts/Patient';
import { stringToHslColor } from '../../utils/color';
import DiagnosisBadge from '../common/DiagnosisBadge';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PERMISSIONS, usePermission } from '../../hooks/usePermission';
import Button from '../buttons/Button';
import { DoctorConsultationPopup } from '../../pages/patients/components/DoctorConsultationPopup';
import { useTranslation } from 'react-i18next';

interface Props {
  onEditClick: () => void;
}

export default function PatientCard(props: Props & PatientType) {
  const { t } = useTranslation('PatientCard');
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);
  return (
    <section aria-labelledby="profile-overview-title" className="print:hidden">
      <div className="rounded-lg bg-white dark:bg-gray-700 shadow p-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex sm:space-x-5">
            <div className="shrink-0">
              <span
                className="inline-flex items-center justify-center h-20 w-20 rounded-full"
                style={{
                  backgroundColor: stringToHslColor(`${props.firstName} ${props.lastName}`),
                }}
              >
                <span className="text-4xl font-medium leading-none text-white uppercase">
                  {props.firstName.slice(0, 1)}
                  {props.lastName.slice(0, 1)}
                </span>
              </span>
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-300">
                {props.firstName} {props.lastName}
              </p>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
                <span className="mr-7">{props.age} m.</span>
                {props.weight && <span className="mr-7">{props.weight} kg</span>}
                {props.height && <span className="mr-7">{props.height} cm</span>}
                {props.bmi && <span>KMI {props.bmi}</span>}
              </p>
              <div className="pr-8">
                {props.diagnoses.map(diagnosis => (
                  <DiagnosisBadge key={diagnosis} className="mr-0.5">
                    {diagnosis}
                  </DiagnosisBadge>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5 flex justify-start sm:mt-0 ml-24 self-start">
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400 leading-8">Kontaktai</p>
              <p className="text-sm font-medium text-gray-900 dark:text-gray-300 ">{props?.phone}</p>
              <p className="text-sm font-medium text-gray-900 dark:text-gray-300 ">{props?.email}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-start sm:mt-0 ml-24 self-start">
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400 leading-8">{props?.clinic}</p>
              {props.caseManager && (
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span className="text-gray-500 dark:text-gray-400 w-8 inline-block">AV:</span>
                  {props?.caseManager.firstName} {props?.caseManager.lastName}
                </p>
              )}
              {props.familyDoctor && (
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span className="text-gray-500 dark:text-gray-400 w-8 inline-block">ŠG:</span>
                  {props?.familyDoctor.firstName} {props?.familyDoctor.lastName}
                </p>
              )}
            </div>
          </div>

          {canManagePatients && (
            <div className="mt-5 flex flex-col space-y-3 justify-end sm:mt-0 ml-auto">
              <Button onClick={props.onEditClick} type={'secondary'}>
                <div className="flex items-center">
                  <PencilIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />
                  {t('edit', { ns: 'common' })}
                </div>
              </Button>
              <DoctorConsultationPopup patientId={props.id} clinicId={props.clinicId} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
