import { MetricDataPointType, MetricHistoryType } from '../../../../../contracts/Metrics';
import { isEqual, parseISO } from 'date-fns';

export enum ChartView {
  Chart = 'chart',
  Table = 'table',
}

export const historyValues = (history: Array<MetricDataPointType>) => history.map(d => d.value);

export const getHistoryDataPoint = ({ value, date }: MetricDataPointType) => ({ x: date, y: value });

export const getDates = (metrics: Array<MetricHistoryType>): Date[] => {
  const dates: Date[] = [];
  metrics.forEach(metric => {
    metric.history.forEach(historyPoint => {
      if (!dates.includes(historyPoint.date)) {
        // noinspection SuspiciousTypeOfGuard
        dates.push(typeof historyPoint.date === 'string' ? parseISO(historyPoint.date) : historyPoint.date);
      }
    });
  });
  return dates
    .filter((value: Date, index: number, self: Date[]) => self.findIndex(iv => isEqual(iv, value)) === index)
    .sort();
};
