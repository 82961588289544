import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ThreadInboxCountResponse } from '../../contracts/Threads';

export const useThreadInboxCount = () => {
  const request = useRequest<ThreadInboxCountResponse>();
  return useQuery<unknown, unknown, ThreadInboxCountResponse>(['threads-me-count'], () => request('/me/inbox/count'), {
    staleTime: 1000 * 30 * 60,
  });
};
