import React from 'react';
import PatientEcgLayout from '../components/PatientEcgLayout';
import { useParams } from 'react-router-dom';
import EcgHourlyBreakdown from '../components/ecgHourlyBreakdown';
import EcgDailyBreakdown from '../components/dayAnalysis';

export default function EcgOverviewPage() {
  const { patientId: patientIdString, ecgRecordId } = useParams<{ patientId: string; ecgRecordId: string }>();
  const patientId = Number(patientIdString);
  const recordId = Number(ecgRecordId);

  return (
    <PatientEcgLayout>
      <EcgDailyBreakdown patientId={patientId} recordId={recordId} className={'mb-8'} />
      <EcgHourlyBreakdown patientId={patientId} recordId={recordId} className={'mb-8'} />
    </PatientEcgLayout>
  );
}
