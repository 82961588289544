import * as React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import Tag from '../../../../components/common/Tag';
import { PatientGadget } from '../../../../contracts/Patient';
import Table from '../../../../components/common/Table/Table';
import SmallButton from '../../../../components/buttons/SmallButton';
import Dialog from '../../../../components/dialog/Dialog';
import { getFullYear } from '../../../../utils/date';
import { PERMISSIONS, usePermission } from '../../../../hooks/usePermission';
import { usePatientGadgets } from '../../../../hooks/queries/usePatientGadgetHistory';
import { GadgetState, GadgetStateColor, getGadgetStateLabels } from '../../../../contracts/Gadget';
import { usePatientReturnGadget } from '../../../../hooks/mutations/usePatientReturnGadget';
import { usePatientAssignGadget } from '../../../../hooks/mutations/usePatientAssignGadget';
import { AddPatientGadgetForm } from '../AddPatientGadgetForm';
import { useTranslation } from 'react-i18next';

interface IPatientGadgetsTab {}

export const PatientGadgetsTab: React.FunctionComponent<IPatientGadgetsTab> = () => {
  const { t } = useTranslation('PatientGadgetsTab');
  const [open, setOpen] = React.useState<boolean>(false);
  const { id: patientId } = useParams<{ id: string }>();
  const returnGadgetMutation = usePatientReturnGadget(Number(patientId));
  const assignGadgetMutation = usePatientAssignGadget();

  const { data } = usePatientGadgets(Number(patientId));
  const { canAny } = usePermission();
  const canManageDevices = canAny([PERMISSIONS.MANAGE_LOCAL_DEVICES, PERMISSIONS.MANAGE_ALL_DEVICES]);

  const [columns, historyData] = React.useMemo(() => {
    const columns: Array<Column<PatientGadget>> = [
      {
        Header: t('deviceManager'),
        accessor: 'model',
      },
      {
        Header: t('seriesNoHeader'),
        accessor: 'seriesNo',
      },
      {
        Header: t('stateHeader'),
        accessor: 'state',
        Cell: ({ value }: CellProps<PatientGadget, GadgetState>) => (
          <Tag className={GadgetStateColor[value]}>{getGadgetStateLabels(t)[value]}</Tag>
        ),
      },
      {
        Header: t('startDateHeader'),
        accessor: 'startDate',
        Cell: ({ value }) => (value ? <>{getFullYear(value)}</> : null),
      },
    ];
    if (canManageDevices) {
      columns.push({
        Header: t('endDateHeader'),
        accessor: 'endDate',
        Cell: ({ value, row }) =>
          value ? (
            <>{getFullYear(value)}</>
          ) : (
            <SmallButton handleClick={() => returnGadgetMutation.mutate(row.original.gadgetId)}>{t('returnLabel')}</SmallButton>
          ),
      });
    }

    return [columns, data];
  }, [data, returnGadgetMutation, canManageDevices, t]);

  function addGadget(values: { gadgetId: number }) {
    assignGadgetMutation.mutate(
      {
        ...values,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => setOpen(false),
      },
    );
  }

  if (!historyData) {
    return null;
  }
  return (
    <>
      <Table data={historyData} columns={columns} handleAddClick={canManageDevices ? () => setOpen(true) : undefined} />
      <Dialog open={open} handleCloseClick={() => setOpen(false)} widthAuto={true}>
        {open ? <AddPatientGadgetForm handleCancel={() => setOpen(false)} handleSave={addGadget} /> : <div />}
      </Dialog>
    </>
  );
};
