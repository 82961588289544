import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';
import { useDropzone } from 'react-dropzone';
import { errorToast } from '../../utils/errorToast';
import { CheckCircleIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface ITextInput {
  label: string;
  description?: string;
}

export const DropFileInput: React.FunctionComponent<FieldProps & ITextInput> = ({ field, form, ...props }) => {
  const { t } = useTranslation('DropFileInput');
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        // handle only single file
        form.setFieldValue(field.name, acceptedFiles[0]);
      }
    },
    [form, field],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: rejectedFiles => {
      if (rejectedFiles.length) {
        errorToast(t('invalidFormatToast', { fileName: rejectedFiles[0].file.name }));
      }
    },
    accept: ['image/png', 'image/jpeg', '.pdf', '.doc', '.docx'],
  });
  let cx = isDragActive ? 'border-primary' : 'border-gray-300';

  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <div
        {...getRootProps()}
        className={`mt-1 max-w-lg justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer hover:border-gray-400 ${cx}`}
      >
        <div className="space-y-1 text-center h-24">
          {!field.value && <DocumentPlusIcon className="h-12 w-12 text-gray-400 mx-auto heroicon-sw-1" />}
          {field.value && <CheckCircleIcon className="h-12 w-12 text-gray-400 mx-auto heroicon-sw-1" />}
          {!field.value && (
            <div className="flex text-sm text-gray-600">
              <input id={field.name} type="file" {...getInputProps()} />
              <p className="text-center w-full">
                <span className="relative cursor-pointer bg-white rounded-md font-medium text-primary hover:text-pink-500 focus-within:outline-none">
                  {t('selectFile')}
                </span>{' '}
                {t('orDragAndDropHere')}
              </p>
            </div>
          )}
          {field.value && (
            <div className="text-sm text-gray-600 ">
              <input id={field.name} type="file" {...getInputProps()} />
              <p className="truncate" title={field.value?.path}>
                {field.value?.path}
              </p>
            </div>
          )}
          {!field.value && <p className="text-xs text-gray-500">{t('supportedFormats')}</p>}
          {field.value && (
            <span
              className={
                'relative text-sm cursor-pointer bg-white rounded-md font-medium text-primary hover:text-pink-500 focus-within:outline-none'
              }
              onClick={e => {
                e.stopPropagation();
                form.setFieldValue(field.name, null);
              }}
            >
              {t('clearFile')}
            </span>
          )}
        </div>
      </div>
    </BaseInput>
  );
};
