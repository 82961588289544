import * as React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface IListboxDoctor {
  name?: string;
  speciality?: string;
  emptyText?: string;
  active?: boolean;
  selected?: boolean;
}

export const ListboxDoctor: React.FC<IListboxDoctor> = ({
  name,
  speciality,
  emptyText,
  active = false,
  selected = false,
}) => {
  if (!name) {
    return (
      <span className="w-full inline-flex truncate">
        <span className="truncate">{emptyText}</span>
      </span>
    );
  }
  return (
    <>
      <span className="w-full inline-flex truncate">
        <span className="truncate">{name}</span>
        <span className={`${active ? 'text-pink-200' : 'text-gray-500'} ml-2 truncate`}>{speciality}</span>
      </span>
      {selected ? (
        <span
          className={classNames(
            active ? 'text-white' : 'text-primary',
            'absolute inset-y-0 right-0 flex items-center pr-4',
          )}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      ) : null}
    </>
  );
};
