import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { FitbitService } from '../../utils/devices/FitbitService';

interface ResponseType {
  accountId: string;
  isAuthorized: boolean;
}

export const useFitbit = (patientId: number) => {
  const request = useRequest<ResponseType>();
  const queryResult = useQuery<unknown, unknown, ResponseType>(
    ['patient-fitbit', patientId],
    () => request(`/patients/${patientId}/fitbit`),
    {
      staleTime: 60 * 1000,
    },
  );

  return {
    ...queryResult,
    data: {
      ...queryResult.data,
      authUrl: FitbitService.getAuthUrl(Number(patientId)),
    },
  };
};
