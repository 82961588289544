import { BarController } from 'chart.js';
import { EcgRecordPeakDatum } from '../../../../contracts/Ecg';

export default class EcgPeaksChartType extends BarController {
  override draw() {
    const ctx = this.chart.ctx;
    const xScale = this.chart.scales['x2'];
    const yScale = this.chart.scales['y'];
    const data = this.getDataset().data as unknown as (EcgRecordPeakDatum & { x: number })[];
    const yZero = yScale.getPixelForValue(0);
    const { min: yMin, max: yMax } = yScale!;
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(0, 0, 0, .5)';
    ctx.lineWidth = 1;
    ctx.font = '100 12px Arial';
    data.forEach(dp => {
      const { x, Q, P0, P, P1, S, T, T1, T0, J } = dp;
      const Qx = typeof Q === 'number' ? xScale.getPixelForValue(x + Q) : null;
      const Rx = xScale.getPixelForValue(x);

      const P0x = typeof P0 === 'number' ? xScale.getPixelForValue(x + P0) : null;
      const Px = typeof P === 'number' ? xScale.getPixelForValue(x + P) : null;
      const P1x = typeof P1 === 'number' ? xScale.getPixelForValue(x + P1) : null;

      const Sx = typeof S === 'number' ? xScale.getPixelForValue(x + S) : null;
      const Jx = typeof J === 'number' ? xScale.getPixelForValue(x + J) : null;

      const T0x = typeof T0 === 'number' ? xScale.getPixelForValue(x + T0) : null;
      const Tx = typeof T === 'number' ? xScale.getPixelForValue(x + T) : null;
      const T1x = typeof T1 === 'number' ? xScale.getPixelForValue(x + T1) : null;

      // this.drawTopLegend(drawContext, 'Q', x + Qx, yMax, 4, -6, 50);

      ctx.moveTo(Rx, yZero);
      ctx.lineTo(Rx, yScale.getPixelForValue(yMax / 3));
      ctx.fillText('R', Rx - 3, yScale.getPixelForValue(yMax / 3 + 50), 1000);

      if (Qx !== null) {
        ctx.moveTo(Qx, yZero);
        ctx.lineTo(Qx, yScale.getPixelForValue(yMin / 1.5));
        ctx.fillText('Q', Qx - 6, yScale.getPixelForValue(yMin / 1.5 - 50), 1000);
      }

      if (Sx !== null) {
        ctx.moveTo(Sx, yZero);
        ctx.lineTo(Sx, yScale.getPixelForValue(yMin / 1.5));
        ctx.fillText('S', Sx - 4, yScale.getPixelForValue(yMin / 1.5 - 50), 1000);
      }

      if (Px !== null) {
        ctx.moveTo(Px, yZero);
        ctx.lineTo(Px, yScale.getPixelForValue(yMax / 5));
        ctx.fillText('P', Px - 3, yScale.getPixelForValue(yMax / 5 + 50), 1000);
      }

      if (Jx !== null) {
        ctx.moveTo(Jx, yZero);
        ctx.lineTo(Jx, yScale.getPixelForValue(yMax / 3));
        ctx.fillText('J', Jx - 3, yScale.getPixelForValue(yMax / 3 + 50), 1000);
      }

      if (Tx !== null) {
        ctx.moveTo(Tx, yZero);
        ctx.lineTo(Tx, yScale.getPixelForValue(yMax / 3));
        ctx.fillText('T', Tx - 3, yScale.getPixelForValue(yMax / 3 + 50), 1000);
      }

      if (P0x !== null) {
        ctx.moveTo(P0x, yZero);
        ctx.lineTo(P0x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('P0', P0x - 16, yScale.getPixelForValue(yMin / 3 - 25), 1000);
      }

      if (P1x !== null) {
        ctx.moveTo(P1x, yZero);
        ctx.lineTo(P1x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('P1', P1x + 3, yScale.getPixelForValue(yMin / 3 - 25), 1000);
      }

      if (P0x !== null && P1x !== null) {
        ctx.moveTo(P0x, yScale.getPixelForValue(yMin / 3));
        ctx.lineTo(P1x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('P', (P1x + P0x) / 2 - 3, yScale.getPixelForValue(yMin / 3 - 100), 1000);
      }

      if (T0x !== null) {
        ctx.moveTo(T0x, yZero);
        ctx.lineTo(T0x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('T0', T0x - 16, yScale.getPixelForValue(yMin / 3 - 25), 1000);
      }

      if (T1x !== null) {
        ctx.moveTo(T1x, yZero);
        ctx.lineTo(T1x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('T1', T1x + 3, yScale.getPixelForValue(yMin / 3 - 25), 1000);
      }

      if (T0x !== null && T1x !== null) {
        ctx.moveTo(T0x, yScale.getPixelForValue(yMin / 3));
        ctx.lineTo(T1x, yScale.getPixelForValue(yMin / 3));
        ctx.fillText('T', (T1x + T0x) / 2 - 3, yScale.getPixelForValue(yMin / 3 - 100), 1000);
      }
    });
    ctx.stroke();
  }
}

EcgPeaksChartType.id = 'EcgPeaksChartType';
