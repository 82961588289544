import { Question } from '../../../contracts/Poll';
import QuestionDescription from '../common/QuestionDescription';

export default function SectionQuestion(props: Question) {
  return (
    <div className="mt-12 mb-6">
      {props.text ? <div className="text-base leading-5 font-medium text-black mb-3">{props.text}</div> : null}
      <QuestionDescription>{props.description}</QuestionDescription>
    </div>
  );
}
