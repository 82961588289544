import { EcgRecordPeaksResponse } from '../../../../../../../contracts/Ecg';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { boxValues } from '../../utils/histograms';
import { useTranslation } from 'react-i18next';

interface Props {
  peaks: EcgRecordPeaksResponse;
}

const boxSize = 10;
const minAcceptableIntervalMs = 310;
const maxAcceptableIntervalMs = 330;

export default function StIntervalHistogram({ peaks }: Props) {
  const { t } = useTranslation('ecgCommon');
  const [data, options] = useMemo(() => {
    const { boxes, max } = boxValues(200, boxSize, peaks, (prev, curr) => {
      const value = curr.T1 - curr.J;
      return value > 0 ? value : 0;
    });
    const data: ChartData<'bar'> = {
      labels: boxes.map((x, i) => i),
      datasets: [
        {
          data: boxes,
          backgroundColor: ({ parsed: { x } }) =>
            x < minAcceptableIntervalMs / boxSize || x >= maxAcceptableIntervalMs / boxSize
              ? 'rgb(56,189,248)'
              : 'rgb(219,39,119)',
        },
      ],
    };

    const options: ChartOptions<'bar'> = {
      plugins: {
        legend: { display: false },
        tooltip: {
          callbacks: {
            title(tooltipItems: TooltipItem<any>[]): string {
              const x = Number(tooltipItems[0].parsed.x);
              if (x === 0) {
                return t('missedPPeak');
              }
              return `${x * boxSize}-${(x + 1) * boxSize} ms.`;
            },
            label(tooltipItem: TooltipItem<any>): string {
              return t('amount', { amount: Number(tooltipItem.parsed.y).toLocaleString('lt-LT') });
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          max: max,
          type: 'linear',
          grid: { drawBorder: true, drawTicks: false, lineWidth: 0 },
          ticks: {
            display: true,
            autoSkip: false,
            stepSize: 2,
            maxRotation: 0,
            callback: tickValue => {
              const expectedValue = (maxAcceptableIntervalMs + minAcceptableIntervalMs) / 2 / boxSize;
              if (Number(tickValue) === expectedValue) {
                return Number(tickValue) * boxSize;
              } else {
                return '';
              }
            },
          },
          title: { text: t('xScaleTitle'), display: true },
        },
        y: {
          beginAtZero: true,
          grid: { drawBorder: true, drawTicks: false, lineWidth: 1 },
          ticks: {
            callback: tickValue => Number(tickValue) / 1000,
          },
          title: { text: t('yScaleTitle'), display: true },
        },
      },
    };

    return [data, options];
  }, [peaks, t]);

  return <Bar data={data} height={160} options={options} />;
}
