import { PencilSquareIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

export default function EditIcon({ onClick }: { onClick: () => void }) {
  return (
    <PencilSquareIcon
      className="ml-0 mr-2 h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
      aria-hidden="false"
      onClick={onClick}
    />
  );
}
