import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { EcgAccidents, EcgRecordResponse } from '../../contracts/Ecg';
import { successToast } from '../../utils/successToast';
import { errorToast } from '../../utils/errorToast';
import { EcgAccidentType } from '../../pages/patients/components/tabs/ecg/utils/types';
import { useTranslation } from 'react-i18next';

interface Request {
  timestamp: number;
  eventsGroup?: EcgAccidentType;
  text: string;
}

export function useEcgEventCommentCreate(patientId: number, recordId: number) {
  const { t } = useTranslation('useEcgEventCommentCreate');
  const queryClient = useQueryClient();
  const request = useRequest<Request>();

  return useMutation<unknown, unknown, Request>(
    (req: Request) =>
      request(`/patients/${patientId}/ecgs/${recordId}/comments`, {
        body: JSON.stringify(req),
        method: 'POST',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData<EcgRecordResponse | undefined>(
          ['patient-ecg-record', `${patientId}${recordId}`],
          oldData => {
            if (oldData) {
              return {
                ...oldData,
                comments: [
                  ...oldData.comments,
                  { timestamp: request.timestamp, text: request.text, eventsGroup: 'sinusBradycardia' },
                ],
              };
            } else {
              return undefined;
            }
          },
        );

        if (request.eventsGroup) {
          queryClient.setQueryData<EcgAccidents | undefined>(
            ['patient-ecg-accidents', `${patientId}${recordId}`],
            oldData => {
              if (!oldData) {
                return undefined;
              }
              return {
                ...oldData,
                [request.eventsGroup as EcgAccidentType]: [
                  ...oldData[request.eventsGroup as EcgAccidentType],
                  request.timestamp,
                ],
              };
            },
          );
        }

        successToast(t('successToast'));
      },
      onError: () => {
        errorToast(t('errorToast'));
      },
    },
  );
}
