import { NullableType } from './Common';
import { BaseDoctorType } from './Doctors';

export type CreatePatientsThreadRequest = {
  patientId: number;
  assigneeId: number;
  text: NullableType<string>;
};

export type PatientThreadType = {
  assignee: BaseDoctorType;
  assigneeId: number;
  creator: BaseDoctorType;
  creatorId: number;
  isClosed: boolean;
  id: number;
  updatedOn: Date;
  messages: Array<ThreadMessageType>;
  status: ThreadStatus;
};

export type ThreadMessageType = {
  doctor: BaseDoctorType;
  doctorId: number;
  text: string;
  timestamp: Date;
  id: number;
};

export type ThreadMessageUpdateRequest = {
  patientId: number;
  assigneeId: number;
  threadId: number;
  messageId: number;
  text: string;
};

export type ThreadMessageReplyRequest = {
  assigneeId: number;
  patientId: number;
  threadId: number;
  text: string;
};

export type ThreadMessageAssignRequest = {
  patientId: number;
  threadId: number;
  assigneeId: number;
  text: string;
};

export type ThreadMessageCloseRequest = {
  patientId: number;
  threadId: number;
};

export interface ThreadStatusRequest {
  patientId: number;
  threadId: number;
  status: ThreadStatus;
}

export enum ThreadStatus {
  Open,
  Closed,
  OnHold,
}

export interface ThreadInboxCountResponse {
  count: number;
  status: ThreadStatus;
}

export interface ThreadInboxResponse {
  assigneeId: number;
  lastMessage: {
    doctor: {
      firstName: string;
      id: number;
      lastName: string;
      speciality: string;
    };
    doctorId: number;
    id: number;
    text: string;
    timestamp: string;
  };
  patient: {
    firstName: string;
    id: number;
    lastName: string;
  };
  status: ThreadStatus;
  threadId: number;
}
