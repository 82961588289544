import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface Props {
  filter?: string;
  setFilter: (filter: string) => void;
}

export default function TableSearchInput({ filter = '', setFilter }: Props) {
  return (
    <div className="w-80 mb-6 self-end">
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="global_search"
          id="global_search"
          className="focus:ring-primary focus:border-primary block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Paciento paieška"
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      </div>
    </div>
  );
}
