import { Column } from 'react-table';
import timeCellRenderer from '../Renderers/TimeCellRenderer';
import { TFunction } from 'i18next';

export default function timeCell<T extends object>(
  t: TFunction,
  title: string,
  accessor: keyof T,
  width?: number,
): Column<T> {
  return {
    Header: title,
    accessor,
    Cell: timeCellRenderer(t),
    width,
  };
}
