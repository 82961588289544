import { PropsWithChildren } from 'react';

import { DashboardMainMenu } from './DashboardMainMenu';
import MobileSupportBanner from '../common/MobileSupportBanner';
import LoadingContainer from './LoadingContainer';

interface IDashboardLayout {
  isLoading?: boolean;
}

export default function DashboardLayout({ children, isLoading = false }: PropsWithChildren<IDashboardLayout>) {
  return (
    <div>
      <MobileSupportBanner />
      <DashboardMainMenu />
      <main>
        <div className="max-w-7xl mx-auto py-8 sm:px-6 lg:px-8">
          <LoadingContainer loading={isLoading}>{children}</LoadingContainer>
        </div>
      </main>
    </div>
  );
}
