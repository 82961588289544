import { EcgAccidents, EcgRecord } from '../../../../../../contracts/Ecg';
import { EcgEvent, EcgEventGroup, REGULAR_EVENT_GROUPS, SPECIAL_EVENT_GROUPS } from './types';
import { TFunction } from 'i18next';

export const getEventGroup = (accidents: EcgAccidents, record: EcgRecord, t: TFunction): EcgEventGroup[] => {
  const deletedEvents: EcgEvent[] = record.artifacts
    .map(a => ({
      isDeleted: true,
      timestamp: a.timestamp,
      eventGroup: a.eventType,
      name: t(a.eventType, { count: 1 }) ?? t('unknownTypeLabel'),
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const commentEvents: EcgEvent[] = record.comments
    .map(c => ({
      isDeleted: deletedEvents.some(e => e.timestamp === c.timestamp),
      timestamp: c.timestamp,
      eventGroup: c.eventType,
      name: t(c.eventType, { count: 1 }) ?? t('unknownTypeLabel'),
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const regularGroups: EcgEventGroup[] = REGULAR_EVENT_GROUPS.map(eg => {
    const events: EcgEvent[] =
      accidents[eg.eventGroup]
        ?.map((timestamp: number) => ({
          name: t(eg.eventGroup),
          isDeleted: record.artifacts.some(art => art.timestamp === timestamp),
          timestamp: timestamp,
          comment: record.comments.find(com => com.timestamp === timestamp)?.text,
          eventGroup: eg.eventGroup,
        }))
        .sort((a, b) => a.timestamp - b.timestamp) ?? [];
    return {
      id: eg.eventGroup,
      name: t(eg.eventGroup, { count: 10 }),
      events,
      count: events.length,
    };
  });
  const allEvents: EcgEvent[] = [
    ...regularGroups.map(g => g.events).flat(),
    ...commentEvents.filter(c => !c.eventGroup),
  ].sort((a, b) => a.timestamp - b.timestamp);

  const specialGroups: EcgEventGroup[] = [
    { id: 'all', events: allEvents, name: t(SPECIAL_EVENT_GROUPS[0].eventGroup), count: allEvents.length },
    { id: 'comment', events: commentEvents, name: t(SPECIAL_EVENT_GROUPS[1].eventGroup), count: commentEvents.length },
    { id: 'artifact', events: deletedEvents, name: t(SPECIAL_EVENT_GROUPS[2].eventGroup), count: deletedEvents.length },
  ];

  return [...specialGroups, ...regularGroups];
};

export const getResolutionAdjustedXValueForSpecialAxis = (secondsPerPage: number, startTime: number, x: number) => {
  if (secondsPerPage === 2) {
    return (x - startTime) * 1.1 - 101;
  }

  if (secondsPerPage === 5) {
    return (x - startTime) * 1.1 - 249;
  }

  if (secondsPerPage === 10) {
    return (x - startTime) * 1.1 - 498;
  }

  if (secondsPerPage === 15) {
    return (x - startTime) * 1.124 - 929;
  }
  return x - startTime;
};
