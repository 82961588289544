import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  BarElement,
  Chart,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScaleChartOptions,
  TimeScale,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DeepPartial } from 'chart.js/types/utils';
import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';

interface IBarChart {
  displayLegend?: boolean;
  data: ChartData<'bar'>;
  annotation?: Record<string, AnnotationOptions>;
  scales?: DeepPartial<ScaleChartOptions<'bar'>>;
}

Chart.register(TimeScale, LinearScale, PointElement, LineElement, BarElement, annotationPlugin, Legend);

export default function BarChart({ data, scales, annotation, displayLegend = false }: IBarChart) {
  return (
    <div>
      <Bar
        data={data}
        height={80}
        options={{
          plugins: {
            legend: {
              display: displayLegend,
            },
            annotation: {
              annotations: annotation,
            },
          },
          locale: 'lt-LT',
          ...scales,
        }}
      />
    </div>
  );
}
