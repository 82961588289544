import { useParams } from 'react-router-dom';
import { usePatient } from '../../../../../../hooks/queries/usePatient';
import React from 'react';
import { getFullYear } from '../../../../../../utils/date';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../../../../../translations/i18n';

export const PrintHealthCarePlanHeader = () => {
  const { t } = useTranslation('PrintHealthCarePlanHeader');
  const { id } = useParams<{ id: string }>();
  const { data: patient } = usePatient(Number(id));
  if (!patient) {
    return null;
  }
  return (
    <div className="hidden print:block">
      <div className="flex justify-between items-center mb-8">
        <div className="text-2xl leading-7 tracking-tight">{t('healthcarePlan')}</div>
        <div className="text-base leading-7 font-medium">{getFullYear(new Date())}</div>
      </div>
      <div className="flex items-start mb-8 text-gray-900 text-sm">
        <div className="w-1/2">
          <div className="mt-4 text-left sm:mt-0 sm:pt-1 sm:text-left">
            <p className="font-medium leading-5">{t('patient', { ns: 'common' })}</p>
            <p className="text-lg font-medium leading-10">{getLocalizedFullName(patient)}</p>
          </div>
        </div>
        {patient.caseManager && (
          <div className="w-1/2">
            <div className="mt-4 text-left sm:mt-0 sm:pt-1 sm:text-left">
              <p className="font-medium leading-5">{t('caseManager', { ns: 'common' })}</p>
              <p className="text-lg font-medium leading-10">{getLocalizedFullName(patient.caseManager)}</p>
              <p className="font-medium">{patient.caseManager.phone}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
