import { Column, ColumnInstance, IdType, SortingRule, TableOptions } from 'react-table';
import classNames from 'classnames';
import { SuperSearchFilterOption, SuperSearchPredicateType } from './SuperSearch/SuperSearchUtils';

interface SuperSearchColumnProps {
  canSuperFilter?: boolean;
  superSearchPredicateType?: SuperSearchPredicateType;
  superSearchPredicateOptions?: SuperSearchFilterOption[];
}

export type ExtendedColumnInstanceProps<T extends object> = ColumnInstance<T> &
  SuperSearchColumnProps & { className?: string };

export type ExtendedColumnProps<T extends object> = Column<T> & SuperSearchColumnProps & { className?: string };

export function getThProps(column: ExtendedColumnInstanceProps<any>) {
  return column.getHeaderProps(
    column.getSortByToggleProps({
      style: { width: column.width, minWidth: column.minWidth },
      // @ts-ignore
      className: classNames(
        'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider select-none dark:text-gray-300',
        column.className,
      ),
    }),
  );
}

export const getChevronUpClassNames = ({ isSorted, isSortedDesc }: ExtendedColumnInstanceProps<any>) =>
  classNames(
    'w-4 -mb-1 ml-2',
    !isSorted || isSortedDesc ? 'text-gray-300 dark:text-gray-600' : 'text-gray-500 dark:text-gray-300',
  );

export const getChevronDownClassNames = ({ isSorted, isSortedDesc }: ExtendedColumnInstanceProps<any>) =>
  classNames(
    'w-4 -mt-1 ml-2',
    !isSorted || !isSortedDesc ? 'text-gray-300 dark:text-gray-600' : 'text-gray-500 dark:text-gray-300',
  );

export const getTableOptions = <T extends object>(
  columns: Column<T>[],
  data: T[],
  autoResetPage: boolean,
  pageSize: number = 10,
  pageIndex: number = 0,
  hiddenColumns: IdType<string | number>[] = [],
  sortBy: SortingRule<T>[] = [],
  fetchData?: boolean,
  pageCount = -1,
): TableOptions<T> => {
  const options: TableOptions<T> = {
    columns,
    data,
    autoResetPage,
    initialState: {
      pageSize,
      pageIndex,
      hiddenColumns,
      sortBy,
    },
  };

  if (fetchData === true) {
    options.manualPagination = fetchData;
    options.pageCount = pageCount;
  }

  return options;
};
