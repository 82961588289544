import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';

interface ITextInput {
  label: string;
  description?: string;
  type?: string;
}

export const TextInput: React.FunctionComponent<FieldProps & ITextInput> = ({
  field,
  form,
  type = 'text',
  ...props
}) => {
  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <input
        type={type}
        id={field.name}
        {...field}
        {...props}
        className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-500 dark:border-gray-400 dark:text-gray-300"
      />
    </BaseInput>
  );
};
