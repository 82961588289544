import * as React from 'react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import classNames from 'classnames';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { SuperSearchSettings, SuperSearchTab } from '../../components/common/Table/SuperSearch/SuperSearchUtils';
import { getTimeTodayOrDateOtherwise } from '../../utils/date';
import { ExtendedColumnProps } from '../../components/common/Table/TableUtilities';
import ThreadStatusTag from './ThreadStatusTag';
import { ThreadInboxResponse, ThreadStatus } from '../../contracts/Threads';
import { getFirstLineText } from '../../components/richText/richTextUtils';
import { tryParseJson } from '../../utils/errors';
import { TFunction } from 'i18next';

const NotificationsCell = ({
  cell,
  pending,
  className = '',
  children,
}: PropsWithChildren<{
  cell: CellProps<ThreadInboxResponse>;
  pending?: boolean;
  className?: string;
}>) => (
  <div className={classNames(cell.row.original.status === ThreadStatus.Open && pending && 'font-semibold', className)}>
    {children}
  </div>
);

export const getColumns = (t: TFunction, userId: number): ExtendedColumnProps<ThreadInboxResponse>[] => [
  {
    id: 'date',
    Header: t('table.dateColHeader', { ns: 'Notifications' }),
    width: '10%',
    accessor: ({ lastMessage: { timestamp } }) => new Date(timestamp),
    sortInverted: false,
    sortDescFirst: true,
    sortType: 'datetime',
    Cell: (cell: CellProps<ThreadInboxResponse>) => (
      <NotificationsCell cell={cell} pending={cell.row.original.assigneeId === userId}>
        {getTimeTodayOrDateOtherwise(cell.value)}
      </NotificationsCell>
    ),
  },
  {
    Header: t('table.patientColHeader', { ns: 'Notifications' }),
    width: '16%',
    accessor: ({ patient: { firstName, lastName } }) => `${firstName} ${lastName}`,
    Cell: (cell: CellProps<ThreadInboxResponse>) => (
      <NotificationsCell cell={cell} pending={cell.row.original.assigneeId === userId}>
        {cell.value}
      </NotificationsCell>
    ),
  },
  {
    Header: t('table.doctorColHeader', { ns: 'Notifications' }),
    width: '16%',
    accessor: ({
      lastMessage: {
        doctor: { firstName, lastName },
      },
    }) => `${firstName} ${lastName}`,
    Cell: (cell: CellProps<ThreadInboxResponse>) => (
      <NotificationsCell cell={cell} pending={cell.row.original.assigneeId === userId}>
        {cell.value}
      </NotificationsCell>
    ),
  },
  {
    Header: t('table.messageColHeader', { ns: 'Notifications' }),
    width: '48%',
    accessor: ({ lastMessage: { text } }) => text,
    Cell: (cell: CellProps<ThreadInboxResponse>) => (
      <NotificationsCell
        cell={cell}
        className="w-96 truncate overflow-ellipsis"
        pending={cell.row.original.assigneeId === userId}
      >
        {cell.value.indexOf('[{') === 0 ? getFirstLineText(tryParseJson(cell.value)) : cell.value}
      </NotificationsCell>
    ),
  },
  {
    Header: t('table.statusColHeader', { ns: 'Notifications' }),
    id: 'status',
    disableSortBy: true,
    width: '1%',
    canSuperFilter: true,
    accessor: ({ status }: { status: ThreadStatus }) => status.toString(),
    Cell: (cell: CellProps<ThreadInboxResponse>) => (
      <div className="flex justify-end">
        {cell.row.original.assigneeId === userId && (
          <InboxArrowDownIcon
            className={classNames('w-6 h-6', cell.row.original.assigneeId === userId && 'text-gray-600')}
          />
        )}
        <ThreadStatusTag status={Number(cell.value)} className="ml-4" />
      </div>
    ),
  },
];

export const superSearchSettings: SuperSearchSettings = {
  hideSettings: true,
  hideFilters: true,
  hideGlobalFilter: true,
};

export const getSuperSearchTabs = (t: TFunction): SuperSearchTab[] => [
  {
    id: 0,
    name: t('table.superSearchTab0', { ns: 'Notifications' }),
    configuration: [],
    hasChanges: false,
    persisted: false,
  },
  {
    id: 1,
    name: t('table.superSearchTab1', { ns: 'Notifications' }),
    configuration: [{ columnAccessor: 'status', selectedValues: [0] }],
    hasChanges: false,
    persisted: false,
  },
  {
    id: 2,
    name: t('table.superSearchTab2', { ns: 'Notifications' }),
    configuration: [{ columnAccessor: 'status', selectedValues: [2] }],
    hasChanges: false,
    persisted: false,
  },
];
