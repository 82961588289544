import PatientEcgLayout from '../components/PatientEcgLayout';
import { useEcgPeaks } from '../../../../../../hooks/queries/useEcgPeaks';
import Card from '../../../../../../components/common/Card';
import RrIntervalHistogram from '../components/charts/rrIntervalHistogram';
import PrIntervalHistogram from '../components/charts/prIntervalHistogram';
import StIntervalHistogram from '../components/charts/stIntervalHistogram';
import QrsIntervalHistogram from '../components/charts/qrsIntervalHistogram';
import LoadingContainer from '../../../../../../components/layouts/LoadingContainer';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function EcgIntervalsPage() {
  const { t } = useTranslation('EcgIntervalsPage');
  const { patientId: patientIdString, ecgRecordId } = useParams<{ patientId: string; ecgRecordId: string }>();
  const patientId = Number(patientIdString);
  const recordId = Number(ecgRecordId);

  const { data, isLoading } = useEcgPeaks(patientId, recordId, true);

  return (
    <PatientEcgLayout>
      <LoadingContainer loading={isLoading}>
        <div className={'grid grid-cols-2'}>
          <Card title={t('rrInterval')} className={'mr-2 mb-4'}>
            <RrIntervalHistogram peaks={data!} />
          </Card>
          <Card title={t('prInterval')} className={'ml-2 mb-4'}>
            <PrIntervalHistogram peaks={data!} />
          </Card>
          <Card title={t('stSegment')} className={'mr-2'}>
            <StIntervalHistogram peaks={data!} />
          </Card>

          <Card title={t('qrsComplex')} className={'ml-2'}>
            <QrsIntervalHistogram peaks={data!} />
          </Card>
        </div>
      </LoadingContainer>
    </PatientEcgLayout>
  );
}
