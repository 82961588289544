import * as React from 'react';
import { usePatientMedications } from '../../../../hooks/queries/usePatientMedications';
import { useParams } from 'react-router-dom';
import Table from '../../../../components/common/Table/Table';
import Dialog from '../../../../components/dialog/Dialog';
import { AddPatientMedicationForm } from '../AddPatientMedicationForm';
import { usePatientMedicationCreate } from '../../../../hooks/mutations/usePatientMedicationCreate';
import { usePatientMedicationUpdate } from '../../../../hooks/mutations/usePatientMedicationUpdate';
import { usePatientMedicationDelete } from '../../../../hooks/mutations/usePatientMedicationDelete';
import { PatientMedicamentType } from '../../../../contracts/Patient';
import Confirmation from '../../../../components/common/Confirmation';
import { PERMISSIONS, usePermission } from '../../../../hooks/usePermission';
import { Column } from 'react-table';
import { NullableType } from '../../../../contracts/Common';
import EditIcon from '../../../../components/icons/EditIcon';
import RemoveIcon from '../../../../components/icons/RemoveIcon';
import { useTranslation } from 'react-i18next';

interface IPatientMedicationsTab {}

export const PatientMedicationsTab: React.FunctionComponent<IPatientMedicationsTab> = () => {
  const { t } = useTranslation('PatientMedicationsTab');
  const [open, setOpen] = React.useState<NullableType<PatientMedicamentType> | 'add'>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean | number>(false);
  const createPatientMedicationMutation = usePatientMedicationCreate();
  const updatePatientMedicationMutation = usePatientMedicationUpdate();
  const deletePatientMedicationMutation = usePatientMedicationDelete();
  const { id: patientId } = useParams<{ id: string }>();
  const { data } = usePatientMedications(Number(patientId));
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const [columns, medicationsData] = React.useMemo(() => {
    const columns: Array<Column<PatientMedicamentType>> = [
      {
        Header: t('nameHeader'),
        accessor: 'name',
      },
      {
        Header: t('usageHeader'),
        accessor: 'usage',
      },
      {
        Header: t('commentsHeader'),
        accessor: 'comments',
      },
    ];
    if (canManagePatients) {
      columns.push({
        Header: t('editHeader'),
        accessor: 'id',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => (
          <div className="flex my-1">
            <div>
              <EditIcon onClick={() => setOpen(row.original)} />
            </div>
            <div>
              <RemoveIcon onClick={() => setOpenDeleteConfirmation(value)} />
            </div>
          </div>
        ),
      });
    }

    return [columns, data];
  }, [data, canManagePatients, t]);

  function addMedication(values: PatientMedicamentType) {
    createPatientMedicationMutation.mutate(
      {
        ...values,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => setOpen(null),
      },
    );
  }

  function updateMedication(values: PatientMedicamentType) {
    updatePatientMedicationMutation.mutate(
      {
        ...values,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => setOpen(null),
      },
    );
  }

  function deleteMedication(id: number) {
    deletePatientMedicationMutation.mutate(
      {
        id: id,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => setOpenDeleteConfirmation(false),
      },
    );
  }

  if (!medicationsData) {
    return null;
  }

  return (
    <>
      <Table
        data={medicationsData}
        columns={columns}
        handleAddClick={canManagePatients ? () => setOpen('add') : undefined}
      />
      <Dialog open={!!open} handleCloseClick={() => setOpen(null)} widthAuto={true}>
        {!!open ? (
          <AddPatientMedicationForm
            handleCancel={() => setOpen(null)}
            handleSave={open === 'add' ? addMedication : updateMedication}
            medication={open === 'add' ? undefined : open}
          />
        ) : null}
      </Dialog>
      <Confirmation
        id={Number(openDeleteConfirmation)}
        open={!!openDeleteConfirmation}
        handleCancel={() => setOpenDeleteConfirmation(false)}
        handleSubmit={deleteMedication}
      />
    </>
  );
};
