import * as React from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { classNames } from '../../utils/classNames';

interface ISwitch {
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const Switch: React.FunctionComponent<ISwitch> = ({ checked, onChange }) => {
  return (
    <HeadlessSwitch
      checked={checked}
      onChange={onChange}
      className="shrink-0 relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          checked ? 'bg-primary' : 'bg-gray-200',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow ring-0 transition-transform ease-in-out duration-200',
        )}
      />
    </HeadlessSwitch>
  );
};
