import SmallButton from '../../../../../components/buttons/SmallButton';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid';
import Dialog from '../../../../../components/dialog/Dialog';
import ScaleDialogForm from '../../../../../components/poll/ScaleDialogForm';
import * as React from 'react';
import { usePollScales } from '../../../../../hooks/mutations/usePollScales';
import { PollScalesRequest } from '../../../../../contracts/Poll';
import { useScales } from '../../../../../hooks/queries/useScales';
import { useTranslation } from 'react-i18next';

interface IScalesDialog {
  pollId: number;
  patientId: number;
}

export const ScalesDialog: React.FunctionComponent<IScalesDialog> = props => {
  const { t } = useTranslation('ScalesDialog');
  const submitScalesChangeMutation = usePollScales(props.patientId, props.pollId);
  const [isScaleDialogOpen, setScaleDialogOpen] = React.useState(false);
  const { data: scales, refetch, isFetching } = useScales(props.patientId, props.pollId);

  React.useEffect(() => {
    if (isScaleDialogOpen) {
      refetch();
    }
  }, [isScaleDialogOpen, refetch]);

  function handlePollScalesSubmit(requestBody: PollScalesRequest) {
    submitScalesChangeMutation.mutate(requestBody, {
      onSuccess: () => setScaleDialogOpen(false),
    });
  }

  return (
    <span className="float-right mt-3">
      <SmallButton
        handleClick={() => setScaleDialogOpen(true)}
        icon={
          <AdjustmentsVerticalIcon className="-ml-1 mr-2 hidden xl:block h-4 w-4 text-gray-500" aria-hidden="true" />
        }
        className="md:px-2 xl:px-6"
      >
        {t('scalesLabel')}
      </SmallButton>
      <Dialog open={isScaleDialogOpen} handleCloseClick={() => setScaleDialogOpen(false)} widthAuto={true}>
        {scales && !isFetching ? (
          <ScaleDialogForm
            scales={scales}
            handleCancel={() => setScaleDialogOpen(false)}
            handleSave={handlePollScalesSubmit}
          />
        ) : null}
      </Dialog>
    </span>
  );
};
