import SectionTitle from '../../../../../components/common/SectionTitle';
import * as React from 'react';
import { HcpDialog } from './HcpDialog';
import { usePatientHcp } from '../../../../../hooks/queries/usePatientHcp';
import { useParams } from 'react-router-dom';
import SerializeToHtml from '../../../../../components/richText/SerializeToHtml';
import { PrintHealthCarePlanHeader } from './components/PrintHealthCarePlanHeader';
import Button from '../../../../../components/buttons/Button';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { Switch } from '../../../../../components/form/Switch';
import { usePatientHcpStatusUpdate } from '../../../../../hooks/mutations/usePatientHcpStatusUpdate';
import { successToast } from '../../../../../utils/successToast';
import { useTranslation } from 'react-i18next';

interface IPatientHcpTab {}

export const PatientHcpTab: React.FunctionComponent<IPatientHcpTab> = () => {
  const { t } = useTranslation('PatientHcpTab');
  const { id: patientId } = useParams<{ id: string }>();
  const { data } = usePatientHcp(Number(patientId));
  const statusMutation = usePatientHcpStatusUpdate();

  function statusUpdateHandler(isComplete: boolean) {
    statusMutation.mutate(
      {
        isComplete,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => {
          successToast(t('updateSuccessMessage'));
        },
      },
    );
  }

  if (!data) {
    return null;
  }
  return (
    <div className={'print:mx-12 print:my-3'}>
      <PrintHealthCarePlanHeader />
      <div className="print:hidden mb-12 flex justify-between items-center">
        <div>
          <h2 className={'text-gray-900 text-xl leading-7 font-medium'}>{t('healthcarePlan')}</h2>
        </div>
        <div className={'flex space-x-10'}>
          <div className={'flex space-x-1.5 items-center'}>
            <Switch checked={data.isComplete} onChange={statusUpdateHandler} />
            <span className={`text-sm font-medium`}>{t('completed')}</span>
          </div>
          <Button onClick={() => window.print()} type={'secondary'}>
            <div className="flex items-center">
              <PrinterIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />
              {t('print')}
            </div>
          </Button>
        </div>
      </div>
      {data.sections.map(section => (
        <div key={section.name} className={`${!section.isPrintable ? 'print:hidden' : ''} relative`}>
          <div className="text-right absolute right-0 print:-mt-16">
            <HcpDialog
              initialValue={section.text ? JSON.parse(section.text) : ''}
              title={section.name}
              sectionId={section.id}
            />
          </div>
          <SectionTitle title={section.name} description={section.description}>
            <div className="flex flex-col -mb-12 print:-mb-16">
              <SerializeToHtml content={section.text ?? section.sample} />
            </div>
          </SectionTitle>
        </div>
      ))}
    </div>
  );
};
