import * as React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../../../components/form/TextInput';
import { PatientMedicamentType } from '../../../contracts/Patient';
import { AutocompleteInput } from '../../../components/form/AutocompleteInput';
import { useMedications } from '../../../hooks/queries/useMedications';
import { MedicationType } from '../../../contracts/Classifiers';
import { TextArea } from '../../../components/form/TextArea';
import DialogForm from '../../../components/layouts/dialog-form/DialogForm';
import {useTranslation} from "react-i18next";

interface IAddPatientMedicationForm {
  handleCancel: () => void;
  handleSave: (values: PatientMedicamentType) => void;
  medication?: PatientMedicamentType | undefined;
}

const MedicationFormSchema = Yup.object().shape({
  name: Yup.string().required(),
});

export const AddPatientMedicationForm: React.FunctionComponent<IAddPatientMedicationForm> = props => {
  const {t} = useTranslation('AddPatientMedicationForm');
  const { data: medications } = useMedications();
  const { medication } = props;

  const initialValues = {
    id: 0,
    name: '',
    comments: '',
    usage: '',
  };

  return (
    <DialogForm
      title={medication ? medication.name : t('dialogTitle')}
      initialValues={medication ?? initialValues}
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
      validationSchema={MedicationFormSchema}
    >
      {({ setFieldValue }) => (
        <div className="sm:flex sm:items-start w-full">
          <div className="flex flex-col space-y-6 w-full">
            {!medication && (
              <Field
                component={AutocompleteInput}
                items={medications}
                itemString={(item: MedicationType) => item?.name}
                onChange={(item: MedicationType) => {
                  if (item) {
                    setFieldValue('id', item?.id);
                    setFieldValue('name', item?.name);
                  } else {
                    setFieldValue('id', 0);
                    setFieldValue('name', '');
                  }
                }}
                onBlur={(inputValue: string) => {
                  setFieldValue('name', inputValue);
                }}
                name={'name'}
                label={t('drugNameLabel')}
              />
            )}
            <Field
              component={TextInput}
              name={'usage'}
              label={t('drugUsageLabel')}
              description={t('drugUsageDescription')}
            />
            <Field
              component={TextArea}
              name={'comments'}
              label={t('notesLabel')}
              description={t('notesDescription')}
            />
          </div>
        </div>
      )}
    </DialogForm>
  );
};
