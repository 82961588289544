import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextInput } from '../../../components/form/TextInput';
import { PatientTargetType } from '../../../contracts/Patient';
import Button from '../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';

interface IEditPatientTargetForm {
  handleCancel: () => void;
  handleSave: (values: PatientTargetType) => void;
  target: PatientTargetType;
}

export const EditPatientTargetForm: React.FunctionComponent<IEditPatientTargetForm> = props => {
  const { t } = useTranslation('EditPatientTargetForm');
  return (
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {props.target ? `${props.target.name}, ${props.target.units}` : t('enterGoalsHeader')}
      </h3>
      <div className="mt-6">
        <Formik
          initialValues={Object.assign({ min: '', from: '', to: '', max: '' }, props.target)}
          onSubmit={props.handleSave}
        >
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="flex space-x-6 w-full">
                <Field component={TextInput} name={'min'} label={t('minLabel')} />
                <Field component={TextInput} name={'from'} label={t('fromLabel')} />
                <Field component={TextInput} name={'to'} label={t('toLabel')} />
                <Field component={TextInput} name={'max'} label={t('maxLabel')} />
              </div>
            </div>
            <div className="mt-6 sm:flex sm:flex-row-reverse">
              <Button buttonType="submit">{t('save', { ns: 'common' })}</Button>
              <Button type="secondary" onClick={props.handleCancel}>
                {t('cancel', { ns: 'common' })}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
