/* eslint-disable react-hooks/exhaustive-deps */
import { IAlertsSearchParams, StatusFiltersEnum, useAlerts } from '../../hooks/queries/useAlerts';
import * as React from 'react';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { AlertLevelColor, AlertType, getAlertLevelLabelArray } from '../../contracts/Alert';
import { PatientInline } from '../patient/PatientInline';
import { getFullTime } from '../../utils/date';
import Tag from '../common/Tag';
import Button from '../buttons/Button';
import { NullableType } from '../../contracts/Common';
import ListSelect from '../common/ListSelect';
import ButtonGroup from '../buttons/ButtonGroup';
import Table from '../common/Table/Table';
import Dialog from '../dialog/Dialog';
import { AlertForm } from '../../pages/alerts/AlertForm';
import { AlertsRequest, useAlertsUpdate } from '../../hooks/mutations/useAlertsUpdate';
import { successToast } from '../../utils/successToast';
import { useTranslation } from 'react-i18next';

export default function AlertListComponent({ patientId }: { patientId?: number }) {
  const [open, setOpen] = React.useState<NullableType<number>>(null);
  const alertsMutation = useAlertsUpdate();
  const { t } = useTranslation('AlertListComponent');
  const dateFilters = useMemo(
    () => [
      {
        title: t('7days'),
        value: 7,
      },
      {
        title: t('30days'),
        value: 30,
      },
      {
        title: t('90days'),
        value: 90,
      },
      {
        title: t('all'),
        value: undefined,
      },
    ],
    [t],
  );

  const statusFilters = useMemo(
    () => [
      {
        value: StatusFiltersEnum.Unprocessed,
        text: t('unprocessed'),
      },
      {
        value: StatusFiltersEnum.Processed,
        text: t('processed'),
      },
      {
        value: StatusFiltersEnum.All,
        text: t('all'),
      },
    ],
    [t],
  );

  const [searchParams, setSearchParams] = React.useState<IAlertsSearchParams>({
    filter: StatusFiltersEnum.Unprocessed,
    pageIndex: 0,
    pageSize: 10,
    days: dateFilters[dateFilters.length - 1].value,
    patientId,
  });

  const { data, isLoading } = useAlerts(searchParams);
  const pageCount: number = React.useMemo(() => {
    if (!data) {
      return 0;
    }
    return Math.ceil(data.totalCount / (searchParams.pageSize ?? 10));
  }, [data, searchParams.pageSize]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setSearchParams({ ...searchParams, pageSize, pageIndex });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams.filter, searchParams.days, searchParams.patientId, searchParams.pageIndex],
  );

  const [columns, tableData] = React.useMemo(() => {
    const columns: Array<Column<AlertType>> = [
      {
        Header: t('timestamp'),
        accessor: 'timestamp',
        Cell: ({ value }) => <>{getFullTime(value)}</>,
      },
      {
        Header: t('metric'),
        accessor: 'metric',
        Cell: ({ row }) => <>{row.original.metric}</>,
      },
      {
        Header: t('target'),
        accessor: 'target',
        Cell: ({ row }) => <>{row.original.target}</>,
      },
      {
        Header: t('message'),
        accessor: 'message',
        Cell: ({ row }) => <>{row.original.message}</>,
      },
      {
        Header: t('level'),
        accessor: 'level',
        Cell: ({ value }) => <Tag className={AlertLevelColor[value]}>{getAlertLevelLabelArray(t)[value]}</Tag>,
      },
      {
        Header: t('isProcessed'),
        accessor: 'isProcessed',
        Cell: ({ value, row }) => (
          <Button onClick={() => setOpen(row.original.id)} type={value ? 'secondary' : 'primary'}>
            {t(value ? 'edit' : 'process')}
          </Button>
        ),
      },
    ];
    if (!patientId) {
      columns.unshift({
        Header: t('patient'),
        accessor: 'patient',
        Cell: ({ value }) => <PatientInline {...value} />,
      });
    }

    let _data = data?.result;

    return [columns, _data];
  }, [data, patientId, t]);

  return (
    <>
      {!isLoading && tableData && (
        <div>
          <div className="flex justify-between pb-8">
            <div>
              <ListSelect
                items={dateFilters}
                selected={searchParams.days}
                setSelected={days => setSearchParams({ ...searchParams, days })}
              />
            </div>
            <div className="flex justify-end">
              <ButtonGroup
                buttons={statusFilters}
                handleClick={currentActive =>
                  setSearchParams({
                    ...searchParams,
                    filter: currentActive,
                    pageIndex: 0,
                  })
                }
                active={searchParams.filter}
              />
            </div>
          </div>
          <Table
            data={tableData}
            columns={columns}
            autoResetPage={true}
            pageCount={pageCount}
            pageIndex={searchParams.pageIndex}
            fetchData={fetchData}
          />
        </div>
      )}
      <Dialog open={!!open} handleCloseClick={() => setOpen(null)}>
        {open !== null ? (
          <AlertForm
            patientId={patientId}
            alertId={open}
            handleCancel={() => setOpen(null)}
            handleSubmit={(data: AlertsRequest) => {
              alertsMutation.mutate(data, {
                onSuccess: () => {
                  setOpen(null);
                  successToast(t('alertSubmitted'));
                },
              });
            }}
          />
        ) : null}
      </Dialog>
    </>
  );
}
