export const TRANSLATIONS_LT = {
  formats: {
    dateShort: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd HH:mm',
    time: 'HH:mm',
  },
  common: {
    edit: 'Redaguoti',
    save: 'Išsaugoti',
    delete: 'Ištrinti',
    rename: 'Pervadinti',
    yes: 'Taip',
    no: 'Ne',
    cancel: 'Atšaukti',
    back: 'Atgal',
    next: 'Pirmyn',
    close: 'Uždaryti',
    systemError: 'Sistemos klaida',
    patient: 'Pacientas',
    name: 'Vardas',
    surname: 'Pavardė',
    gender: 'Lytis',
    clinic: 'Klinika',
    betaFeature: 'BETA',
    caseManager: 'Atvejo vadybininkas',
  },
  validation: {
    required: 'Laukas yra privalomas',
    default: 'Laukas neteisingai užpildytas',
    email: 'El.pašto formatas nėra teisingas',
    fullDateFormat: 'Datos formatas yyyy-MM-dd',
  },
  Breadcrumbs: {
    Home: 'Pradžia',
  },
  Confirmation: {
    title: 'Ištrinti įrašą?',
    yes: 'Taip, ištrinti',
    no: 'Atšaukti',
  },
  DeleteConfirmationWithInput: {
    title: 'Ištrinti įrašą?',
    message: 'Patvirtinimui įrašykite "taip".',
    yes: 'Ištrinti',
    no: 'Atšaukti',
    requiredConfirmationText: 'taip',
  },
  LastSync: {
    updated: 'Atnaujinta: {{date}}',
  },
  ProfileMenu: {
    signOut: 'Atsijungti',
    language: 'Kalba',
    languages: {
      en: 'EN',
      lt: 'LT',
      pl: 'PL',
      ru: 'РУ',
    },
  },
  DashboardMainMenu: {
    patients: 'Pacientai',
    alerts: 'Pranešimai',
    notifications: 'Užklausos',
    gadgets: 'Įranga',
    doctors: 'Gydytojai',
    analyses: 'Tyrimai',
    help: 'Pagalba',
  },
  AlertLevelValue: {
    info: 'Informacija',
    warning: 'Dėmesio',
    danger: 'Pavojus',
  },
  AlertListComponent: {
    '7days': '7 dienos',
    '30days': '30 dienų',
    '90days': '90 dienų',
    unprocessed: 'Neapdoroti',
    processed: 'Apdoroti',
    timestamp: 'Laikas',
    metric: 'Rodiklis',
    target: 'Tikslas',
    message: 'Reikšmė',
    level: 'Lygis',
    isProcessed: 'Būsena',
    edit: 'Redaguoti',
    process: 'Apdoroti',
    patient: 'Pacientas',
    alertSubmitted: 'Pranešimas apdorotas',
    all: 'Visi',
  },
  MobileSupportBanner: {
    message: 'Siūlome Chrodis programėlę naudoti kompiuteryje',
  },
  SuperSearch: {
    saveFilter: 'Išsaugoti filtrą',
    renameFilter: 'Pervadinti filtrą',
    deleteFilter: 'Ištrinti filtrą?',
    deleteFilterInquiry: 'Ar tikrai norite ištrinti filtrą?',
    name: 'Pavadinimas',
  },
  SuperSearchFilterPopover: {
    header: 'Filtrai',
  },
  DropFileInput: {
    invalidFormatToast: 'Netinkamas dokumento {{fileName}} formatas.',
    selectFile: 'Pasirinkite failą',
    orDragAndDropHere: 'arba užtempkite jį čia',
    supportedFormats: 'PNG, JPG, DOC, PDF iki 10MB',
    clearFile: 'Pašalinti failą',
  },
  MultiFileInput: {
    invalidFormatToast: 'Netinkamas dokumento {{fileName}} formatas.',
    selectFile: 'Pasirinkite failus',
    orDragAndDropHere: 'arba užtempkite jį čia',
    supportedFormats: 'PNG, JPG, DOC, PDF iki 10MB',
    clearFile: 'Pašalinti failą',
  },
  RichTextInput: {
    heading1: 'Heading 1',
    heading2: 'Heading 2',
    normal: 'Normal',
    boldAlt: 'Bold',
    italicAlt: 'Italic',
    underlineAlt: 'Underline',
    numberedListAlt: 'Numbered list',
    bulettedListAlt: 'Bulleted list',
  },
  ScaleDialogForm: {
    title: 'Anketos skalės',
  },
  useSuperSearchPersistence: {
    filterSaved: 'Filtras "{{name}}" išsaugotas',
  },
  Alert: {
    info: 'Info',
    warning: 'Dėmesio',
    danger: 'Pavojus',
  },
  DoctorRoleLabels: {
    familyDoctor: 'Šeimos gydytojas',
    physician: 'Specialistas',
    expert: 'Ekspertas',
  },
  GadgetStateLabels: {
    ok: 'Veikia',
    broken: 'Sugedęs',
    lost: 'Pamestas',
  },
  MetricsTitleByType: {
    physicalActivity: 'Fizinis aktyvumas, min',
    hr: 'ŠSD, k/min',
    hrMin: 'ŠSD min, k/min',
    hrMax: 'ŠSD max, k/min',
    steps: 'Žingsniai',
    sleep: 'Miegas',
    weight: 'Svoris, kg',
    bpSys: 'sAKS, mmHg',
    bpDia: 'dAKS, mmHg',
  },
  MetricsSleepCell: {
    dateHeader: 'Data',
    wakeHeader: 'Nemiga, val.',
    remHeader: 'REM, val.',
    lightHeader: 'Negilus, val.',
    deepHeader: 'Gilus, val.',
    napHeader: 'Dienos miegas, val.',
    deep: 'Gilus',
    light: 'Lengvas',
    rem: 'REM',
    wake: 'Pabudęs',
    nap: 'Dienos miegas',
    chartTooltipHoursFormat: '{{hours}} val. {{minutes}} min.',
    chartTooltipMinutesFormat: '{{minutes}} min.',
  },
  // Hooks
  useEcgEventArtifactCreate: {
    successToast: 'Įvykis perkeltas į šiukšliadėžę',
    errorToast: 'Įvykis perkelti į šiukšliadėžę nepavyko',
  },
  useEcgEventArtifactDelete: {
    successToast: 'Įvykis atkurtas iš šiukšliadėžę',
    errorToast: 'Įvykio atkurti iš šiukšliadėžės nepavyko',
  },
  useEcgEventCommentCreate: {
    successToast: 'Įvykio komentaras išsaugotas',
    errorToast: 'Įvykio komentaro išsaugoti nepavyko',
  },
  // Pages
  AlertForm: {
    table: {
      timestamp: 'Laikas',
      metric: 'Rodiklis',
      target: 'Tikslas',
      message: 'Reikšmė',
      level: 'Lygis',
      patient: 'Pacientas',
    },
    title: 'Apdoroti pranešimą',
    commentLabel: 'Pastabos',
    processSimilar: 'Apdoroti panašius pranešimus',
    submit_zero: 'Adoroti {{count}} pranešimų',
    submit_one: 'Adoroti {{count}} pranešimą',
    submit_other: 'Apdoroti {{count}} pranešimus',
  },
  AlertList: {
    breadcrumb: 'Pranešimai',
  },
  NotFound: {
    title: '404',
    heading: 'Puslapis nerastas',
    message: 'Patikrinkite puslapio adresą',
    back: 'Grįžti į pradžią',
  },
  DoctorForm: {
    chooseRoleValidationError: 'Pasirinkite pareigas',
    dialogTitle: 'Gydytojas',
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: '- Pasirinkite kliniką -',
    firstNameLabel: 'Vardas',
    lastNameLabel: 'Pavardė',
    specialtyLabel: 'Specialybė',
    roleLabel: 'Pareigos',
    rolePlaceholder: '- Pasirinkite pareigas -',
    emailLabel: 'El. paštas',
    phoneLabel: 'Telefonas',
  },
  DoctorsList: {
    table: {
      nameColHeader: 'Vardas Pavardė',
      specialtyColHeader: 'Specialybė',
      clinicColHeader: 'Klinika',
      roleColHeader: 'Pareigos',
      phoneColHeader: 'Telefonas',
      editColHeader: 'Redaguoti',
    },
    addDoctorSuccessToast: 'Gydytojas sukurtas',
    updateDoctorSuccessToast: 'Gydytojo informacija atnaujinta',
  },
  PatientNotAvailable: {
    header: 'Pacientas nerastas',
    message: 'Tokio paciento nėra arba jis nedalyvauja tyrime',
    linkText: 'Pacientų sąrašas',
  },
  PatientDetails: {
    patientPollTab: 'Kortelė',
    patientMetricsTab: 'Rodikliai',
    patientAlertsListTab: 'Pranešimai',
    threadListTab: 'Užklausos',
    patientAnamnesisTab: 'Anamnezė',
    patientHcpTab: 'Planas',
    patientFeedTab: 'Dienynas',
    patientAnalysesTab: 'Tyrimai',
    patientMedicationsTab: 'Vaistai',
    patientDocumentsTab: 'Dokumentai',
    patientGadgetsTab: 'Įranga',
    patientSettingsTab: 'Nustatymai',
  },
  Faq: {
    header: 'Dažniausiai užduodami klausimai',
    subHeader: 'Nerandate atsakymo? Parašykite',
    inquiryEmail: 'pagalba@chrodis.lt',
    faqSection: {
      title: 'Chrodis paskyra',
      q1Question: 'Kaip sukurti naują Chrodis paskyrą?',
      q1Answer:
        'Naudotojo paskyras Chrodis pacientams ir gydytojams kuria sistemos administratorius. Prireikus naujos paskyros, parašykite <a href="mailto:pagalba@chrodis.lt" class="text-primary">pagalba@chrodis.lt</a>',
      q2Question: 'Kaip pakeisti naudotojo slaptažodį?',
      q2Answer:
        'Atsijunkite nuo Chrodis, prisijungimo lange spauskite <b>Pamiršote slaptažodį?</b> ir sekite tolesnes instrukcijas.',
    },
  },
  AddGadgetForm: {
    title: 'Pridėti prietaisą',
    modelIdLabel: 'Modelis',
    modelIdPlaceholder: 'Pasirinkite',
    seriesNoLabel: 'Serijos numeris',
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: 'Pasirinkite',
  },
  EditGadgetForm: {
    table: {
      patientColHeader: 'Pacientas',
      startDateColHeader: 'Nuo',
      endDateColHeader: 'Iki',
    },
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: 'Pasirinkite',
    stateLabel: 'Būklė',
  },
  GadgetList: {
    table: {
      modelColHeader: 'Modelis',
      seriesNoColHeader: 'Serijos numeris',
      clinicColHeader: 'Klinika',
      patientColHeader: 'Pacientas',
      stateColHeader: 'Būklė',
      editColHeader: 'Redaguoti',
    },
  },
  Notifications: {
    breadcrumb: 'Užklausos',
    table: {
      dateColHeader: 'Data',
      patientColHeader: 'Pacientas',
      doctorColHeader: 'Gydytojas',
      messageColHeader: 'Žinutė',
      statusColHeader: ' ',
      superSearchTab0: 'Visos',
      superSearchTab1: 'Neatsakytos',
      superSearchTab2: 'Atidėtos',
    },
  },
  AnalysisParametersForm: {
    minError: 'Per didelė reikšmė',
    maxError: 'Per maža reikšmė',
    groupIdError: 'Pasirinkite grupę',
    title: 'Tyrimas',
    nameLabel: 'Sutrumpinimas',
    groupIdLabel: 'Grupė',
    groupIdPlaceholder: '- Pasirinkite grupę -',
    descriptionLabel: 'Aprašymas',
    unitLabel: 'Matmuo',
    femaleNormHeader: 'Norma moterims',
    maleNormHeader: 'Norma vyrams',
    minLabel: 'Min',
    maxLabel: 'Max',
  },
  AnalysesList: {
    table: {
      nameColHeader: 'Tyrimas',
      descriptionColHeader: 'Aprašymas',
      unitColHeader: 'Matmuo',
      femaleMinColHeader: 'Norma moterims',
      maleMinColHeader: 'Norma vyrams',
      editColHeader: 'Redaguoti',
    },
    updateSuccessToast: 'Tyrimas sukurtas',
    createSuccessToast: 'Tyrimas pridėtas',
    deleteSuccessToast: 'Tyrimas ištrintas',
  },
  PatientList: {
    table: {
      patientColHeader: 'Pacientas',
      ageColHeader: 'Amžius',
      diagnosesColHeader: 'Diagnozė',
      gadgetsColHeader: 'Gadžeto reikšmė',
      alarmsColHeader: 'Pranešimai',
      lastSyncColHeader: 'Atnaujinta',
      clinicColHeader: 'Klinika',
      clinicIdColHeader: 'Klinika',
      familyDoctorIdColHeader: 'Šeimos gydytojas',
      superSearchTab0: 'Visi',
      superSearchGlobalFilterLabel: 'Paciento paieška...',
    },
    breadcrumb: 'Pacientai',
  },
  PatientAlertsListTab: {
    messages: 'Pranešimai',
  },
  AddPatientAnalysisForm: {
    header: 'Pridėti tyrimą',
    dateLabel: 'Tyrimo data',
    analysisIdLabel: 'Tyrimo grupė',
    selectAnalysisIdLabel: 'Pasirinkite',
    parameterIdLabel: 'Tyrimas',
    selectParameterIdLabel: 'Pasirinkite',
    valueLabel: 'Reikšmė',
  },
  ParametersDialog: {
    successMessage: 'Parametrai atnaujinti',
    parametersLabel: 'Parametrai',
  },
  ParametersDialogForm: {
    dialogHeader: 'Tyrimų parametrai',
  },
  PatientAnalysesTab: {
    header: 'Tyrimai',
    tableHeader: 'Dokumentai',
    dateHeader: 'Data',
    startDateHeader: 'Stebėjimo pradžia',
    endDateHeader: 'Stebėjimo pabaiga',
    parametersHeader: 'Parametras',
    unitHeader: 'VNT.',
    minHeader: 'Norma',
    submitSuccessMessage: 'Tyrimas išsaugotas',
    ekgLabel: 'EKG',
  },
  AnamnesisDialog: {
    saveSuccessMessage: 'Anamnezė išsaugota',
    dialogTitle: 'Anamnezė',
  },
  PatientAnamnesisTab: {
    anamnesisTableTitle: 'Anamnezė',
    documentsTableTitle: 'Dokumentai',
  },
  AddPatientDiagnosisForm: {
    dialogTitle: 'Įvesti diagnozę',
    diagnosisLabel: 'Diagnozė',
    selectDiagnosis: 'Pasirinkite',
    commentsLabel: 'Pastabos',
  },
  DiagnosesSection: {
    codeHeader: 'Kodas',
    nameHeader: 'Diagnozė',
    commentsHeader: 'Pastabos',
    diagnosesTableTitle: 'Diagnozės',
    successMessage: 'Diagnozė {{code}} - {{name}} pridėta',
  },
  EditPatientDiagnosisForm: {
    commentsLabel: 'Pastabos',
  },
  AddPatientDocumentForm: {
    dialogTitle: 'Dokumentas',
    fileLabel: 'Failas',
    commentsLabel: 'Pastabos',
  },
  PatientDocuments: {
    fileNameLabel: 'Dokumentas',
    commentsLabel: 'Pastabos',
    uploadedOnUtcLabel: 'Įkelta',
    successMessage: 'Dokumentas įkeltas',
  },
  ecgCommon: {
    missedPPeak: 'Praleistas P pikas',
    amount: '{{count}} kartai(/ų)',
    xScaleTitle: 't, ms',
    yScaleTitle: 'N x 1000',
  },
  EcgDailyBreakdown: {
    cardTitle: 'Paros informacija',
  },
  SummaryTable: {
    pulse: 'Pulsas',
    ventricularExtrasystoles: 'Skilvelinės ekstrasistolės',
    supraventricularExtrasystoles: 'Prieširdinės ekstrasistolės',
    beats: 'Dūžiai',
    total: 'Viso',
    mean: 'Vidutinis',
    pairs: 'Poros',
    min: 'Min.',
    bigeminies: 'Bigemijos',
    max: 'Maks.',
    trigeminies: 'Trigemijos',
    pauses: 'Trūkiai',
  },
  EcgCommentModal: {
    title: 'Pažymėti įvykį',
    submitLabel: 'Išsaugoti',
    eventTypeLabel: 'Įvykio tipas',
    selectEventType: '- Pasirinkite įvykio tipą -',
    commentLabel: 'Komentaras',
  },
  Layers: {
    aligned: 'Išlygiuotas',
    denoised: 'Nutriukšmintas',
    raw: 'Neapdorotas',
    peaks: 'Pikai',
    millimeterPaper: 'Milimetrinis popierius',
    signalsLabel: 'Signalai:',
  },
  TimePickerPopOver: {
    hours: 'Valandos',
    minutes: 'Minutės',
  },
  EcgHourlyBreakdown: {
    cardTitle: 'Valandinė ataskaita',
    time: 'Laikas',
    pulse: 'Pulsas',
    skExtrasystoles: 'SK ekstrasistolės',
    prExtrasystoles: 'PR ekstrasistolės',
    noise: 'Triukšmas',
    fromHeader: 'Nuo',
    toHeader: 'Iki',
    beatsHeader: 'Dūžiai',
    meanHeader: 'Vid.',
    minHeader: 'Min.',
    maxHeader: 'Maks.',
    pausesHeader: 'Trūkiai',
    totalHeader: 'Viso',
    pairsHeader: 'Poros',
    snrHeader: 'Dalis',
  },
  PatientEcgComponent: {
    badTimestamp: 'Neteisinga laiko atžyma',
  },
  PatientEcgLayout: {
    breadcrumbPatients: 'Pacientai',
    breadcrumbAnalyses: 'Tyrimai',
    breadcrumbECG: 'EKG',
    linkDashboard: 'Apžvalga',
    linkAccidents: 'Incidentai',
    linkViewer: 'Kardiograma',
    linkIntervals: 'Intervalai',
  },
  useEcgChunkSections: {
    alignedChunk: 'Išlygiuotas',
    denoisedChunk: 'Nutriukšmintas',
    rawChunk: 'Originalus',
  },
  EcgIntervalsPage: {
    rrInterval: 'RR intervalas',
    prInterval: 'PR intervalas',
    stSegment: 'ST segmentas',
    qrsComplex: 'QRS kompleksas',
  },
  EcgAccidentsPage: {
    unknownTypeLabel: 'Nežinomas tipas',
  },
  ecgEventGroups: {
    supraventricularBeats_one: 'Prieširdinė ekstrasistolė',
    supraventricularBeats_other: 'Prieširdinių ekstrasistolių',
    supraventricularBeats_few: 'Prieširdinės ekstrasistolės',

    supraventricularCouplets_one: 'Prieširdinė pora',
    supraventricularCouplets_other: 'Prieširdinių porų',
    supraventricularCouplets_few: 'Prieširdinės poros',

    supraventricularBigeminies_one: 'Prieširdinė bigemija',
    supraventricularBigeminies_other: 'Prieširdinių bigemijų',
    supraventricularBigeminies_few: 'Prieširdinės bigemijos',

    supraventricularTrigeminies_one: 'Prieširdinė trigemija',
    supraventricularTrigeminies_other: 'Prieširdinių trigemijų',
    supraventricularTrigeminies_few: 'Prieširdinės trigemijos',

    ventricularBeats_one: 'Skilvelinė ekstrasistolė',
    ventricularBeats_other: 'Skilvelinių ekstrasistolių',
    ventricularBeats_few: 'Skilvelinės ekstrasistolės',

    ventricularCouplets_one: 'Skilvelinė pora',
    ventricularCouplets_other: 'Skilvelinių porų',
    ventricularCouplets_few: 'Skilvelinės poros',

    ventricularBigeminies_one: 'Skilvelinė bigemija',
    ventricularBigeminies_other: 'Skilvelinių bigemijų',
    ventricularBigeminies_few: 'Skilvelinės bigemijos',

    ventricularTrigeminies_one: 'Skilvelinė trigemija',
    ventricularTrigeminies_other: 'Skilvelinių trigemijų',
    ventricularTrigeminies_few: 'Skilvelinės trigemijos',

    sinusBradycardia_one: 'Sinusinė bradikardija',
    sinusBradycardia_other: 'Sinusinių bradikardijų',
    sinusBradycardia_few: 'Sinusinės bradikardijos',

    sinusTachycardia_one: 'Sinusinė tachikardija',
    sinusTachycardia_other: 'Sinusinių tachikardijų',
    sinusTachycardia_few: 'Sinusinės tachikardijos',

    all: 'Visi',
    comment: 'Su komentarais',
    artifact: 'Ištrinti',
  },
  PatientFeedRecordForm: {
    dialogTitleEdit: 'Įrašo redagavimas',
    dialogTitleCreate: 'Naujas įrašas',
    createLabel: 'Pridėti',
    commentsLabel: 'Pastabos',
    filesLabel: 'Dokumentai',
  },
  PatientFeedTab: {
    saveSuccessMessage: 'Įrašas išsaugotas',
    updateSuccessMessage: 'Įrašas atnaujintas',
    deleteSuccessMessage: 'Įrašas buvo pašalintas',
  },
  PrintHealthCarePlanHeader: {
    healthcarePlan: 'Sveikatos priežiūros planas',
  },
  HcpDialog: {
    saveSuccessMessage: 'Planas išsaugotas',
  },
  PatientHcpTab: {
    updateSuccessMessage: 'Statusas atnaujintas',
    healthcarePlan: 'Sveikatos priežiūros planas',
    completed: 'Užbaigtas',
    print: 'Spausdinti',
  },
  useDynamicTables: {
    dateHeader: 'Data',
  },
  MetricCell: {
    goalLessThan: 'Tikslas mažiau nei {{targetMax}}',
    goalMoreThan: 'Tikslas daugiau nei {{targetMin}}',
  },
  MetricHistoryForm: {
    dialogTitle: 'Pridėti reikšmę',
    submitLabel: 'Pridėti',
    dateLabel: 'Data',
    valueLabel: 'Reikšmė',
  },
  MetricsHistory: {
    oneWeek: '1 sav.',
    oneMonth: '1 mėn.',
    threeMonths: '3 mėn.',
    twelveMonths: '12 mėn.',
    header: 'Istorija',
    sleepCellName: 'Miegas',
  },
  PatientPoll: {
    pollsBreadcrumb: 'Anketos',
    forPatient: 'Pacientui',
    forDoctor: 'Gydytojui',
  },
  PatientPollQuestions: {
    unanswered: 'Neatsakyti',
    unknown: 'Nežinoma',
  },
  PatientPollTab: {
    dateHeader: 'Data',
    completionPercentHeader: 'Užpildyta',
    commentsHeader: 'Pastabos',
    pollsTitle: 'Anketos',
    deleteSuccessMessage: 'Anketa ištrinta',
  },
  ScalesDialog: {
    scalesLabel: 'Skalės',
  },
  ThreadActions: {
    replySuccessMessage: 'Atsakymas išsaugotas',
    closeSuccessMessage: 'Užklausa uždaryta',
    holdSuccessMessage: 'Užklausa atidėta',
    answered: 'Atsakytas',
    answer: 'Atsakyti',
    hold: 'Atidėti',
    close: 'Uždaryti',
    threadMessageTitle: 'Atsakyti užklausą',
    threadMessageTextArea: 'Atsakymas',
    threadMessageButton: 'Atsakyti',
  },
  ThreadAssignForm: {
    title: 'Klausimo persiuntimas',
  },
  ThreadListTab: {
    updateSuccessMessage: 'Klausimas atnaujintas',
  },
  ThreadMessageForm: {
    selectDoctorValidationMessage: 'Pasirinkite gydytoją',
    dialogTitle: 'Redaguoti klausimą',
    questionLabel: 'Klausimas',
    assigneeLabel: 'Atsakingas asmuo',
  },
  PatientGadgetsTab: {
    deviceHeader: 'Prietaisas',
    seriesNoHeader: 'Serijos numeris',
    stateHeader: 'Būsena',
    startDateHeader: 'Gauta',
    endDateHeader: 'Grąžinta',
    returnLabel: 'Grąžinti',
  },
  PatientMedicationsTab: {
    nameHeader: 'Pavadinimas',
    usageHeader: 'Vartojimas',
    commentsHeader: 'Pastabos',
    editHeader: 'Redaguoti',
  },
  HealthStepBar: {
    targetsMin: 'Minimalus',
    targetsFrom: 'Optimalus nuo',
    targetsTo: 'Optimalus iki',
    targetsMax: 'Maksimalus',
  },
  PatientSettingsTab: {
    targetsTableTitle: 'Tikslai',
    targetsNameHeader: 'Rodiklis',
    targetsMinHeader: 'Minimalus',
    targetsFromHeader: 'Optimalus nuo',
    targetsToHeader: 'Optimalus iki',
    targetsMaxHeader: 'Maksimalus',
    targetsEditHeader: 'Redaguoti',
    devicesTableTitle: 'Paskyros',
    devicesNameHeader: 'Paskyra',
    devicesAccountIdHeader: 'Paskyros ID',
    devicesIsAuthorizedHeader: 'Prijungti',
    devicesAuthenticatedLabel: 'Prijungta',
    devicesLoginLabel: 'Prijungti',
    goalsUpdated: 'Tikslai atnaujinti',
  },
  AddPatientForm: {
    invalidWeight: 'Neteisingas svoris',
    invalidHeight: 'Neteisingas ūgis',
    dateOfBirthLabel: 'Gimimo data',
    female: 'Moteris',
    male: 'Vyras',
    heightLabel: 'Ūgis, cm',
    weightLabel: 'Svoris, kg',
    emailLabel: 'El. paštas',
    phoneNoLabel: 'Telefonas',
    selectClinic: '- Pasirinkite kliniką -',
    selectCaseManager: '- Pasirinkite -',
    familyDoctorLabel: 'Šeimos gydytojas',
    selectFamilyDoctor: '- Pasirinkite -',
  },
  AddPatientGadgetForm: {
    dialogTitle: 'Priskirti prietaisą',
    serialNoLabel: 'Serijos numeris',
  },
  AddPatientMedicationForm: {
    dialogTitle: 'Vaistas',
    drugNameLabel: 'Pavadinimas',
    drugUsageLabel: 'Vartojimas',
    drugUsageDescription: 'Kiek kartų per dieną, kuriuo metu',
    notesLabel: 'Pastabos',
    notesDescription: 'Kodėl vartojamas, kiek laiko',
  },
  DoctorConsultationForm: {
    selectDoctorValidationMessage: 'Pasirinkite gydytoją',
    doctorLabel: 'Gydytojas',
    selectDoctorLabel: '- Pasirinkite gydytoją -',
    questionLabel: 'Klausimas',
    sendLabel: 'Siųsti',
  },
  DoctorConsultationPopup: {
    consultationRequestSent: 'Konsultacijos užklausa išsiųsta',
    dialogTitle: 'Konsultacija gydytojas-gydytojas',
    consultLabel: 'Konsultuotis',
  },
  EditPatientTargetForm: {
    enterGoalsHeader: 'Įvesti tikslus',
    minLabel: 'Minimalus',
    fromLabel: 'Optimalus nuo',
    toLabel: 'Optimalus iki',
    maxLabel: 'Maksimalus',
  },
};
