import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import H3 from '../typography/H3';

interface Props {
  title: string;
  className?: string;
  noPadding?: boolean;
}

export default function Card({ title, className, children, noPadding }: PropsWithChildren<Props>) {
  return (
    <div>
      <H3 children={title} className={classNames('mb-4')} />
      <div className={classNames('rounded-md shadow border mb-8', !noPadding && 'p-4', className)}>{children}</div>
    </div>
  );
}
