import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DocumentType, DocumentTypeEnum } from '../../contracts/Document';

type PatientDocumentDeleteRequest = {
  id: number;
  patientId: number;
  documentType: DocumentTypeEnum;
};

export function usePatientDocumentDelete() {
  const queryClient = useQueryClient();
  const request = useRequest();
  return useMutation<unknown, unknown, PatientDocumentDeleteRequest>(
    ({ patientId, id }) =>
      request(`/patients/${patientId}/documents/${id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, request) => {
        queryClient.setQueryData(
          ['patient-documents', request.patientId, request.documentType],
          (oldData: Array<DocumentType> = []) => oldData.filter(doc => doc.id !== request.id),
        );
      },
    },
  );
}
