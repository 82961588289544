import { useTranslation } from 'react-i18next';

export default function MobileSupportBanner() {
  const { t } = useTranslation('MobileSupportBanner');
  return (
    <div className="relative bg-primary lg:hidden max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 font-medium text-white sm:text-center print:hidden">
      {t('message')}
    </div>
  );
}
