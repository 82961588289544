import * as React from 'react';
import Button from '../../buttons/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  isSubmitting?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  onCancelClicked: () => void;
}

export default function DialogFormControls({ submitLabel, cancelLabel, isSubmitting, onCancelClicked }: Props) {
  const { t } = useTranslation('common');
  return (
    <>
      <div className="mt-6 sm:flex sm:flex-row-reverse">
        <Button buttonType="submit" isLoading={isSubmitting}>
          {submitLabel ?? t('save')}
        </Button>
        <Button type="secondary" disabled={isSubmitting} onClick={onCancelClicked}>
          {cancelLabel ?? t('cancel')}
        </Button>
      </div>
    </>
  );
}
