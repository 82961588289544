import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';
import { PropsWithChildren } from 'react';

interface ISelectInput {
  label?: string;
}

export const SelectInput = ({ field, form, children, ...props }: PropsWithChildren<FieldProps & ISelectInput>) => {
  return (
    <BaseInput field={field} form={form} label={props.label}>
      <select
        {...field}
        {...props}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
      >
        {children}
      </select>
    </BaseInput>
  );
};
