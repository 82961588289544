import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DiagnosisType } from '../../contracts/Classifiers';
import { NullableType } from '../../contracts/Common';

export type PatientAnalysisRequest = {
  patientId: number;
  parameterId: NullableType<number>;
  analysisId: NullableType<number>;
  value: NullableType<number>;
  date: Date | string;
};

export function usePatientAnalysis() {
  const queryClient = useQueryClient();
  const request = useRequest<DiagnosisType>();
  return useMutation<DiagnosisType, unknown, PatientAnalysisRequest>(
    ({ patientId, ...data }) =>
      request(`/patients/${patientId}/analyses`, {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(['patient-analyses', request.patientId]);
      },
    },
  );
}
