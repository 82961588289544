import * as React from 'react';
import { usePatientFeed } from '../../../../../hooks/queries/usePatientFeed';
import { useParams } from 'react-router-dom';
import CircularButton from '../../../../../components/buttons/CircularButton';
import Dialog from '../../../../../components/dialog/Dialog';
import { PatientFeedRecordForm, PatientFeedRecordFormType } from './PatientFeedRecordForm';
import { usePatientFeedCreate } from '../../../../../hooks/mutations/feed/usePatientFeedCreate';
import { successToast } from '../../../../../utils/successToast';
import { FeedRecordType } from '../../../../../contracts/Feed';
import Confirmation from '../../../../../components/common/Confirmation';
import { usePatientFeedDelete } from '../../../../../hooks/mutations/feed/usePatientFeedDelete';
import { usePatientFeedUpdate } from '../../../../../hooks/mutations/feed/usePatientFeedUpdate';
import FeedItem from '../../../../../components/feed/FeedItem';
import { useMe } from '../../../../../hooks/queries/useMe';
import Feed from '../../../../../components/feed/Feed';
import { tryParseJson } from '../../../../../utils/errors';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../../../../translations/i18n';

interface IPatientFeedTab {}

export const PatientFeedTab: React.FunctionComponent<IPatientFeedTab> = () => {
  const { t } = useTranslation('PatientFeedTab');
  const [open, setOpen] = React.useState<FeedRecordType | boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<number | boolean>(false);
  const { id: patientId } = useParams<{ id: string }>();
  const { data } = usePatientFeed(Number(patientId));
  const { data: meData } = useMe();
  const patientFeedCreateMutation = usePatientFeedCreate();
  const patientFeedUpdateMutation = usePatientFeedUpdate();
  const patientFeedDeleteMutation = usePatientFeedDelete();

  function handleFormSubmit(values: PatientFeedRecordFormType) {
    if (values.id) {
      handleUpdate(values);
    } else {
      handleSave(values);
    }
  }

  function handleSave(values: PatientFeedRecordFormType) {
    patientFeedCreateMutation.mutate(
      {
        patientId: Number(patientId),
        ...values,
      },
      {
        onSuccess: () => {
          setOpen(false);
          successToast(t('saveSuccessMessage'));
        },
      },
    );
  }

  function handleUpdate(values: PatientFeedRecordFormType) {
    patientFeedUpdateMutation.mutate(
      {
        patientId: Number(patientId),
        ...values,
      },
      {
        onSuccess: () => {
          setOpen(false);
          successToast(t('updateSuccessMessage'));
        },
      },
    );
  }

  function handleDelete(medicalRecordId: number) {
    patientFeedDeleteMutation.mutate(
      {
        medicalRecordId,
        patientId: Number(patientId),
      },
      {
        onSuccess: () => {
          successToast(t('deleteSuccessMessage'));
          setOpenDeleteConfirmation(false);
        },
      },
    );
  }

  return (
    <>
      <div>
        <div className={'text-right pr-0 -mb-8 -mt-2 self-end z-10 relative'}>
          <CircularButton onClick={() => setOpen(true)} />
        </div>
      </div>
      {data && data.length > 0 && (
        <Feed>
          {data.map((item, index) => (
            <FeedItem
              name={getLocalizedFullName(item.doctor)}
              headline={item.doctor.speciality}
              date={item.dateTime}
              content={item.comments}
              isLast={index === data.length - 1}
              isActionsVisible={!!(meData && item.doctor.id === meData.id)}
              handleEditClick={() => setOpen(item)}
              handleDeleteClick={() => setOpenDeleteConfirmation(item.id)}
              documents={item.documents}
              patientId={Number(patientId)}
              key={item.id}
            />
          ))}
        </Feed>
      )}
      <Dialog open={!!open} handleCloseClick={() => setOpen(false)} widthAuto={true}>
        <PatientFeedRecordForm
          handleSave={handleFormSubmit}
          handleCancel={() => setOpen(false)}
          feedRecord={typeof open === 'boolean' ? undefined : { ...open, comments: tryParseJson(open.comments) }}
        />
      </Dialog>
      <Confirmation
        id={Number(openDeleteConfirmation)}
        open={!!openDeleteConfirmation}
        handleCancel={() => setOpenDeleteConfirmation(false)}
        handleSubmit={handleDelete}
      />
    </>
  );
};
