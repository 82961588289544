import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { DoctorRequestType, DoctorType } from '../../../contracts/Doctors';

export function useDoctorsUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<DoctorType>();
  return useMutation<DoctorType, unknown, DoctorRequestType>(
    ({ id, ...data }) =>
      request(`/doctors/${id}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['doctors'], (oldData: Array<DoctorType> = []) =>
          oldData.map(doctor => (doctor.id === data.id ? { ...data } : { ...doctor })),
        );
      },
    },
  );
}
