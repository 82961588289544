import { AlertLevel, AlertLevelColor, getAlertLevelLabelArray } from '../../contracts/Alert';
import Tag from './Tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

enum COLORS {
  Danger = 'bg-red-800',
  Warning = 'bg-amber-600',
  Info = 'bg-gray-300',
}

const Badge = ({ value }: { value: AlertLevel }) => {
  const { t } = useTranslation('Badge');

  return (
    <div className="text-center -bottom-6 absolute w-full">
      <Tag className={AlertLevelColor[value]}>{getAlertLevelLabelArray(t)[value]}</Tag>
    </div>
  );
};

export default function HealthStepBar() {
  const { t } = useTranslation('HealthStepBar');

  return (
    <div className="w-full mb-12 mt-2">
      <ol className={'flex items-center rounded-full'}>
        <li className="relative w-1/5 h-4">
          <div className="absolute inset-0 flex items-center">
            <div className={`rounded-l-full h-1 w-full ${COLORS.Danger}`} />
          </div>
          <Badge value={AlertLevel.Danger} />
        </li>
        <li className="relative w-1/5 h-4">
          <div className="absolute inset-0 flex items-center">
            <div className={`h-1 w-full ${COLORS.Warning}`} />
          </div>
          <Badge value={AlertLevel.Warning} />
          <div className={`relative w-4 h-4 -ml-2 flex items-center justify-center rounded-full ${COLORS.Warning}`}>
            <span className="-mt-10 text-xs font-medium uppercase whitespace-nowrap">{t('targetsMin')}</span>
          </div>
        </li>
        <li className="relative w-1/5 h-4">
          <div className="absolute inset-0 flex items-center">
            <div className={`h-1 w-full  ${COLORS.Info}`} />
          </div>
          <Badge value={AlertLevel.Info} />
          <div className={`relative w-4 h-4 -ml-2 flex items-center justify-center rounded-full ${COLORS.Info}`}>
            <span className="-mt-10 text-xs font-medium uppercase whitespace-nowrap">{t('targetsFrom')}</span>
          </div>
        </li>
        <li className="relative w-1/5 h-4">
          <div className="absolute inset-0 flex items-center">
            <div className={`h-1 w-full ${COLORS.Warning}`} />
          </div>
          <Badge value={AlertLevel.Warning} />
          <div className={`relative w-4 h-4 -ml-2 flex items-center justify-center rounded-full ${COLORS.Info}`}>
            <span className="-mt-10 text-xs font-medium uppercase whitespace-nowrap">{t('targetsTo')}</span>
          </div>
        </li>
        <li className="relative w-1/5 h-4">
          <div className="absolute inset-0 flex items-center">
            <div className={`rounded-r-full h-1 w-full ${COLORS.Danger}`} />
          </div>
          <Badge value={AlertLevel.Danger} />
          <div className={`relative w-4 h-4 -ml-2 flex items-center justify-center rounded-full ${COLORS.Warning}`}>
            <span className="-mt-10 text-xs font-medium uppercase whitespace-nowrap">{t('targetsMax')}</span>
          </div>
        </li>
      </ol>
    </div>
  );
}
