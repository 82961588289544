import { useMemo } from 'react';
import * as Yup from 'yup';
import Dialog from '../../../../../../../components/dialog/Dialog';
import DialogForm from '../../../../../../../components/layouts/dialog-form/DialogForm';
import { Field } from 'formik';
import { TextArea } from '../../../../../../../components/form/TextArea';
import * as React from 'react';
import { EcgAccidentType, REGULAR_EVENT_GROUPS } from '../../utils/types';
import { SelectInput } from '../../../../../../../components/form/SelectInput';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
  onSubmit: (values: { comment: string; eventType?: EcgAccidentType }) => void;
}

export default function EcgCommentModal({ onCancel, onSubmit }: Props) {
  const { t } = useTranslation('EcgCommentModal');
  const validator = useMemo(() => Yup.object().shape({ comment: Yup.string().required() }), []);

  return (
    <Dialog open={true} handleCloseClick={onCancel} widthAuto={true}>
      <DialogForm
        title={t('title')}
        initialValues={{ comment: '', eventType: undefined }}
        onSubmit={value => onSubmit(value)}
        onCancel={onCancel}
        validationSchema={validator}
        labels={{ submit: t('submitLabel'), cancel: t('cancel', { ns: 'common' }) }}
      >
        <div className="sm:flex sm:items-start">
          <div className="flex flex-col space-y-6 w-full">
            <div className="flex space-x-10 w-full">
              <Field component={SelectInput} name={'eventType'} label={t('eventTypeLabel')}>
                <option value={undefined} key={0}>
                  {t('selectEventType')}
                </option>
                {REGULAR_EVENT_GROUPS.map(({ eventGroup }) => (
                  <option value={eventGroup} key={eventGroup}>
                    {t(eventGroup, { count: 1 })}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="flex flex-col space-y-6 w-full">
            <div className="flex space-x-10 w-full">
              <Field component={TextArea} name="comment" label={t('commentLabel')} />
            </div>
          </div>
        </div>
      </DialogForm>
    </Dialog>
  );
}
