import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientHcpResponse } from '../../contracts/Patient';

type PatientHcpUpdateStatusRequest = {
  patientId: number;
  isComplete: boolean;
};

export function usePatientHcpStatusUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientHcpResponse>();
  return useMutation<PatientHcpResponse, unknown, PatientHcpUpdateStatusRequest>(
    ({ patientId, isComplete }) =>
      request(`/patients/${patientId}/plan`, {
        body: JSON.stringify({ isComplete }),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData<PatientHcpResponse>(['patient-hcp', request.patientId], () => {
          return data;
        });
      },
    },
  );
}
