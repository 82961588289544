import * as React from 'react';
import { Field } from 'formik';
import { SelectInput } from '../../../../../components/form/SelectInput';
import * as Yup from 'yup';
import { DiagnosisType } from '../../../../../contracts/Classifiers';
import { TextArea } from '../../../../../components/form/TextArea';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

interface Props {
  handleCancel: () => void;
  handleSave: (values: { comments: string; id: number }) => void;
  diagnoses: Array<DiagnosisType>;
}

const AddPatientScheme = Yup.object().shape({
  id: Yup.number().required(),
});

const initialValues = {
  comments: '',
  id: 0,
};

export function AddPatientDiagnosisForm(props: Props) {
  const { t } = useTranslation('AddPatientDiagnosisForm');
  return (
    <DialogForm
      title={t('dialogTitle')}
      initialValues={initialValues}
      onSubmit={props.handleSave}
      validationSchema={AddPatientScheme}
      onCancel={props.handleCancel}
    >
      <div className="sm:flex sm:items-start">
        <div className="flex flex-col space-y-6 w-full">
          <Field component={SelectInput} name={'id'} label={t('diagnosisLabel')}>
            <option value={''} key={0}>
              {t('selectDiagnosis')}
            </option>
            {props.diagnoses.map(diagnosis => (
              <option value={diagnosis.id} key={diagnosis.id}>
                {diagnosis.code} - {diagnosis.name}
              </option>
            ))}
          </Field>
          <Field component={TextArea} name={'comments'} label={'Pastabos'} />
        </div>
      </div>
    </DialogForm>
  );
}
