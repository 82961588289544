import { ExtendedColumnProps } from '../TableUtilities';
import {
  FilterConfiguration,
  FilterEntity,
  FiltersPostRequest,
  FiltersPutRequest,
} from '../../../../contracts/Filters';

export type SuperSearchPredicateType = 'select' | 'multiselect';

export interface SuperSearchFilterOption {
  id: string | number;
  label: string;
}

export interface SuperSearchTab extends FilterEntity {
  hasChanges: boolean;
  persisted: boolean;
}

export interface SuperSearchFilters {
  title: string;
  type: SuperSearchPredicateType;
  columnAccessor: string;
  options: SuperSearchFilterOption[];
  value: (string | number)[];
}

export interface SuperSearchSettings {
  globalFilterLabel?: string;
  hideGlobalFilter?: boolean;
  hideFilters?: boolean;
  hideSettings?: boolean;
}

export const mapFilterEntityToSuperSearchTab =
  (persisted = false) =>
  ({ id, name, configuration = [] }: FilterEntity): SuperSearchTab => ({
    id,
    name,
    configuration: configuration || [],
    hasChanges: false,
    persisted: persisted,
  });

export const mapFiltersToSuperSearchTabs = (filters: FilterEntity[]): SuperSearchTab[] =>
  filters.map<SuperSearchTab>(mapFilterEntityToSuperSearchTab(true));

export const mapSuperSearchTabToFilterPostRequest = ({ name, configuration }: SuperSearchTab): FiltersPostRequest => ({
  name,
  configuration,
});

export const mapSuperSearchTabToFilterPutRequest = ({
  id,
  name,
  configuration,
}: SuperSearchTab): FiltersPutRequest => ({
  id,
  name,
  configuration,
});

export const mapColumnsToFilterConfig = <T extends object>(
  columns: ExtendedColumnProps<T>[],
  data: T[],
  tab: SuperSearchTab,
): SuperSearchFilters[] =>
  columns
    .filter(({ canSuperFilter }) => canSuperFilter)
    .map(({ accessor, Header, superSearchPredicateType, superSearchPredicateOptions }) => ({
      title: Header as string,
      type: superSearchPredicateType!,
      columnAccessor: accessor as string,
      options: superSearchPredicateOptions
        ? superSearchPredicateOptions
        : data
            ?.map(v => v[accessor as keyof T] as string)
            .flat()
            .filter(isStringDistinct)
            .map(v => ({ id: v, label: v })) || [],
      value: tab.configuration.find(p => p.columnAccessor === accessor)?.selectedValues ?? [],
    }));

export const isStringDistinct = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

export const mapNewFiltersToTabUpdates = (
  tabs: SuperSearchTab[],
  activeTabId: number,
  columnAccessor: string,
  newValues: (string | number)[],
): SuperSearchTab[] =>
  tabs.map<SuperSearchTab>(t =>
    t.id !== activeTabId
      ? t
      : {
          ...t,
          hasChanges: true,
          configuration: mapPredicateUpdate(t.configuration, columnAccessor, newValues),
        },
  );

const mapPredicateUpdate = (
  filterConfigurations: FilterConfiguration[],
  columnAccessor: string,
  selectedValues: (string | number)[],
): FilterConfiguration[] => {
  if (!filterConfigurations.length) {
    return [
      {
        columnAccessor,
        selectedValues,
      },
    ];
  }

  if (!filterConfigurations.some(p => p.columnAccessor === columnAccessor)) {
    return [
      ...filterConfigurations,
      {
        columnAccessor,
        selectedValues,
      },
    ];
  }

  return filterConfigurations.map(p => (p.columnAccessor !== columnAccessor ? p : { ...p, selectedValues }));
};

export const mapDisabledFiltersToTabUpdates = (
  tabs: SuperSearchTab[],
  activeTabId: number,
  columnAccessor: string,
  valueToDisable: string | number,
): SuperSearchTab[] =>
  tabs.map<SuperSearchTab>(t =>
    t.id !== activeTabId
      ? t
      : {
          ...t,
          hasChanges: true,
          configuration: t.configuration.map(p =>
            p.columnAccessor !== columnAccessor
              ? p
              : {
                  ...p,
                  selectedValues: p.selectedValues.filter(v => v !== valueToDisable),
                },
          ),
        },
  );

export const filterTableData = (data: any[], filterConfigurations: FilterConfiguration[]): any[] => {
  let values = data;
  for (const predicate of filterConfigurations.filter(p => p.selectedValues.length)) {
    values = values.filter(v =>
      predicate.selectedValues.some(sv => {
        const targetValue = v[predicate.columnAccessor];
        // Column's value may either be a string, or an array of values
        // eslint-disable-next-line eqeqeq
        return Array.isArray(targetValue) ? targetValue.some(tv => tv == sv) : targetValue == sv;
      }),
    );
  }
  return values;
};

export const buildTabs = (staticEntities: FilterEntity[], serverEntities: FilterEntity[]): SuperSearchTab[] => [
  ...staticEntities.map(mapFilterEntityToSuperSearchTab(false)),
  ...serverEntities.map(mapFilterEntityToSuperSearchTab(true)),
];
