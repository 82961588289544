import React, { PropsWithChildren } from 'react';
import TimeInput from './TimeInput';
import Layers from './Layers';
import { AnalysisMode } from '../../utils/types';
import { EcgDataLayer } from '../../../../../../../contracts/Ecg';
import ButtonGroup from '../../../../../../../components/buttons/ButtonGroup';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import IconButton from '../../../../../../../components/buttons/IconButton';
import classNames from 'classnames';

interface Props {
  resolution: number;

  time: string;
  activeDataLayers: EcgDataLayer[];

  analysesOverlayModes: AnalysisMode[];
  commentModeActive: boolean;

  onAnalysesOverlayModesChanged: (newValue: AnalysisMode[]) => void;
  onResolutionChanged: (value: number) => void;
  onTimeChanged: (time: string) => void;
  onActiveDataLayersChanged: (mode: EcgDataLayer[]) => void;

  onCommentModeClicked: () => void;

  onTimeIncreased: (newSeconds: number) => void;
}

export default function PatientEcgWrapper({
  children,
  time,
  activeDataLayers,
  resolution,
  analysesOverlayModes,
  commentModeActive,
  onAnalysesOverlayModesChanged,
  onTimeChanged,
  onActiveDataLayersChanged,
  onResolutionChanged,
  onTimeIncreased,
  onCommentModeClicked,
}: PropsWithChildren<Props>) {
  const allowedResolutions = [2, 5, 10, 15];
  const defaultResolution = 15;

  return (
    <div>
      <div className={'inline-flex w-full p-4 justify-center bg-gray-50 border-b'}>
        <IconButton
          type={'secondary'}
          onClick={onCommentModeClicked}
          className={classNames(commentModeActive && 'bg-primary text-white')}
        >
          <ChatBubbleBottomCenterTextIcon className={'w-5 h-5'} />
        </IconButton>

        <div className={'flex-1'}></div>
        <TimeInput
          className={'border rounded-md'}
          time={time}
          onTimeChanged={onTimeChanged}
          onTimeIncreased={onTimeIncreased}
        />

        <div className={'flex-1'}></div>
        <ButtonGroup
          buttons={allowedResolutions.map(r => ({ text: `${r} s`, value: r }))}
          handleClick={onResolutionChanged}
          active={resolution ?? defaultResolution}
        />
      </div>

      {children}

      <div className={'inline-flex w-full p-4 items-center bg-gray-50 border-t'}>
        <Layers
          modes={activeDataLayers}
          analysesOverlayModes={analysesOverlayModes}
          onModesChanged={onActiveDataLayersChanged}
          onAnalysesOverlayModesChanged={onAnalysesOverlayModesChanged}
        />
      </div>
    </div>
  );
}
