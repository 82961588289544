import { useNavigate } from 'react-router-dom';

export function useQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  function get(paramName: string) {
    return urlParams.get(paramName);
  }
  function set(paramName: string, paramValue: string) {
    urlParams.set(paramName, paramValue);
    navigate({
      search: urlParams.toString(),
    });
  }

  return {
    get,
    set,
  };
}
