import * as React from 'react';
import Dialog from '../dialog/Dialog';
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (id: number) => void;
}

export function DeleteConfirmationWithInput(props: Props) {
  const { t } = useTranslation('DeleteConfirmationWithInput');
  const [inputValue, setInputValue] = React.useState('');
  return (
    <Dialog open={props.open} handleCloseClick={props.handleCancel} widthAuto={true}>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-96">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('title')}</h3>
        <div className="mt-6">{t('message')}</div>
        <div className="mt-3">
          <input
            className={
              'mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
            }
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        </div>
        <div className="mt-6 sm:flex sm:flex-row-reverse">
          <Button
            buttonType="submit"
            onClick={() => props.handleSubmit(props.id)}
            disabled={inputValue.toLowerCase() !== t('requiredConfirmationText')}
          >
            {t('yes')}
          </Button>
          <Button type="secondary" onClick={props.handleCancel}>
            {t('no')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
