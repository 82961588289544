import React from 'react';
import { Question } from '../../contracts/Poll';
import SectionQuestion from './questionTypes/SectionQuestion';
import SingleChoiceQuestion from './questionTypes/SingleChoiceQuestion';
import ChoiceTableQuestion from './questionTypes/ChoiceTableQuestion';
import YesNoQuestion from './questionTypes/YesNoQuestion';
import TextQuestion from './questionTypes/TextQuestion';
import NumberQuestion from './questionTypes/NumberQuestion';
import MultipleChoiceQuestion from './questionTypes/MultipleChoiceQuestion';
import TextAreaQuestion from './questionTypes/TextAreaQuestion';

interface Props extends Question {}

const QuestionComponents: { [k: string]: React.FC<Question> } = {
  Section: SectionQuestion,
  ChoiceTable: ChoiceTableQuestion,
  SingleChoice: SingleChoiceQuestion,
  YesNo: YesNoQuestion,
  Text: TextQuestion,
  Number: NumberQuestion,
  MultipleChoice: MultipleChoiceQuestion,
  TextArea: TextAreaQuestion,
};

export default function QuestionComponent(props: Props) {
  const Component = QuestionComponents[props.type];

  if (!Component) {
    return null;
  }
  return <Component {...props} />;
}
