import { Panel } from '../layouts/Panel';
import * as React from 'react';
import { Metric, MetricsImageByType, MetricsStatus, getMetricsTitleByType } from '../../contracts/Metrics';
import { useTranslation } from 'react-i18next';

export const MetricPanel: React.FunctionComponent<Metric> = props => {
  const { t } = useTranslation('MetricPanel');
  let valueColor = 'text-gray-700';
  if (props.status === MetricsStatus.Warning) {
    valueColor = 'text-amber-500';
  } else if (props.status === MetricsStatus.Danger) {
    valueColor = 'text-red-500';
  }

  function getTarget(min: number, max: number) {
    if (min && max) {
      return `${props.targetMin}-${props.targetMax}`;
    }
    if (min) {
      return `${props.targetMin}+`;
    }
    return `< ${props.targetMax}`;
  }

  return (
    <Panel>
      <div className="flex">
        <div className="w-16 pr-5 pl-5 pt-4">
          <img src={MetricsImageByType[props.type]} alt="" className="w-full" />
        </div>
        <div>
          <div className="text-sm text-gray-700 font-medium leading-5">{getMetricsTitleByType(t)[props.type]}</div>
          <div className={`text-2xl leading-8 font-semibold mb-3 ${valueColor}`}>{props.value}</div>
          <div className="flex text-gray-400 text-sm leading-5 font-semibold">
            <img src="/images/target.svg" alt="" className="mr-1" /> {getTarget(props.targetMin, props.targetMax)}
          </div>
        </div>
      </div>
    </Panel>
  );
};
