import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientType } from '../../contracts/Patient';

export function usePatients() {
  const request = useRequest<Array<PatientType>>();
  return useQuery<unknown, unknown, Array<PatientType>>(['patients'], () => request('/patients'), {
    staleTime: 1000 * 60 * 15,
  });
}
