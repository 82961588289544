import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AssignGadgetRequest } from '../../contracts/Patient';
import { GadgetHistory } from '../../contracts/Gadget';

export function usePatientAssignGadget() {
  const queryClient = useQueryClient();
  const request = useRequest<GadgetHistory>();
  return useMutation<GadgetHistory, unknown, AssignGadgetRequest>(
    ({ patientId, gadgetId, ...data }) =>
      request(`/patients/${patientId}/gadgets/${gadgetId}/assign`, {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(['gadgets']);
        queryClient.setQueryData(
          ['patient-gadget-history', request.patientId],
          (oldData: Array<GadgetHistory> = []) => [...oldData, data],
        );
        queryClient.invalidateQueries(['patients', request.patientId]);
      },
    },
  );
}
