import DashboardLayout from '../../components/layouts/DashboardLayout';
import Table from '../../components/common/Table/Table';
import { getColumns, superSearchSettings, getSuperSearchTabs } from './NotificationsTableConfig';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import * as React from 'react';
import { useThreadInbox } from '../../hooks/queries/useThreadInbox';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { ThreadInboxResponse } from '../../contracts/Threads';
import { useMe } from '../../hooks/queries/useMe';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation('Notifications');
  const breadcrumbPages = [{ name: t('breadcrumb'), href: '/notifications', current: true }];
  const { data: meData } = useMe();
  const { data, isLoading } = useThreadInbox();
  const navigate = useNavigate();

  const goToPatientThreads = (row: Row<ThreadInboxResponse>) => {
    navigate(`/patients/${row.original.patient.id}?tab=threads`);
  };

  const columns = React.useMemo(() => (meData ? getColumns(t, meData.id) : []), [meData, t]);

  return (
    <DashboardLayout isLoading={isLoading || !columns}>
      <Breadcrumbs pages={breadcrumbPages} />
      <Table
        data={data || []}
        columns={columns}
        superSearch={superSearchSettings}
        superSearchDefaultTabs={getSuperSearchTabs(t)}
        handleRowClick={goToPatientThreads}
        defaultSortBy={[{ id: 'date', desc: true }]}
      />
    </DashboardLayout>
  );
};
export default Notifications;
