import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { PatientThreadType, ThreadStatusRequest } from '../../../contracts/Threads';

export function useThreadStatus() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientThreadType>();
  return useMutation<PatientThreadType, unknown, ThreadStatusRequest>(
    ({ patientId, threadId, status }) =>
      request(`/patients/${patientId}/threads/${threadId}/status`, {
        method: 'POST',
        body: JSON.stringify({ status }),
      }),
    {
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(['threads-me']);
        queryClient.setQueryData(['patient-threads', request.patientId], (oldData: Array<PatientThreadType> = []) => {
          return oldData.map(oldItem => (oldItem.id === data.id ? data : oldItem));
        });
      },
    },
  );
}
