import * as React from 'react';
import { Switch } from '../../components/form/Switch';
import { Field, Form, Formik } from 'formik';
import { useAlertSiblings } from '../../hooks/queries/useAlertSiblings';
import Loading from '../../components/common/Loading';
import { Column } from 'react-table';
import { AlertLevelColor, AlertType, getAlertLevelLabelArray } from '../../contracts/Alert';
import { PatientInline } from '../../components/patient/PatientInline';
import Tag from '../../components/common/Tag';
import Table from '../../components/common/Table/Table';
import Button from '../../components/buttons/Button';
import { DialogFormContainer } from '../../components/layouts/dialog-form/DialogFormContainer';
import { TextArea } from '../../components/form/TextArea';
import { AlertsRequest } from '../../hooks/mutations/useAlertsUpdate';
import { getFullTime } from '../../utils/date';
import { useTranslation } from 'react-i18next';

interface IAlertForm {
  alertId: number;
  patientId?: number;
  handleCancel: () => void;
  handleSubmit: (data: AlertsRequest) => void;
}

export const AlertForm: React.FunctionComponent<IAlertForm> = props => {
  const { t } = useTranslation('AlertForm');
  const [includeAll, setIncludeAll] = React.useState(true);
  const { data: alerts, isLoading } = useAlertSiblings(props.alertId);

  const [columns, tableData] = React.useMemo(() => {
    const columns: Array<Column<AlertType>> = [
      {
        Header: t('table.timestamp'),
        accessor: 'timestamp',
        Cell: ({ value }) => <>{getFullTime(value)}</>,
      },
      {
        Header: t('table.metric'),
        accessor: 'metric',
        Cell: ({ row }) => <>{row.original.metric}</>,
      },
      {
        Header: t('table.target'),
        accessor: 'target',
        Cell: ({ row }) => <>{row.original.target}</>,
      },
      {
        Header: t('table.message'),
        accessor: 'message',
        Cell: ({ row }) => <>{row.original.message}</>,
      },
      {
        Header: t('table.level'),
        accessor: 'level',
        Cell: ({ value }) => <Tag className={AlertLevelColor[value]}>{getAlertLevelLabelArray(t)[value]}</Tag>,
      },
    ];
    if (!props.patientId) {
      columns.unshift({
        Header: t('table.patient'),
        accessor: 'patient',
        Cell: ({ value, row }) => (
          <div className="flex items-center relative">
            {row.original.id === props.alertId && (
              <div className="w-2 h-2 inline-flex absolute items-center -ml-4 text-xs leading-5 font-semibold rounded-full bg-primary" />
            )}
            <PatientInline {...value} />
          </div>
        ),
      });
    }
    let _data = alerts;
    if (!includeAll && _data) {
      _data = _data.filter(item => item.id === props.alertId);
    }
    return [columns, _data];
  }, [alerts, includeAll, props.alertId, props.patientId, t]);

  function handleSubmit({ comment }: { comment: string }) {
    let alertIds: Array<number> = [];
    if (includeAll && alerts) {
      alertIds = alerts.map(alert => alert.id);
    } else {
      alertIds.push(props.alertId);
    }
    props.handleSubmit({
      alertIds,
      comment,
    });
  }

  const initialValues = {
    comment: '',
  };

  return (
    <DialogFormContainer title={t('title')}>
      {isLoading && (
        <div className={'flex justify-center'}>
          <Loading />
        </div>
      )}
      {tableData && (
        <div>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <div className="sm:flex sm:items-start mb-4">
                  <div className="flex flex-col space-y-6 w-full">
                    <Field component={TextArea} name={'comment'} label={t('commentLabel')} />
                  </div>
                </div>
                {alerts && alerts?.length > 1 && (
                  <div className="flex mt-10 mb-4">
                    <Switch checked={includeAll} onChange={value => setIncludeAll(value)} />
                    <span className="text-sm leading-5 text-gray-700 leading-5 pl-2">{t('processSimilar')}</span>
                  </div>
                )}
                <Table data={tableData} columns={columns} pageSize={5} />
                <div className="mt-6 sm:flex sm:flex-row-reverse">
                  <Button buttonType="submit" isLoading={isSubmitting}>
                    {t('submit', { count: tableData?.length ?? 0 })}
                  </Button>
                  <Button type="secondary" onClick={props.handleCancel}>
                    {t('cancel', { ns: 'common' })}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </DialogFormContainer>
  );
};
