import { TrashIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

export default function RemoveIcon({ onClick }: { onClick: () => void }) {
  return (
    <TrashIcon
      className="ml-2 mr-0 h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
      aria-hidden="true"
      onClick={onClick}
    />
  );
}
