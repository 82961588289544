interface IFaqSection {
  title: string;
  description?: string;
  questions: Array<{
    question: string;
    answer: string;
  }>;
}

export const FaqSection: React.FunctionComponent<IFaqSection> = props => {
  return (
    <div className="pb-16">
      <div>
        <div className="pb-8">
          <h2 className="text-2xl font-semibold text-gray-900">{props.title}</h2>
          {props.description ? (
            <p className="mt-1 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: props.description }} />
          ) : null}
        </div>
        <div className="mb-4">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-12">
            {props.questions.map(faq => (
              <div key={faq.question}>
                <dt className="text-lg leading-8 font-semibold text-gray-900">{faq.question}</dt>
                <dd className="mt-1 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
