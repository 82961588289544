import * as React from 'react';
import { Row } from 'react-table';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import { usePatients } from '../../hooks/queries/usePatients';
import Table from '../../components/common/Table/Table';
import { PatientRequest, PatientType } from '../../contracts/Patient';
import { AddPatientForm } from './components/AddPatientForm';
import Dialog from '../../components/dialog/Dialog';
import { usePatientCreate } from '../../hooks/mutations/usePatientCreate';
import { PERMISSIONS, usePermission } from '../../hooks/usePermission';

import { getPatientListTableColumns } from './PatientListTableConfig';
import { useDoctors } from '../../hooks/queries/useDoctors';
import { DoctorRoles } from '../../contracts/Doctors';
import { useClinics } from '../../hooks/queries/useClinics';
import { useTranslation } from 'react-i18next';

export const PatientList = () => {
  const { t } = useTranslation('PatientList');
  const breadcrumbPages = [{ name: t('breadcrumb'), href: '/patients', current: true }];
  const { data: doctorsData } = useDoctors();
  const [open, setOpen] = React.useState<boolean>(false);
  const { can, canAny } = usePermission();
  const createPatientMutation = usePatientCreate();
  const navigate = useNavigate();
  const { data: clinicsData } = useClinics();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const { data, isLoading } = usePatients();

  const defaultTabs = React.useMemo(
    () => [
      {
        id: -1,
        name: t('table.superSearchTab0'),
        canDelete: false,
        hasChanges: false,
        persisted: false,
        configuration: [],
      },
    ],
    [t],
  );

  React.useEffect(() => {
    console.log(data);
    if (data && data.length === 1 && !canManagePatients) {
      navigate(`/patients/${data[0].id}`);
    }
  }, [data, navigate, canManagePatients]);

  const columns = React.useMemo(
    () =>
      getPatientListTableColumns(
        t,
        clinicsData ?? [],
        doctorsData
          ?.filter(({ role }) => role === DoctorRoles.FamilyDoctor)
          ?.map(d => ({ id: d.id, label: `${d.firstName} ${d.lastName}` })) ?? [],
        url => navigate(url),
      ),
    [clinicsData, navigate, doctorsData, t],
  );

  function handleFormSubmit({ id, ...values }: PatientRequest) {
    createPatientMutation.mutate(values, {
      onSuccess: () => setOpen(false),
    });
  }

  return (
    <DashboardLayout isLoading={isLoading && !data}>
      <Breadcrumbs pages={breadcrumbPages} />
      {!isLoading && data && (
        <div className="md:-mt-20">
          <Table
            data={data}
            hiddenColumnIds={['clinic', 'familyDoctorId', 'clinicId']}
            superSearchDefaultTabs={defaultTabs}
            columns={columns}
            defaultSortBy={[{ id: 'events', desc: true }]}
            superSearch={{ globalFilterLabel: t('table.superSearchGlobalFilterLabel') }}
            tableName="patient-list"
            showFilters={true}
            handleRowClick={(row: Row<PatientType>) => navigate(`/patients/${row.original.id}`)}
            handleAddClick={canManagePatients ? () => setOpen(true) : undefined}
            autoResetPage={true}
          />
        </div>
      )}

      <Dialog open={open} handleCloseClick={() => setOpen(false)} widthAuto={true}>
        <AddPatientForm
          handleCancel={() => setOpen(false)}
          handleSave={handleFormSubmit}
          canAssignClinic={can(PERMISSIONS.MANAGE_ALL_PATIENTS)}
        />
      </Dialog>
    </DashboardLayout>
  );
};
