import React from 'react';
import classNames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

interface Props {
  className: string;
  time: string;
  onTimeChanged: (time: string) => void;
  onTimeIncreased: (seconds: number) => void;
}

const QuickNavButton = ({
  text,
  direction,
  disabled,
  className,
  onClick,
}: {
  text: string;
  direction: 'left' | 'right';
  disabled: boolean;
  className?: string;
  onClick: () => void;
}) => (
  <button
    type={'button'}
    disabled={disabled}
    className={classNames(
      'py-2 px-4 inline-flex items-center text-sm',
      disabled ? 'bg-gray-50 text-gray-300 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100',
      className,
    )}
    onClick={onClick}
  >
    {direction === 'left' && <ChevronLeftIcon className={'w-4 h-4 mr-1 stroke-2'} />}
    {text}
    {direction === 'right' && <ChevronRightIcon className={'w-4 h-4 ml-1 stroke-2'} />}
  </button>
);

export default function TimeInput({ time, onTimeChanged, onTimeIncreased, className }: Props) {
  const [hours, minutes, seconds] = time.split(':').map(t => Number(t));
  const isTimeValid = [hours, minutes, seconds].every(x => !isNaN(x));

  const handleSecondsChanged = (dt: number) => () => onTimeIncreased(dt);

  const filterNonTimeRelatedKeys: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const key = event.key.toLowerCase();
    const allowedSpecialKeys = ['tab', 'delete', 'backspace', 'arrowup', 'arrowdown', 'arrowleft', 'arrowright'];
    if (!/[0-9:]/.test(key) && !allowedSpecialKeys.includes(key)) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  };

  return (
    <div className={classNames(className, 'bg-white inline-flex items-stretch')}>
      <QuickNavButton text={'1 min'} disabled={!isTimeValid} direction={'left'} onClick={handleSecondsChanged(-60)} />
      <QuickNavButton text={'10 s'} disabled={!isTimeValid} direction={'left'} onClick={handleSecondsChanged(-10)} />
      <QuickNavButton text={'1 s'} disabled={!isTimeValid} direction={'left'} onClick={handleSecondsChanged(-1)} />
      <div className={'inline-flex justify-center items-center relative'}>
        <input
          id={'time-filter'}
          className={'p-2 w-24 h-full font-medium text-center hover:bg-gray-100'}
          placeholder={'00:00:00'}
          value={time}
          onChange={event => onTimeChanged(event.target.value)}
          onKeyDown={filterNonTimeRelatedKeys}
        />
      </div>

      <QuickNavButton text={'1 s'} disabled={!isTimeValid} direction={'right'} onClick={handleSecondsChanged(1)} />
      <QuickNavButton text={'10 s'} disabled={!isTimeValid} direction={'right'} onClick={handleSecondsChanged(10)} />
      <QuickNavButton text={'1 min'} disabled={!isTimeValid} direction={'right'} onClick={handleSecondsChanged(60)} />
    </div>
  );
}
