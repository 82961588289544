import * as React from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlTick from '../common/HtmlTick';
import { PrimitiveType } from '../../../contracts/Common';
import { useCallback } from 'react';
import { debounce } from '../../../utils/debounce';
import QuestionDescription from '../common/QuestionDescription';
import { useTranslation } from 'react-i18next';

export default function YesNoQuestion(props: Question) {
  const { t } = useTranslation('common');
  const [selected, setSelected] = React.useState<PrimitiveType | null>(props.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeApi = useCallback(debounce(props.handleAnswerSubmit), []);

  function handleChange(questionId: number, value: PrimitiveType) {
    setSelected(value);
    handleChangeApi({
      questionId,
      answer: { value },
    });
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <QuestionDescription>{props.description}</QuestionDescription>
      <div className="mt-3 space-y-3">
        <HtmlTick
          id={`${props.id}_yes`}
          type={'radio'}
          value={1}
          isSelected={selected === true}
          handleChange={choiceValue => handleChange(props.id, Boolean(choiceValue))}
          label={t('yes')}
          key={1}
        />

        <HtmlTick
          id={`${props.id}_no`}
          type={'radio'}
          value={0}
          isSelected={selected === false}
          handleChange={choiceValue => handleChange(props.id, Boolean(choiceValue))}
          label={t('no')}
          key={0}
        />
      </div>
    </div>
  );
}
