export const slateNodesHaveText = (v: any): boolean => {
  if (v.text) {
    return true;
  }

  if (!v?.children) {
    return false;
  }

  if (typeof v.children === 'string' || typeof v.children === 'number' || typeof v.children === 'boolean') {
    return true;
  }

  return v.children.some(slateNodesHaveText);
};
