import { EcgAccidents } from '../../../../../../contracts/Ecg';

export type AnalysisMode = 'peaks';

export interface EventGroup {
  eventGroup: string;
}

export interface SpecialEventGroup extends EventGroup {
  eventGroup: 'all' | 'artifact' | 'comment';
}

export interface RegularEventGroup extends EventGroup {
  eventGroup: EcgAccidentType;
}

export const REGULAR_EVENT_GROUPS: RegularEventGroup[] = [
  { eventGroup: 'supraventricularBeats' },
  { eventGroup: 'supraventricularCouplets' },
  { eventGroup: 'supraventricularBigeminies' },
  { eventGroup: 'supraventricularTrigeminies' },
  { eventGroup: 'ventricularBeats' },
  { eventGroup: 'ventricularCouplets' },
  { eventGroup: 'ventricularBigeminies' },
  { eventGroup: 'ventricularTrigeminies' },
  { eventGroup: 'sinusBradycardia' },
  { eventGroup: 'sinusTachycardia' },
];

export const SPECIAL_EVENT_GROUPS: SpecialEventGroup[] = [
  { eventGroup: 'all' },
  { eventGroup: 'comment' },
  { eventGroup: 'artifact' },
];

export interface EcgEventGroup {
  id: EcgAccidentType | 'all' | 'comment' | 'artifact';
  name: string;
  count: number;
  events: EcgEvent[];
}

export type EcgAccidentType = string & keyof EcgAccidents;

export interface EcgEvent {
  timestamp: number;
  name: string;
  isDeleted: boolean;
  comment?: string;
  eventGroup: string & keyof EcgAccidents;
}

// Defines in what order the hours should appear
export const getHoursOrder = (timestamp: number) => {
  const value = [
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '12',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '00',
    '01',
  ];

  const offset = new Date(timestamp).getTimezoneOffset();
  if (offset === 120) {
    return ['02', ...value];
  } else {
    return [...value, '02'];
  }
};
