import React from 'react';
import Card from '../../../../../../../components/common/Card';
import { useEcgHourlyAnalysis } from '../../../../../../../hooks/queries/useEcgHourlyAnalysis';
import LoadingContainer from '../../../../../../../components/layouts/LoadingContainer';
import { useTranslation } from 'react-i18next';

interface Props {
  patientId: number;
  recordId: number;

  className?: string;
}

export default function EcgHourlyBreakdown({ patientId, recordId, className }: Props) {
  const { t } = useTranslation('EcgHourlyBreakdown');
  const { data, isLoading } = useEcgHourlyAnalysis(patientId, recordId);

  return (
    <Card title={t('cardTitle')} noPadding={true} className={className}>
      <LoadingContainer loading={isLoading}>
        <table className={'w-full text-sm'}>
          <thead>
            <tr className={'border-b text-gray-500'}>
              <th colSpan={2} className={'border-r py-2'}>
                {t('time')}
              </th>
              <th colSpan={5} className={'border-r py-2'}>
                {t('pulse')}
              </th>
              <th colSpan={2} className={'border-r py-2'}>
                {t('skExtrasystoles')}
                <br />
              </th>
              <th colSpan={2} className={'border-r py-2'}>
                {t('prExtrasystoles')}
              </th>
              <th className={'py-2 '}>{t('noise')}</th>
            </tr>
            <tr className={'border-b text-black'}>
              <th className={'border-r py-2 px-2 w-1/12 font-medium'}>{t('fromHeader')}</th>
              <th className={'border-r py-2 px-2 w-1/12 font-medium'}>{t('toHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('beatsHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('meanHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('minHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('maxHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('pausesHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('totalHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('pairsHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('totalHeader')}</th>
              <th className={'border-r py-2 px-2 text-right w-1/12 font-medium'}>{t('pairsHeader')}</th>
              <th className={'py-2 px-4 text-right font-medium'}>{t('snrHeader')}</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {data &&
              data.map(d => (
                <tr>
                  <td className={'border-r py-2 px-2 text-center'}>{d.start}</td>
                  <td className={'border-r py-2 px-2 text-center'}>{d.end}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.heartRate.totalBeats}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.heartRate.avg}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.heartRate.min}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.heartRate.max}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.heartRate.pauses}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.ventricular.beats}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.ventricular.couplets}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.supraventricular.beats}</td>
                  <td className={'border-r py-2 px-2 text-right'}>{d.supraventricular.couplets}</td>
                  <td className={'py-2 px-2 text-right'}>{d.noise.ratio}%</td>
                </tr>
              ))}
          </tbody>
        </table>
      </LoadingContainer>
    </Card>
  );
}
