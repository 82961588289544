import * as React from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../../../../components/common/Table/Table';
import Dialog from '../../../../../components/dialog/Dialog';
import Confirmation from '../../../../../components/common/Confirmation';
import { PERMISSIONS, usePermission } from '../../../../../hooks/usePermission';
import { Column } from 'react-table';
import { NullableType } from '../../../../../contracts/Common';
import { DocumentType, DocumentTypeEnum } from '../../../../../contracts/Document';
import { usePatientDocuments } from '../../../../../hooks/queries/usePatientDocuments';
import { AddPatientDocumentForm, AddPatientDocumentFormType } from './AddPatientDocumentForm';
import { usePatientDocumentCreate } from '../../../../../hooks/mutations/usePatientDocumentCreate';
import { successToast } from '../../../../../utils/successToast';
import { usePatientDocumentDelete } from '../../../../../hooks/mutations/usePatientDocumentDelete';
import { format, isValid } from 'date-fns';
import { usePatientDocumentUpdate } from '../../../../../hooks/mutations/usePatientDocumentUpdate';
import EditIcon from '../../../../../components/icons/EditIcon';
import RemoveIcon from '../../../../../components/icons/RemoveIcon';
import DocumentLink from '../../../../../components/common/DocumentLink';
import { useTranslation } from 'react-i18next';

interface IPatientDocuments {
  documentType: DocumentTypeEnum;
}

export const PatientDocuments: React.FunctionComponent<IPatientDocuments> = props => {
  const { t } = useTranslation('PatientDocuments');
  const [open, setOpen] = React.useState<NullableType<DocumentType> | 'add'>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean | number>(false);
  const createPatientDocumentMutation = usePatientDocumentCreate();
  const updatePatientDocumentMutation = usePatientDocumentUpdate();
  const deletePatientDocumentMutation = usePatientDocumentDelete();

  const { id: patientId } = useParams<{ id: string }>();

  const { data } = usePatientDocuments(Number(patientId), props.documentType);
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const [columns, documentsData] = React.useMemo(() => {
    const columns: Array<Column<DocumentType>> = [
      {
        Header: t('fileNameLabel'),
        accessor: 'fileName',
        width: 200,
        Cell: ({ row }) => <DocumentLink {...row.original} patientId={Number(patientId)} key={row.original.id} />,
      },
      {
        Header: t('commentsLabel'),
        accessor: 'comments',
        width: 200,
      },
      {
        Header: t('uploadedOnUtcLabel'),
        width: 70,
        accessor: 'uploadedOnUtc',
        Cell: ({ value }) => <>{isValid(new Date(value)) && format(new Date(value), 'yyyy-MM-dd')}</>,
      },
    ];
    if (canManagePatients) {
      columns.push({
        Header: t('edit', { ns: 'common' }),
        accessor: 'id',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => (
          <div className="flex my-1">
            <div>
              <EditIcon onClick={() => setOpen(row.original)} />
            </div>
            <div>
              <RemoveIcon onClick={() => setOpenDeleteConfirmation(value)} />
            </div>
          </div>
        ),
      });
    }

    return [columns, data];
  }, [data, canManagePatients, patientId, t]);

  function addDocument({ id, file, ...values }: AddPatientDocumentFormType) {
    createPatientDocumentMutation.mutate(
      {
        ...values,
        file: file as File,
        patientId: Number(patientId),
        documentType: props.documentType,
      },
      {
        onSuccess: () => {
          successToast(t('successMessage'));
          setOpen(null);
        },
      },
    );
  }

  function updateDocument({ id, comments }: AddPatientDocumentFormType) {
    updatePatientDocumentMutation.mutate(
      {
        id,
        comments: comments ?? '',
        patientId: Number(patientId),
        documentType: props.documentType,
      },
      {
        onSuccess: () => setOpen(null),
      },
    );
  }

  function deleteDocument(id: number) {
    deletePatientDocumentMutation.mutate(
      {
        id: id,
        patientId: Number(patientId),
        documentType: props.documentType,
      },
      {
        onSuccess: () => setOpenDeleteConfirmation(false),
      },
    );
  }

  if (!documentsData) {
    return null;
  }

  return (
    <>
      <Table
        data={documentsData}
        columns={columns}
        handleAddClick={canManagePatients ? () => setOpen('add') : undefined}
      />
      <Dialog open={!!open} handleCloseClick={() => setOpen(null)} widthAuto={true}>
        {!!open ? (
          <AddPatientDocumentForm
            handleCancel={() => setOpen(null)}
            handleSave={(data: AddPatientDocumentFormType) => (data.id > 0 ? updateDocument(data) : addDocument(data))}
            document={open === 'add' ? undefined : open}
          />
        ) : null}
      </Dialog>
      <Confirmation
        id={Number(openDeleteConfirmation)}
        open={!!openDeleteConfirmation}
        handleCancel={() => setOpenDeleteConfirmation(false)}
        handleSubmit={deleteDocument}
      />
    </>
  );
};
