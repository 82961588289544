import React, { Fragment } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleCloseClick: () => void;
  children: JSX.Element | Array<JSX.Element> | null;
  widthAuto?: boolean;
  className?: string;
}

export default function Dialog(props: Props) {
  const { t } = useTranslation('Dialog');
  let width: string = classNames(
    props.widthAuto ? ' sm:max-w-3xl sm:w-auto' : ' max-w-screen-lg w-full',
    props.className,
  );
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <HeadlessDialog
        as="div"
        static={true}
        className="fixed z-40 inset-0 overflow-y-auto"
        open={props.open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-90 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:align-middle sm:p-6 ${width} z-50 relative`}
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white dark:bg-gray-600 rounded-md text-gray-400 dark:text-gray-300 hover:text-gray-500 focus:outline-none"
                  onClick={props.handleCloseClick}
                >
                  <span className="sr-only">{t('close', { ns: 'common' })}</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {props.children}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
}
