import * as React from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlInput from '../common/HtmlInput';
import { debounce } from '../../../utils/debounce';
import { useCallback } from 'react';
import QuestionDescription from '../common/QuestionDescription';
import { PrimitiveType } from '../../../contracts/Common';

export default function NumberQuestion(props: Question) {
  const [value, setValue] = React.useState<number | ''>((props.value as number) ?? '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeApi = useCallback(debounce(props.handleAnswerSubmit), []);

  function handleChange(questionId: number, value: PrimitiveType) {
    let _value: number | '' = Number(value);
    if (value === '') {
      _value = '';
    }
    setValue(_value);
    handleChangeApi({
      questionId,
      answer: { value: _value },
    });
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <QuestionDescription>{props.description}</QuestionDescription>
      <div className="mt-2 space-y-3 w-40">
        <HtmlInput
          id={`${props.id}`}
          value={value}
          handleChange={value => {
            handleChange(props.id, value);
          }}
          type={'number'}
        />
      </div>
    </div>
  );
}
