import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FieldProps } from 'formik';
import { TFunction, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ValidationMessage } from '../../utils/validation';

interface IBaseInput {
  label?: string;
  description?: string;
}

const getFirstError = (t: TFunction, errors: any): string => {
  switch (typeof errors) {
    case 'undefined':
      return '';
    case 'object': {
      if (errors === null) {
        return '';
      }
      if (Array.isArray(errors)) {
        return getFirstError(t, errors[0]);
      }
      if (
        Object.hasOwn(errors, 'key' as keyof ValidationMessage) &&
        Object.hasOwn(errors, 'props' as keyof ValidationMessage)
      ) {
        return t(errors.key, errors.props as TOptions);
      }
      return getFirstError(t, Object.values(errors)[0]);
    }
    case 'boolean':
      return t('systemError', { ns: 'common' });
    case 'number':
      return errors.toString();
    case 'string':
      return errors;
    case 'function':
      return t('systemError', { ns: 'common' });
    case 'symbol':
      break;
    case 'bigint':
      return errors.toString();
  }

  return t('systemError', { ns: 'common' });
};

export const BaseInput = ({
  label,
  description,
  children,
  field,
  form: { touched, errors },
}: PropsWithChildren<IBaseInput & Pick<FieldProps, 'field' | 'form'>>) => {
  const { t } = useTranslation('BaseInput');
  return (
    <div className="col-span-6 sm:col-span-3 max-w-full w-full">
      {label && (
        <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
      )}
      {children}
      {description ? (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {description}
        </p>
      ) : null}
      {touched[field.name] && errors[field.name] && (
        <p className="mt-2 text-sm text-red-600 " id="email-error">
          {getFirstError(t, errors[field.name])}
        </p>
      )}
    </div>
  );
};
