import * as React from 'react';
import { Field } from 'formik';
import Dialog from '../../../../../components/dialog/Dialog';
import { successToast } from '../../../../../utils/successToast';
import { RichTextInput } from '../../../../../components/form/RichTextInput';
import { Descendant } from 'slate';
import { usePatientAnamnesisUpdate } from '../../../../../hooks/mutations/usePatientAnamnesisUpdate';
import { useParams } from 'react-router-dom';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

interface IAnamnesisDialog {
  initialValue: Descendant[];
}

export const AnamnesisDialog = (props: IAnamnesisDialog) => {
  const { t } = useTranslation('AnamnesisDialog');
  const { id: patientId } = useParams<{ id: string }>();
  const [isOpen, setOpen] = React.useState(false);
  const patientAnamnesisMutation = usePatientAnamnesisUpdate();

  const hasInitialValue = Array.isArray(props.initialValue);

  function handleSave(values: { content: Descendant[] }) {
    patientAnamnesisMutation.mutate(
      {
        patientId: Number(patientId),
        text: JSON.stringify(values.content),
      },
      {
        onSuccess: () => {
          successToast(t('saveSuccessMessage'));
          setOpen(false);
        },
      },
    );
  }

  const initialValues: { content: Descendant[] } = {
    content: hasInitialValue
      ? props.initialValue
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
      >
        {t('edit', { ns: 'common' })}
      </button>
      <Dialog open={isOpen} handleCloseClick={() => setOpen(false)} widthAuto={false}>
        <DialogForm
          title={t('dialogTitle')}
          initialValues={initialValues}
          onSubmit={handleSave}
          onCancel={() => setOpen(false)}
        >
          <div className="flex flex-col space-y-5 sm:items-start w-full dark:text-gray-300">
            <Field component={RichTextInput} name={'content'} />
          </div>
        </DialogForm>
      </Dialog>
    </>
  );
};
