import React from 'react';

interface ICircleClinic {
  text: string;
}

export const CircleClinic: React.FunctionComponent<ICircleClinic> = ({ text }) => {
  let bgColor: string;
  let textColor: string;

  switch (text) {
    case 'InMedica':
      bgColor = 'border-inmedica bg-white border border-opacity-70 dark:bg-gray-900';
      textColor = 'text-inmedica';
      break;
    case 'Druskininkų šeimos klinika':
      bgColor = 'bg-red-800/10 dark:bg-red-900 border border-opacity-0 dark:border-opacity-70 dark:border-red-500';
      textColor = 'text-red-700 dark:text-red-500';
      break;
    case 'Lyros šeimos centras':
      bgColor =
        'bg-emerald-800/10 dark:bg-emerald-900 border border-opacity-0 dark:border-opacity-70 dark:border-emerald-500';
      textColor = 'text-emerald-700 dark:text-emerald-500';
      break;
    case 'Tilžės BPG':
      bgColor = 'bg-blue-100  border border-opacity-0 dark:bg-blue-900 dark:border-opacity-70 dark:border-blue-500';
      textColor = 'text-blue-700 dark:text-blue-500';
      break;
    default:
      bgColor = 'bg-gray-100  border border-opacity-0 dark:bg-gray-900 dark:border-opacity-70 dark:border-gray-500';
      textColor = 'text-gray-700 dark:text-gray-500';
  }

  return (
    <span className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${bgColor}`}>
      <span className={`font-normal leading-none uppercase ${textColor}`}>{text?.slice(0, 1)}</span>
    </span>
  );
};
