import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientTargetType } from '../../contracts/Patient';

export function usePatientTargetUpdate(patientId: number) {
  const queryClient = useQueryClient();
  const request = useRequest<Array<PatientTargetType>>();
  return useMutation<Array<PatientTargetType>, unknown, PatientTargetType>(
    ({ id: targetId, ...data }) =>
      request(`/patients/${patientId}/targets/${targetId}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['patient-targets', patientId], [...data]);
      },
    },
  );
}
