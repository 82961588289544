import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientThreadType } from '../../contracts/Threads';

export function usePatientThreads(patientId: number) {
  const request = useRequest<Array<PatientThreadType>>();
  return useQuery<unknown, unknown, Array<PatientThreadType>>(
    ['patient-threads', patientId],
    () => request(`/patients/${patientId}/threads`),
    {
      staleTime: 30 * 1000,
    },
  );
}
