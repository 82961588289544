import React from 'react';
import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import { Checkbox } from '../Checkbox';
import { SuperSearchFilterOption } from '../../common/Table/SuperSearch/SuperSearchUtils';

interface Props {
  option: SuperSearchFilterOption;
}

const MultiSelectOption = ({ option }: Props) => (
  <Listbox.Option className="relative cursor-default select-none py-2 pl-10 pr-4" value={option.id}>
    {({ selected }) => (
      <>
        <span className={classNames('block truncate', selected ? 'font-medium' : 'font-normal')}>{option.label}</span>

        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <Checkbox isChecked={selected} onChange={() => {}} />
        </span>
      </>
    )}
  </Listbox.Option>
);

export default MultiSelectOption;
