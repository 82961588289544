export type DocumentType = {
  comments: string;
  date: Date;
  fileName: string;
  id: number;
  length: number;
  uploadedOnUtc: Date;
};
export enum DocumentTypeEnum {
  Generic = 0,
  Analysis = 1,
  Anamnesis = 2,
}
export function isDocument(item: File | DocumentType): item is DocumentType {
  return (item as DocumentType).fileName !== undefined;
}
