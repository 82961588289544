import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../useRequest';

type FitbitCodeRequest = {
  code: string;
  redirectUrl: string;
};

export function useFitbitCode(patientId: number) {
  const request = useRequest<FitbitCodeRequest>();
  return useMutation<unknown, unknown, FitbitCodeRequest>(data =>
    request(`/patients/${patientId}/fitbit`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  );
}
