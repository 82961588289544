import React from 'react';

import H3 from '../../../typography/H3';
import MultiSelectInput from '../../../form/MultiSelect/MultiSelectInput';
import { SuperSearchFilters } from './SuperSearchUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  setActiveFilters: (id: string, value: string[]) => void;
  filters: SuperSearchFilters[];
}

export default function SuperSearchFilterPopover({ setActiveFilters, filters }: Props) {
  const { t } = useTranslation('SuperSearchFilterPopover');
  return (
    <div className="px-4 py-2 rounded-md border-0 dark:bg-gray-700 dark:text-gray-300 z-10">
      <H3 className="py-2 border-b dark:border-gray-600 dark:text-gray-300">{t('header')}</H3>
      {filters.map(({ columnAccessor, title, options, type, value }) => (
        <div className="flex py-2" key={columnAccessor}>
          <div className="mt-2 mr-2 text-sm font-semibold whitespace-nowrap w-32">{title}</div>
          <div className="w-64">
            <MultiSelectInput
              onChange={value => setActiveFilters(columnAccessor, value)}
              options={options.sort()}
              values={value}
              multiple={type === 'multiselect'}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
