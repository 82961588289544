import * as React from 'react';
import classNames from 'classnames';

import { SuperSearchTab } from './SuperSearchUtils';

interface Props {
  tabs: SuperSearchTab[];
  activeTabId: number | null;
  onTabClicked: (tabId: number) => void;
}

export default function SuperSearchTabs({ tabs, activeTabId, onTabClicked }: Props) {
  return (
    <div className="border-b border-gray-100 dark:border-gray-700 dark:text-gray-400 pt-0">
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500">
        {tabs.map(({ id, name, hasChanges }) => (
          <li key={id} className="mx-2">
            <button
              className={classNames('flex px-4 pt-4 pb-2 active dark:text-gray-300 items-center justify-center', {
                'border-b-2 border-primary dark:text-pink-500': id === activeTabId,
              })}
              aria-current="page"
              onClick={() => onTabClicked(id)}
            >
              <span>
                {name} {hasChanges ? '*' : null}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
