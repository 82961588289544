import DashboardLayout from '../../components/layouts/DashboardLayout';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import H2 from '../../components/typography/H2';
import { Link } from 'react-router-dom';
import { getButtonClassNames } from '../../components/buttons/ButtonUtilities';
import { useTranslation } from 'react-i18next';

const PatientNotAvailable = () => {
  const { t } = useTranslation('PatientNotAvailable');
  return (
    <DashboardLayout>
      <div className="">
        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center flex-col">
          <UserCircleIcon className="w-24 h-24 mb-4" />
          <H2 className="pb-4">{t('header')}</H2>
          <p className="pb-16">{t('message')}</p>
          <Link to="/patients" className={getButtonClassNames('primary')}>
            {t('linkText')}
          </Link>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PatientNotAvailable;
