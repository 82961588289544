import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientPoll } from '../../contracts/Patient';

export function usePatientPolls(patientId: number) {
  const request = useRequest<Array<PatientPoll>>();
  return useQuery<unknown, unknown, Array<PatientPoll>>(
    ['patient-polls', patientId],
    () => request(`/patients/${patientId}/polls`),
    {
      staleTime: 30 * 1000,
    },
  );
}
