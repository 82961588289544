import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { EcgAccidents } from '../../contracts/Ecg';

export function useEcgAccidents(patientId: number, ecgRecordId: number) {
  const request = useRequest<EcgAccidents>();

  return useQuery<EcgAccidents>(['patient-ecg-accidents', `${patientId}${ecgRecordId}`], () =>
    request(`/patients/${patientId}/ecgs/${ecgRecordId}/events`),
  );
}
