import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';

interface AnalysisParameterDelete {
  id: number;
}

export function useAnalysesDelete() {
  const queryClient = useQueryClient();
  const request = useRequest();
  return useMutation<unknown, unknown, AnalysisParameterDelete>(
    ({ id }) => request(`/analyses/parameters/${id}`, { method: 'DELETE' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['analyses']);
        queryClient.invalidateQueries(['patient-analyses']);
      },
    },
  );
}
