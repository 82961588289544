import React from 'react';
import { PrimitiveType } from '../../../contracts/Common';

interface Props {
  id: number | string;
  value: string | number;
  handleChange: (value: PrimitiveType) => void;
  label?: string;
}

export default function HtmlTextArea({ id, value, handleChange, label }: Props) {
  return (
    <div className={`flex items-center ${!label ? 'justify-center' : ''}`}>
      {label ? (
        <label htmlFor={`input_${id}_${value}`} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <textarea
        id={`input_${id}`}
        name={`input_${id}`}
        rows={3}
        className="shadow-sm focus:ring-blue-poll focus:border-blue-poll block w-full sm:text-sm border border-gray-300 rounded-md"
        defaultValue={value}
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => handleChange(e.currentTarget.value)}
      />
    </div>
  );
}
