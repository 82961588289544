import React from 'react';
import { Listbox } from '@headlessui/react';

import MultiSelectButton from './MultiSelectButton';
import MultiSelectOption from './MultiSelectOption';
import { SuperSearchFilterOption } from '../../common/Table/SuperSearch/SuperSearchUtils';

interface Props {
  onChange: (id: string[]) => void;
  multiple?: boolean;
  options: SuperSearchFilterOption[];
  values: (string | number)[];
}

const MultiSelectInput = ({ values, options, onChange, multiple = false }: Props) => {
  const handleValueChange = (newValue: string[]) => onChange(Array.isArray(newValue) ? newValue : [newValue]);
  const value = multiple ? values : values[0];

  return (
    <Listbox value={value} onChange={handleValueChange} multiple={multiple}>
      {({ open }) => (
        <>
          <MultiSelectButton values={values} options={options} open={open} />

          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white dark:bg-gray-600 dark:border-gray-500 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, idx) => (
              <MultiSelectOption option={option} key={idx} />
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

export default MultiSelectInput;
