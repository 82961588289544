import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';

import { DaysFilterType, SleepMetricsResponse } from '../../contracts/Metrics';
import { useRequest } from '../useRequest';

export const useMetricsSleep = (patientId: number, date: Date, days: DaysFilterType) => {
  const request = useRequest<SleepMetricsResponse>();
  return useQuery<unknown, unknown, SleepMetricsResponse>(
    ['patient-metrics-sleep', { patientId, days, date }],
    () => request(`/patients/${patientId}/metrics/sleep/${format(date, 'yyyy-MM-dd')}/${days}`),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
    },
  );
};
