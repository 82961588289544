import { Row, TableBodyProps } from 'react-table';
import * as React from 'react';
import classNames from 'classnames';

interface Props<T extends object> {
  page: Row<T>[];
  tableBodyProps: TableBodyProps;
  prepareRow: (row: Row<T>) => void;
  handleRowClick?: (row: Row<T>) => void;
}

export default function TableBody<T extends object>({ page, tableBodyProps, prepareRow, handleRowClick }: Props<T>) {
  return (
    <tbody
      className="dark:bg-gray-800 bg-white divide-y divide-gray-200 dark:divide-gray-600 text-sm text-gray-500 dark:text-gray-300"
      {...tableBodyProps}
    >
      {page.map(row => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            onClick={() => handleRowClick && handleRowClick(row)}
            className={classNames({ 'cursor-pointer': handleRowClick })}
          >
            {row.cells.map(cell => (
              <td
                className="px-6 py-3 whitespace-nowrap"
                {...cell.getCellProps({
                  // @ts-ignore
                  className: `px-6 py-3 whitespace-nowrap ${cell.column?.className ?? ''}`,
                })}
              >
                <>{cell.render('Cell')}</>
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}
