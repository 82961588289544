import * as React from 'react';
interface IButtonGroup<T> {
  buttons: Array<{
    value: T;
    text: string;
    icon?: JSX.Element;
  }>;
  handleClick: (button: T) => void;
  label?: string;
  active: T;
}

export default function ButtonGroup<T extends unknown>(props: IButtonGroup<T>) {
  const cx = (index: number) => {
    let defaultCX =
      'relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium focus:z-10 focus:outline-none';
    if (index === 0) {
      defaultCX += `${defaultCX} rounded-l-md`;
    }
    if (props.buttons.length - 1 === index) {
      defaultCX += `${defaultCX} rounded-r-md`;
    }
    if (index > 0) {
      defaultCX += `${defaultCX} -ml-px`;
    }
    if (isActive(index)) {
      defaultCX += `${defaultCX} bg-primary text-white hover:bg-pink-700`;
    } else {
      defaultCX += `${defaultCX} bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-300 hover:dark:bg-gray-600`;
    }
    return defaultCX;
  };
  const isActive = (index: number) => props.buttons[index].value === props.active;
  return (
    <div className="flex items-center">
      {props.label ? <div className="text-sm font-medium mr-3">{props.label}</div> : null}
      <span className="relative z-0 inline-flex shadow-sm rounded-md">
        {props.buttons.map((button, index) => (
          <button key={index} type="button" className={cx(index)} onClick={() => props.handleClick(button.value)}>
            <span className={isActive(index) ? 'text-white' : 'text-gray-400'}>{button.icon}</span> {button.text}
          </button>
        ))}
      </span>
    </div>
  );
}
