import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DiagnosisType } from '../../contracts/Classifiers';

type PatientDiagnosisUpdateRequest = {
  patientId: number;
  diagnosisId: number;
  comments: string;
};

export function usePatientDiagnosisUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<DiagnosisType>();
  return useMutation<DiagnosisType, unknown, PatientDiagnosisUpdateRequest>(
    ({ patientId, diagnosisId, ...data }) =>
      request(`/patients/${patientId}/diagnoses/${diagnosisId}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(['patient-diagnoses', request.patientId], (oldData: Array<DiagnosisType> = []) => {
          return oldData.map(item => (item.id === data.id ? { ...data } : { ...item }));
        });
      },
    },
  );
}
