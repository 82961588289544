import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { GadgetType, GadgetUpdateRequest } from '../../contracts/Gadget';

export function useGadgetUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<GadgetType>();
  return useMutation<GadgetType, unknown, GadgetUpdateRequest>(
    ({ id, ...data }) =>
      request(`/gadgets/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['gadgets'], (oldData: Array<GadgetType> = []) => {
          const index = oldData.findIndex(o => o.id === data.id);
          if (index !== -1) {
            return [...oldData.slice(0, index), data, ...oldData.slice(index + 1)];
          }
          return oldData;
        });
      },
    },
  );
}
