import * as React from 'react';
import { useParams } from 'react-router-dom';
import { usePatientThreads } from '../../../../../hooks/queries/usePatientThreads';
import FeedItem from '../../../../../components/feed/FeedItem';
import Feed from '../../../../../components/feed/Feed';
import { useMe } from '../../../../../hooks/queries/useMe';
import { NullableType } from '../../../../../contracts/Common';
import {
  PatientThreadType,
  ThreadMessageType,
  ThreadMessageUpdateRequest,
  ThreadStatus,
} from '../../../../../contracts/Threads';
import Dialog from '../../../../../components/dialog/Dialog';
import { useThreadMessageUpdate } from '../../../../../hooks/mutations/thread/useThreadMessageUpdate';
import { ThreadMessageForm } from './ThreadMessageForm';
import { successToast } from '../../../../../utils/successToast';
import { ThreadActions } from './ThreadActions';
import { Descendant } from 'slate';
import { tryParseJson } from '../../../../../utils/errors';
import { useDoctors } from '../../../../../hooks/queries/useDoctors';
import { usePatient } from '../../../../../hooks/queries/usePatient';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../../../../translations/i18n';

export const ThreadListTab: React.FC = () => {
  const { t } = useTranslation('ThreadListTab');
  const { id: patientId } = useParams<{ id: string }>();
  const [open, setOpen] = React.useState<NullableType<ThreadMessageUpdateRequest>>(null);
  const { data: meData } = useMe();
  const doctors = useDoctors();
  const patient = usePatient(Number(patientId));
  const { data } = usePatientThreads(Number(patientId));
  const threadMessageUpdateMutation = useThreadMessageUpdate();

  function handleMessageUpdate({ text, assigneeId }: { text: Descendant[]; assigneeId?: number }) {
    if (open) {
      threadMessageUpdateMutation.mutate(
        {
          ...open,
          text: JSON.stringify(text),
          assigneeId: assigneeId!,
        },
        {
          onSuccess: () => {
            setOpen(null);
            successToast(t('updateSuccessMessage'));
          },
        },
      );
    }
  }

  if (!data || doctors.isLoading || patient.isLoading || !patient.data || !doctors.data) {
    return null;
  }

  return (
    <>
      {data.map((thread: PatientThreadType) => (
        <div className="pb-8" key={thread.id}>
          <Feed>
            {thread.messages.map((message: ThreadMessageType, index: number) => (
              <FeedItem
                name={getLocalizedFullName(message.doctor)}
                headline={message.doctor.speciality}
                date={message.timestamp}
                content={message.text}
                isLast={index === thread.messages.length - 1 && !thread.assignee}
                isActionsVisible={message.doctor.id === meData?.id}
                handleEditClick={() =>
                  setOpen({
                    threadId: thread.id,
                    messageId: message.id,
                    patientId: Number(patientId),
                    assigneeId: thread.messages?.[0].doctorId,
                    text: message.text,
                  })
                }
                key={message.id}
              />
            ))}
            {thread.assignee && (
              <FeedItem
                name={getLocalizedFullName(thread.assignee)}
                headline={thread.assignee.speciality}
                isLast={true}
                isActionsVisible={thread.assignee.id === meData?.id}
              />
            )}
          </Feed>
          <div className="mt-6 ml-12 mb-8 relative z-10">
            <ThreadActions
              threadId={thread.id}
              patientId={Number(patientId)}
              doctors={doctors.data}
              assignedDoctorId={thread.messages[0].doctor.id}
              isThreadClosed={thread.isClosed}
              isThreadOnHold={thread.status === ThreadStatus.OnHold}
            />
          </div>
          <hr />
        </div>
      ))}
      <Dialog open={!!open} handleCloseClick={() => setOpen(null)} className="w-3/4">
        {open && (
          <ThreadMessageForm
            doctors={doctors.data}
            assigneeId={open.assigneeId}
            handleSave={handleMessageUpdate}
            handleCancel={() => setOpen(null)}
            text={tryParseJson(open.text)}
          />
        )}
      </Dialog>
    </>
  );
};
