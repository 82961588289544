import * as React from 'react';
import { ExtendedColumnProps } from '../../TableUtilities';
import {
  buildTabs,
  filterTableData,
  mapColumnsToFilterConfig,
  mapDisabledFiltersToTabUpdates,
  mapFilterEntityToSuperSearchTab,
  mapNewFiltersToTabUpdates,
  SuperSearchFilters,
  SuperSearchTab,
} from '../SuperSearchUtils';
import { useSuperSearchPersistence } from './useSuperSearchPersistance';

export const useSuperSearch = <T extends object>(
  columns: ExtendedColumnProps<T>[],
  data: T[],
  defaultTabs?: SuperSearchTab[],
  tableName?: string,
) => {
  const defaultTabsMemo = React.useMemo(() => defaultTabs || [], [defaultTabs]);
  const storageKey = React.useMemo(() => `sslot_${tableName}`, [tableName]);

  const { persistedFilters, handleTabCreate, handleTabUpdate, handleTabRename, handleTabDelete } =
    useSuperSearchPersistence(tableName);
  const [tabs, setTabs] = React.useState<SuperSearchTab[]>(defaultTabsMemo);
  const [filters, setFilters] = React.useState<SuperSearchFilters[]>([]);
  const [activeTabId, setActiveTabId] = React.useState<number | null>(null);
  const [filteredData, setFilteredData] = React.useState<T[]>([]);

  const activeTab = React.useMemo<SuperSearchTab>(() => {
    const newActiveTab = tabs.find(({ id }) => id === activeTabId) ?? tabs[0];
    // If blank tab - nothing to do, return
    if (!newActiveTab?.configuration) {
      return newActiveTab;
    }
    // Make sure there are no left-over filters that conflict with table configs
    newActiveTab.configuration = newActiveTab.configuration.filter(
      ({ columnAccessor }) => columns.find(({ id }) => id === columnAccessor)?.canSuperFilter === true,
    );
    return newActiveTab;
  }, [activeTabId, tabs, columns]);

  // On first load - try to get last viewed tab from browser's storage
  React.useEffect(() => {
    const lastViewedTabId = localStorage.getItem(storageKey);
    if (lastViewedTabId !== null) {
      setActiveTabId(Number(lastViewedTabId));
    }
  }, [storageKey]);

  // When activeTabId changes, update browser storage's "last viewed" record
  React.useEffect(() => {
    if (activeTabId !== null) {
      localStorage.setItem(storageKey, activeTabId.toString());
    } else {
      localStorage.removeItem(storageKey);
    }
  }, [activeTabId, storageKey]);

  // When active tab or data changes - update filter options
  React.useEffect(() => {
    if (activeTab) {
      setFilters(mapColumnsToFilterConfig(columns, data, activeTab));
    }
  }, [columns, data, activeTab]);

  // When tabs are loaded from backend - display them
  React.useEffect(() => {
    setTabs(buildTabs(defaultTabsMemo, persistedFilters));
    setActiveTabId(id => (id === null ? defaultTabsMemo?.[0]?.id ?? null : id));
  }, [defaultTabsMemo, persistedFilters]);

  // When active tab or data changes - filter the data
  React.useEffect(() => {
    if (!activeTab) {
      setFilteredData(data);
      return;
    }

    setFilteredData(filterTableData(data, activeTab.configuration));
  }, [activeTab, data]);

  const setActiveFilters = React.useCallback(
    (columnAccessor: string, values: (string | number)[]): void => {
      if (activeTabId !== null) {
        setTabs(currentTabs => mapNewFiltersToTabUpdates(currentTabs, activeTabId, columnAccessor, values));
      }
    },
    [activeTabId],
  );

  const deactivateFilter = React.useCallback(
    (columnAccessor: string, value: string | number): void => {
      if (activeTabId !== null) {
        setTabs(tabs => mapDisabledFiltersToTabUpdates(tabs, activeTabId, columnAccessor, value));
      }
    },
    [activeTabId],
  );

  const deleteTab = React.useCallback(() => {
    if (!activeTab) {
      return;
    }

    handleTabDelete(activeTab, () => {
      const id = activeTab.id;
      setActiveTabId(defaultTabsMemo?.[0]?.id);
      setTabs(current => current.filter(t => t.id !== id));
    });
  }, [handleTabDelete, activeTab, defaultTabsMemo]);

  const saveTab = React.useCallback(
    (name: string) => {
      if (!activeTab) {
        return;
      }

      if (activeTab.id === -1) {
        handleTabCreate(name, activeTab, entity => {
          const originalDefaultTab = defaultTabsMemo.find(({ id }) => id === activeTab.id)!;
          const newTab = mapFilterEntityToSuperSearchTab(true)(entity);
          setTabs(tabs => [...tabs.map(t => (t.id === activeTab.id ? originalDefaultTab : t)), newTab]);
          setActiveTabId(newTab!.id);
        });
      } else {
        handleTabUpdate(name, activeTab, entity => {
          setTabs(tabs => tabs.map(t => (t.id === entity.id ? mapFilterEntityToSuperSearchTab(true)(entity) : t)));
        });
      }
    },
    [handleTabCreate, handleTabUpdate, activeTab, defaultTabsMemo],
  );

  const renameTab = React.useMemo(
    () => (name: string) => {
      if (!activeTab) {
        return;
      }

      const originalTab = persistedFilters.find(f => f.id === activeTab.id);
      if (!originalTab) {
        console.error('Trying to rename a tab that does not exist on server');
        return;
      }
      handleTabRename(name, originalTab, () => {
        setTabs(current => current.map(t => (t.id !== originalTab.id ? t : { ...t, name })));
      });
    },
    [handleTabRename, activeTab, persistedFilters],
  );

  return {
    filters,
    filteredData,
    tabs,
    saveTab,
    renameTab,
    deleteTab,
    activeTab,
    setActiveTabId,
    setActiveFilters,
    deactivateFilter,
  };
};
