export const KNOWN_DIAGNOSES = [
  'E10',
  'E11',
  'E13',
  'E14',
  'I11',
  'I12',
  'I13',
  'I15',
  'I48',
  'I50',
  'I69',
  'J44',
  'J45',
  'G20',
  'G40',
  'M05',
  'M06',
].map(d => ({ id: d, label: d }));
