import * as React from 'react';

export enum TagColor {}

interface Props {
  children: string | number | Array<string | number> | JSX.Element;
  className?: string; //css class
  title?: string;
  size?: 'base' | 'larger';
}

export default function Tag({ children, title, className = 'bg-red-100 text-emerald-800', size = 'base' }: Props) {
  let cx = 'px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full';
  if (size === 'larger') {
    cx = 'inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium';
  }
  return (
    <span className={`${cx} ${className}`} title={title}>
      {children}
    </span>
  );
}
