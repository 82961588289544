import * as React from 'react';
import { Tab } from '@headlessui/react';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import Breadcrumbs, { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import PatientCard from '../../components/patient/PatientCard';
import { useParams } from 'react-router-dom';
import { PatientGadgetsTab } from './components/tabs/PatientGadgetsTab';
import { PatientMedicationsTab } from './components/tabs/PatientMedicationsTab';
import {
  DevicePhoneMobileIcon,
  FolderIcon,
  BeakerIcon,
  HeartIcon,
  PaperClipIcon,
  CalendarIcon,
  NoSymbolIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  BellIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { PatientPollTab } from './components/tabs/poll/PatientPollTab';
import { usePatient } from '../../hooks/queries/usePatient';
import { classNames } from '../../utils/classNames';
import { PERMISSIONS, usePermission } from '../../hooks/usePermission';
import { PatientSettingsTab } from './components/tabs/PatientSettingsTab';
import { useQueryParams } from '../../hooks/useQueryParams';
import { PatientMetricsTab } from './components/tabs/metrics/PatientMetricsTab';
import { PatientDocumentsTab } from './components/tabs/documents/PatientDocumentsTab';
import { NullableType } from '../../contracts/Common';
import { PatientRequest, PatientType } from '../../contracts/Patient';
import { AddPatientForm } from './components/AddPatientForm';
import Dialog from '../../components/dialog/Dialog';
import { usePatientUpdate } from '../../hooks/mutations/usePatientUpdate';
import PatientAnalysesTab from './components/tabs/analyses/PatientAnalysesTab';
import { PatientFeedTab } from './components/tabs/feed/PatientFeedTab';
import { PatientAnamnesisTab } from './components/tabs/anamnesis/PatientAnamnesisTab';
import { PatientHcpTab } from './components/tabs/healthcarePlan/PatientHcpTab';
import { PatientAlertsListTab } from './components/tabs/alerts/PatientAlertsListTab';
import { ThreadListTab } from './components/tabs/threads/ThreadListTab';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../translations/i18n';

export const PatientDetails = () => {
  const { t } = useTranslation('PatientDetails');
  const [patientEdit, setPatientEdit] = React.useState<NullableType<PatientType>>(null);
  const [breadcrumbPages, setBreadcrumbPages] = React.useState<Array<BreadcrumbItem>>([]);
  const updatePatientMutation = usePatientUpdate();
  const queryParams = useQueryParams();
  const { id } = useParams<{ id: string }>();
  const { data: patient } = usePatient(Number(id));
  const { can, canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const navigation = React.useMemo(
    () => [
      { name: t('patientPollTab'), icon: FolderIcon, component: PatientPollTab, queryParam: 'poll' },
      { name: t('patientMetricsTab'), icon: HeartIcon, component: PatientMetricsTab, queryParam: 'metrics' },
      { name: t('patientAlertsListTab'), icon: BellIcon, component: PatientAlertsListTab, queryParam: 'alerts' },
      {
        name: t('threadListTab'),
        icon: ChatBubbleBottomCenterTextIcon,
        component: ThreadListTab,
        queryParam: 'threads',
      },
      {
        name: t('patientAnamnesisTab'),
        icon: DocumentTextIcon,
        component: PatientAnamnesisTab,
        queryParam: 'anamnesis',
      },
      { name: t('patientHcpTab'), icon: ClipboardDocumentListIcon, component: PatientHcpTab, queryParam: 'hcp' },
      { name: t('patientFeedTab'), icon: CalendarIcon, component: PatientFeedTab, queryParam: 'feed' },
      { name: t('patientAnalysesTab'), icon: BeakerIcon, component: PatientAnalysesTab, queryParam: 'analyses' },
      {
        name: t('patientMedicationsTab'),
        icon: NoSymbolIcon,
        component: PatientMedicationsTab,
        queryParam: 'medication',
      },
      { name: t('patientDocumentsTab'), icon: PaperClipIcon, component: PatientDocumentsTab, queryParam: 'documents' },
      { name: t('patientGadgetsTab'), icon: DevicePhoneMobileIcon, component: PatientGadgetsTab, queryParam: 'gadget' },
      { name: t('patientSettingsTab'), icon: Cog6ToothIcon, component: PatientSettingsTab, queryParam: 'settings' },
    ],
    [t],
  );

  const defaultTabIndex = React.useMemo(() => {
    return navigation.findIndex(navigationItem => navigationItem.queryParam === queryParams.get('tab')) ?? 0;
  }, [navigation, queryParams]);

  React.useEffect(() => {
    if (patient) {
      if (canManagePatients) {
        const breadcrumbPages = [] as Array<BreadcrumbItem>;
        breadcrumbPages.push({ name: t('patients', { ns: 'DashboardMainMenu' }), href: '/patients', current: false });
        breadcrumbPages.push({ name: getLocalizedFullName(patient), current: true });
        setBreadcrumbPages(breadcrumbPages);
      }
    }
  }, [patient, canManagePatients, t]);

  function handlePatientUpdateSubmit(values: PatientRequest) {
    updatePatientMutation.mutate(values, {
      onSuccess: () => setPatientEdit(null),
    });
  }

  return (
    <DashboardLayout>
      <div>
        {canManagePatients && <Breadcrumbs pages={breadcrumbPages} />}
        {patient && <PatientCard {...patient} onEditClick={() => setPatientEdit(patient)} />}

        <div className={'print:mt-0 mt-16 flex'}>
          <Tab.Group
            vertical={true}
            defaultIndex={defaultTabIndex}
            onChange={index => {
              queryParams.set('tab', navigation[index].queryParam);
            }}
          >
            <Tab.List className={'print:hidden space-y-1 w-72'}>
              {navigation.map(item => (
                <Tab
                  key={item.name}
                  className={({ selected }) =>
                    classNames(
                      selected ? 'text-primary font-medium' : 'text-gray-500 hover:text-primary',
                      'group flex items-center px-3 py-2 text-lg focus:outline-none',
                    )
                  }
                >
                  <item.icon className={classNames('shrink-0 -ml-1 mr-3 h-6 w-6')} aria-hidden="true" />
                  <span className="truncate">{item.name}</span>
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="w-full">
              {navigation.map(item => (
                <Tab.Panel key={item.name}>
                  <item.component />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
        <Dialog open={!!patientEdit} handleCloseClick={() => setPatientEdit(null)} widthAuto={true}>
          <AddPatientForm
            handleCancel={() => setPatientEdit(null)}
            handleSave={handlePatientUpdateSubmit}
            patient={patientEdit === null ? undefined : patientEdit}
            canAssignClinic={can(PERMISSIONS.MANAGE_ALL_PATIENTS)}
          />
        </Dialog>
      </div>
    </DashboardLayout>
  );
};
