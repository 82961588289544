import * as React from 'react';
import { Row, SortingRule, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import classNames from 'classnames';

import SuperSearch from './SuperSearch/SuperSearch';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableSearchInput from './TableSearchInput';
import { ExtendedColumnProps, getTableOptions } from './TableUtilities';
import { useSuperSearch } from './SuperSearch/hooks/useSuperSearch';
import { SuperSearchSettings, SuperSearchTab } from './SuperSearch/SuperSearchUtils';

interface ITable<T extends object = {}> {
  data: Array<T>;
  columns: Array<ExtendedColumnProps<T>>;
  hiddenColumnIds?: Array<string>;
  handleAddClick?: () => void;
  handleRowClick?: (row: Row<T>) => void;
  showSearch?: boolean;
  pageSize?: number;
  autoResetPage?: boolean;

  defaultSortBy?: SortingRule<T>[];
  // SuperSearch props
  // If true, will display advanced search and filter settings
  superSearch?: boolean | SuperSearchSettings;
  // Key to use when storing/querying data from backend
  tableName?: string;
  superSearchDefaultTabs?: SuperSearchTab[];

  // controlled table
  pageCount?: number;
  fetchData?: (args: { pageIndex: number; pageSize: number }) => void;
  pageIndex?: number;
  showFilters?: boolean;
}

export default function Table<T extends object = {}>({ fetchData, ...props }: ITable<T>) {
  const { filteredData, ...superSearchProps } = useSuperSearch(
    props.columns,
    props.data,
    props.superSearchDefaultTabs,
    props.tableName,
  );

  const tableOptions = React.useMemo(
    () =>
      getTableOptions(
        props.columns,
        filteredData,
        props.autoResetPage || !!props.superSearch,
        props.pageSize,
        props.pageIndex,
        props.hiddenColumnIds,
        props.defaultSortBy,
        !!fetchData,
        props.pageCount,
      ),
    [props, filteredData, fetchData],
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headers,
    page,

    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    state: { pageIndex, globalFilter, pageSize },
    setGlobalFilter,
  } = useTable(tableOptions, useGlobalFilter, useSortBy, usePagination);

  // fetch data if Table data is controlled(comes form server)
  React.useEffect(() => {
    if (typeof fetchData !== 'undefined') {
      fetchData({ pageIndex, pageSize });
    }
  }, [pageIndex, pageSize, fetchData]);

  return (
    <div className="flex flex-col relative">
      {props.showSearch ? <TableSearchInput setFilter={setGlobalFilter} filter={globalFilter} /> : null}

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg dark:border-gray-600">
            {props.superSearch ? (
              <SuperSearch
                tabs={superSearchProps.tabs}
                activeTab={superSearchProps.activeTab}
                setActiveFilters={superSearchProps.setActiveFilters}
                filters={superSearchProps.filters}
                deactivateFilter={superSearchProps.deactivateFilter}
                setActiveTabId={superSearchProps.setActiveTabId}
                onTabSaved={superSearchProps.saveTab}
                onTabDeleted={superSearchProps.deleteTab}
                onTabRenamed={superSearchProps.renameTab}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                settings={props.superSearch}
              />
            ) : null}
            <table
              className={classNames(
                'min-w-full divide-y divide-gray-200 dark:divide-gray-600 overflow-hidden rounded-b-md',
                !props.superSearch && 'rounded-tl-md rounded-tr-md',
              )}
              {...getTableProps()}
            >
              <TableHeader headers={headers} />
              <TableBody
                page={page}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                handleRowClick={props.handleRowClick}
              />
            </table>
          </div>
        </div>
      </div>

      <TableFooter
        pageCount={pageCount}
        handleAddClick={props.handleAddClick}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
      />
    </div>
  );
}
