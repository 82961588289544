import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { GadgetHistory } from '../../contracts/Gadget';

export function useGadgetHistory(gadgetId: number) {
  const request = useRequest<GadgetHistory>();
  return useQuery<unknown, unknown, GadgetHistory>(
    ['gadget-history', gadgetId],
    () => request(`/gadgets/${gadgetId}/history`),
    {
      staleTime: 60 * 1000,
    },
  );
}
