/* This example requires Tailwind CSS v2.0+ */
import React from 'react';

interface IPanel {
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
  onClick?: () => void;
}

export const Panel: React.FunctionComponent<IPanel> = ({ children, className = '', ...rest }) => {
  return (
    <div className={`bg-white shadow rounded-lg ${className}`} {...rest}>
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
};
