interface Props {
  children: JSX.Element | Array<JSX.Element> | null;
  className?: string;
}

export default function Scrollbar({ children, className = '' }: Props) {
  return (
    <div
      className={`overflow-x-visible overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 transition duration-300 hover:scrollbar-thumb-gray-500 -m-1 p-1 py-2 ${className}`}
    >
      {children}
    </div>
  );
}
