import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';
import { useDropzone } from 'react-dropzone';
import { errorToast } from '../../utils/errorToast';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import { DocumentType, isDocument } from '../../contracts/Document';
import { useTranslation } from 'react-i18next';

interface IMultiFileInput {
  label: string;
  description?: string;
  removedFileIdsFieldName?: string; // array
}

export const MultiFileInput: React.FunctionComponent<FieldProps & IMultiFileInput> = ({
  field,
  form,
  removedFileIdsFieldName,
  ...props
}) => {
  const { t } = useTranslation('MultiFileInput');
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        if (field.value && Array.isArray(field.value)) {
          form.setFieldValue(field.name, [...field.value, ...acceptedFiles]);
        } else {
          form.setFieldValue(field.name, acceptedFiles);
        }
      }
    },
    [form, field],
  );

  function removeItem(index: number) {
    if (field.value && field.value[index]) {
      // if document needs to be removed, add it to different form field
      if (isDocument(field.value[index]) && removedFileIdsFieldName !== undefined) {
        form.setFieldValue(removedFileIdsFieldName, [
          field.value[index].id,
          ...(form.values[removedFileIdsFieldName] || []),
        ]);
      }
      if (field.value.length === 1) {
        form.setFieldValue(field.name, null);
      } else {
        form.setFieldValue(field.name, [...field.value.slice(0, index), ...field.value.slice(index + 1)]);
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: rejectedFiles => {
      if (rejectedFiles.length) {
        errorToast(t('invalidFormatToast', { fileName: rejectedFiles[0].file.name }));
      }
    },
    accept: ['image/png', 'image/jpeg', '.pdf', '.doc', '.docx'],
  });
  let cx = isDragActive ? 'border-primary' : 'border-gray-300';
  if (field.value) {
    cx += ' rounded-t-none';
  }
  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <div className={'mt-1'}>
        {field.value && <Attachments files={field.value} onRemove={index => removeItem(index)} />}
        <div
          {...getRootProps()}
          className={`max-w-lg justify-center px-4 pt-2 pb-3 border-2 border-dashed rounded-md cursor-pointer hover:border-gray-400 ${cx}`}
        >
          <div className="space-y-1 text-center">
            <DocumentPlusIcon className="h-12 w-12 text-gray-400 mx-auto heroicon-sw-1" />
            <div className="flex text-sm text-gray-600 text-center">
              <input id={field.name} type="file" {...getInputProps()} />
              <p className="pl-1 text-center w-full">
                <span className="cursor-pointer bg-white rounded-md font-medium text-primary hover:text-pink-500 focus-within:outline-none">
                  {t('selectFile')}
                </span>
                {t('orDragAndDropHere')}
              </p>
            </div>
            <p className="text-xs text-gray-500">{t('supportedFormats')}</p>
          </div>
        </div>
      </div>
    </BaseInput>
  );
};

interface IAttachments {
  files: Array<File | DocumentType>;
  onRemove: (index: number) => void;
}

const Attachments: React.FunctionComponent<IAttachments> = ({ files, onRemove }) => {
  function getFileName(item: File | DocumentType): string {
    if (isDocument(item)) {
      return item.fileName;
    } else {
      return item.name;
    }
  }
  if (files.length <= 0) {
    return null;
  }

  return (
    <ul className="border border-gray-200 rounded-md rounded-b-none border-b-0 divide-y divide-gray-200">
      {files.map((file, index) => (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm" key={index}>
          <div className="w-0 flex-1 flex items-center">
            <PaperClipIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="ml-2 flex-1 w-0 truncate" title={getFileName(file)}>
              {getFileName(file)}
            </span>
          </div>
          <div className="ml-4 shrink-0">
            <TrashIcon
              className="shrink-0 h5 w-5 text-gray-400 cursor-pointer hover:text-primary"
              onClick={() => onRemove(index)}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
