import React from 'react';
import { usePatient } from '../../hooks/queries/usePatient';
import { useTranslation } from 'react-i18next';

export const PatientMiniCard: React.FunctionComponent<{ patientId: number }> = ({ patientId }) => {
  const { t } = useTranslation('PatientMiniCard');
  const { data: patient } = usePatient(patientId);
  if (!patient) {
    return null;
  }
  return (
    <section aria-labelledby="profile-overview-title">
      <dt className="text-sm font-medium text-gray-700">{t('patient', { ns: 'common' })}</dt>
      <dd className="mt-1 text-sm">
        {patient.firstName} {patient.lastName}
      </dd>
    </section>
  );
};
