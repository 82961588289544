import { serializeToHtml } from './richTextUtils';
import * as React from 'react';
import { NullableType } from '../../contracts/Common';
import { tryParseJson } from '../../utils/errors';

export default function SerializeToHtml({ content }: { content: NullableType<string> }) {
  if (!content) {
    return null;
  }
  return (
    <div className={`${content.length > 0 ? 'mb-4' : ''}`}>
      <div dangerouslySetInnerHTML={{ __html: serializeToHtml({ children: tryParseJson(content) }) }} />
    </div>
  );
}
