import { useEcgHourlyAnalysis } from '../../../../../../../hooks/queries/useEcgHourlyAnalysis';
import Card from '../../../../../../../components/common/Card';
import LoadingContainer from '../../../../../../../components/layouts/LoadingContainer';
import SummaryTable from './SummaryTable';
import SummaryPieChart from './SummaryPieChart';
import { useTranslation } from 'react-i18next';

interface Props {
  patientId: number;
  recordId: number;
  className?: string;
}

export default function EcgDailyBreakdown({ patientId, recordId, className }: Props) {
  const { t } = useTranslation('EcgDailyBreakdown');
  const { data, isLoading } = useEcgHourlyAnalysis(patientId, recordId);

  if (!data) {
    return null;
  }

  return (
    <div className={'flex'}>
      <div className={'flex-1'}>
        <Card title={t('cardTitle')} noPadding={true} className={className}>
          <div className={'flex-col'}>
            <LoadingContainer loading={isLoading}>{data && <SummaryTable data={data} />}</LoadingContainer>
          </div>
        </Card>
      </div>
      <div className={'w-64 mt-10 ml-8'}>
        <SummaryPieChart data={data} />
      </div>
    </div>
  );
}
