import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { FeedRecordType } from '../../contracts/Feed';

export function usePatientFeed(patientId: number) {
  const request = useRequest<unknown>();
  return useQuery<unknown, unknown, Array<FeedRecordType>>(
    ['patient-feed', patientId],
    () => request(`/patients/${patientId}/feed`),
    {
      staleTime: 60 * 1000,
    },
  );
}
