import * as React from 'react';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import { useDoctors } from '../../hooks/queries/useDoctors';
import { CellProps, Column } from 'react-table';
import Table from '../../components/common/Table/Table';
import { DoctorRequestType, getDoctorRoleLabelList, DoctorType } from '../../contracts/Doctors';
import PersonLine from '../../components/common/PersonLine';
import EditIcon from '../../components/icons/EditIcon';
import RemoveIcon from '../../components/icons/RemoveIcon';
import { NullableType } from '../../contracts/Common';
import Dialog from '../../components/dialog/Dialog';
import { DoctorForm } from './DoctorForm';
import { useDoctorsCreate } from '../../hooks/mutations/doctors/useDoctorsCreate';
import { successToast } from '../../utils/successToast';
import { useDoctorsDelete } from '../../hooks/mutations/doctors/useDoctorsDelete';
import Confirmation from '../../components/common/Confirmation';
import { useDoctorsUpdate } from '../../hooks/mutations/doctors/useDoctorsUpdate';
import { useTranslation } from 'react-i18next';

const breadcrumbPages = [{ name: 'Gydytojai', href: '/doctors', current: true }];

export const DoctorsList = () => {
  const { t } = useTranslation('DoctorsList');
  const [open, setOpen] = React.useState<NullableType<DoctorType> | 'add'>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean | number>(false);
  const { data, isLoading } = useDoctors();
  const doctorsCreateMutation = useDoctorsCreate();
  const doctorsUpdateMutation = useDoctorsUpdate();
  const doctorsDeleteMutation = useDoctorsDelete();
  const [columns, tableData] = React.useMemo(() => {
    const columns: Array<Column<DoctorType>> = [
      {
        Header: t('table.nameColHeader'),
        width: 'auto',
        Cell: ({ row }: CellProps<DoctorType>) => (
          <PersonLine>
            {row.original.firstName} {row.original.lastName}
          </PersonLine>
        ),
      },
      {
        Header: t('table.specialtyColHeader'),
        accessor: 'speciality',
        width: 'auto',
      },
      {
        Header: t('table.clinicColHeader'),
        accessor: 'clinic',
        Cell: ({ value }) => <>{value?.name ?? '-'}</>,
        width: 'auto',
      },
      {
        Header: t('table.roleColHeader'),
        accessor: 'role',
        width: 'auto',
        Cell: ({ value }) => <>{getDoctorRoleLabelList(t)[value]}</>,
      },
      {
        Header: t('table.phoneColHeader'),
        accessor: 'phone',
        width: 'auto',
      },
      {
        Header: t('table.editColHeader'),
        accessor: 'id',
        disableSortBy: true,
        width: 70,
        Cell: ({ value, row }) => (
          <div className="flex my-1">
            <div>
              <EditIcon onClick={() => setOpen(row.original)} />
            </div>
            <div>
              <RemoveIcon onClick={() => setOpenDeleteConfirmation(value)} />
            </div>
          </div>
        ),
      },
    ];

    return [columns, data];
  }, [data, t]);

  function addDoctor(values: DoctorRequestType) {
    doctorsCreateMutation.mutate(values, {
      onSuccess: () => {
        setOpen(null);
        successToast(t('addDoctorSuccessToast'));
      },
    });
  }

  function updateDoctor(values: DoctorRequestType) {
    doctorsUpdateMutation.mutate(values, {
      onSuccess: () => {
        setOpen(null);
        successToast(t('updateDoctorSuccessToast'));
      },
    });
  }

  function deleteDoctor(doctorId: number) {
    doctorsDeleteMutation.mutate(
      {
        doctorId,
      },
      {
        onSuccess: () => {
          setOpenDeleteConfirmation(false);
          successToast('Gydytojas ištrintas');
        },
      },
    );
  }

  return (
    <DashboardLayout isLoading={isLoading && !tableData}>
      <div>
        <Breadcrumbs pages={breadcrumbPages} />
        {!isLoading && tableData && <Table data={tableData} columns={columns} handleAddClick={() => setOpen('add')} />}
        <Dialog open={!!open} handleCloseClick={() => setOpen(null)} widthAuto={true}>
          {!!open ? (
            <DoctorForm
              handleCancel={() => setOpen(null)}
              handleSave={(data: DoctorRequestType) => (data.id && data.id > 0 ? updateDoctor(data) : addDoctor(data))}
              doctor={typeof open === 'string' ? undefined : open}
            />
          ) : null}
        </Dialog>
        <Confirmation
          id={Number(openDeleteConfirmation)}
          open={!!openDeleteConfirmation}
          handleCancel={() => setOpenDeleteConfirmation(false)}
          handleSubmit={deleteDoctor}
        />
      </div>
    </DashboardLayout>
  );
};
