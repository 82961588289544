import * as React from 'react';
import { Formik, Field, Form } from 'formik';
import { SelectInput } from '../../../../../components/form/SelectInput';
import * as Yup from 'yup';
import { TextInput } from '../../../../../components/form/TextInput';
import { getFullYear } from '../../../../../utils/date';
import { useAnalyses } from '../../../../../hooks/queries/useAnalyses';
import Button from '../../../../../components/buttons/Button';
import { getValidationMessage } from '../../../../../utils/validation';
import { useTranslation } from 'react-i18next';

export type AnalysisFormValues = {
  date: string;
  value: number | string;
  parameterId: number;
  analysisId: number;
};

interface IAddPatientAnalysisForm {
  analysisGroupId?: number;
  handleCancel: () => void;
  handleSave: (values: AnalysisFormValues) => void;
}
const AddAnalysisScheme = Yup.object().shape({
  parameterId: Yup.number().min(1, getValidationMessage('required', 'validation')),
  analysisId: Yup.number().min(1, getValidationMessage('required', 'validation')),
  date: Yup.string()
    .matches(/\d{4}-\d{2}-\d{2}/, getValidationMessage('fullDateFormat', 'validation'))
    .required(),
  value: Yup.number().required(),
});

export const AddPatientAnalysisForm: React.FunctionComponent<IAddPatientAnalysisForm> = props => {
  const { t } = useTranslation('AddPatientAnalysisForm');
  const { data: analyses } = useAnalyses();
  return (
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{t('header')}</h3>
      <div className="mt-6">
        <Formik
          initialValues={{
            value: '',
            parameterId: 0,
            analysisId: props.analysisGroupId ?? 0,
            date: getFullYear(new Date()),
          }}
          onSubmit={props.handleSave}
          validationSchema={AddAnalysisScheme}
        >
          {({ values }) => (
            <Form>
              <div className="sm:flex sm:items-start">
                <div className="flex flex-col space-y-6 w-full">
                  <Field component={TextInput} name={'date'} label={t('dateLabel')} />
                  <Field component={SelectInput} name={'analysisId'} label={t('analysisIdLabel')}>
                    <option value={0} key={0}>
                      {t('selectAnalysisIdLabel')}
                    </option>
                    {analyses &&
                      analyses.map(analysisGroup => (
                        <option value={analysisGroup.id} key={analysisGroup.id}>
                          {analysisGroup.name}
                        </option>
                      ))}
                  </Field>
                  {values.analysisId > 0 ? (
                    <Field component={SelectInput} name={'parameterId'} label={t('parameterIdLabel')}>
                      <option value={0} key={0}>
                        {t('selectParameterIdLabel')}
                      </option>
                      {analyses &&
                        analyses
                          .filter(analysisGroup => analysisGroup.id === Number(values.analysisId))
                          .map(analysisGroup =>
                            analysisGroup.parameters.map(analysis => (
                              <option value={analysis.id} key={analysis.id}>
                                {analysis.name}, {analysis.unit}
                              </option>
                            )),
                          )}
                    </Field>
                  ) : null}
                  {values.analysisId > 0 ? (
                    <Field component={TextInput} name={'value'} label={t('valueLabel')} type={'number'} />
                  ) : null}
                </div>
              </div>
              <div className="mt-6 sm:flex sm:flex-row-reverse">
                <Button buttonType="submit">{t('save', { ns: 'common' })}</Button>
                <Button type="secondary" onClick={props.handleCancel}>
                  {t('cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
