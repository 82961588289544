import { PropsWithChildren } from 'react';
import Loading from '../common/Loading';

interface Props {
  loading: boolean;
}

export default function LoadingContainer({ loading, children }: PropsWithChildren<Props>) {
  if (loading) {
    return (
      <div className="w-full flex justify-center">
        <Loading />
      </div>
    );
  }

  return <>{children}</>;
}
