import { useParams } from 'react-router-dom';
import { usePatient } from '../../../../../../hooks/queries/usePatient';
import { useEcgRecord } from '../../../../../../hooks/queries/useEcgRecord';
import format from 'date-fns/format';

export function usePatientEcgPageData() {
  const {
    patientId: patientIdString,
    ecgRecordId,
    timestamp,
  } = useParams<{ patientId: string; ecgRecordId: string; timestamp?: string }>();
  const patientId = Number(patientIdString);
  const recordId = Number(ecgRecordId);
  const startTimestamp = timestamp ? format(new Date(Number(timestamp)), 'HH:mm:ss') : null;

  const { data: patient, isLoading: isLoadingPatient } = usePatient(patientId);
  const { data: ecgRecord, isLoading: isLoadingEcgRecord } = useEcgRecord(patientId, recordId);

  return {
    patient,
    ecgRecord,
    startTimestamp,
    isLoading: isLoadingPatient || isLoadingEcgRecord,
  };
}
