import { useEcgRecordChunk } from '../../../../../../hooks/queries/useEcgRecordChunk';
import { useMemo, useState } from 'react';
import { EcgDataLayer } from '../../../../../../contracts/Ecg';

const defaultLayers: EcgDataLayer[] = ['raw'];

export function usePatientEcgDataLayers(patientId: number, recordId: number, chunkNumber: number) {
  const [activeDataLayers, setActiveDataLayers] = useState<EcgDataLayer[]>(defaultLayers);

  const [alignedEnabled, denoisedEnabled, rawEnabled] = useMemo(
    () => [
      activeDataLayers.includes('aligned'),
      activeDataLayers.includes('denoised'),
      activeDataLayers.includes('raw'),
    ],
    [activeDataLayers],
  );

  const { data: alignedChunk, isLoading: isAlignedLoading } = useEcgRecordChunk(
    patientId,
    recordId,
    chunkNumber,
    'aligned',
    alignedEnabled,
  );

  const { data: deNoisedChunk, isLoading: isDenoisedLoading } = useEcgRecordChunk(
    patientId,
    recordId,
    chunkNumber,
    'denoised',
    denoisedEnabled,
  );

  const { data: rawChunk, isLoading: isRawLoading } = useEcgRecordChunk(
    patientId,
    recordId,
    chunkNumber,
    'raw',
    rawEnabled,
  );

  const isLoading = useMemo(
    () =>
      (alignedEnabled && isAlignedLoading) || (denoisedEnabled && isDenoisedLoading) || (rawEnabled && isRawLoading),
    [alignedEnabled, isAlignedLoading, denoisedEnabled, isDenoisedLoading, rawEnabled, isRawLoading],
  );

  return {
    activeDataLayers,
    setActiveDataLayers,

    alignedChunk: alignedEnabled ? alignedChunk : null,
    rawChunk: rawEnabled ? rawChunk : null,
    deNoisedChunk: denoisedEnabled ? deNoisedChunk : null,
    isLoading,
  };
}
