import { PropsWithChildren } from 'react';
import { ButtonType, disabledButtonClassNames, getButtonClassNames } from './ButtonUtilities';
import classNames from 'classnames';

interface Props {
  className?: string;
  type: ButtonType;
  title?: string;
  disabled?: boolean;
  onClick: () => void;
}

export default function IconButton({ onClick, type, className, ...standardProps }: PropsWithChildren<Props>) {
  return (
    <button
      {...standardProps}
      onClick={onClick}
      className={classNames(
        getButtonClassNames(type),
        className,
        'flex justify-center flex-col',
        standardProps.disabled && disabledButtonClassNames,
      )}
    />
  );
}
