import { PlusIcon as PlusIconOutline } from '@heroicons/react/24/outline';

interface Props {
  onClick: () => void;
}

export default function CircularButton({ onClick }: Props) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
    >
      <PlusIconOutline className="h-6 w-6" aria-hidden="true" />
    </button>
  );
}
