import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientTargetType } from '../../contracts/Patient';

export function usePatientTargets(patientId: number) {
  const request = useRequest<Array<PatientTargetType>>();
  return useQuery<unknown, unknown, Array<PatientTargetType>>(
    ['patient-targets', patientId],
    () => request(`/patients/${patientId}/targets`),
    {
      staleTime: 30 * 1000,
    },
  );
}
