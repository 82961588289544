import * as React from 'react';
import { PropsWithChildren } from 'react';

interface IDialogFormLayout {
  title: string;
}

export const DialogFormContainer = ({ children, title }: PropsWithChildren<IDialogFormLayout>) => (
  <div className="block mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left min-w-96">
    <h3 className="block text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mr-4 truncate ...">
      <div>{title}</div>
    </h3>
    <div className="mt-6">{children}</div>
  </div>
);
