import * as React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { CheckIcon, ClockIcon } from '@heroicons/react/24/solid';

import { ThreadStatus } from '../../../../../contracts/Threads';
import { useThreadReply } from '../../../../../hooks/mutations/thread/useThreadReply';
import { useThreadStatus } from '../../../../../hooks/mutations/thread/useThreadStatus';
import DropDownButton from '../../../../../components/buttons/DropDownButton';
import Dialog from '../../../../../components/dialog/Dialog';
import { successToast } from '../../../../../utils/successToast';

import { ThreadMessageForm } from './ThreadMessageForm';
import { Descendant } from 'slate';
import { DoctorType } from '../../../../../contracts/Doctors';
import { useTranslation } from 'react-i18next';

interface IThreadActions {
  doctors: DoctorType[];
  threadId: number;
  patientId: number;
  assignedDoctorId: number;
  isThreadClosed: boolean;
  isThreadOnHold: boolean;
}

export const ThreadActions: React.FC<IThreadActions> = props => {
  const { t } = useTranslation('ThreadActions');
  const [open, setOpen] = React.useState<boolean>(false);
  const threadReplyMutation = useThreadReply();
  const threadStatusMutation = useThreadStatus();

  function handleReply({ text, assigneeId }: { text: Descendant[]; assigneeId?: number }) {
    threadReplyMutation.mutate(
      {
        threadId: props.threadId,
        patientId: props.patientId,
        text: JSON.stringify(text),
        assigneeId: assigneeId!,
      },
      {
        onSuccess: () => {
          successToast(t('replySuccessMessage'));
          setOpen(false);
        },
      },
    );
  }

  function handleClose(threadId: number) {
    threadStatusMutation.mutate(
      {
        threadId,
        patientId: props.patientId,
        status: ThreadStatus.Closed,
      },
      {
        onSuccess: () => {
          successToast(t('closeSuccessMessage'));
        },
      },
    );
  }

  function handleOnHold(threadId: number) {
    threadStatusMutation.mutate(
      {
        threadId,
        patientId: props.patientId,
        status: ThreadStatus.OnHold,
      },
      {
        onSuccess: () => {
          successToast(t('holdSuccessMessage'));
        },
      },
    );
  }

  const iconClassNames = 'h-10 w-16 px-3 py-2 cursor-pointer transition-colors border-gray-300';

  return (
    <div>
      {props.isThreadClosed ? (
        <DropDownButton
          type="secondary"
          label={
            <>
              <CheckIcon className={'h-5 w-5 mr-2 text-emerald-500'} aria-hidden="true" /> {t('answered')}
            </>
          }
          items={[{ onClick: () => setOpen(true), name: t('answer') }]}
        />
      ) : (
        <div className="inline-flex rounded-md shadow border border-gray-300">
          <button
            className={classNames(
              'px-2 rounded-l-md w-32 inline-flex items-center justify-center',
              props.isThreadClosed
                ? 'text-gray-400 cursor-not-allowed text-sm border-r'
                : 'text-pink-100 hover:text-white bg-primary',
            )}
            onClick={() => setOpen(true)}
          >
            {props.isThreadClosed && <CheckIcon className="text-emerald-500 w-6 h-6" />}
            {props.isThreadClosed ? t('answered') : t('answer')}
          </button>

          <ClockIcon
            className={classNames(
              iconClassNames,
              'border-r ',
              props.isThreadOnHold ? 'text-primary cursor-not-allowed' : 'text-gray-500 hover:text-gray-700',
            )}
            data-tip={t('hold')}
            onClick={props.isThreadOnHold ? () => {} : () => handleOnHold(props.threadId)}
          />
          <ReactTooltip />

          <CheckIcon
            className={classNames(iconClassNames, 'text-gray-500 hover:text-gray-700')}
            data-tip={t('close')}
            onClick={() => handleClose(props.threadId)}
          />
          <ReactTooltip />
        </div>
      )}
      <Dialog open={open} handleCloseClick={() => setOpen(false)} className="w-3/4">
        {!open ? null : (
          <ThreadMessageForm
            doctors={props.doctors}
            handleSave={handleReply}
            handleCancel={() => setOpen(false)}
            assigneeId={props.assignedDoctorId}
            labels={{
              title: t('threadMessageTitle'),
              textarea: t('threadMessageTextArea'),
              button: t('threadMessageButton'),
            }}
          />
        )}
      </Dialog>
    </div>
  );
};
