import * as React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { AnalysisParameterFormType, AnalysisParameterType } from '../../../../contracts/Analysis';

import { TextInput } from '../../../../components/form/TextInput';
import { SelectInput } from '../../../../components/form/SelectInput';
import H3 from '../../../../components/typography/H3';
import DialogForm from '../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';
import { getValidationMessage, TranslateValidationMessageFn } from '../../../../utils/validation';

interface IAnalysisParametersForm {
  handleCancel: () => void;
  handleSave: (values: AnalysisParameterFormType) => void;
  analysisParameter?: AnalysisParameterType | number;
  groups: Array<{ id: number; name: string }>;
}

function yupTestMin(_: number | undefined, context: Yup.TestContext, minMax: [less: string, more: string]) {
  if (typeof context.parent[minMax[0]] === 'undefined' || typeof context.parent[minMax[1]] === 'undefined') {
    return true;
  }
  return context.parent[minMax[0]] < context.parent[minMax[1]];
}

const getNumberOrNull = (value: number | string | null | undefined): number | null =>
  typeof value === 'number' ? value : null;

const getMinMaxValidator = (field: string, minField: string, maxField: string, error: TranslateValidationMessageFn) =>
  Yup.number()
    .nullable(true)
    .test(field, error, (value, context) => {
      if (value === null || value === undefined) {
        return true;
      }
      return yupTestMin(value, context, [minField, maxField]);
    });

export const AnalysisParametersForm = ({
  analysisParameter,
  handleCancel,
  handleSave,
  groups,
}: IAnalysisParametersForm) => {
  const { t } = useTranslation('AnalysisParametersForm');
  const defaultValues = typeof analysisParameter === 'number' ? { groupId: analysisParameter } : analysisParameter;
  const AnalysisParametersFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    femaleMin: getMinMaxValidator(
      'femaleMin',
      'femaleMin',
      'femaleMax',
      getValidationMessage('maxError', 'AnalysisParametersForm'),
    ),
    femaleMax: getMinMaxValidator(
      'femaleMax',
      'femaleMin',
      'femaleMax',
      getValidationMessage('minError', 'AnalysisParametersForm'),
    ),
    maleMin: getMinMaxValidator(
      'maleMin',
      'maleMin',
      'maleMax',
      getValidationMessage('maxError', 'AnalysisParametersForm'),
    ),
    maleMax: getMinMaxValidator(
      'maleMax',
      'maleMin',
      'maleMax',
      getValidationMessage('minError', 'AnalysisParametersForm'),
    ),
    groupId: Yup.number().min(1, getValidationMessage('groupIdError', 'AnalysisParametersForm')),
    unit: Yup.string().required(),
  });
  const initialValues: AnalysisParameterFormType = {
    name: '',
    femaleMin: '',
    femaleMax: '',
    maleMin: '',
    maleMax: '',
    unit: '',
    description: '',
    groupId: 0,
    ...defaultValues,
  };

  // After parsing, yup's "number().nullable(true)" returns empty string in case of emty field.
  // We need to remap back to `number | null`, or the BE will reject the value.
  const onSave = (values: AnalysisParameterFormType) =>
    handleSave({
      ...values,
      femaleMin: getNumberOrNull(values.femaleMin),
      femaleMax: getNumberOrNull(values.femaleMax),
      maleMin: getNumberOrNull(values.maleMin),
      maleMax: getNumberOrNull(values.maleMax),
    });

  return (
    <DialogForm
      title={t('title')}
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={AnalysisParametersFormSchema}
      onCancel={handleCancel}
    >
      <div className="flex flex-col space-y-5 sm:items-start w-full">
        <div className="flex space-x-10 w-full">
          <Field component={TextInput} name={'name'} label={t('nameLabel')} />
          <Field component={SelectInput} name={'groupId'} label={t('groupIdLabel')}>
            <option value={0} key={0}>
              {t('groupIdPlaceholder')}
            </option>
            {groups.map(group => (
              <option value={group.id} key={group.id}>
                {group.name}
              </option>
            ))}
          </Field>
        </div>
        <div className="flex space-x-10 w-full">
          <Field component={TextInput} name={'description'} label={t('descriptionLabel')} />
          <Field component={TextInput} name={'unit'} label={t('unitLabel')} />
        </div>
        <div className="flex space-x-10 w-full">
          <div className="flex w-1/2 space-y-2 flex-col">
            <H3>{t('femaleNormHeader')}</H3>
            <div className="flex space-x-5">
              <Field component={TextInput} type={'number'} name={'femaleMin'} label={t('minLabel')} />
              <Field component={TextInput} type={'number'} name={'femaleMax'} label={t('maxLabel')} />
            </div>
          </div>
          <div className="flex w-1/2 space-y-2 flex-col">
            <H3>{t('maleNormHeader')}</H3>
            <div className="flex space-x-5">
              <Field component={TextInput} type={'number'} name={'maleMin'} label={t('minLabel')} />
              <Field component={TextInput} type={'number'} name={'maleMax'} label={t('maxLabel')} />
            </div>
          </div>
        </div>
      </div>
    </DialogForm>
  );
};
