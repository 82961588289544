import H2 from '../typography/H2';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props {
  title: string | JSX.Element;
  description?: string;
  className?: string;
}

export function TableTitle({ title, children, description, className }: PropsWithChildren<Props>) {
  return (
    <div className={classNames(className ?? 'mb-16')}>
      <H2 className={description ? 'sm:pb-0' : ''}>{title}</H2>
      {description ? <p className="mt-0.5 max-w-2xl text-sm text-gray-500 pb-6">{description}</p> : null}
      {children}
    </div>
  );
}
