import { TableTitle } from '../../../../../components/common/TableTitle';
import * as React from 'react';
import { AnamnesisDialog } from './AnamnesisDialog';
import { usePatientAnamnesis } from '../../../../../hooks/queries/usePatientAnamnesis';
import { useParams } from 'react-router-dom';
import { DocumentTypeEnum } from '../../../../../contracts/Document';
import { PatientDocuments } from '../documents/PatientDocuments';
import SerializeToHtml from '../../../../../components/richText/SerializeToHtml';
import { useTranslation } from 'react-i18next';

interface IPatientAnamnesisTab {}

export const PatientAnamnesisTab: React.FunctionComponent<IPatientAnamnesisTab> = () => {
  const { t } = useTranslation('PatientAnamnesisTab');
  const { id: patientId } = useParams<{ id: string }>();
  const { data } = usePatientAnamnesis(Number(patientId));

  if (!data) {
    return null;
  }
  return (
    <>
      <TableTitle title={t('anamnesisTableTitle')}>
        <div className="flex flex-col">
          <SerializeToHtml content={data.text} />
          <div className="text-left">
            <AnamnesisDialog initialValue={JSON.parse(data.text)} />
          </div>
        </div>
      </TableTitle>
      <TableTitle title={t('documentsTableTitle')}>
        <PatientDocuments documentType={DocumentTypeEnum.Anamnesis} />
      </TableTitle>
    </>
  );
};
