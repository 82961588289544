import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { EcgData, EcgDataLayer, EcgRecordChunkResponse } from '../../contracts/Ecg';
import { useMemo } from 'react';

// Sampling happens 128 times a second, or once every 10^6 / 128 microseconds.
// Using microseconds here to get integer division result
const samplingInterval = 10 ** 6 / 128;

export const useEcgRecordChunk = (
  patientId: number,
  ecgRecordId: number,
  chunkNumber: number,
  mode: EcgDataLayer = 'aligned',
  enabled: boolean,
) => {
  const request = useRequest<EcgRecordChunkResponse[]>();

  const result = useQuery<EcgRecordChunkResponse[]>(
    ['patient-ecg-record-chunk', patientId + chunkNumber + mode],
    () => request(`/patients/${patientId}/ecgs/${ecgRecordId}/chunks/${chunkNumber}?format=${mode}`),
    { enabled, cacheTime: 0, keepPreviousData: false },
  );

  const ecgResponseData = result?.data;

  const ecgData = useMemo(() => {
    if (!ecgResponseData) {
      return null;
    }

    const keyValueArray = ecgResponseData
      .map(r => ({
        // Keys are timestamps in miliseconds. Hence multiplication to bump into microseconds
        key: Number(Object.keys(r)[0]) * 10 ** 3,
        value: Object.values(r)[0],
      }))
      .map<{ t: number; mv: number }>(({ key, value }) =>
        value.map((v: number, idx: number) => ({
          t: key + idx * samplingInterval,
          mv: v,
        })),
      )
      .flat();

    const startTimeInUs = keyValueArray[0].t;

    const ecgData: EcgData = {
      tStartInUs: startTimeInUs,
      tStartInMs: startTimeInUs / 1000,
      dataPoints: keyValueArray.map((v, idx) => ({ mv: v.mv, dt: idx })), //: v.t - startTimeInUs })),
    };

    return ecgData;
  }, [ecgResponseData]);

  return {
    ...result,
    data: ecgData,
  };
};
