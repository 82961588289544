import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AnalysisParameterType } from '../../contracts/Analysis';

export function useAnalysesParameters() {
  const request = useRequest<Array<AnalysisParameterType>>();
  return useQuery<unknown, unknown, Array<AnalysisParameterType>>(
    ['analyses-filter'],
    () => request('/analyses/parameters/filter'),
    {
      staleTime: 10 * 60 * 1000,
    },
  );
}
