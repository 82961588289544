import React from 'react';
import './utils/validation';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { errorToast } from './utils/errorToast';
import { createRoot } from 'react-dom/client';
import './translations/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: error => {
        if (error instanceof Error) {
          errorToast(error.message);
        }
      },
    },
    mutations: {
      onError: error => {
        if (error instanceof Error) {
          errorToast(error.message);
        }
      },
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster position={'top-right'} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
