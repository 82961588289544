import { Fragment, PropsWithChildren } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface Props {
  type?: 'primary' | 'secondary';
  label: string | JSX.Element;
  labelAsDropdownTrigger?: boolean;
  onClick?: () => void;
  items: Array<{
    name: string;
    onClick: () => void;
    disabled?: boolean;
  }>;
  className?: string;
}

export default function DropDownButton(props: PropsWithChildren<Props>) {
  let cx = 'bg-primary text-white hover:bg-pink-700';
  if (props.type === 'secondary') {
    cx =
      'bg-white text-gray-700 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 border-gray-300 dark:bg-gray-600 dark:border-gray-500';
  }
  return (
    <span className={classNames('relative z-0 inline-flex shadow-sm rounded-md', props.className)}>
      {!props.labelAsDropdownTrigger && (
        <button
          type="button"
          className={classNames(
            'w-full relative inline-flex justify-center rounded-l-md border shadow-sm px-4 py-2 text-sm font-medium focus:outline-none items-center',
            cx,
            typeof props.onClick === 'undefined' && 'cursor-default',
          )}
          onClick={props.onClick}
        >
          {props.label}
        </button>
      )}
      <Menu as="span" className="-ml-px relative block">
        <Menu.Button
          className={classNames(
            'relative inline-flex items-center px-2 py-2 border',
            props.type === 'primary' && 'border-l-white',
            'text-sm font-medium focus:z-50 focus:outline-none',
            cx,
            props.labelAsDropdownTrigger ? 'rounded-md' : 'rounded-r-md',
          )}
        >
          {props.labelAsDropdownTrigger && props.label}

          <ChevronDownIcon
            className={classNames(
              'h-5 w-5',
              props.type === 'primary' && 'text-white',
              props.labelAsDropdownTrigger && 'ml-2',
            )}
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-40 rounded-md shadow-lg bg-white dark:bg-gray-700 dark:border-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {props.items.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        'block px-4 py-2 text-sm',
                        active &&
                          !item.disabled &&
                          'cursor-pointer bg-gray-100 dark:bg-gray-600 dark:text-gray-300 text-gray-900',
                        item.disabled && 'text-gray-300 dark:text-gray-500 cursor-default',
                        !item.disabled && !active && 'text-gray-500 dark:text-gray-300',
                      )}
                      onClick={item.disabled ? () => {} : item.onClick}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
              {props.children}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  );
}
