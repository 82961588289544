import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';
import { NullableType } from '../contracts/Common';

export function getAnnotations(
  min: NullableType<number>,
  max: NullableType<number>,
): Record<string, AnnotationOptions> {
  const lines: Record<string, AnnotationOptions> = {};
  if (min !== null && max !== null) {
    lines['box1'] = {
      drawTime: 'beforeDraw',
      type: 'box',
      backgroundColor: 'rgba(156, 221, 151, 0.26)',
      borderColor: 'rgba(156, 221, 151, 0.86)',
      borderWidth: 1,
      adjustScaleRange: true,
      yMin: min ?? undefined,
      yMax: max ?? undefined,
    };
  } else {
    lines['line1'] = {
      drawTime: 'beforeDraw',
      type: 'line',
      borderColor: 'rgba(156, 221, 151, 0.86)',
      borderWidth: 2,
      yMin: min ?? max ?? undefined,
      yMax: min ?? max ?? undefined,
    };
  }
  return lines;
}
