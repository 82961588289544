import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { AnalysisParameterFormType } from '../../../contracts/Analysis';

export function useAnalysesUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<AnalysisParameterFormType>();
  return useMutation<unknown, unknown, AnalysisParameterFormType>(
    data =>
      request(`/analyses/parameters/${data.id}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['analyses']);
        queryClient.invalidateQueries(['patient-analyses']);
      },
    },
  );
}
