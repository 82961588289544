import ButtonGroup from '../../../../../components/buttons/ButtonGroup';
import * as React from 'react';
import { DaysFilterType, MetricDataPointRequestType } from '../../../../../contracts/Metrics';
import { useMetricsHistory } from '../../../../../hooks/queries/useMetricsHistory';
import { MetricsChart } from './charts/MetricsChart';
import { useMetrics } from '../../../../../hooks/mutations/useMetrics';
import H2 from '../../../../../components/typography/H2';
import { useMetricsSleep } from '../../../../../hooks/queries/useMetricsSleep';
import { MetricsSleepCell } from './charts/MetricsSleepCell';
import { useMemo } from 'react';
import addDays from 'date-fns/addDays';
import { useTranslation } from 'react-i18next';

interface IMetricCharts {
  patientId: number;
  date: Date;
}

const DAYS_FILTER: Array<DaysFilterType> = [7, 30, 90, 365];

export const MetricsHistory = ({ patientId, date }: IMetricCharts) => {
  const { t } = useTranslation('MetricsHistory');
  const [daysFilter, setDaysFilter] = React.useState<DaysFilterType>(DAYS_FILTER[1]);
  const { data } = useMetricsHistory(patientId, date, daysFilter);
  const { data: sleepData } = useMetricsSleep(patientId, date, daysFilter);
  const metricsMutation = useMetrics(patientId);
  const startDate = useMemo(() => addDays(date, -1 * daysFilter), [date, daysFilter]);

  function buttonName(days: DaysFilterType): string {
    switch (days) {
      case 7:
        return t('oneWeek');
      case 30:
        return t('oneMonth');
      case 90:
        return t('threeMonths');
      case 365:
        return t('twelveMonths');
    }
  }

  function addHistoryPoint(values: MetricDataPointRequestType) {
    return metricsMutation.mutateAsync(values);
  }

  return (
    <div className="pt-8">
      <H2>{t('header')}</H2>
      <ButtonGroup
        buttons={DAYS_FILTER.map(days => ({
          value: days,
          text: buttonName(days),
        }))}
        handleClick={(button: DaysFilterType) => setDaysFilter(button)}
        label={''}
        active={daysFilter}
      />

      {data?.map((metrics, index) => (
        <MetricsChart addHistoryPoint={addHistoryPoint} key={index} startDate={startDate} endDate={date} {...metrics} />
      ))}

      {sleepData && <MetricsSleepCell name={t('sleepCellName')} data={sleepData} />}
    </div>
  );
};
