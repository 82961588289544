import { PatientBaseType } from '../../contracts/Patient';
import React from 'react';
import { Link } from 'react-router-dom';
import { CircleClinic } from './CircleClinic';
import PersonLine from '../common/PersonLine';

export const PatientInline: React.FunctionComponent<PatientBaseType> = ({ id, firstName, lastName, clinic }) => {
  if (!id) {
    return null;
  }
  return (
    <Link to={`/patients/${id}`}>
      <div className="flex items-center group">
        {clinic && (
          <div className="shrink-0 mr-6">
            <CircleClinic text={clinic} />
          </div>
        )}
        <div>
          <PersonLine headline={clinic}>
            {firstName} {lastName}
          </PersonLine>
        </div>
      </div>
    </Link>
  );
};
