import PaginationButton from '../buttons/PaginationButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { PropsWithChildren } from 'react';

interface ILeftRightPicker {
  handleStepChange: (step: 1 | -1) => void;
  isPrevVisible?: boolean;
  isNextVisible?: boolean;
}

export default function LeftRightPicker({
  handleStepChange,
  children,
  isPrevVisible = true,
  isNextVisible,
}: PropsWithChildren<ILeftRightPicker>) {
  return (
    <div className="flex items-center justify-between w-48">
      <div className="flex-1 flex">
        {isPrevVisible === true ? (
          <PaginationButton handleClick={() => handleStepChange(-1)} withBorder={false}>
            <ChevronLeftIcon className="mr-3 h-5 w-5 text-gray-400 hover:text-gray-700" aria-hidden="true" />
          </PaginationButton>
        ) : null}
      </div>
      <div className="font-semibold">{children}</div>
      <div className="flex-1 flex justify-end">
        {isNextVisible === true ? (
          <PaginationButton handleClick={() => handleStepChange(1)} withBorder={false}>
            <ChevronRightIcon className="ml-3 h-5 w-5 text-gray-400 hover:text-gray-700" aria-hidden="true" />
          </PaginationButton>
        ) : null}
      </div>
    </div>
  );
}
