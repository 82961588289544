import React from 'react';
import { ChartDataset, ScatterDataPoint } from 'chart.js';

import { EcgData, EcgRecordPeakData } from '../../../../../../contracts/Ecg';
import { getResolutionAdjustedXValueForSpecialAxis } from '../utils/utils';
import { useTranslation } from 'react-i18next';

function mapChunkToChartDataset(
  label: string,
  rawData: EcgData | null,
  startMinute: number,
  startSecond: number,
  secondsPerPage: number,
): ChartDataset<'line', ScatterDataPoint[]> {
  if (!rawData?.dataPoints?.length) {
    return { label, data: [], hidden: true };
  }

  const startIndex = (startMinute * 60 + startSecond) * 128;
  const count = startIndex + 128 * secondsPerPage;

  return {
    label,
    data: rawData.dataPoints.slice(startIndex, count).map(({ dt, mv }, idx) => ({ x: idx, y: mv })),
  };
}

const mapPeakToChartDataset = (
  peaksData: EcgRecordPeakData[],
  startTime: number,
  secondsPerPage: number,
): ChartDataset => {
  if (!peaksData?.length) {
    return { data: [], hidden: true };
  }
  const firstIndex = peaksData.findIndex(peak => peak.tStartInMs >= startTime);
  const lastTimestamp = startTime + secondsPerPage * 1000;
  const lastIndex = peaksData.findIndex(value => value.tStartInMs >= lastTimestamp);

  const dataSets = peaksData
    .slice(firstIndex > 0 ? firstIndex - 1 : firstIndex, lastIndex >= peaksData.length ? lastIndex : lastIndex + 1)
    .map(value => ({
      x: getResolutionAdjustedXValueForSpecialAxis(secondsPerPage, startTime, value.tStartInMs),
      y: 0,
      ...value.dataPoints,
    }));

  return {
    xAxisID: 'x2',
    type: 'EcgPeaksChartType' as 'line',
    data: dataSets,
    label: 'Q',
    hidden: false,
    pointRadius: 10,
    borderWidth: 10,
  };
};

export function useEcgChunkSections(
  alignedChunk: EcgData | null,
  denoisedChunk: EcgData | null,
  rawChunk: EcgData | null,
  minute: number,
  second: number,
  secondsPerPage: number,
  peaksData: EcgRecordPeakData[],
  startTime: number,
) {
  const { t } = useTranslation('useEcgChunkSections');
  const [alignedSignal, deNoisedSignal, rawSignal, peaksSignal] = React.useMemo(
    () => [
      mapChunkToChartDataset(t('alignedChunk'), alignedChunk, minute, second, secondsPerPage),
      mapChunkToChartDataset(t('denoisedChunk'), denoisedChunk, minute, second, secondsPerPage),
      mapChunkToChartDataset(t('rawChunk'), rawChunk, minute, second, secondsPerPage),
      mapPeakToChartDataset(peaksData, startTime + (minute * 60 + second) * 1000, secondsPerPage),
    ],
    [alignedChunk, minute, second, secondsPerPage, denoisedChunk, rawChunk, peaksData, startTime, t],
  );

  return {
    alignedSignal,
    deNoisedSignal,
    rawSignal,
    peaksSignal,
  };
}
