import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { EcgRecord, EcgRecordResponse } from '../../contracts/Ecg';
import parseISO from 'date-fns/parseISO';
import { useMemo } from 'react';

export const useEcgRecord = (patientId: number, ecgRecordId: number) => {
  const request = useRequest<EcgRecordResponse>();
  const mapDtoToResult = (responseData?: EcgRecordResponse): EcgRecord | undefined => {
    const ecgChunks =
      responseData?.ecgChunks
        .map(chunk => ({
          ecgRecordId: chunk.ecgRecordId,
          number: chunk.number,
          start: parseISO(`${chunk.start}`),
          end: parseISO(`${chunk.end}`),
        }))
        .sort((a, b) => a.number - b.number) ?? [];
    return responseData
      ? {
          id: responseData.id,
          patientId: responseData.patientId,
          date: parseISO(responseData.date),
          startDate: ecgChunks.length ? ecgChunks[0].start : null,
          endDate: ecgChunks.length ? ecgChunks[ecgChunks.length - 1].end : null,
          ecgChunks,
          comments: responseData.comments.map(({ eventsGroup, text, timestamp }) => ({
            eventType: eventsGroup,
            text,
            timestamp,
          })),
          artifacts: responseData.artifacts.map(({ eventsGroup, timestamp }) => ({
            timestamp,
            eventType: eventsGroup,
          })),
        }
      : undefined;
  };

  const queryResult = useQuery<EcgRecordResponse>(
    ['patient-ecg-record', `${patientId}${ecgRecordId}`],
    () => request(`/patients/${patientId}/ecgs/${ecgRecordId}`),
    { staleTime: 60 * 1000 },
  );

  return useMemo(
    () => ({
      ...queryResult,
      data: mapDtoToResult(queryResult.data),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryResult?.data],
  );
};
