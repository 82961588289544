import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { WithingsService } from '../../utils/devices/WithingsService';

interface ResponseType {
  accountId: string;
  isAuthorized: boolean;
}

export const useWithings = (patientId: number) => {
  const request = useRequest<ResponseType>();
  const queryResult = useQuery<unknown, unknown, ResponseType>(
    ['patient-withings', patientId],
    () => request(`/patients/${patientId}/withings`),
    {
      staleTime: 60 * 1000,
    },
  );

  return {
    ...queryResult,
    data: {
      ...queryResult.data,
      authUrl: WithingsService.getAuthUrl(Number(patientId)),
    },
  };
};
