import * as React from 'react';
import { FieldProps } from 'formik';
import Downshift from 'downshift';
import Scrollbar from '../common/Scrollbar';
import { BaseInput } from './BaseInput';
import Tag from '../common/Tag';

interface IAutocompleteInput extends FieldProps {
  label: string;
  description?: string;
  // @fixme change to generic type
  items: Array<any>;
  onChange: (item: any) => void;
  onBlur?: (inputValue: string | null) => void;
  itemString: (item: unknown) => string;
}

export const AutocompleteInput: React.FunctionComponent<IAutocompleteInput> = ({
  field,
  form,
  items,
  itemString,
  ...props
}) => {
  if (field.value) {
    return (
      <BaseInput field={field} form={form} label={props.label} description={props.description}>
        <div className="border py-1.5 px-3 mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          <Tag size="larger" className="bg-indigo-100 text-indigo-700">
            <>
              {field.value}
              <button
                type="button"
                className="shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none"
                onClick={() => props.onChange(undefined)}
              >
                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </>
          </Tag>
        </div>
      </BaseInput>
    );
  }
  return (
    <Downshift onChange={props.onChange} itemToString={item => itemString(item) ?? ''}>
      {({ getInputProps, getLabelProps, getItemProps, getMenuProps, isOpen, inputValue, selectedItem }) => (
        <div className="relative w-full">
          <div className="col-span-6 sm:col-span-3 max-w-full w-full">
            <label className="block text-sm font-medium text-gray-700" {...getLabelProps()}>
              {props.label}
            </label>
            <input
              type="text"
              id={field.name}
              {...field}
              {...props}
              className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              {...getInputProps({
                onBlur: () => {
                  typeof props.onBlur === 'function' && props.onBlur(inputValue);
                },
              })}
            />
            {form.touched[field.name] && form.errors[field.name] && (
              <p className="mt-2 text-sm text-red-600 " id="email-error">
                {form.errors[field.name] as string}
              </p>
            )}
          </div>
          <div
            {...getMenuProps()}
            className={`z-50 absolute w-full border-gray-300 rounded-md bg-white mt-1 ${isOpen ? 'border' : ''}`}
          >
            {isOpen ? (
              <Scrollbar className="max-h-56 sm:p-0 sm:m-0 w-full">
                {(items || [])
                  .filter(item => !inputValue || itemString(item).toLowerCase().includes(inputValue.toLowerCase()))
                  .map((item, index) => (
                    <div
                      {...getItemProps({
                        key: item.id,
                        index,
                        item,
                      })}
                      className={`text-sm border-b border-gray-300 p-2 cursor-pointer last:border-0 ${
                        selectedItem === item ? 'text-bold' : ''
                      }`}
                    >
                      {itemString(item)}
                    </div>
                  ))}
              </Scrollbar>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
};
