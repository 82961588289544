import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';

export function usePatientReturnGadget(patientId: number) {
  const queryClient = useQueryClient();
  const request = useRequest<unknown>();
  return useMutation<unknown, unknown, number>(
    gadgetId =>
      request(`/gadgets/${gadgetId}/return`, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gadgets']);
        queryClient.invalidateQueries(['patient-gadget-history']);
        queryClient.invalidateQueries(['patients', patientId]);
        // queryClient.setQueryData(['devices'], (oldData: Array<DeviceType> = []) => [...oldData, data]);
      },
    },
  );
}
