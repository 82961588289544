import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientPoll } from '../../contracts/Patient';

export function usePatientPollCreate(patientId: number) {
  const queryClient = useQueryClient();
  const request = useRequest<PatientPoll>();
  return useMutation<PatientPoll, unknown, {}>(
    () =>
      request(`/patients/${patientId}/polls`, {
        method: 'POST',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['patient-polls', patientId], (oldData: Array<PatientPoll> = []) => {
          return [...oldData, data];
        });
      },
    },
  );
}
