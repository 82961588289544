import * as React from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlTick from '../common/HtmlTick';
import { useCallback } from 'react';
import { debounce } from '../../../utils/debounce';
import QuestionDescription from '../common/QuestionDescription';

export default function MultipleChoiceQuestion(props: Question) {
  const [selected, setSelected] = React.useState<Array<number>>([]);

  React.useEffect(() => {
    const selectedChoice = props.choices.filter(choice => choice.isSelected).map(choice => choice.id);
    if (selectedChoice) {
      setSelected([...selectedChoice]);
    }
  }, [props.choices]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeApi = useCallback(debounce(props.handleAnswerSubmit), []);

  function handleChange(questionId: number, value: number) {
    const choice = props.choices.find(choice => choice.id === value);
    if (!choice) {
      return;
    }
    const choiceIndex = selected.indexOf(value);
    let requestBody: Array<number>;
    if (choiceIndex !== -1) {
      requestBody = [...selected.slice(0, choiceIndex), ...selected.slice(choiceIndex + 1)];
    } else {
      if (choice.isExclusive) {
        requestBody = [value];
      } else if (selected.length === 1) {
        const maybeExclusive = props.choices.find(choice => choice.id === selected[0]);
        if (maybeExclusive && maybeExclusive.isExclusive) {
          requestBody = [value];
        } else {
          requestBody = [...selected, value];
        }
      } else {
        requestBody = [...selected, value];
      }
    }
    setSelected(requestBody);
    handleChangeApi({
      questionId,
      answer: { value: requestBody },
    });
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <QuestionDescription>{props.description}</QuestionDescription>
      <div className="mt-3 space-y-3">
        {props.choices.map(choice => {
          return (
            <HtmlTick
              id={`${choice.id}`}
              type={'checkbox'}
              value={choice.id}
              isSelected={selected.includes(choice.id)}
              handleChange={choiceId => handleChange(props.id, choiceId as number)}
              label={choice.text}
              key={choice.id}
            />
          );
        })}
      </div>
    </div>
  );
}
