import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientGadget } from '../../contracts/Patient';

export function usePatientGadgets(patientId: number) {
  const request = useRequest<Array<PatientGadget>>();
  return useQuery<unknown, unknown, Array<PatientGadget>>(
    ['patient-gadget-history', patientId],
    () => request(`/patients/${patientId}/gadgets`),
    {
      staleTime: 60 * 1000,
    },
  );
}
