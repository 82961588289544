import { BaseEditor, Editor, Element as SlateElement, Transforms, Text, Node } from 'slate';
import { ReactEditor, RenderLeafProps, useSlate } from 'slate-react';
import * as React from 'react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import EditorButton from '../buttons/EditorButton';
import { CustomElement } from '../../types/react-slate';
import { PropsWithChildren } from 'react';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const isBlockActive = (editor: BaseEditor & ReactEditor, format: string): boolean => {
  const { selection } = editor;
  if (!selection) return false;

  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const isMarkActive = (editor: BaseEditor & ReactEditor, format: string): boolean => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const MarkButton = ({ children, format }: PropsWithChildren<{ format: string }>) => {
  const editor = useSlate();
  return (
    <EditorButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={() => {
        toggleMark(editor, format);
      }}
    >
      {children}
    </EditorButton>
  );
};

export const BlockButton = ({ children, format }: PropsWithChildren<{ format: string }>) => {
  const editor = useSlate();
  return (
    <EditorButton isActive={isBlockActive(editor, format)} onMouseDown={() => toggleBlock(editor, format)}>
      {children}
    </EditorButton>
  );
};
export const ClearButton = ({ children }: PropsWithChildren) => {
  const editor = useSlate();

  function clearFormatting() {
    const newProperties: Partial<SlateElement> = {
      type: 'paragraph',
    };
    Transforms.setNodes(editor, newProperties);
  }

  return (
    <EditorButton isActive={false} onMouseDown={clearFormatting}>
      {children}
    </EditorButton>
  );
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export const RichTextElement = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul className="list-disc list-inside text-sm pb-4" {...attributes}>
          {children}
        </ul>
      );
    case 'h1':
      return (
        <h1 className={'text-xl font-medium py-2'} {...attributes}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={'text-base font-medium py-2'} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li className={'leading-relaxed'} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol className="list-decimal list-inside text-sm pb-4" {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p className="pb-4 text-sm leading-relaxed" {...attributes}>
          {children}
        </p>
      );
  }
};

export const serializeToHtml = (node: Node): string => {
  if (Text.isText(node)) {
    let string = node.text as string;
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = (node.children || []).map((n: any) => serializeToHtml(n)).join('');

  switch ((node as CustomElement).type) {
    case 'paragraph':
      return `<p class="pb-4 text-sm leading-relaxed">${children}</p>`;
    case 'list-item':
      return `<li class="leading-relaxed">${children}</li>`;
    case 'numbered-list':
      return `<ol class="list-decimal list-inside text-sm pb-4">${children}</ol>`;
    case 'bulleted-list':
      return `<ul class="list-disc list-inside text-sm pb-4">${children}</ul>`;
    case 'h1':
      return `<h1 class="text-xl font-medium py-2">${children}</h1>`;
    case 'h2':
      return `<h2 class="text-base font-medium py-2">${children}</h2>`;
    default:
      return children;
  }
};

const toggleBlock = (editor: BaseEditor & ReactEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: BaseEditor & ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const getFirstLineText = (content: Node[] | null): string => {
  if (content === null) {
    return '';
  }
  for (const contentElement of content) {
    if (Text.isText(content)) {
      return content.text as string;
    }

    if (!contentElement.children) {
      return '';
    }

    for (const contentElement2 of contentElement.children as Node[]) {
      if (Text.isText(contentElement2)) {
        return contentElement2.text as string;
      }
    }
  }

  return '';
};
