import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { DoctorType } from '../../../contracts/Doctors';

type DoctorsRemoveRequest = {
  doctorId: number;
};

export function useDoctorsDelete() {
  const queryClient = useQueryClient();
  const request = useRequest();
  return useMutation<unknown, unknown, DoctorsRemoveRequest>(
    ({ doctorId }) =>
      request(`/doctors/${doctorId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, request) => {
        queryClient.setQueryData(['doctors'], (oldData: Array<DoctorType> = []) => {
          return oldData.filter(item => item.id !== request.doctorId);
        });
      },
    },
  );
}
