import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type BreadcrumbItem = {
  name: string;
  href?: string;
  current: boolean;
};

interface Props {
  pages: Array<BreadcrumbItem>;
}

export default function Breadcrumbs(props: Props) {
  const { t } = useTranslation('Breadcrumbs');
  return (
    <nav className="print:hidden flex pt-6 pb-12" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{t('home')}</span>
            </Link>
          </div>
        </li>
        {props.pages.map(page => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              {page.href ? (
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              ) : (
                <span className="ml-4 text-sm font-medium text-gray-500">{page.name}</span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
