import * as React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { SuperSearchFilters } from './SuperSearchUtils';

interface Props {
  className?: string;
  filters: SuperSearchFilters[];
  onDeleteClick: (columnAccessor: string, id: string | number) => void;
}

export default function SuperSearchTags({ filters, onDeleteClick, className }: Props) {
  return (
    <div className={classNames(className)}>
      {filters
        .filter(({ value }) => value.length)
        .map(({ columnAccessor, title, value: ids, options }) =>
          ids.map(id => (
            <span
              className="px-2 py-0 mr-2 border inline-flex items-center text-xs leading-5 font-semibold rounded-full border-gray-200 bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-300 cursor-default hover:dark:bg-gray-900"
              title={title}
              key={id}
            >
              <XMarkIcon onClick={() => onDeleteClick(columnAccessor, id)} className="w-3 mr-2 cursor-pointer" />
              <span className="mr-1">{options.find(o => o.id === id)?.label ?? ''}</span>
            </span>
          )),
        )}
    </div>
  );
}
