import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PatientType } from '../../../../../../contracts/Patient';
import { EcgAccidents, EcgRecord } from '../../../../../../contracts/Ecg';
import { usePatientEcgDataLayers } from '../hooks/usePatientEcgDataLayers';
import { usePatientEcgAnalysesLayers } from '../hooks/usePatientEcgAnalysesLayers';
import LoadingContainer from '../../../../../../components/layouts/LoadingContainer';
import EcgChunkViewer from './EcgViewer/EcgChunkViewer';
import PatientEcgWrapper from './ecgFilter';
import format from 'date-fns/format';
import { addSeconds } from 'date-fns';
import H2 from '../../../../../../components/typography/H2';
import EcgCommentModal from './EcgCommentModal';
import { EcgAccidentType } from '../utils/types';
import { useTranslation } from 'react-i18next';

interface Props {
  patient: PatientType;
  record: EcgRecord;
  startTime: string | null;
  startTemporalResolution?: number;
  highlightValues?: number[];
  onCommentCreated: (comment: { timestamp: number; comment: string; eventType?: string & keyof EcgAccidents }) => void;
}

// We can assume that the chunks come from BE in ascending chronological order
const getFirstChunkStartTime = (record: EcgRecord) => format(record.ecgChunks[0].start, 'HH:mm:ss');
export default function PatientEcgComponent({
  patient,
  record,
  startTime,
  startTemporalResolution,
  highlightValues,
  onCommentCreated,
}: Props) {
  const { t } = useTranslation('PatientEcgComponent');
  const [time, setTime] = useState(startTime ?? getFirstChunkStartTime(record));
  const [temporalResolution, setTemporalResolution] = useState(startTemporalResolution ?? 10);
  const [isCommentOpen, setIsCommentOpen] = useState<number | undefined>();
  const [isCommentMode, setIsCommentMode] = useState<boolean>(false);
  const [hours, minutes, seconds] = useMemo(() => time?.split(':').map(t => Number(t) || 0) ?? [], [time]);
  const isTimestampValid = [hours, minutes, seconds].every(t => !isNaN(t));
  const [yZoom, setYZoom] = useState<number>(1);

  const chunkNumber = useMemo(
    () => record.ecgChunks.find(c => c.start.getHours() === hours)?.number ?? 1,
    [hours, record],
  );

  useEffect(() => {
    if (startTime) {
      setTime(startTime);
    }
  }, [startTime]);

  const { alignedChunk, rawChunk, deNoisedChunk, setActiveDataLayers, activeDataLayers, isLoading } =
    usePatientEcgDataLayers(patient.id, record.id, chunkNumber);

  const { peaksData, analysesOverlayModes, setAnalysesOverlayModes } = usePatientEcgAnalysesLayers(
    patient.id,
    record.id,
  );

  const handleIncreaseTime = (newSeconds: number) => {
    const startDate = new Date(2000, 0, 0, hours, minutes, seconds, 0);
    const endDate = addSeconds(startDate, newSeconds);
    const formatted = format(endDate, 'HH:mm:ss');
    setTime(formatted);
  };

  const handleCommentSubmit = ({ comment, eventType }: { comment: string; eventType?: EcgAccidentType }) => {
    const timestamp = isCommentOpen!;
    setIsCommentOpen(undefined);
    setIsCommentMode(false);
    onCommentCreated({ comment, timestamp, eventType });
  };

  const handleCommentCancel = () => {
    setIsCommentOpen(undefined);
    setIsCommentMode(false);
  };

  const handleYZoom = useCallback((modifier: number) => {
    setYZoom(value => {
      const newZoomValue = value + modifier;
      if (newZoomValue >= 0.1 && newZoomValue <= 3) {
        return newZoomValue;
      }

      return value;
    });
  }, []);

  return (
    <div className={classNames('shadow border rounded-md  mt-4')}>
      <PatientEcgWrapper
        time={time}
        onTimeChanged={setTime}
        onTimeIncreased={handleIncreaseTime}
        activeDataLayers={activeDataLayers}
        commentModeActive={isCommentMode}
        onActiveDataLayersChanged={setActiveDataLayers}
        resolution={temporalResolution}
        analysesOverlayModes={analysesOverlayModes}
        onAnalysesOverlayModesChanged={setAnalysesOverlayModes}
        onResolutionChanged={setTemporalResolution}
        onCommentModeClicked={() => setIsCommentMode(!isCommentMode)}
      >
        {!!isCommentOpen && <EcgCommentModal onCancel={handleCommentCancel} onSubmit={handleCommentSubmit} />}
        <LoadingContainer loading={isLoading}>
          {!isTimestampValid && (
            <div className={'w-full text-center p-16 pb-8'}>
              <H2>{t('badTimestamp')}</H2>
            </div>
          )}

          {chunkNumber && isTimestampValid && (
            <EcgChunkViewer
              startTime={alignedChunk?.tStartInMs ?? deNoisedChunk?.tStartInMs ?? rawChunk?.tStartInMs ?? 0}
              alignedChunk={alignedChunk}
              rawChunk={rawChunk}
              denoisedChunk={deNoisedChunk}
              peaksData={peaksData}
              minute={minutes || 0}
              second={seconds}
              secondsPerPage={temporalResolution}
              analysesOverlayModes={analysesOverlayModes}
              highlightValues={highlightValues}
              yZoom={yZoom}
              onYZoomChanged={handleYZoom}
              isCommentMode={isCommentMode}
              onChartClicked={t => setIsCommentOpen(t)}
            />
          )}
        </LoadingContainer>
      </PatientEcgWrapper>
    </div>
  );
}
