import { EcgHourlyDatum } from '../../../../../../../contracts/Ecg';
import { useTranslation } from 'react-i18next';

interface Props {
  data: EcgHourlyDatum[];
}

export default function SummaryTable({ data }: Props) {
  const { t } = useTranslation('SummaryTable');
  return (
    <table className={'w-full text-sm'}>
      <thead className={'border-b text-black'}>
        <tr>
          <th colSpan={2} className={'w-1/3 py-2 pl-4 border-r text-left font-medium'}>
            {t('pulse')}
          </th>
          <th colSpan={2} className={'w-1/3 py-2 pl-4 border-r text-left font-medium'}>
            {t('ventricularExtrasystoles')}
          </th>
          <th colSpan={2} className={'w-1/3 py-2 pl-4 text-left font-medium'}>
            {t('supraventricularExtrasystoles')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={'py-2 px-4'}>{t('beats')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + curr.heartRate.totalBeats, 0)}
          </td>
          <td className={'py-2 px-4'}>{t('total')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + curr.ventricular.beats, 0)}
          </td>
          <td className={'py-2 px-4'}>{t('total')}</td>
          <td className={'py-2 px-4 text-right'}>{data.reduce((acc, curr) => acc + curr.supraventricular.beats, 0)}</td>
        </tr>

        <tr>
          <td className={'py-2 px-4'}>{t('mean')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {Math.floor(data.reduce((acc, curr) => acc + curr.heartRate.avg, 0) / data.length)}
          </td>
          <td className={'py-2 px-4'}>{t('pairs')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + curr.ventricular.couplets, 0)}
          </td>
          <td className={'py-2 px-4'}>{t('pairs')}</td>
          <td className={'py-2 px-4 text-right'}>
            {data.reduce((acc, curr) => acc + curr.supraventricular.couplets, 0)}
          </td>
        </tr>

        <tr>
          <td className={'py-2 px-4'}>{t('min')}</td>
          <td className={'py-2 px-4 text-right border-r'}>{Math.min(...data.map(({ heartRate }) => heartRate.min))}</td>
          <td className={'py-2 px-4'}>{t('bigeminies')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + (curr.ventricular.bigeminies ?? 0), 0)}
          </td>
          <td className={'py-2 px-4'}>{t('bigeminies')}</td>
          <td className={'py-2 px-4 text-right'}>
            {data.reduce((acc, curr) => acc + (curr.supraventricular.bigeminies ?? 0), 0)}
          </td>
        </tr>

        <tr className={'h-4'}>
          <td className={'py-2 px-4'}>{t('max')}</td>
          <td className={'py-2 px-4 text-right border-r'}>{Math.max(...data.map(({ heartRate }) => heartRate.max))}</td>
          <td className={'py-2 px-4'}>{t('trigeminies')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + (curr.ventricular.trigeminies ?? 0), 0)}
          </td>
          <td className={'py-2 px-4'}>{t('trigeminies')}</td>
          <td className={'py-2 px-4 text-right'}>
            {data.reduce((acc, curr) => acc + (curr.supraventricular.trigeminies ?? 0), 0)}
          </td>
        </tr>

        <tr className={'h-4'}>
          <td className={'py-2 px-4'}>{t('pauses')}</td>
          <td className={'py-2 px-4 text-right border-r'}>
            {data.reduce((acc, curr) => acc + curr.heartRate.pauses, 0)}
          </td>
          <td className={'py-2 px-4 border-r'} colSpan={2}></td>
          <td className={'py-2 px-4'} colSpan={2}></td>
        </tr>
      </tbody>
    </table>
  );
}
