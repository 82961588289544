import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormikSwitch } from '../form/FormikSwitch';
import Scrollbar from '../common/Scrollbar';
import { PollScalesRequest, Scale } from '../../contracts/Poll';
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  handleCancel: () => void;
  handleSave: (value: PollScalesRequest) => void;
  scales: Array<Scale>;
}

export default function ScaleDialogForm({ handleSave, handleCancel, scales }: Props) {
  const { t } = useTranslation('ScaleDialogForm');
  const initialValues = scales.map(scale => {
    return { id: scale.id, value: scale.isIncluded };
  });

  function handleSubmit({ scales }: { scales: Array<{ id: number; value: boolean }> }) {
    const includedScales = scales.filter(val => val.value).map(val => val.id);
    handleSave({ scalesIds: includedScales });
  }

  return (
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{t('title')}</h3>
      <Formik initialValues={{ scales: initialValues }} onSubmit={handleSubmit}>
        <Form>
          <div className="mt-6">
            <Scrollbar className="h-72">
              {scales.map((scale, index) => {
                return (
                  <div className="mb-6" key={index}>
                    <div className="flex">
                      <Field name={`scales[${index}]`} component={FormikSwitch} />
                      <div className="ml-4 w-96">
                        <h4 className="text-sm font-medium leading-5 text-black">{scale.name}</h4>
                        <span className="text-sm leading-5 text-gray-500">{scale.description}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Scrollbar>
            <div className="mt-6 sm:flex sm:flex-row-reverse">
              <Button buttonType="submit">{t('save', { ns: 'common' })}</Button>
              <Button type="secondary" onClick={handleCancel}>
                {t('cancel', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
