import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ActiveTab, PollResponse } from '../../contracts/Poll';

export function usePoll(
  patientId: number,
  pollId: number,
  activeTab: ActiveTab,
  activeGroupId: number | 'first' | undefined = 'first',
) {
  const request = useRequest<PollResponse>();
  return useQuery<unknown, unknown, PollResponse>(
    ['polls', pollId, activeTab, activeGroupId],
    () => request(`/patients/${patientId}/polls/${pollId}/${activeTab}/${activeGroupId}`),
    {
      keepPreviousData: true,
    },
  );
}
