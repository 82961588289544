import * as React from 'react';
import { FieldProps } from 'formik';
import { BaseInput } from './BaseInput';

interface ITextArea {
  label: string;
  description?: string;
  rows?: number;
}

export const TextArea: React.FunctionComponent<FieldProps & ITextArea> = ({ field, form, rows = 3, ...props }) => {
  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <textarea
        id={field.name}
        rows={rows}
        {...field}
        {...props}
        className="mt-1 shadow-sm focus:ring-blue-poll focus:border-blue-poll block w-full sm:text-sm border border-gray-300 rounded-md"
      />
    </BaseInput>
  );
};
