import { NullableType } from '../../../../contracts/Common';

interface Props {
  min: NullableType<number>;
  max: NullableType<number>;
}

export const MinMaxCell = ({ min, max }: Props) => {
  if (!min && !max) {
    return null;
  }
  if (!min) {
    return <>{`< ${max}`}</>;
  }
  if (!max) {
    return <>{`> ${min}`}</>;
  }
  return <>{`${min} ... ${max}`}</>;
};
