import React from 'react';
import { PrimitiveType } from '../../../contracts/Common';

interface Props {
  id: number | string;
  value: string | number;
  isSelected: boolean;
  handleChange: (value: PrimitiveType) => void;
  label?: string;
  type: 'radio' | 'checkbox';
}

export default function HtmlTick({ id, value, isSelected, handleChange, type = 'radio', label }: Props) {
  return (
    <div className={`flex items-center ${!label ? 'justify-center' : ''}`}>
      <input
        id={`input_${id}_${value}`}
        name={`input_${id}`}
        type={type}
        value={value}
        checked={isSelected}
        className="focus:ring-blue-poll h-4 w-4 text-blue-poll border-gray-300 cursor-pointer"
        onChange={() => handleChange(value)}
      />
      {label ? (
        <label htmlFor={`input_${id}_${value}`} className="ml-3 block text-sm font-normal text-gray-700 cursor-pointer">
          {label}
        </label>
      ) : null}
    </div>
  );
}
