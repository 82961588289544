import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PatientList } from './pages/patients/PatientList';
import { GadgetList } from './pages/gadgets/GadgetList';
import { PatientDetails } from './pages/patients/PatientDetails';
import PatientPoll from './pages/patients/components/tabs/poll/PatientPoll';
import { AlertList } from './pages/alerts/AlertList';
import { DoctorsList } from './pages/doctors/DoctorsList';
import { Faq } from './pages/faq/Faq';
import { NotFound } from './pages/NotFound';
import { AnalysesList } from './pages/settings/analyses/AnalysesList';
import FitBitOAuthPage from './pages/oauth/FitBitOAuthPage';
import WithingsOAuthPage from './pages/oauth/WithingsOAuthPage';
import Notifications from './pages/notifications/Notifications';
import PatientNotAvailable from './pages/errors/PatientNotAvailable';
import ProtectedRoute from './auth/ProtectedRoute';
import PatientEcgLayout from './pages/patients/components/tabs/ecg/components/PatientEcgLayout';
import EcgOverviewPage from './pages/patients/components/tabs/ecg/pages/EcgOverviewPage';
import EcgViewerPage from './pages/patients/components/tabs/ecg/pages/EcgViewerPage';
import EcgIntervalsPage from './pages/patients/components/tabs/ecg/pages/EcgIntervalsPage';
import EcgAccidentsPage from './pages/patients/components/tabs/ecg/pages/EcgAccidentsPage';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute component={PatientList} />} />
      <Route path="/patients/:patientId/poll/:pollId" element={<ProtectedRoute component={PatientPoll} />} />
      <Route path="/patients/:patientId/ecg/:ecgRecordId" element={<Navigate to={'dashboard'} />} />
      <Route
        path="/patients/:patientId/ecg/:ecgRecordId/dashboard"
        element={<ProtectedRoute component={EcgOverviewPage} />}
      />
      <Route
        path="/patients/:patientId/ecg/:ecgRecordId/viewer"
        element={<ProtectedRoute component={EcgViewerPage} />}
      />
      <Route
        path="/patients/:patientId/ecg/:ecgRecordId/viewer/:timestamp"
        element={<ProtectedRoute component={EcgViewerPage} />}
      />
      <Route
        path="/patients/:patientId/ecg/:ecgRecordId/intervals"
        element={<ProtectedRoute component={EcgIntervalsPage} />}
      />
      <Route
        path="/patients/:patientId/ecg/:ecgRecordId/accidents"
        element={<ProtectedRoute component={EcgAccidentsPage} />}
      />
      <Route path="/patients/:patientId/ecg" element={<ProtectedRoute component={PatientEcgLayout} />} />
      <Route path="/patients/:id" element={<ProtectedRoute component={PatientDetails} />} />
      <Route path="/patients" element={<ProtectedRoute component={PatientList} />} />
      <Route path="/gadgets" element={<ProtectedRoute component={GadgetList} />} />
      <Route path="/fitbit" element={<ProtectedRoute component={FitBitOAuthPage} />} />
      <Route path="/withings" element={<ProtectedRoute component={WithingsOAuthPage} />} />
      <Route path="/alerts" element={<ProtectedRoute component={AlertList} />} />
      <Route path="/doctors" element={<ProtectedRoute component={DoctorsList} />} />
      <Route path="/settings/analyses" element={<ProtectedRoute component={AnalysesList} />} />
      <Route path="/help" element={<ProtectedRoute component={Faq} />} />
      <Route path="/notifications" element={<ProtectedRoute component={Notifications} />} />
      <Route path="/error/patient-not-available" element={<ProtectedRoute component={PatientNotAvailable} />} />
      <Route path="*" element={<ProtectedRoute component={NotFound} />} />
    </Routes>
  );
}
