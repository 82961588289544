import { PaperClipIcon, ArrowPathIcon as RefreshIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { DocumentType } from '../../contracts/Document';
import { usePatientDocument } from '../../hooks/queries/usePatientDocument';

export default function DocumentLink({ patientId, ...document }: DocumentType & { patientId: number }) {
  const { refetch, isFetching } = usePatientDocument(Number(patientId), document.id);

  return (
    <div className="pl-0 pr-4 py-2 flex items-center justify-between">
      <div className="w-0 flex-1 flex items-center cursor-pointer hover:text-primary" onClick={() => refetch()}>
        {!isFetching && <PaperClipIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />}
        {isFetching && (
          <RefreshIcon className="animate-spin rotate-180 shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
        <span className="ml-2 flex-1 w-0 truncate font-semibold" title={document.fileName}>
          {document.fileName}
        </span>
      </div>
    </div>
  );
}
