import { Pie } from 'react-chartjs-2';
import { useMemo } from 'react';
import { ArcElement, Chart, ChartOptions } from 'chart.js';
import { COLORS } from '../../../../../../../utils/color';
import { EcgHourlyDatum } from '../../../../../../../contracts/Ecg';

Chart.register(ArcElement);

interface Props {
  data: EcgHourlyDatum[];
}

export default function SummaryPieChart({ data }: Props) {
  const dataPoints = useMemo(
    () =>
      (data && [
        data.reduce((acc, curr) => acc + curr.heartRate.totalBeats, 0),
        data.reduce((acc, curr) => acc + curr.supraventricular.beats, 0),
        data.reduce((acc, curr) => acc + curr.ventricular.beats, 0),
      ]) ||
      [],
    [data],
  );

  const totalDataPoints = useMemo(() => dataPoints.reduce((acc, curr) => curr + acc, 0), [dataPoints]);

  const options = useMemo<ChartOptions<'pie'>>(
    () => ({
      plugins: {
        tooltip: { enabled: false },
        legend: { fullSize: true, position: 'bottom', align: 'center' },
      },
    }),
    [],
  );

  const chartData = useMemo(
    () => ({
      labels: [
        `N - ${((dataPoints?.[0] / totalDataPoints) * 100).toLocaleString() ?? ''}%`,
        `SV - ${((dataPoints?.[1] / totalDataPoints) * 100).toLocaleString() ?? ''}%`,
        `V - ${((dataPoints?.[2] / totalDataPoints) * 100).toLocaleString() ?? ''}%`,
      ],
      datasets: [
        {
          data: dataPoints,
          backgroundColor: [COLORS[0].backgroundColor, COLORS[1].backgroundColor, COLORS[2].backgroundColor],
        },
      ],
    }),
    [dataPoints, totalDataPoints],
  );

  return (
    <div>
      <Pie height={300} width={200} options={options} data={chartData} />
    </div>
  );
}
