import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DocumentType, DocumentTypeEnum } from '../../contracts/Document';

type PatientDocumentUpdateRequest = {
  patientId: number;
  id: number;
  comments: string;
  documentType: DocumentTypeEnum;
};

export function usePatientDocumentUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<DocumentType>();
  return useMutation<DocumentType, unknown, PatientDocumentUpdateRequest>(
    ({ patientId, id, documentType, ...data }) =>
      request(`/patients/${patientId}/documents/${id}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(
          ['patient-documents', request.patientId, request.documentType],
          (oldData: Array<DocumentType> = []) =>
            oldData.map(medicament => (medicament.id === data.id ? { ...data } : { ...medicament })),
        );
      },
    },
  );
}
