import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { MetricDataPointRequestType } from '../../contracts/Metrics';

export function useMetrics(patientId: number) {
  const queryClient = useQueryClient();
  const request = useRequest<MetricDataPointRequestType>();
  return useMutation<unknown, unknown, MetricDataPointRequestType>(
    data =>
      request(`/patients/${patientId}/metrics`, {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['patient-metrics-history', patientId]);
      },
    },
  );
}
