import * as React from 'react';
import { PropsWithChildren } from 'react';

export default function Feed({ children }: PropsWithChildren) {
  return (
    <div className="flow-root">
      <ul className="-mb-8">{children}</ul>
    </div>
  );
}
