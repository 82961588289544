import { stringToHslColor } from '../../utils/color';
import React from 'react';

interface ICircleInitials {
  text: string;
  className?: string;
}

export const CircleInitials: React.FunctionComponent<ICircleInitials> = ({ text, className = '' }) => (
  <span
    className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${className}`}
    style={{ backgroundColor: stringToHslColor(text) }}
  >
    <span className="font-medium leading-none text-white uppercase">
      {text
        ?.split(' ')
        .map(v => v.slice(0, 1))
        .join('')
        .slice(0, 2)}
    </span>
  </span>
);
