import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { FiltersPutRequest, FiltersPutResponse } from '../../contracts/Filters';

export function useFiltersUpdate() {
  const request = useRequest<FiltersPutResponse>();
  const mutationFn = (data: FiltersPutRequest) =>
    request(`/filters/${data.id}`, {
      body: JSON.stringify(data),
      method: 'PUT',
    });

  return useMutation<FiltersPutResponse, unknown, FiltersPutRequest>(mutationFn);
}
