import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  filter: string;
  setFilter: (newPredicate: string) => void;
  label?: string;
}

export default function SuperSearchGlobalFilter({ className, filter, setFilter, label }: Props) {
  const { t } = useTranslation('SuperSearchGlobalFilter');
  return (
    <div className={className}>
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="global_search"
          id="global_search"
          className="focus:ring-primary focus:border-primary block w-full pl-10 sm:text-sm border-gray-300 dark:border-gray-500 dark:bg-gray-600 rounded-md dark:placeholder-gray-500 dark:text-gray-300"
          placeholder={label || t('search')}
          value={filter || ''}
          onChange={e => setFilter(e.target.value)}
        />
      </div>
    </div>
  );
}
