import React from 'react';
import format from 'date-fns/format';
import { usePatient } from '../../../../../../../hooks/queries/usePatient';
import { useEcgRecord } from '../../../../../../../hooks/queries/useEcgRecord';
import LoadingContainer from '../../../../../../../components/layouts/LoadingContainer';
import PatientEcgComponent from '../PatientEcgComponent';
import { EcgAccidentType } from '../../utils/types';

interface Props {
  patientId: number;
  recordId: number;
  timestamp?: number;
  temporalResolution?: number;
  highlightValues?: number[];
  onCommentCreated: (comment: { timestamp: number; comment: string; eventType?: EcgAccidentType }) => void;
}

export default function EcgViewer({
  patientId,
  recordId,
  timestamp,
  temporalResolution,
  highlightValues,
  onCommentCreated,
}: Props) {
  const { data: patient, isLoading: isLoadingPatient } = usePatient(patientId);
  const { data: ecgRecord, isLoading: isLoadingEcgRecord } = useEcgRecord(patientId, recordId);
  const startTimestamp = timestamp ? format(new Date(timestamp), 'HH:mm:ss') : null;

  return (
    <LoadingContainer loading={isLoadingPatient || isLoadingEcgRecord}>
      {patient && ecgRecord && (
        <PatientEcgComponent
          patient={patient}
          record={ecgRecord}
          highlightValues={highlightValues}
          startTime={startTimestamp}
          startTemporalResolution={temporalResolution}
          onCommentCreated={onCommentCreated}
        />
      )}
    </LoadingContainer>
  );
}
