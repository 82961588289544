import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { PatientAnalysesResponse } from '../../contracts/Patient';

export function usePatientAnalyses(patientId: number) {
  const request = useRequest<PatientAnalysesResponse>();
  return useQuery<unknown, unknown, PatientAnalysesResponse>(
    ['patient-analyses', patientId],
    () => request(`/patients/${patientId}/analyses`),
    {
      staleTime: 60 * 1000,
    },
  );
}
