import { Chart, Plugin } from 'chart.js';

export const millimeterPaperBackground: Plugin = {
  id: 'zeroZeroLines',
  beforeDatasetsDraw: (chart: Chart, args: { cancelable: true }, options: { color: string; width: number }): void => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
      scales: { x, y },
    } = chart;
    ctx.lineWidth = options.width || 1;
    ctx.strokeStyle = options.color || '#ffd9d9';

    // 1 milliVolt is supposed to be two major ticks in y axis
    const yMajorStep = 1000 / 2;
    // 1 second is supposed to be 5 major ticks in x axis
    const xMajorStep = 128 / 5;
    // Each major tick should be subdivided into 5 minor ones
    const yMinorStep = yMajorStep / 5;
    // Each major tick should be subdivided into 5 minor ones
    const xMinorStep = xMajorStep / 5;

    const showMinorGridLines = x.max - x.min < 1000;

    if (showMinorGridLines) {
      // Draw minor grids
      ctx.beginPath();
      for (let i = 0; i < x.max; i += xMinorStep) {
        ctx.moveTo(x.getPixelForValue(i), bottom);
        ctx.lineTo(x.getPixelForValue(i), top);
      }

      for (let i = 0; i < y.max; i += yMinorStep) {
        ctx.moveTo(left, y.getPixelForValue(i));
        ctx.lineTo(right, y.getPixelForValue(i));
      }
      for (let i = 0; i > y.min; i -= yMinorStep) {
        ctx.moveTo(left, y.getPixelForValue(i));
        ctx.lineTo(right, y.getPixelForValue(i));
      }
      ctx.stroke();
    }

    // Draw major grids
    ctx.beginPath();
    ctx.lineWidth = 2;
    for (let i = 0; i < x.max; i += xMajorStep) {
      ctx.moveTo(x.getPixelForValue(i), bottom);
      ctx.lineTo(x.getPixelForValue(i), top);
    }
    for (let i = 0; i < y.max; i += yMajorStep) {
      ctx.moveTo(left, y.getPixelForValue(i));
      ctx.lineTo(right, y.getPixelForValue(i));
    }
    for (let i = 0; i > y.min; i -= yMajorStep) {
      ctx.moveTo(left, y.getPixelForValue(i));
      ctx.lineTo(right, y.getPixelForValue(i));
    }
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'rgba(0, 0, 0, .66)';
    ctx.fillStyle = 'rgba(0, 0, 0, .66)';
    ctx.lineWidth = 1;
    ctx.font = '100 12px Arial';

    if (showMinorGridLines) {
      ctx.fillText('25 mm/s, 10 mm/mV', x.getPixelForValue(x.max) - 120, y.getPixelForValue(y.max) + 16);
    } else {
      ctx.fillText('5 mm/s, 10 mm/mV', x.getPixelForValue(x.max) - 120, y.getPixelForValue(y.max) + 16);
    }
    ctx.stroke();
  },
};
