import * as React from 'react';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props {
  isActive: boolean;
  onMouseDown: () => void;
}

export default function EditorButton({ onMouseDown, isActive = false, children }: PropsWithChildren<Props>) {
  return (
    <button
      className={classNames(
        'inline-flex whitespace-nowrap items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-500 focus:outline-none dark:text-gray-300 dark:border-gray-400',
        isActive ? 'bg-gray-200 dark:bg-gray-600' : 'bg-white dark:bg-gray-500 hover:dark:bg-gray-600',
      )}
      type="button"
      onMouseDown={event => {
        event.preventDefault();
        onMouseDown();
      }}
    >
      {children}
    </button>
  );
}
