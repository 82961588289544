import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DaysFilterType, MetricsHistoryResponseType } from '../../contracts/Metrics';
import { format } from 'date-fns';

export function useMetricsHistory(patientId: number, date: Date, days: DaysFilterType) {
  const request = useRequest<MetricsHistoryResponseType>();
  return useQuery<unknown, unknown, MetricsHistoryResponseType>(
    ['patient-metrics-history', { patientId, date, days }],
    () => request(`/patients/${patientId}/metrics/groups/${format(date, 'yyyy-MM-dd')}/${days}`),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
    },
  );
}
