import { getDates } from '../MetricsUtils';
import { Column } from 'react-table';
import { getDateWithoutTime, getDateWithTime } from '../../../../../../utils/date';
import { MetricCell } from '../MetricCell';
import * as React from 'react';
import { MetricHistoryType } from '../../../../../../contracts/Metrics';
import { format, isEqual, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useDynamicTables = (metrics: MetricHistoryType[]): [Column<any>[], any[]] => {
  const { t } = useTranslation('useDynamicTables');
  return React.useMemo(() => {
    const dates = getDates(metrics);
    const columns: Array<Column<any>> = [
      {
        id: 'date',
        Header: t('dateHeader'),
        accessor: ({ date }: { date: Date | string }): Date => (typeof date === 'string' ? parseISO(date) : date),
        width: 130,
        sortType: 'datetime',
        Cell: ({ value }) => (
          <>{format(value, 'HH:mm:ss') === '00:00:00' ? getDateWithoutTime(value) : getDateWithTime(value)}</>
        ),
      },
    ];

    metrics.forEach((metric, index) => {
      columns.push({
        Header: (
          <div className="text-right w-full">
            {metric.metric.name}, {metric.metric.unit}
          </div>
        ),
        width: 'auto',
        accessor: `metric_type_${index}`,
        Cell: ({ value }) => <MetricCell value={value} metric={metric.metric} />,
      });
    });

    const tableData = dates.map(date => {
      const metricTypes = {};
      metrics.forEach((metric, index) => {
        (metricTypes as any)[`metric_type_${index}`] =
          metric.history.find(history => isEqual(parseISO(history.date.toString()), date))?.value ?? '';
      });

      return {
        date: date,
        ...metricTypes,
      };
    });
    return [columns, tableData];
  }, [metrics, t]);
};
