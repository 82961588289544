import React from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '../../../components/buttons/Button';
import { useDoctorsPeers } from '../../../hooks/queries/doctors/useDoctorsPeers';
import * as Yup from 'yup';
import { ListboxInput } from '../../../components/form/ListboxInput/ListboxInput';
import { ListboxItem } from '../../../components/form/ListboxInput/ListboxItem';
import { DoctorType } from '../../../contracts/Doctors';
import { ListboxDoctor } from '../../../components/form/ListboxInput/ListboxDoctor';
import { Descendant } from 'slate';
import { slateNodesHaveText } from '../../../utils/text';
import { RichTextInput } from '../../../components/form/RichTextInput';
import { getValidationMessage } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';

interface Props {
  clinicId?: number;
  handleCancel: () => void;
  handleSave: (values: { assigneeId: number; text: Descendant[] }) => void;
  isLoading: boolean;
}

const DoctorConsultationFormScheme = Yup.object().shape({
  assigneeId: Yup.number().min(1, getValidationMessage('selectDoctorValidationMessage', 'DoctorConsultationForm')),
  text: Yup.array().test('has-text', getValidationMessage('required', 'validation'), value =>
    (value as Descendant[]).some(slateNodesHaveText),
  ),
});

export const DoctorConsultationForm = ({ clinicId, ...props }: Props) => {
  const { t } = useTranslation('DoctorConsultationForm');
  const { data: doctors } = useDoctorsPeers();

  const initialValues: { assigneeId: number; text: Descendant[] } = {
    assigneeId: 0,
    text: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
  };

  function getDoctor(id: number): DoctorType | undefined {
    if (!doctors) {
      return undefined;
    }
    return doctors.find(doctor => doctor.id === Number(id));
  }

  return (
    <Formik initialValues={initialValues} onSubmit={props.handleSave} validationSchema={DoctorConsultationFormScheme}>
      {({ values }) => {
        const doctor = getDoctor(values['assigneeId']);
        return (
          <Form>
            <div className="space-y-6 mt-6" style={{ minWidth: 650 }}>
              <div className="w-96">
                <Field
                  component={ListboxInput}
                  name={'assigneeId'}
                  label={t('doctorLabel')}
                  listboxValue={
                    <ListboxDoctor
                      name={
                        doctor
                          ? `${getDoctor(values['assigneeId'])?.firstName} ${getDoctor(values['assigneeId'])?.lastName}`
                          : undefined
                      }
                      speciality={doctor?.speciality}
                      emptyText={t('selectDoctorLabel')}
                    />
                  }
                >
                  {!!doctors &&
                    doctors.map(doctor => (
                      <ListboxItem value={doctor.id} key={doctor.id}>
                        <ListboxDoctor
                          name={`${doctor.firstName} ${doctor.lastName}`}
                          speciality={doctor?.speciality}
                          emptyText={t('selectDoctorLabel')}
                        />
                      </ListboxItem>
                    ))}
                </Field>
              </div>
              <div>
                <Field component={RichTextInput} name={'text'} label={t('questionLabel')} rows={5} />
              </div>
              <div className="mt-6 sm:flex sm:flex-row-reverse">
                <Button buttonType="submit" isLoading={props.isLoading}>
                  {t('sendLabel')}
                </Button>
                <Button type="secondary" onClick={props.handleCancel}>
                  {t('cancel', { ns: 'common' })}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
