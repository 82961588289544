import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientMedicamentType } from '../../contracts/Patient';

export function usePatientMedications(patientId: number) {
  const request = useRequest<Array<PatientMedicamentType>>();
  return useQuery<unknown, unknown, Array<PatientMedicamentType>>(
    ['patient-medication', patientId],
    () => request(`/patients/${patientId}/medications`),
    {
      staleTime: 60 * 1000,
    },
  );
}
