import { useRequest } from '../useRequest';
import { EcgHourlyDatum } from '../../contracts/Ecg';
import { useQuery } from '@tanstack/react-query';

export function useEcgHourlyAnalysis(patientId: number, ecgRecordId: number) {
  const request = useRequest<EcgHourlyDatum[]>();

  return useQuery<EcgHourlyDatum[]>(['patient-ecg-hourly-analysis', patientId + ecgRecordId], () =>
    request(`/patients/${patientId}/ecgs/${ecgRecordId}/hourly`),
  );
}
