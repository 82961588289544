import React, { useMemo, useState } from 'react';
import { useEcgPeaks } from '../../../../../../hooks/queries/useEcgPeaks';
import { EcgRecordPeakData } from '../../../../../../contracts/Ecg';
import { AnalysisMode } from '../utils/types';

export function usePatientEcgAnalysesLayers(patientId?: number, recordId?: number) {
  const [analysesOverlayModes, setAnalysesOverlayModes] = useState<AnalysisMode[]>([]);

  const isPeaksEnabled = useMemo(() => analysesOverlayModes.includes('peaks'), [analysesOverlayModes]);

  const { data, isLoading: isLoadingPeaks } = useEcgPeaks(
    patientId!,
    recordId!,
    !!recordId && !!patientId && isPeaksEnabled,
  );

  const peaksData = React.useMemo(
    () =>
      isPeaksEnabled
        ? data?.map<EcgRecordPeakData>(record => {
            return {
              tStartInUs: record.R,
              tStartInMs: record.R,
              dataPoints: { ...record, R: 0 },
            };
          }) ?? []
        : [],
    [isPeaksEnabled, data],
  );

  return {
    peaksData,
    isLoadingPeaks,
    analysesOverlayModes,
    setAnalysesOverlayModes,
  };
}
