export class FitbitService {
  static scope = 'activity heartrate location nutrition profile settings sleep social weight';
  static clientId = process.env.REACT_APP_FITBIT_APP_ID;
  static url = 'https://www.fitbit.com/oauth2/authorize';
  static expiration = '604800';
  public static getAuthUrl(patientId: number, returnUrl = FitbitService.getReturnUrl()) {
    return `${FitbitService.url}?state=${patientId}&response_type=code&client_id=${
      FitbitService.clientId
    }&redirect_uri=${encodeURI(returnUrl)}&scope=${encodeURI(FitbitService.scope)}&expires_in=${
      FitbitService.expiration
    }`;
  }

  public static getReturnUrl() {
    return `${window.location.origin}/fitbit`;
  }
}
