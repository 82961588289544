import { Column } from 'react-table';
import dateCellRenderer from '../Renderers/DateCellRenderer';
import { TFunction } from 'i18next';

export default function dateCell<T extends object>(
  t: TFunction,
  title: string,
  accessor: string & keyof T,
  width?: number,
): Column<T> {
  return {
    id: accessor,
    Header: title,
    accessor,
    Cell: dateCellRenderer(t),
    width,
  };
}
