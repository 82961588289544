import * as React from 'react';
import { HistoryMetricType } from '../../../../../contracts/Metrics';
import { useTranslation } from 'react-i18next';

interface IMetricCell {
  metric: HistoryMetricType;
  value: number;
}

export const MetricCell = ({ value, metric }: IMetricCell) => {
  const { t } = useTranslation('MetricCell');
  const isOutsideTarget =
    (metric.targetMax && metric.targetMax < value) || (metric.targetMin && metric.targetMin > value);
  const title = React.useMemo(() => {
    if (isOutsideTarget) {
      if (metric.targetMax && metric.targetMax < value) {
        return t('goalLessThan', { targetMax: metric.targetMax });
      } else {
        return t('goalMoreThan', { targetMin: metric.targetMin });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutsideTarget]);

  const displayValue = value.toLocaleString('lt-LT');

  return (
    <div className={`text-right ${isOutsideTarget ? 'text-primary' : ''}`} title={title}>
      {displayValue}
    </div>
  );
};
