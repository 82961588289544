import * as React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { AutocompleteInput } from '../../../components/form/AutocompleteInput';
import { GadgetType } from '../../../contracts/Gadget';
import { useGadgets } from '../../../hooks/queries/useGadgets';
import DialogForm from '../../../components/layouts/dialog-form/DialogForm';
import {useTranslation} from "react-i18next";

interface IAddPatientGadgetForm {
  handleCancel: () => void;
  handleSave: (values: { gadgetId: number }) => void;
}

type FormInitialValues = { gadgetId: number; serialNo: string };

const GadgetFromSchema = Yup.object().shape({
  serialNo: Yup.string().required(),
});

export const AddPatientGadgetForm: React.FunctionComponent<IAddPatientGadgetForm> = props => {
  const {t} = useTranslation('AddPatientGadgetForm')
  const { data: gadgets } = useGadgets();
  const initialValues: FormInitialValues = {
    gadgetId: 0,
    serialNo: '',
  };

  function handleSave(values: FormInitialValues) {
    props.handleSave({ gadgetId: values.gadgetId });
  }

  return (
    <DialogForm
      title={t('dialogTitle')}
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={GadgetFromSchema}
      onCancel={props.handleCancel}
    >
      {({ setFieldValue }) => (
        <div className="sm:flex sm:items-start w-full">
          <Field
            component={AutocompleteInput}
            name={'serialNo'}
            label={t('serialNoLabel')}
            items={gadgets}
            onChange={(item: GadgetType) => {
              if (item) {
                setFieldValue('gadgetId', item?.id);
                setFieldValue('serialNo', item?.seriesNo);
              } else {
                setFieldValue('gadgetId', 0);
                setFieldValue('serialNo', '');
              }
            }}
            itemString={(item: GadgetType) => item?.seriesNo ?? ''}
          />
        </div>
      )}
    </DialogForm>
  );
};
