/* This example requires Tailwind CSS v2.0+ */
import Pagino from 'pagino';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import PaginationButton from '../../buttons/PaginationButton';
import { useTranslation } from 'react-i18next';

interface IPagination {
  previousPage?: () => void;
  nextPage?: () => void;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  pageIndex: number;
  pageCount: number;
  previousText?: string;
  nextText?: string;
}

function PageButton({
  children,
  handleClick,
  isActive = false,
}: {
  children: string;
  handleClick: () => void;
  isActive?: boolean;
}) {
  let cx = 'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium focus:outline-none';
  if (isActive) {
    cx += ' border-primary text-primary border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
  } else {
    cx += ' border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ';
  }
  return (
    <button onClick={handleClick} type="button" className={cx}>
      {children}
    </button>
  );
}

export default function Pagination(props: IPagination) {
  const { t } = useTranslation('Pagination');

  if (props.pageCount <= 1) {
    return null;
  }
  // @ts-ignore
  const pagino = new Pagino({
    showFirst: false,
    showLast: false,
    showNext: false,
    showPrevious: false,
    page: props.pageIndex + 1,
    count: props.pageCount,
  });

  function getPage(page: string | number) {
    if (typeof page === 'number') {
      return (
        <PageButton
          handleClick={() => props.gotoPage(Number(page) - 1)}
          isActive={Number(page) === props.pageIndex + 1}
          key={page}
        >
          {String(page)}
        </PageButton>
      );
    } else {
      return (
        <span
          className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
          key={page}
        >
          ...
        </span>
      );
    }
  }

  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {typeof props.previousPage !== 'undefined' ? (
          <PaginationButton handleClick={props.previousPage}>
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            {props.previousText ?? t('back', { ns: 'common' })}
          </PaginationButton>
        ) : null}
      </div>
      <div className="hidden md:-mt-px md:flex">{pagino.getPages().map((page: string | number) => getPage(page))}</div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {typeof props.nextPage !== 'undefined' ? (
          <PaginationButton handleClick={props.nextPage}>
            {props.nextText ?? t('next', { ns: 'common' })}
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </PaginationButton>
        ) : null}
      </div>
    </nav>
  );
}
