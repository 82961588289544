import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { BaseInput } from './BaseInput';

interface ITextInput {
  label: string;
  description?: string;
  values: Array<{ value: string; text: string }>;
}

export const RadioInput: React.FunctionComponent<FieldProps & ITextInput> = ({ field, form, ...props }) => {
  return (
    <BaseInput field={field} form={form} label={props.label} description={props.description}>
      <div className="flex flex-row space-x-5 mt-1.5 h-8">
        {props.values.map(item => (
          <label key={item.value} className="flex flex-row items-center justify-center">
            <Field
              {...field}
              id={`${field.name}_${item.value}`}
              type={'radio'}
              value={item.value}
              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 cursor-pointer mr-1"
            />
            <span>{item.text}</span>
          </label>
        ))}
      </div>
    </BaseInput>
  );
};
