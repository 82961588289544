import * as React from 'react';

import { MetricDataPointRequestType, MetricGroupType } from '../../../../../../contracts/Metrics';
import Table from '../../../../../../components/common/Table/Table';
import LineChart from '../../../../../../components/common/charts/LineChart';
import BarChart from '../../../../../../components/common/charts/BarChart';

import { ChartView } from '../MetricsUtils';
import { MetricsCellHeader } from '../MetricsCellHeader';
import { useDynamicTables } from './useDynamicTables';
import { useDynamicCharts } from './useDynamicCharts';
import NoMetricsData from './NoMetricsData';

interface Props extends MetricGroupType {
  startDate: Date;
  endDate: Date;
  addHistoryPoint: (values: MetricDataPointRequestType) => Promise<unknown>;
}

export const MetricsChart = ({ name, chartType, metrics, startDate, endDate }: Props) => {
  // const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState<ChartView>(ChartView.Chart);
  const [chartData, scales] = useDynamicCharts(metrics, startDate, endDate);
  const [columns, tableData] = useDynamicTables(metrics);

  // function addHistoryPoint(values: MetricDataPointRequestType) {
  //     props.addHistoryPoint(values).then(() => setOpen(false));
  // }

  if (metrics.every(m => !m.history.length)) {
    return <NoMetricsData name={name} view={view} setView={setView} />;
  }

  return (
    <div className={'mb-16 mt-10'}>
      <MetricsCellHeader name={name} view={view} setView={setView} />
      {/*<Dialog*/}
      {/*    open={!!open}*/}
      {/*    handleCloseClick={() => setOpen(false)}*/}
      {/*    widthAuto={true}*/}
      {/*>*/}
      {/*    {!!open ?*/}
      {/*        <MetricHistoryForm*/}
      {/*            handleCancel={() => setOpen(false)}*/}
      {/*            handleSave={addHistoryPoint}*/}
      {/*            metricType={props.metrics.metricType}*/}
      {/*        /> : null}*/}
      {/*</Dialog>*/}

      {view === ChartView.Chart && chartType === 'line' ? (
        <LineChart
          displayLegend={true}
          data={chartData}
          scales={scales}
          // handleAddClick={() => setOpen(true)}
        />
      ) : null}

      {view === ChartView.Chart && chartType === 'bar' ? (
        <BarChart displayLegend={true} data={chartData} scales={scales} />
      ) : null}

      {view === ChartView.Table ? (
        <Table
          columns={columns}
          data={tableData}
          defaultSortBy={[{ id: 'date', desc: true }]}
          // handleAddClick={() => setOpen(true)}
        />
      ) : null}
    </div>
  );
};
