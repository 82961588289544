import * as React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { TextInput } from '../../../components/form/TextInput';
import { SelectInput } from '../../../components/form/SelectInput';
import { useClinics } from '../../../hooks/queries/useClinics';
import { GadgetCreateRequest } from '../../../contracts/Gadget';
import { useModels } from '../../../hooks/queries/useModels';
import DialogForm from '../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

interface IAddGadgetForm {
  handleCancel: () => void;
  handleSave: (value: GadgetCreateRequest) => void;
}

const GadgetFormSchema = Yup.object().shape({
  modelId: Yup.number().required(),
  seriesNo: Yup.string().required(),
});

export const AddGadgetForm: React.FunctionComponent<IAddGadgetForm> = props => {
  const { t } = useTranslation('AddGadgetForm');
  const { data: clinics } = useClinics();
  const { data: models } = useModels();

  const initialValues: GadgetCreateRequest = {
    seriesNo: '',
    modelId: 0,
    clinicId: 0,
  };
  return (
    <DialogForm
      title={t('title')}
      initialValues={initialValues}
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
      validationSchema={GadgetFormSchema}
    >
      <div className="sm:flex sm:items-start">
        <div className="grid grid-cols-6 gap-6 w-full">
          <Field component={SelectInput} name={'modelId'} label={t('modelIdLabel')}>
            <option value={undefined} key={0}>
              {t('modelIdPlaceholder')}
            </option>
            {models &&
              models.map(model => (
                <option value={model.id} key={model.id}>
                  {model.name}
                </option>
              ))}
          </Field>
          <Field component={TextInput} name={'seriesNo'} label={t('seriesNoLabel')} />
          <Field component={SelectInput} name={'clinicId'} label={t('clinicIdLabel')}>
            <option value={0} key={0}>
              {t('clinicIdPlaceholder')}
            </option>
            {clinics &&
              clinics.map(clinic => (
                <option value={clinic.id} key={clinic.id}>
                  {clinic.name}
                </option>
              ))}
          </Field>
        </div>
      </div>
    </DialogForm>
  );
};
