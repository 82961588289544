import { PatientBaseType } from './Patient';
import { TFunction } from 'i18next';

export type GadgetType = {
  id: number;
  brand: string;
  brandId: number;
  modelId: number;
  model: string;
  category: string;
  categoryId: number;
  seriesNo: string;
  clinic?: string;
  clinicId?: number;
  patient?: PatientBaseType;
  state: GadgetState;
};

export type GadgetCreateRequest = {
  modelId: number;
  seriesNo: string;
  clinicId?: number;
};

export type GadgetUpdateRequest = {
  id: number;
  state: GadgetState;
  clinicId?: number;
};

export enum GadgetState {
  'Ok' = 0,
  'Broken',
  'Lost',
}
export const getGadgetStateLabels = (t: TFunction): Array<string> => [
  t('ok', { ns: 'GadgetStateLabels' }),
  t('broken', { ns: 'GadgetStateLabels' }),
  t('lost', { ns: 'GadgetStateLabels' }),
];

export const getGadgetStateArray = (t: TFunction): Array<{ name: string; value: GadgetState }> => [
  {
    name: t('ok', { ns: 'GadgetStateLabels' }),
    value: GadgetState.Ok,
  },
  {
    name: t('broken', { ns: 'GadgetStateLabels' }),
    value: GadgetState.Broken,
  },
  {
    name: t('lost', { ns: 'GadgetStateLabels' }),
    value: GadgetState.Lost,
  },
];

export const GadgetStateColor: Array<string> = [
  'bg-green-100 text-green-800', // In use
  'bg-orange-100 text-yellow-600', // Broken
  'bg-red-100 text-red-800', // Lost
];

export interface GadgetHistoryEntity {
  gadgetId: number;
  startDate: Date;
  endDate: Date;
  patient: PatientBaseType;
}

export type GadgetHistory = Array<GadgetHistoryEntity>;

export type GadgetShortType = {
  id: number;
  brandId: number;
  shortName: string;
  modelName: string;
};

export const GadgetColorByBrandId: Record<number, string> = {
  0: 'bg-gray-200',
  1: 'bg-orange-500 text-white',
  2: 'bg-fitbit text-white',
  3: 'bg-green-500 text-white',
};
