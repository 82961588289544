import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PollScalesRequest } from '../../contracts/Poll';

export function usePollScales(patientId: number, pollId: number) {
  const queryClient = useQueryClient();
  const request = useRequest<unknown>();
  return useMutation<unknown, unknown, PollScalesRequest>(
    body =>
      request(`/patients/${patientId}/polls/${pollId}/scales`, {
        body: JSON.stringify(body),
        method: 'PUT',
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['polls', pollId, 'scales'], { type: 'active' });
        queryClient.refetchQueries(['scales', pollId], { type: 'active' });
      },
    },
  );
}
