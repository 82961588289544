import * as React from 'react';
import classNames from 'classnames';

interface Props {
  progress: number;
  className?: string;
}

export default function ProgressBar({ className, progress }: Props) {
  return (
    <div className={classNames('rounded-full bg-gray-200 h-2 overflow-hidden', className)}>
      <div
        className={classNames(
          `rounded-full h-full shadow-progress-bar transition-all duration-500 ease-in-out`,
          progress >= 100 ? 'bg-green-poll' : 'bg-blue-poll',
        )}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}
