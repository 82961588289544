import React, { useMemo } from 'react';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart, ChartData, ChartDataset, Decimation, PointElement } from 'chart.js';
import EcgPeaksChartType from './EcgPeaksChartType';
import { millimeterPaperBackground } from './MillimeterPaperBackground';
import VerticalLineChartType from './VerticalLineChartType';
import { useEcgChartOptions } from './useEcgChartOptions';

Chart.register(annotationPlugin, PointElement, CategoryScale, Decimation, EcgPeaksChartType, VerticalLineChartType);

interface Props {
  yZoom: number;
  dataSets: ChartDataset[];
  startTime: number;
  secondsPerPage: number;
  commentMode: boolean;
  onClick: (x: number, y: number) => void;
}

export default function EcgChart({ dataSets, secondsPerPage, startTime, commentMode, onClick, yZoom }: Props) {
  const options = useEcgChartOptions(secondsPerPage, startTime, commentMode, onClick, yZoom);

  const data: ChartData = useMemo(
    () => ({
      datasets: dataSets
        .filter(d => d.data.length)
        .map<ChartDataset>(dp => ({
          ...dp,
          pointRadius: 0,
          borderWidth: 1,
          borderColor: '#777',
          spanGaps: true,
          normalized: true,
          parsing: false,
        })),
      labels: dataSets?.find(s => s.data.length)?.data?.map((x, index) => index) ?? [],
    }),
    [dataSets],
  );

  return <Line plugins={[millimeterPaperBackground]} data={data as any} options={options} height={250} width={600} />;
}
