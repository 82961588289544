import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ThreadInboxResponse } from '../../contracts/Threads';

export const useThreadInbox = () => {
  const request = useRequest<ThreadInboxResponse[]>();
  return useQuery<unknown, unknown, ThreadInboxResponse[]>(['threads-me'], () => request('/me/inbox'), {
    staleTime: 1000 * 30 * 60,
  });
};
