import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { FiltersPostRequest, FiltersPostResponse } from '../../contracts/Filters';

export function useFiltersCreate(table?: string) {
  const request = useRequest<FiltersPostResponse>();

  return useMutation<FiltersPostResponse, unknown, FiltersPostRequest>((req: FiltersPostRequest) =>
    request(`/filters/${table}`, {
      body: JSON.stringify(req),
      method: 'POST',
    }),
  );
}
