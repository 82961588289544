import * as React from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import SmallButton from '../../../../../components/buttons/SmallButton';
import Dialog from '../../../../../components/dialog/Dialog';
import { ParametersDialogForm } from './ParametersDialogForm';
import { useAnalysesParametersUpdate } from '../../../../../hooks/mutations/useAnalysesParametersUpdate';
import { successToast } from '../../../../../utils/successToast';
import { useAnalysesParameters } from '../../../../../hooks/queries/useAnalysesParameters';
import { useTranslation } from 'react-i18next';

export const ParametersDialog = () => {
  const { t } = useTranslation('ParametersDialog');
  const { data: analyses } = useAnalysesParameters();
  const [isOpen, setOpen] = React.useState(false);
  const analysesParametersMutation = useAnalysesParametersUpdate();

  function handleSave(values: Array<number>) {
    analysesParametersMutation.mutate(
      {
        parameterIds: values,
      },
      {
        onSuccess: () => {
          successToast(t('successMessage'));
          setOpen(false);
        },
      },
    );
  }

  return (
    <>
      <SmallButton
        handleClick={() => setOpen(true)}
        icon={<FunnelIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />}
      >
        {t('parametersLabel')}
      </SmallButton>
      <Dialog open={isOpen} handleCloseClick={() => setOpen(false)} widthAuto={true}>
        <div>
          <ParametersDialogForm
            handleSave={handleSave}
            handleCancel={() => setOpen(false)}
            parameters={analyses ?? []}
          />
        </div>
      </Dialog>
    </>
  );
};
