import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { MetricsResponseType } from '../../contracts/Metrics';
import { getFullYear } from '../../utils/date';

export function useMetrics(patientId: number, _date: Date | undefined = undefined) {
  const date = React.useMemo(() => {
    if (!_date) {
      return getFullYear(new Date());
    }
    return getFullYear(_date);
  }, [_date]);

  const request = useRequest<MetricsResponseType>();
  return useQuery<{ date: Date }, unknown, MetricsResponseType>(
    ['patient-heart-rates', { patientId, _date }],
    () => request(`/patients/${patientId}/metrics/${date}`),
    {
      staleTime: 60 * 1000,
    },
  );
}
