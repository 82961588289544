import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { AlertType } from '../../contracts/Alert';

export function useAlertSiblings(alertId: number) {
  const request = useRequest<Array<AlertType>>();
  return useQuery<unknown, unknown, Array<AlertType>>(['alert-siblings', alertId], () =>
    request(`/alerts/${alertId}/siblings`),
  );
}
