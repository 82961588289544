import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import {
  ExtendedColumnInstanceProps,
  getChevronDownClassNames,
  getChevronUpClassNames,
  getThProps,
} from './TableUtilities';

interface Props {
  headers: ExtendedColumnInstanceProps<any>[];
}

export default function TableHeader({ headers }: Props) {
  return (
    <thead className="bg-gray-50 dark:bg-gray-700">
      <tr>
        {headers
          .filter(({ isVisible }) => isVisible)
          .map(column => (
            <th scope="col" {...getThProps(column)}>
              <div className="flex items-center">
                <>
                  {column.render('Header')}
                  {!column.disableSortBy && (
                    <span className="ml-1 flex flex-col">
                      <ChevronUpIcon className={getChevronUpClassNames(column)} />
                      <ChevronDownIcon className={getChevronDownClassNames(column)} />
                    </span>
                  )}
                </>
              </div>
            </th>
          ))}
      </tr>
    </thead>
  );
}
