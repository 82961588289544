import * as React from 'react';
import { Field } from 'formik';
import { DiagnosisType } from '../../../../../contracts/Classifiers';
import { TextArea } from '../../../../../components/form/TextArea';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

interface IEditPatientDiagnosisForm {
  handleCancel: () => void;
  handleSave: (values: DiagnosisType & { comments: string }) => void;
  diagnosis: DiagnosisType;
}

export const EditPatientDiagnosisForm: React.FunctionComponent<IEditPatientDiagnosisForm> = props => {
  const { t } = useTranslation('EditPatientDiagnosisForm');
  return (
    <DialogForm
      title={`${props.diagnosis.code} / ${props.diagnosis.name}`}
      initialValues={Object.assign({}, props.diagnosis, { comments: props.diagnosis.comments ?? '' })}
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
    >
      <div className="sm:flex sm:items-start">
        <div className="flex space-x-6 w-full">
          <Field component={TextArea} name={'comments'} label={t('commentsLabel')} />
        </div>
      </div>
    </DialogForm>
  );
};
