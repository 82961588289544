import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientType } from '../../contracts/Patient';
import { useNavigate } from 'react-router-dom';

export function usePatient(patientId: number): UseQueryResult<PatientType> {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handlePatientNotFound = (res: Response): boolean => {
    if (res.status === 404) {
      navigate('/error/patient-not-available');
      return true;
    }
    return false;
  };
  const request = useRequest<PatientType>();
  return useQuery<unknown, unknown, PatientType>(
    ['patients', patientId],
    () => request(`/patients/${patientId}`, {}, handlePatientNotFound),
    {
      staleTime: 1000 * 60 * 15,
      onSuccess: (data: PatientType) => {
        const oldData: Array<PatientType> | undefined = queryClient.getQueryData(['patients']);
        if (oldData) {
          queryClient.setQueryData(['patients'], (oldData: Array<PatientType> = []) =>
            oldData.map(patient => (patient.id === data.id ? { ...data } : { ...patient })),
          );
        }
      },
      onError: err => console.dir(err),
    },
  );
}
