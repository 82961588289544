import { Field } from 'formik';
import * as React from 'react';
import { TextInput } from '../../components/form/TextInput';
import { SelectInput } from '../../components/form/SelectInput';
import { useClinics } from '../../hooks/queries/useClinics';
import { DoctorRequestType, getDoctorRoleLabelList, DoctorType } from '../../contracts/Doctors';
import * as Yup from 'yup';
import DialogForm from '../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';
import { getValidationMessage } from '../../utils/validation';

interface IDoctorForm {
  handleCancel: () => void;
  handleSave: (values: DoctorRequestType) => void;
  doctor?: DoctorType;
}

export const DoctorForm: React.FunctionComponent<IDoctorForm> = props => {
  const { t } = useTranslation('DoctorForm');
  const { data: clinics } = useClinics();
  const DoctorFormSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    speciality: Yup.string().required(),
    role: Yup.number().required().min(1, getValidationMessage('chooseRoleValidationError', 'DoctorForm')),
    email: Yup.string().required().email(),
  });

  const initialValues: DoctorRequestType = React.useMemo(() => {
    if (!props.doctor) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        speciality: '',
        role: 0,
        clinicId: 0,
      };
    }
    const { clinic, phone = '', ...doctorValues } = props.doctor;
    return {
      ...doctorValues,
      clinicId: clinic?.id,
      phone,
    };
  }, [props.doctor]);

  function handleSubmit({ clinicId, ...values }: DoctorRequestType) {
    const data: DoctorRequestType = values;
    data.role = Number(values.role);
    if (clinicId && clinicId > 0) {
      data.clinicId = clinicId;
    }
    props.handleSave(data);
  }

  return (
    <DialogForm
      title={t('dialogTitle')}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={props.handleCancel}
      validationSchema={DoctorFormSchema}
    >
      <div className="sm:flex sm:items-start">
        <div className="flex flex-col space-y-6 w-full">
          <div className="flex pr-5 w-1/2">
            <Field component={SelectInput} name={'clinicId'} label={t('clinicIdLabel')}>
              <option value={0} key={0}>
                {t('clinicIdPlaceholder')}
              </option>
              {clinics &&
                clinics.map(clinic => (
                  <option value={clinic.id} key={clinic.id}>
                    {clinic.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'firstName'} label={t('name', { ns: 'common' })} />
            <Field component={TextInput} name={'lastName'} label={t('surname', { ns: 'common' })} />
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'speciality'} label={t('specialtyLabel')} />
            <Field component={SelectInput} name={'role'} label={t('roleLabel')}>
              <option value={0} key={0}>
                {t('rolePlaceholder')}
              </option>
              {Object.entries(getDoctorRoleLabelList(t)).map(([role, roleLabel]) => (
                <option value={role} key={role}>
                  {roleLabel}
                </option>
              ))}
            </Field>
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'email'} label={t('emailLabel')} />
            <Field component={TextInput} name={'phone'} label={t('phoneLabel')} />
          </div>
        </div>
      </div>
    </DialogForm>
  );
};
