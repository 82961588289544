import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { GadgetCreateRequest, GadgetType } from '../../contracts/Gadget';

export function useGadgetCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<GadgetType>();
  return useMutation<GadgetType, unknown, GadgetCreateRequest>(
    data =>
      request('/gadgets', {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['gadgets'], (oldData: Array<GadgetType> = []) => [...oldData, data]);
      },
    },
  );
}
