import * as React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tag from '../../../../../components/common/Tag';
import { Column } from 'react-table';
import Table from '../../../../../components/common/Table/Table';
import { usePatientPolls } from '../../../../../hooks/queries/usePatientPolls';
import { getFullYear } from '../../../../../utils/date';
import { TableTitle } from '../../../../../components/common/TableTitle';
import { DiagnosesSection } from '../diagnoses/DiagnosesSection';
import { PERMISSIONS, usePermission } from '../../../../../hooks/usePermission';
import { usePatientPollCreate } from '../../../../../hooks/mutations/usePatientPollCreate';
import { PatientPoll, PatientType } from '../../../../../contracts/Patient';
import RemoveIcon from '../../../../../components/icons/RemoveIcon';
import { NullableType } from '../../../../../contracts/Common';
import { usePatientPollDelete } from '../../../../../hooks/mutations/usePatientPollDelete';
import { successToast } from '../../../../../utils/successToast';
import { DeleteConfirmationWithInput } from '../../../../../components/common/DeleteConfirmationWithInput';
import { useTranslation } from 'react-i18next';

export const PatientPollTab = () => {
  const { t } = useTranslation('PatientPollTab');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<NullableType<PatientType>>(null);
  const currentLocation = useLocation();
  const { id: patientId } = useParams<{ id: string }>();
  const { data: _pollData } = usePatientPolls(Number(patientId));
  const patientPollCreateMutation = usePatientPollCreate(Number(patientId));
  const patientPollDeleteMutation = usePatientPollDelete();
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const [pollColumns, pollData] = React.useMemo(() => {
    const columns: Array<Column<PatientPoll>> = [
      {
        Header: t('dateHeader'),
        accessor: 'date',
        width: '20%',
        Cell: ({ value, row }) =>
          value ? (
            <Link
              to={`${currentLocation.pathname}/poll/${String(row.original.id)}`}
              className={'text-black font-medium hover:underline shrink-0'}
            >
              {getFullYear(value)}
            </Link>
          ) : null,
      },
      {
        Header: t('completionPercentHeader'),
        accessor: 'completionPercent',
        width: '10%',
        Cell: ({ value }: { value: number }) => {
          return <Tag className={value >= 100 ? 'bg-emerald-100' : 'bg-gray-100'}>{value} %</Tag>;
        },
      },
      {
        Header: t('commentsHeader'),
        width: 'auto',
        accessor: 'comments',
      },
    ];

    if (canManagePatients) {
      columns.push({
        Header: t('edit', { ns: 'common' }),
        disableSortBy: true,
        accessor: 'id',
        width: 30,
        Cell: ({ row }) => (
          <div className="flex my-1">
            <div>
              <RemoveIcon onClick={() => setOpenDeleteConfirmation(row.original as any)} />
            </div>
          </div>
        ),
      });
    }

    return [columns, _pollData];
  }, [t, _pollData, currentLocation.pathname, canManagePatients]);

  return (
    <>
      <TableTitle title={t('pollsTitle')}>
        {pollData && (
          <Table
            data={pollData}
            columns={pollColumns}
            handleAddClick={canManagePatients ? () => patientPollCreateMutation.mutate({}) : undefined}
          />
        )}
        {openDeleteConfirmation && (
          <DeleteConfirmationWithInput
            id={Number(openDeleteConfirmation.id)}
            open={!!openDeleteConfirmation}
            handleCancel={() => setOpenDeleteConfirmation(null)}
            handleSubmit={() => {
              patientPollDeleteMutation.mutate(
                {
                  patientId: Number(patientId),
                  pollId: openDeleteConfirmation.id,
                },
                {
                  onSuccess: () => {
                    setOpenDeleteConfirmation(null);
                    successToast(t('deleteSuccessMessage'));
                  },
                },
              );
            }}
          />
        )}
      </TableTitle>
      <DiagnosesSection patientId={Number(patientId)} />
    </>
  );
};
