import * as React from 'react';
import { useCallback } from 'react';
import { Question } from '../../../contracts/Poll';
import QuestionTitle from '../common/QuestionTitle';
import HtmlTextArea from '../common/HtmlTextArea';
import { debounce } from '../../../utils/debounce';
import QuestionDescription from '../common/QuestionDescription';

export default function TextAreaQuestion(props: Question) {
  const [value, setValue] = React.useState<string>((props.value as string) ?? '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeApi = useCallback(debounce(props.handleAnswerSubmit), []);

  function handleChange(questionId: number, value: string) {
    setValue(value);
    handleChangeApi({
      questionId,
      answer: { value },
    });
  }

  return (
    <div className="mb-8">
      <QuestionTitle number={props.number}>{props.text}</QuestionTitle>
      <QuestionDescription>{props.description}</QuestionDescription>
      <div className="mt-2 space-y-3">
        <HtmlTextArea id={props.id} value={value} handleChange={value => handleChange(props.id, value as string)} />
      </div>
    </div>
  );
}
