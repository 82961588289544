import { BarController } from 'chart.js';

export default class VerticalLineChartType extends BarController {
  override draw() {
    const ctx = this.chart.ctx;
    const { x2: xScale, y: yScale } = this.chart.scales;
    const { min: yMin, max: yMax } = yScale!;
    const data = this.getDataset().data as { x: number; y: number }[];
    ctx.beginPath();
    ctx.strokeStyle = 'rgb(219,39,119)';
    data
      .map(({ x }) => xScale.getPixelForValue(x))
      .filter(x => x >= 0)
      .forEach(xPx => {
        ctx.moveTo(xPx, yMax);
        ctx.lineTo(xPx, yMin);
      });
    ctx.stroke();
  }
}

VerticalLineChartType.id = 'VerticalLineChartType';
