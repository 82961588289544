import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import Table from '../../../../components/common/Table/Table';
import { useFitbit } from '../../../../hooks/queries/useFitbit';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { usePatientTargets } from '../../../../hooks/queries/usePatientTargets';
import { PatientTargetType } from '../../../../contracts/Patient';
import { PERMISSIONS, usePermission } from '../../../../hooks/usePermission';
import Dialog from '../../../../components/dialog/Dialog';
import { EditPatientTargetForm } from '../EditPatientTargetForm';
import { usePatientTargetUpdate } from '../../../../hooks/mutations/usePatientTargetUpdate';
import { successToast } from '../../../../utils/successToast';
import { TableTitle } from '../../../../components/common/TableTitle';
import SmallButton from '../../../../components/buttons/SmallButton';
import EditIcon from '../../../../components/icons/EditIcon';
import HealthStepBar from '../../../../components/common/HealthStepBar';
import { useWithings } from '../../../../hooks/queries/useWithings';
import { useTranslation } from 'react-i18next';

interface IPatientSettingsTab {}

export const PatientSettingsTab: React.FunctionComponent<IPatientSettingsTab> = () => {
  const { t } = useTranslation('PatientSettingsTab');
  const [target, setTarget] = React.useState<PatientTargetType | null>(null);
  const { id: patientId } = useParams<{ id: string }>();
  const { data: fitbitData } = useFitbit(Number(patientId));
  const { data: withingsData } = useWithings(Number(patientId));
  const { data: _targetsData } = usePatientTargets(Number(patientId));
  const patientTargetMutation = usePatientTargetUpdate(Number(patientId));
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const [targetsColumns, targetsData] = React.useMemo(() => {
    const columns: Array<Column<PatientTargetType> & { className?: string }> = [
      {
        Header: t('targetsNameHeader'),
        accessor: 'name',
        Cell: ({ row, value }) =>
          row.original.units ? (
            <>
              {value}, {row.original.units}
            </>
          ) : (
            <>{value}</>
          ),
      },
      {
        Header: t('targetsMinHeader'),
        accessor: 'min',
        className: 'text-center',
      },
      {
        Header: t('targetsFromHeader'),
        accessor: 'from',
        className: 'text-center',
      },
      {
        Header: t('targetsToHeader'),
        accessor: 'to',
        className: 'text-center',
      },
      {
        Header: t('targetsMaxHeader'),
        accessor: 'max',
        className: 'text-center',
      },
    ];
    if (canManagePatients) {
      columns.push({
        Header: t('targetsEditHeader'),
        accessor: 'id',
        disableSortBy: true,
        width: 50,
        Cell: ({ row }) => (
          <div className="grid grid-cols-3 mb-2">
            <div>
              <EditIcon onClick={() => setTarget(row.original)} />
            </div>
          </div>
        ),
      });
    }
    return [columns, _targetsData];
  }, [_targetsData, canManagePatients, t]);

  const [deviceColumns, deviceData] = React.useMemo(() => {
    const columns: Array<Column> = [
      {
        Header: t('devicesNameHeader'),
        accessor: 'name',
      },
      {
        Header: t('devicesAccountIdHeader'),
        accessor: 'accountId',
      },
      {
        Header: t('devicesIsAuthorizedHeader'),
        accessor: 'isAuthorized',
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          if (value) {
            return (
              <div className="flex items-center">
                <span className="bg-emerald-poll flex w-2.5 h-2.5 mr-2 rounded-full" />
                <span>{t('devicesAuthenticatedLabel')}</span>
              </div>
            );
          } else {
            return (
              <SmallButton
                handleClick={() => (window.location.href = row.original.authUrl)}
                icon={<UserPlusIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />}
              >
                {t('devicesLoginLabel')}
              </SmallButton>
            );
          }
        },
      },
    ];

    return [
      columns,
      [
        {
          ...fitbitData,
          name: 'Fitbit',
        },
        {
          ...withingsData,
          name: 'Withings',
        },
      ],
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitbitData, patientId]);

  function handleTargetUpdate(target: PatientTargetType) {
    let targetData: { [key: string]: string | number | null } = {};
    (Object.keys(target) as Array<keyof PatientTargetType>).forEach(key => {
      if (target[key] === '') {
        targetData[key] = null;
      } else {
        targetData[key] = target[key];
      }
    });

    patientTargetMutation.mutate(targetData as PatientTargetType, {
      onSuccess: () => {
        successToast(t('goalsUpdated'));
        setTarget(null);
      },
    });
  }

  return (
    <>
      <TableTitle title={t('devicesTableTitle')}>
        {deviceData && <Table data={deviceData} columns={deviceColumns} />}
      </TableTitle>
      <TableTitle title={t('targetsTableTitle')}>
        <div className={'mb-4 w-full'}>
          <HealthStepBar />
        </div>
        {targetsData && (
          <>
            <Table data={targetsData} columns={targetsColumns} pageSize={999} />
            <Dialog open={!!target} handleCloseClick={() => setTarget(null)} widthAuto={true}>
              {target && (
                <EditPatientTargetForm
                  handleCancel={() => setTarget(null)}
                  handleSave={handleTargetUpdate}
                  target={target}
                />
              )}
            </Dialog>
          </>
        )}
      </TableTitle>
    </>
  );
};
