import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientMedicamentDeleteRequest } from '../../contracts/Patient';
import { PatientMedicamentType } from '../../contracts/Patient';

export function usePatientMedicationDelete() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientMedicamentType>();
  return useMutation<PatientMedicamentType, unknown, PatientMedicamentDeleteRequest>(
    ({ patientId, id }) =>
      request(`/patients/${patientId}/medications/${id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, request) => {
        queryClient.setQueryData(
          ['patient-medication', request.patientId],
          (oldData: Array<PatientMedicamentType> = []) => oldData.filter(medicament => medicament.id !== request.id),
        );
      },
    },
  );
}
