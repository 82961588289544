import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import * as React from 'react';
import {
  Chart,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScaleChartOptions,
  TimeScale,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import CircularButton from '../../buttons/CircularButton';
import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';
import { DeepPartial } from 'chart.js/types/utils';

interface ILineChart {
  displayLegend?: boolean;
  data: ChartData<'line'>;
  handleAddClick?: () => void;
  annotation?: Record<string, AnnotationOptions>;
  scales?: DeepPartial<ScaleChartOptions<'line'>>;
}

Chart.register(TimeScale, LinearScale, PointElement, Filler, LineElement, annotationPlugin, Legend, Tooltip);

export default function LineChart({ displayLegend = false, ...props }: ILineChart) {
  return (
    <div>
      <Line
        data={props.data}
        height={80}
        options={{
          plugins: {
            legend: {
              display: displayLegend,
            },
            annotation: {
              annotations: props.annotation,
            },
          },
          locale: 'lt-LT',
          ...props.scales,
        }}
      />
      {typeof props.handleAddClick === 'function' && (
        <div className={'text-right pr-5 -mt-12 self-end relative z-10'}>
          <CircularButton onClick={props.handleAddClick} />
        </div>
      )}
    </div>
  );
}
