import H2 from '../../../../../components/typography/H2';
import AlertListComponent from '../../../../../components/alerts/AlertListComponent';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PatientAlertsListTab = () => {
  const { t } = useTranslation('PatientAlertsListTab');
  const { id: patientId } = useParams<{ id: string }>();
  return (
    <>
      <div>
        <H2>
          <div className={'flex items-center justify-between'}>
            <span>{t('messages')}</span>
          </div>
        </H2>
      </div>
      <AlertListComponent patientId={Number(patientId)} />
    </>
  );
};
