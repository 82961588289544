import React from 'react';
import PatientEcgLayout from '../components/PatientEcgLayout';
import { useParams } from 'react-router-dom';
import EcgViewer from '../components/EcgViewer/index';
import { useEcgEventCommentCreate } from '../../../../../../hooks/mutations/useEcgEventCommentCreate';

export default function EcgViewerPage() {
  const {
    patientId: patientIdString,
    ecgRecordId,
    timestamp,
  } = useParams<{ patientId: string; ecgRecordId: string; timestamp?: string }>();
  const patientId = Number(patientIdString);
  const recordId = Number(ecgRecordId);
  const timestampValue = typeof timestamp === 'undefined' ? undefined : Number(timestamp);
  const commentCreateMutation = useEcgEventCommentCreate(patientId, recordId);

  return (
    <PatientEcgLayout>
      <EcgViewer
        patientId={patientId}
        recordId={recordId}
        timestamp={timestampValue}
        onCommentCreated={c =>
          commentCreateMutation.mutate({ timestamp: c.timestamp, text: c.comment, eventsGroup: c.eventType })
        }
      />
    </PatientEcgLayout>
  );
}
