import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline';
import { ThreadStatus } from '../../contracts/Threads';
import classNames from 'classnames';

interface Props {
  status: ThreadStatus;
  className?: string;
}

const iconClassNames = 'w-6 h-6 text-gray-600';

const ThreadStatusTag = ({ status, className = '' }: Props) => {
  switch (status) {
    case ThreadStatus.Closed:
      return <CheckIcon className={classNames(iconClassNames, className)} />;
    case ThreadStatus.OnHold:
      return <ClockIcon className={classNames(iconClassNames, className)} />;
    default:
      return null;
  }
};

export default ThreadStatusTag;
