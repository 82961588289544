import DashboardLayout from '../../../components/layouts/DashboardLayout';
import { useAnalyses } from '../../../hooks/queries/useAnalyses';
import * as React from 'react';
import { Column } from 'react-table';
import Table from '../../../components/common/Table/Table';
import H2 from '../../../components/typography/H2';
import { AnalysisGroupType, AnalysisParameterFormType, AnalysisParameterType } from '../../../contracts/Analysis';
import { MinMaxCell } from './components/MinMaxCell';
import EditIcon from '../../../components/icons/EditIcon';
import RemoveIcon from '../../../components/icons/RemoveIcon';
import { NullableType } from '../../../contracts/Common';
import Confirmation from '../../../components/common/Confirmation';
import { useAnalysesDelete } from '../../../hooks/mutations/analyses/useAnalysesDelete';
import { AnalysisParametersForm } from './components/AnalysisParametersForm';
import Dialog from '../../../components/dialog/Dialog';
import { useAnalysesCreate } from '../../../hooks/mutations/analyses/useAnalysesCreate';
import { successToast } from '../../../utils/successToast';
import { useAnalysesUpdate } from '../../../hooks/mutations/analyses/useAnalysesUpdate';
import { useTranslation } from 'react-i18next';

export const AnalysesList = () => {
  const { t } = useTranslation('AnalysesList');
  const [isOpen, setOpen] = React.useState<NullableType<AnalysisParameterType | number>>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<NullableType<AnalysisParameterType>>(null);
  const { data, isLoading } = useAnalyses();
  const analysisDeleteMutation = useAnalysesDelete();
  const analysisCreateMutation = useAnalysesCreate();
  const analysisUpdateMutation = useAnalysesUpdate();
  const [columns, analysisData] = React.useMemo(() => {
    const columns: Array<Column<AnalysisParameterType>> = [
      {
        Header: t('table.nameColHeader'),
        accessor: 'name',
      },
      {
        Header: t('table.descriptionColHeader'),
        accessor: 'description',
        width: 140,
      },
      {
        Header: t('table.unitColHeader'),
        accessor: 'unit',
        width: 140,
      },
      {
        Header: t('table.femaleMinColHeader'),
        accessor: 'femaleMin',
        width: 140,
        disableSortBy: true,
        Cell: ({ row }) => <MinMaxCell min={row.original.femaleMin} max={row.original.femaleMax} />,
      },
      {
        Header: t('table.maleMinColHeader'),
        accessor: 'maleMin',
        width: 140,
        disableSortBy: true,
        Cell: ({ row }) => <MinMaxCell min={row.original.maleMin} max={row.original.maleMax} />,
      },
      {
        Header: t('table.editColHeader'),
        accessor: 'id',
        disableSortBy: true,
        width: 30,
        Cell: ({ row }) => (
          <div className="flex my-1">
            <div>
              <EditIcon onClick={() => setOpen(row.original)} />
            </div>
            <div>
              <RemoveIcon onClick={() => setOpenDeleteConfirmation(row.original)} />
            </div>
          </div>
        ),
      },
    ];
    return [columns, data ?? []];
  }, [data, t]);

  function handleFormSubmit(values: AnalysisParameterFormType) {
    if (values.id) {
      analysisUpdateMutation.mutate(values, {
        onSuccess: () => {
          setOpen(null);
          successToast(t('updateSuccessToast'));
        },
      });
    } else {
      analysisCreateMutation.mutate(values, {
        onSuccess: () => {
          setOpen(null);
          successToast(t('createSuccessToast'));
        },
      });
    }
  }

  return (
    <DashboardLayout isLoading={isLoading && !analysisData}>
      {analysisData.map((tableData: AnalysisGroupType) => (
        <div className="mb-8" key={tableData.id}>
          <H2>{tableData.name}</H2>
          <Table
            data={tableData.parameters}
            columns={columns}
            pageSize={9999}
            handleAddClick={() => setOpen(tableData.id)}
          />
        </div>
      ))}
      <Confirmation
        id={0}
        open={!!openDeleteConfirmation}
        handleCancel={() => setOpenDeleteConfirmation(null)}
        handleSubmit={() => {
          analysisDeleteMutation.mutate(
            {
              id: openDeleteConfirmation!.id,
            },
            {
              onSuccess: () => {
                setOpenDeleteConfirmation(null);
                successToast(t('deleteSuccessToast'));
              },
            },
          );
        }}
      />
      <Dialog open={!!isOpen} handleCloseClick={() => setOpen(null)} widthAuto={true}>
        <AnalysisParametersForm
          handleCancel={() => setOpen(null)}
          handleSave={handleFormSubmit}
          analysisParameter={isOpen !== null ? isOpen : undefined}
          groups={analysisData.map((_analysis: AnalysisGroupType) => {
            return { id: _analysis.id, name: _analysis.name };
          })}
        />
      </Dialog>
    </DashboardLayout>
  );
};
