import * as React from 'react';
import ProgressBar from './ProgressBar';
import { TightPanel } from '../layouts/TightPanel';
import { NullableType } from '../../contracts/Common';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ScoreRange, ScoreRangeClass } from '../../contracts/Poll';

interface IScore {
  score: NullableType<number>;
  range: ScoreRange;
}

interface Props {
  title: string;
  questionsProgress: string;
  progress: number;
  handleClick?: () => void;
  isActive: boolean;
  description?: string;
  score?: IScore;
}

function Score({ score, isComplete }: { score?: IScore; isComplete: boolean }) {
  if (!isComplete) {
    return null;
  }
  if (!score || score.score === null) {
    return <CheckIcon className="block h-5 w-5 text-emerald-poll" aria-hidden="true" />;
  }
  return (
    <span
      className={`w-12 py-1 inline-flex rounded-full justify-center text-xs tracking-widest font-semibold ${
        ScoreRangeClass[score.range]
      }`}
    >
      {score.score}
    </span>
  );
}

export default function GroupCard(props: Props) {
  const cx = props.isActive ? 'bg-gray-100' : 'bg-white hover:bg-gray-50';
  const isComplete = props.progress >= 100;

  return (
    <TightPanel className={`mb-3 cursor-pointer ${cx}`} onClick={props.handleClick}>
      <>
        <div className="flex items-center font-medium">
          <div className="text-sm text-black py-1">{props.title}</div>
          <div className="w-12 flex justify-center ml-auto text-xs text-gray-500">
            {!isComplete && props.questionsProgress}
            <Score score={props.score} isComplete={isComplete} />
          </div>
        </div>
        {!isComplete && (
          <div className="flex flex-row mt-1 justify-between items-center">
            <ProgressBar progress={props.progress} className="mr-2 grow" />
          </div>
        )}
      </>
    </TightPanel>
  );
}
