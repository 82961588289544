import format from 'date-fns/format';
import { lt } from 'date-fns/esm/locale';
import isToday from 'date-fns/isToday';

type DateParsingFn = (date: string | Date) => string;

const parseDateAndDo =
  (callBack: (date: Date) => string): DateParsingFn =>
  (date: string | Date): string =>
    callBack(typeof date === 'string' ? new Date(date) : date);

export const getFullYear: DateParsingFn = parseDateAndDo(
  d => `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`,
);

export const getFullTime: DateParsingFn = parseDateAndDo(
  d =>
    `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)} ${(
      '0' + d.getHours()
    ).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}`,
);

export const getLocalDayMonth: DateParsingFn = parseDateAndDo(d =>
  format(d, 'LLL d', {
    locale: lt,
  }),
);

export const getDateWithoutTime: DateParsingFn = parseDateAndDo(d => format(d, 'yyyy-MM-dd'));

export const getDateWithTime: DateParsingFn = parseDateAndDo(d => format(d, 'yyyy-MM-dd HH:mm:ss'));

export const getTime: DateParsingFn = parseDateAndDo(d => format(d, 'HH:mm:ss'));

export const getTimeTodayOrDateOtherwise: DateParsingFn = parseDateAndDo(d =>
  format(d, isToday(d) ? 'HH:mm' : 'yyyy-MM-dd'),
);
