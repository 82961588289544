export const TRANSLATIONS_EN = {
  formats: {
    dateShort: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd HH:mm',
    time: 'HH:mm',
  },
  common: {
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    rename: 'Rename',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    back: 'Back',
    next: 'Next',
    close: 'Close',
    systemError: 'System error',
    patient: 'Patient',
    name: 'First name',
    surname: 'Last name',
    gender: 'Gender',
    clinic: 'Clinic',
    betaFeature: 'BETA',
    caseManager: 'Case manager',
  },
  validation: {
    required: 'This field is required',
    default: 'The value of this field is invalid',
    email: 'Email is invalid',
    fullDateFormat: 'Date should be in this format: yyyy-MM-dd',
  },
  Breadcrumbs: {
    Home: 'Home',
  },
  Confirmation: {
    title: 'Delete record?',
    yes: 'Yes, delete',
    no: 'Cancel',
  },
  DeleteConfirmationWithInput: {
    title: 'Delete record?',
    message: 'To confirm, type "yes"',
    yes: 'Delete',
    no: 'Cancel',
    requiredConfirmationText: 'yes',
  },
  LastSync: {
    updated: 'Synced: {{date}}',
  },
  ProfileMenu: {
    signOut: 'Sign out',
    language: 'Language',
    languages: {
      en: 'EN',
      lt: 'LT',
      pl: 'PL',
      ru: 'РУ',
    },
  },
  DashboardMainMenu: {
    patients: 'Patients',
    alerts: 'Alerts',
    notifications: 'Notifications',
    gadgets: 'Gadgets',
    doctors: 'Physicians',
    analyses: 'Analyses',
    help: 'Help',
  },
  AlertLevelValue: {
    info: 'Information',
    warning: 'Warning',
    danger: 'Danger',
  },
  AlertListComponent: {
    '7days': '7 days',
    '30days': '30 days',
    '90days': '90 days',
    unprocessed: 'Unprocessed',
    processed: 'Processed',
    timestamp: 'Time',
    metric: 'Metric',
    target: 'Target',
    message: 'Message',
    level: 'Level',
    isProcessed: 'State',
    edit: 'Edit',
    process: 'Process',
    patient: 'Patient',
    alertSubmitted: 'Alert processed',
    all: 'All',
  },
  MobileSupportBanner: {
    message: 'We recommend using Chrodis on a desktop PC',
  },
  SuperSearch: {
    saveFilter: 'Save filter',
    renameFilter: 'Rename filter',
    deleteFilter: 'Delete filter?',
    deleteFilterInquiry: 'Are you sure you want to delete this filter?',
    name: 'Name',
  },
  SuperSearchGlobalFilter: {
    search: 'Ieškoti...',
  },
  SuperSearchFilterPopover: {
    header: 'Filters',
  },
  DropFileInput: {
    invalidFormatToast: 'File {{fileName}} has an invalid format.',
    selectFile: 'Select file',
    orDragAndDropHere: 'or drag-and-drop it here',
    supportedFormats: 'PNG, JPG, DOC, or PDF up to 10MB',
    clearFile: 'Delete file',
  },
  MultiFileInput: {
    invalidFormatToast: 'File {{fileName}} has an invalid format.',
    selectFile: 'Select file',
    orDragAndDropHere: 'or drag-and-drop it here',
    supportedFormats: 'PNG, JPG, DOC, or PDF up to 10MB',
    clearFile: 'Delete file',
  },
  RichTextInput: {
    heading1: 'Heading 1',
    heading2: 'Heading 2',
    normal: 'Normal',
    boldAlt: 'Bold',
    italicAlt: 'Italic',
    underlineAlt: 'Underline',
    numberedListAlt: 'Numbered list',
    bulettedListAlt: 'Bulleted list',
  },
  ScaleDialogForm: {
    title: 'Poll scales',
  },
  useSuperSearchPersistence: {
    filterSaved: 'Filter "{{name}}" saved',
  },
  Alert: {
    info: 'Info',
    warning: 'Warning',
    danger: 'Danger',
  },
  DoctorRoleLabels: {
    familyDoctor: 'General practitioner',
    physician: 'Physician',
    expert: 'Specialist',
  },
  GadgetStateLabels: {
    ok: 'Operational',
    broken: 'Broken',
    lost: 'Lost',
  },
  MetricsTitleByType: {
    physicalActivity: 'Physical activity, min',
    hr: 'HR',
    hrMin: 'HR min',
    hrMax: 'HR max',
    steps: 'Steps',
    sleep: 'Sleep',
    weight: 'Weight, kg',
    bpSys: 'sAKS, mmHg',
    bpDia: 'dAKS, mmHg',
  },
  MetricsSleepCell: {
    dateHeader: 'Date',
    wakeHeader: 'Awake, hrs.',
    remHeader: 'REM, hrs.',
    lightHeader: 'Light, hrs.',
    deepHeader: 'Deep, hrs.',
    napHeader: 'Nap, hrs.',
    deep: 'Deep',
    light: 'Light',
    rem: 'REM',
    wake: 'Awake',
    nap: 'Nap',
    chartTooltipHoursFormat: '{{hours}} hrs. {{minutes}} min.',
    chartTooltipMinutesFormat: '{{minutes}} min.',
  },
  useEcgEventArtifactCreate: {
    successToast: 'Event marked as an artifact',
    errorToast: 'Could not mark event as an artifact',
  },
  useEcgEventArtifactDelete: {
    successToast: 'Event restored from artifact list',
    errorToast: 'Could not restore event from artifact list',
  },
  useEcgEventCommentCreate: {
    successToast: 'Comment created',
    errorToast: 'Could not delete the comment',
  },
  AlertForm: {
    table: {
      timestamp: 'Time',
      metric: 'Metric',
      target: 'Goal',
      message: 'Value',
      level: 'Level',
      patient: 'Patient',
    },
    title: 'Process alert',
    commentLabel: 'Comments',
    processSimilar: 'Process similar alerts',
    submit_zero: 'Process {{count}} alerts',
    submit_one: 'Process {{count}} alert',
    submit_other: 'Process {{count}} alerts',
  },
  AlertList: {
    breadcrumb: 'Alerts',
  },
  NotFound: {
    title: '404',
    heading: 'Page not found',
    message: 'Please check the address',
    back: 'Back to Home',
  },
  DoctorForm: {
    chooseRoleValidationError: 'Please select the role',
    dialogTitle: 'Physician',
    clinicIdLabel: 'Clinic',
    clinicIdPlaceholder: '- Select a clinic -',
    specialtyLabel: 'Specialty',
    roleLabel: 'Role',
    rolePlaceholder: '- Select a role -',
    emailLabel: 'Email',
    phoneLabel: 'Phone No.',
  },
  DoctorsList: {
    table: {
      nameColHeader: 'First name, Last name',
      specialtyColHeader: 'Specialty',
      clinicColHeader: 'Clinic',
      roleColHeader: 'Role',
      phoneColHeader: 'Phone No.',
      editColHeader: 'Edit',
    },
    addDoctorSuccessToast: 'Physician created successfully',
    updateDoctorSuccessToast: 'Physician updated successfully',
  },
  PatientNotAvailable: {
    header: 'Patient not found',
    message: 'This patient does not exist or does not participate in the research',
    linkText: 'Patient list',
  },
  PatientDetails: {
    patientPollTab: `Patient's card`,
    patientMetricsTab: 'Metrics',
    patientAlertsListTab: 'Alerts',
    threadListTab: 'Inquiries',
    patientAnamnesisTab: 'Anamnesis',
    patientHcpTab: 'Healthcare plan',
    patientFeedTab: 'Feed',
    patientAnalysesTab: 'Analyses',
    patientMedicationsTab: 'Medications',
    patientDocumentsTab: 'Documents',
    patientGadgetsTab: 'Gadgets',
    patientSettingsTab: 'Settings',
  },
  Faq: {
    header: 'Frequently Asked Questions',
    subHeader: `Cant find an answer? Message us`,
    inquiryEmail: 'pagalba@chrodis.lt',
    faqSection: {
      title: 'Chrodis account',
      q1Question: 'How to create a chrodis account?',
      q1Answer:
        'User accounts for patients and physicians are created by the system administrator. To request a new account, please message <a href="mailto:pagalba@chrodis.lt" class="text-primary">pagalba@chrodis.lt</a>',
      q2Question: 'How can I change my password?',
      q2Answer:
        'Sign out of Chrodis, then, in login screen, click <b>Forgotten password?</b> and follow the instructions.',
    },
  },
  AddGadgetForm: {
    title: 'Add a gadget',
    modelIdLabel: 'Model',
    modelIdPlaceholder: 'Select',
    seriesNoLabel: 'Serial No.',
    clinicIdLabel: 'Clinic',
    clinicIdPlaceholder: 'Select',
  },
  EditGadgetForm: {
    table: {
      patientColHeader: 'Patient',
      startDateColHeader: 'From',
      endDateColHeader: 'To',
    },
    clinicIdLabel: 'Clinic',
    clinicIdPlaceholder: 'Select',
    stateLabel: 'State',
  },
  GadgetList: {
    table: {
      modelColHeader: 'Model',
      seriesNoColHeader: 'Serial No.',
      clinicColHeader: 'Clinic',
      patientColHeader: 'Patient',
      stateColHeader: 'State',
      editColHeader: 'Edit',
    },
  },
  Notifications: {
    breadcrumb: 'Notifications',
    table: {
      dateColHeader: 'Date',
      patientColHeader: 'Patient',
      doctorColHeader: 'Physician',
      messageColHeader: 'Message',
      statusColHeader: ' ',
      superSearchTab0: 'All',
      superSearchTab1: 'Unanswered',
      superSearchTab2: 'Postponed',
    },
  },
  AnalysisParametersForm: {
    minError: 'Value too large',
    maxError: 'Value to small',
    groupIdError: 'Select a group',
    title: 'Analysis',
    nameLabel: 'Abbreviated name',
    groupIdLabel: 'Group',
    groupIdPlaceholder: '- Select group -',
    descriptionLabel: 'Description',
    unitLabel: 'Unit',
    femaleNormHeader: 'Female norm.',
    maleNormHeader: 'Male norm.',
    minLabel: 'Min',
    maxLabel: 'Max',
  },
  AnalysesList: {
    table: {
      nameColHeader: 'Analysis',
      descriptionColHeader: 'Description',
      unitColHeader: 'Unit',
      femaleMinColHeader: 'Female norm.',
      maleMinColHeader: 'Male norm.',
      editColHeader: 'Edit',
    },
    updateSuccessToast: 'Analysis updated',
    createSuccessToast: 'Analysis created',
    deleteSuccessToast: 'Analysis deleted',
  },
  PatientList: {
    table: {
      patientColHeader: 'Patient',
      ageColHeader: 'Age',
      diagnosesColHeader: 'Diagnoses',
      gadgetsColHeader: 'Gadgets',
      alarmsColHeader: 'Alarms',
      lastSyncColHeader: 'Last synced',
      clinicColHeader: 'Clinic',
      clinicIdColHeader: 'Clinic',
      familyDoctorIdColHeader: 'Physician',
      superSearchTab0: 'All',
      superSearchGlobalFilterLabel: 'Find a patient...',
    },
    breadcrumb: 'Patient',
  },
  PatientAlertsListTab: {
    messages: 'Alerts',
  },
  AddPatientAnalysisForm: {
    header: 'Add an analysis',
    dateLabel: 'Date',
    analysisIdLabel: 'Analysis type',
    selectAnalysisIdLabel: 'Select',
    parameterIdLabel: 'Analysis',
    selectParameterIdLabel: 'Select',
    valueLabel: 'Value',
  },
  ParametersDialog: {
    successMessage: 'Parameters updated',
    parametersLabel: 'Parameters',
  },
  ParametersDialogForm: {
    dialogHeader: 'Analysis parameters',
  },
  PatientAnalysesTab: {
    header: 'Analyses',
    tableHeader: 'Documents',
    dateHeader: 'Date',
    startDateHeader: 'Monitoring start',
    endDateHeader: 'Monitoring finish',
    parametersHeader: 'Parameter',
    unitHeader: 'pcs.',
    minHeader: 'Norm.',
    submitSuccessMessage: 'Analysis saved',
    ekgLabel: 'ECG',
  },
  AnamnesisDialog: {
    saveSuccessMessage: 'Anamnesis saved',
    dialogTitle: 'Anamnesis',
  },
  PatientAnamnesisTab: {
    anamnesisTableTitle: 'Anamnesis',
    documentsTableTitle: 'Documents',
  },
  AddPatientDiagnosisForm: {
    dialogTitle: 'Enter a diagnosis',
    diagnosisLabel: 'Diagnosis',
    selectDiagnosis: 'Select',
    commentsLabel: 'Comments',
  },
  DiagnosesSection: {
    codeHeader: 'Code',
    nameHeader: 'Diagnosis',
    commentsHeader: 'Comments',
    diagnosesTableTitle: 'Diagnoses',
    successMessage: 'Diagnosis {{code}} - {{name}} added',
  },
  EditPatientDiagnosisForm: {
    commentsLabel: 'Comments',
  },
  AddPatientDocumentForm: {
    dialogTitle: 'Documents',
    fileLabel: 'File',
    commentsLabel: 'Comments',
  },
  PatientDocuments: {
    fileNameLabel: 'Document',
    commentsLabel: 'Comments',
    uploadedOnUtcLabel: 'Uploaded',
    successMessage: 'Document has been uploaded',
  },
  ecgCommon: {
    missedPPeak: 'Missed P-peak',
    amount: '{{count}} time(/s)',
    xScaleTitle: 't, ms',
    yScaleTitle: 'N x 1000',
  },
  EcgDailyBreakdown: {
    cardTitle: '24-hour information',
  },
  SummaryTable: {
    pulse: 'HR',
    ventricularExtrasystoles: 'Ventricular extrasystoles',
    supraventricularExtrasystoles: 'Supraventricular extrasystoles',
    beats: 'Beats',
    total: 'Total',
    mean: 'Mean',
    pairs: 'Pairs',
    min: 'Min.',
    bigeminies: 'Bigeminies',
    max: 'Max.',
    trigeminies: 'Trigeminies',
    pauses: 'Noise',
  },
  EcgCommentModal: {
    title: 'Mark an event',
    submitLabel: 'Save',
    eventTypeLabel: 'Event type',
    selectEventType: '- Select an event type -',
    commentLabel: 'Comment',
  },
  Layers: {
    aligned: 'Aligned',
    denoised: 'Denoised',
    raw: 'Unprocessed',
    peaks: 'Peaks',
    millimeterPaper: 'Millimeter paper',
    signalsLabel: 'Signals:',
  },
  TimePickerPopOver: {
    hours: 'Hours',
    minutes: 'Minutes',
  },
  EcgHourlyBreakdown: {
    cardTitle: 'Hourly breakdown',
    time: 'Time',
    pulse: 'Pulse',
    skExtrasystoles: 'Ventricular extrasystoles',
    prExtrasystoles: 'Supraventricular extrasystoles',
    noise: 'Noise',
    fromHeader: 'From',
    toHeader: 'To',
    beatsHeader: 'Beats',
    meanHeader: 'Mean',
    minHeader: 'Min.',
    maxHeader: 'Max.',
    pausesHeader: 'Noises',
    totalHeader: 'Total',
    pairsHeader: 'Pairs',
    snrHeader: 'Signal-Noise Ratio',
  },
  PatientEcgComponent: {
    badTimestamp: 'Invalid timestamp',
  },
  PatientEcgLayout: {
    breadcrumbPatients: 'Patients',
    breadcrumbAnalyses: 'Analyses',
    breadcrumbECG: 'ECG',
    linkDashboard: 'Overview',
    linkAccidents: 'Incidents',
    linkViewer: 'Chart',
    linkIntervals: 'Intervals',
  },
  useEcgChunkSections: {
    alignedChunk: 'Aligned',
    denoisedChunk: 'Denoised',
    rawChunk: 'Raw',
  },
  EcgIntervalsPage: {
    rrInterval: 'R-R interval',
    prInterval: 'P-R interval',
    stSegment: 'S-T segment',
    qrsComplex: 'QRS complex',
  },
  EcgAccidentsPage: {
    unknownTypeLabel: 'Unknown type',
  },
  ecgEventGroups: {
    supraventricularBeats_one: 'Supraventricular beat',
    supraventricularBeats_other: 'Supraventricular beats',
    supraventricularBeats_few: 'Supraventricular beats',

    supraventricularCouplets_one: 'Supraventricular couplet',
    supraventricularCouplets_other: 'Supraventricular couplets',
    supraventricularCouplets_few: 'Supraventricular couplets',

    supraventricularBigeminies_one: 'Supraventricular bigeminy',
    supraventricularBigeminies_other: 'Supraventricular bigeminies',
    supraventricularBigeminies_few: 'Supraventricular bigeminies',

    supraventricularTrigeminies_one: 'Supraventricular trigeminy',
    supraventricularTrigeminies_other: 'Supraventricular trigeminies',
    supraventricularTrigeminies_few: 'Supraventricular trigeminies',

    ventricularBeats_one: 'Ventricular beat',
    ventricularBeats_other: 'Ventricular beats',
    ventricularBeats_few: 'Ventricular beats',

    ventricularCouplets_one: 'Ventricular couplet',
    ventricularCouplets_other: 'Ventricular couplets',
    ventricularCouplets_few: 'Ventricular couplets',

    ventricularBigeminies_one: 'Ventricular bigeminy',
    ventricularBigeminies_other: 'Ventricular bigeminies',
    ventricularBigeminies_few: 'Ventricular bigeminies',

    ventricularTrigeminies_one: 'Ventricular trigeminy',
    ventricularTrigeminies_other: 'Ventricular trigeminies',
    ventricularTrigeminies_few: 'Ventricular trigeminies',

    sinusBradycardia_one: 'Sinus bradycardia',
    sinusBradycardia_other: 'Sinus bradycardias',
    sinusBradycardia_few: 'Sinus bradycardias',

    sinusTachycardia_one: 'Sinus tachycardia',
    sinusTachycardia_other: 'Sinus tachycardias',
    sinusTachycardia_few: 'Sinus tachycardias',

    all: 'All',
    comment: 'With comments',
    artifact: 'Artifacts',
  },
  PatientFeedRecordForm: {
    dialogTitleEdit: 'Edit entry',
    dialogTitleCreate: 'New entry',
    createLabel: 'Add',
    commentsLabel: 'Comments',
    filesLabel: 'Files',
  },
  PatientFeedTab: {
    saveSuccessMessage: 'Entry saved',
    updateSuccessMessage: 'Entry updated',
    deleteSuccessMessage: 'Entry deleted',
  },
  PrintHealthCarePlanHeader: {
    healthcarePlan: 'Healthcare plan',
  },
  HcpDialog: {
    saveSuccessMessage: 'Plan saved',
  },
  PatientHcpTab: {
    updateSuccessMessage: 'Status updated',
    healthcarePlan: 'Healthcare plan',
    completed: 'Completed',
    print: 'Print',
  },
  useDynamicTables: {
    dateHeader: 'Date',
  },
  MetricCell: {
    goalLessThan: 'Goal less than {{targetMax}}',
    goalMoreThan: 'Goal more than {{targetMin}}',
  },
  MetricHistoryForm: {
    dialogTitle: 'Add a value',
    submitLabel: 'Add',
    dateLabel: 'Date',
    valueLabel: 'Value',
  },
  MetricsHistory: {
    oneWeek: '1 week',
    oneMonth: '1 month',
    threeMonths: '3 months',
    twelveMonths: '12 months',
    header: 'History',
    sleepCellName: 'Sleep',
  },
  PatientPoll: {
    pollsBreadcrumb: 'Polls',
    forPatient: 'For patient',
    forDoctor: 'For physician',
  },
  PatientPollQuestions: {
    unanswered: 'Unanswered',
    unknown: 'Unknown',
  },
  PatientPollTab: {
    dateHeader: 'Date',
    completionPercentHeader: 'Completion',
    commentsHeader: 'Comments',
    pollsTitle: 'Polls',
    deleteSuccessMessage: 'Poll deleted',
  },
  ScalesDialog: {
    scalesLabel: 'Scales',
  },
  ThreadActions: {
    replySuccessMessage: 'Inquiry answered',
    closeSuccessMessage: 'Thread closed',
    holdSuccessMessage: 'Thread on hold',
    answered: 'Answered',
    answer: 'Answer',
    hold: 'Put on hold',
    close: 'Close',
    threadMessageTitle: 'Answer an inquiry',
    threadMessageTextArea: 'Answer',
    threadMessageButton: 'Answer',
  },
  ThreadAssignForm: {
    title: 'Forward an inquiry',
  },
  ThreadListTab: {
    updateSuccessMessage: 'Inquiry updated',
  },
  ThreadMessageForm: {
    selectDoctorValidationMessage: 'Select a physician',
    dialogTitle: 'Edit a inquiry',
    questionLabel: 'Inquiry',
    assigneeLabel: 'Assignee',
  },
  PatientGadgetsTab: {
    deviceHeader: 'Gadges',
    seriesNoHeader: 'Serial No.',
    stateHeader: 'State',
    startDateHeader: 'Given',
    endDateHeader: 'Returned',
    returnLabel: 'Return',
  },
  PatientMedicationsTab: {
    nameHeader: 'Name',
    usageHeader: 'Usage',
    commentsHeader: 'Comments',
    editHeader: 'Edit',
  },
  HealthStepBar: {
    targetsMin: 'Min',
    targetsFrom: 'Optimal, from',
    targetsTo: 'Optimal, to',
    targetsMax: 'Max',
  },
  PatientSettingsTab: {
    targetsTableTitle: 'Targets',
    targetsNameHeader: 'Target',
    targetsMinHeader: 'Min',
    targetsFromHeader: 'Optimal from',
    targetsToHeader: 'Optimal to',
    targetsMaxHeader: 'Max',
    targetsEditHeader: 'Edit',
    devicesTableTitle: 'Accounts',
    devicesNameHeader: 'Account',
    devicesAccountIdHeader: 'Account ID',
    devicesIsAuthorizedHeader: 'Sign in',
    devicesAuthenticatedLabel: 'Authenticated',
    devicesLoginLabel: 'Authenticate',
    goalsUpdated: 'Goals updated',
  },
  AddPatientForm: {
    invalidWeight: 'Invalid weight',
    invalidHeight: 'Invalid height',
    dateOfBirthLabel: 'Date of birth',
    female: 'Female',
    male: 'Male',
    heightLabel: 'Height, cm',
    weightLabel: 'Weight, kg',
    emailLabel: 'Email',
    phoneNoLabel: 'Phone No.',
    selectClinic: '- Select a clinic -',
    selectCaseManager: '- Select case manager -',
    familyDoctorLabel: 'Family physician',
    selectFamilyDoctor: '- Select -',
  },
  AddPatientGadgetForm: {
    dialogTitle: 'Assign a device',
    serialNoLabel: 'Serial No.',
  },
  AddPatientMedicationForm: {
    dialogTitle: 'Medication',
    drugNameLabel: 'Name',
    drugUsageLabel: 'Usage',
    drugUsageDescription: 'How many times a day, at what times',
    notesLabel: 'Notes',
    notesDescription: 'Why is this prescribed? For how long?',
  },
  DoctorConsultationForm: {
    selectDoctorValidationMessage: 'Select a physician',
    doctorLabel: 'Physician',
    selectDoctorLabel: '- Select a physician -',
    questionLabel: 'Question',
    sendLabel: 'Send',
  },
  DoctorConsultationPopup: {
    consultationRequestSent: 'Consultation request sent',
    dialogTitle: 'Consultation physician-physician',
    consultLabel: 'Consult',
  },
  EditPatientTargetForm: {
    enterGoalsHeader: 'Enter goals',
    minLabel: 'Min',
    fromLabel: 'Optimal from',
    toLabel: 'Optimal to',
    maxLabel: 'Max',
  },
};
