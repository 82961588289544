import * as React from 'react';
import DashboardLayout from '../components/layouts/DashboardLayout';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation('NotFound');
  return (
    <DashboardLayout>
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 md:-mt-32">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary sm:text-5xl">{t('title')}</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{t('heading')}</h1>
                <p className="mt-1 text-base text-gray-500">{t('message')}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-pink-500 focus:outline-none"
                >
                  {t('back')}
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardLayout>
  );
};
