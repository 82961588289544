import * as React from 'react';
import { Field } from 'formik';
import { Descendant } from 'slate';
import * as Yup from 'yup';
import { RichTextInput } from '../../../../../components/form/RichTextInput';
import { SelectInput } from '../../../../../components/form/SelectInput';
import { DoctorType } from '../../../../../contracts/Doctors';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';
import { getValidationMessage } from '../../../../../utils/validation';
import { getLocalizedFullName } from '../../../../../translations/i18n';

interface IThreadMessageUpdateFormType {
  text: Descendant[];
  assigneeId?: number;
}

interface IThreadMessageUpdateForm {
  doctors: DoctorType[];
  assigneeId?: number;
  text?: Descendant[];
  labels?: {
    title?: string;
    assignee?: string;
    textarea?: string;
    button?: string;
  };
  handleCancel: () => void;
  handleSave: (values: IThreadMessageUpdateFormType) => void;
}

const ThreadMessageUpdateFormSchema = Yup.object().shape({
  text: Yup.array(),
  assigneeId: Yup.string().required(getValidationMessage('selectDoctorValidationMessage', 'ThreadMessageForm')),
});

export const ThreadMessageForm: React.FunctionComponent<IThreadMessageUpdateForm> = props => {
  const { t } = useTranslation('ThreadMessageForm');
  const initialValues: IThreadMessageUpdateFormType = {
    text: Array.isArray(props.text)
      ? props.text
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
    assigneeId: props.assigneeId,
  };

  return (
    <DialogForm
      title={props.labels?.title ?? t('dialogTitle')}
      initialValues={initialValues}
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
      validationSchema={ThreadMessageUpdateFormSchema}
      labels={{ submit: props.labels?.button ?? t('save', { ns: 'common' }) }}
    >
      <div className="sm:flex sm:items-start max-w-full flex-col">
        <div className="flex flex-col space-y-6 w-full dark:text-gray-300">
          <Field component={RichTextInput} name="text" label={props.labels?.textarea ?? t('questionLabel')} />
        </div>
        <div className="flex flex-col space-y-6 w-full dark:text-gray-300 mt-4">
          <Field component={SelectInput} name={'assigneeId'} label={props.labels?.assignee ?? t('assigneeLabel')}>
            {props.doctors.map(doctor => (
              <option value={doctor.id} key={doctor.id}>
                {getLocalizedFullName(doctor)}
              </option>
            ))}
          </Field>
        </div>
      </div>
    </DialogForm>
  );
};
