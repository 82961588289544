import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { PatientHcpResponse } from '../../contracts/Patient';

export function usePatientHcp(patientId: number) {
  const request = useRequest<PatientHcpResponse>();
  return useQuery<unknown, unknown, PatientHcpResponse>(
    ['patient-hcp', patientId],
    () => request(`/patients/${patientId}/plan`),
    {
      staleTime: 60 * 1000,
    },
  );
}
