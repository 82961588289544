export const TRANSLATIONS_RU = {
  formats: {
    dateShort: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd HH:mm',
    time: 'HH:mm',
  },
  common: {
    edit: 'Изменить',
    save: 'Сохранить',
    delete: 'Удалить',
    rename: 'Переименовать',
    yes: 'Да',
    no: 'Нет',
    cancel: 'Отмена',
    back: 'Назад',
    next: 'Далее',
    close: 'Закрыть',
    systemError: 'Системная ошибка',
    patient: 'Пациент',
    name: 'Имя',
    surname: 'Фамилия',
    gender: 'Пол',
    clinic: 'Клиника',
    betaFeature: 'BETA',
    caseManager: 'Менеджер по случаям',
  },
  validation: {
    required: 'Это поле обязательно для заполнения',
    default: 'Значение этого поля недействительно',
    email: 'Электронная почта недействительна',
    fullDateFormat: 'Дата должна быть в формате: yyyy-MM-dd',
  },
  Breadcrumbs: {
    Home: 'Главная',
  },
  Confirmation: {
    title: 'Удалить запись?',
    yes: 'Да, удалить',
    no: 'Отмена',
  },
  DeleteConfirmationWithInput: {
    title: 'Удалить запись?',
    message: 'Для подтверждения введите "да"',
    yes: 'Удалить',
    no: 'Отмена',
    requiredConfirmationText: 'да',
  },
  LastSync: {
    updated: 'Синхронизировано: {{date}}',
  },
  ProfileMenu: {
    signOut: 'Выход',
    language: 'Язык',
    languages: {
      en: 'EN',
      lt: 'LT',
      pl: 'PL',
      ru: 'РУ',
    },
  },
  DashboardMainMenu: {
    patients: 'Пациенты',
    alerts: 'Оповещения',
    notifications: 'Уведомления',
    gadgets: 'Гаджеты',
    doctors: 'Врачи',
    analyses: 'Анализы',
    help: 'Помощь',
  },
  AlertLevelValue: {
    info: 'Информация',
    warning: 'Предупреждение',
    danger: 'Опасность',
  },
  AlertListComponent: {
    '7days': '7 дней',
    '30days': '30 дней',
    '90days': '90 дней',
    unprocessed: 'Необработанные',
    processed: 'Обработанные',
    timestamp: 'Время',
    metric: 'Метрика',
    target: 'Цель',
    message: 'Сообщение',
    level: 'Уровень',
    isProcessed: 'Состояние',
    edit: 'Изменить',
    process: 'Обработать',
    patient: 'Пациент',
    alertSubmitted: 'Оповещение обработано',
    all: 'Все',
  },
  MobileSupportBanner: {
    message: 'Мы рекомендуем использовать Chrodis на персональном компьютере',
  },
  SuperSearch: {
    saveFilter: 'Сохранить фильтр',
    renameFilter: 'Переименовать фильтр',
    deleteFilter: 'Удалить фильтр?',
    deleteFilterInquiry: 'Вы уверены, что хотите удалить этот фильтр?',
    name: 'Название',
  },
  SuperSearchGlobalFilter: {
    search: 'Поиск...',
  },
  SuperSearchFilterPopover: {
    header: 'Фильтры',
  },
  DropFileInput: {
    invalidFormatToast: 'Файл {{fileName}} имеет недопустимый формат.',
    selectFile: 'Выберите файл',
    orDragAndDropHere: 'или перетащите его сюда',
    supportedFormats: 'PNG, JPG, DOC или PDF размером до 10 МБ',
    clearFile: 'Удалить файл',
  },
  MultiFileInput: {
    invalidFormatToast: 'Файл {{fileName}} имеет недопустимый формат.',
    selectFile: 'Выберите файл',
    orDragAndDropHere: 'или перетащите его сюда',
    supportedFormats: 'PNG, JPG, DOC или PDF размером до 10 МБ',
    clearFile: 'Удалить файл',
  },
  RichTextInput: {
    heading1: 'Заголовок 1',
    heading2: 'Заголовок 2',
    normal: 'Обычный',
    boldAlt: 'Жирный',
    italicAlt: 'Курсив',
    underlineAlt: 'Подчеркнутый',
    numberedListAlt: 'Нумерованный список',
    bulettedListAlt: 'Список с маркерами',
  },
  ScaleDialogForm: {
    title: 'Шкалы опроса',
  },
  useSuperSearchPersistence: {
    filterSaved: 'Фильтр "{{name}}" сохранен',
  },
  Alert: {
    info: 'Инфо',
    warning: 'Предупреждение',
    danger: 'Опасность',
  },
  DoctorRoleLabels: {
    familyDoctor: 'Терапевт',
    physician: 'Врач',
    expert: 'Специалист',
  },
  GadgetStateLabels: {
    ok: 'Рабочее состояние',
    broken: 'Неисправное',
    lost: 'Потеряное',
  },
  MetricsTitleByType: {
    physicalActivity: 'Физическая активность, мин',
    hr: 'Пульс',
    hrMin: 'Минимальный пульс',
    hrMax: 'Максимальный пульс',
    steps: 'Шаги',
    sleep: 'Сон',
    weight: 'Вес, кг',
    bpSys: 'Артериальное давление (систолическое), мм рт. ст.',
    bpDia: 'Артериальное давление (диастолическое), мм рт. ст.',
  },
  MetricsSleepCell: {
    dateHeader: 'Дата',
    wakeHeader: 'Бодрствование, часов',
    remHeader: 'REM-сон, часов',
    lightHeader: 'Легкий сон, часов',
    deepHeader: 'Глубокий сон, часов',
    napHeader: 'Сон днем, часов',
    deep: 'Глубокий',
    light: 'Легкий',
    rem: 'REM-сон',
    wake: 'Бодрствование',
    nap: 'Сон днем',
    chartTooltipHoursFormat: '{{hours}} ч. {{minutes}} мин.',
    chartTooltipMinutesFormat: '{{minutes}} мин.',
  },
  useEcgEventArtifactCreate: {
    successToast: 'Событие помечено как артефакт',
    errorToast: 'Не удалось пометить событие как артефакт',
  },
  useEcgEventArtifactDelete: {
    successToast: 'Событие восстановлено из списка артефактов',
    errorToast: 'Не удалось восстановить событие из списка артефактов',
  },
  useEcgEventCommentCreate: {
    successToast: 'Комментарий создан',
    errorToast: 'Не удалось удалить комментарий',
  },
  AlertForm: {
    table: {
      timestamp: 'Время',
      metric: 'Метрика',
      target: 'Цель',
      message: 'Значение',
      level: 'Уровень',
      patient: 'Пациент',
    },
    title: 'Обработать оповещение',
    commentLabel: 'Комментарии',
    processSimilar: 'Обработать аналогичные оповещения',
    submit_zero: 'Обработать {{count}} оповещений',
    submit_one: 'Обработать {{count}} оповещение',
    submit_other: 'Обработать {{count}} оповещений',
  },
  AlertList: {
    breadcrumb: 'Оповещения',
  },
  NotFound: {
    title: '404',
    heading: 'Страница не найдена',
    message: 'Пожалуйста, проверьте адрес',
    back: 'Вернуться на главную',
  },
  DoctorForm: {
    chooseRoleValidationError: 'Пожалуйста, выберите роль',
    dialogTitle: 'Врач',
    clinicIdLabel: 'Клиника',
    clinicIdPlaceholder: '- Выберите клинику -',
    specialtyLabel: 'Специальность',
    roleLabel: 'Роль',
    rolePlaceholder: '- Выберите роль -',
    emailLabel: 'Электронная почта',
    phoneLabel: 'Номер телефона',
  },
  DoctorsList: {
    table: {
      nameColHeader: 'Имя, Фамилия',
      specialtyColHeader: 'Специальность',
      clinicColHeader: 'Клиника',
      roleColHeader: 'Роль',
      phoneColHeader: 'Номер телефона',
      editColHeader: 'Изменить',
    },
    addDoctorSuccessToast: 'Врач успешно создан',
    updateDoctorSuccessToast: 'Врач успешно обновлен',
  },
  PatientNotAvailable: {
    header: 'Пациент не найден',
    message: 'Такой пациент не существует или не участвует в исследовании',
    linkText: 'Список пациентов',
  },
  PatientDetails: {
    patientPollTab: 'Карта пациента',
    patientMetricsTab: 'Метрики',
    patientAlertsListTab: 'Оповещения',
    threadListTab: 'Запросы',
    patientAnamnesisTab: 'Анамнез',
    patientHcpTab: 'План медицинской помощи',
    patientFeedTab: 'Лента',
    patientAnalysesTab: 'Анализы',
    patientMedicationsTab: 'Медикаменты',
    patientDocumentsTab: 'Документы',
    patientGadgetsTab: 'Гаджеты',
    patientSettingsTab: 'Настройки',
  },
  Faq: {
    header: 'Часто задаваемые вопросы',
    subHeader: 'Не можете найти ответ? Напишите нам',
    inquiryEmail: 'pagalba@chrodis.lt',
    faqSection: {
      title: 'Аккаунт Chrodis',
      q1Question: 'Как создать аккаунт Chrodis?',
      q1Answer:
        'Аккаунты для пациентов и врачей создаются администратором системы. Для запроса нового аккаунта напишите письмо на адрес <a href="mailto:pagalba@chrodis.lt" class="text-primary">pagalba@chrodis.lt</a>',
      q2Question: 'Как я могу изменить пароль?',
      q2Answer: 'Выйдите из Chrodis, затем на экране входа нажмите <b>Забыли пароль?</b> и следуйте инструкциям.',
    },
  },
  AddGadgetForm: {
    title: 'Добавить гаджет',
    modelIdLabel: 'Модель',
    modelIdPlaceholder: 'Выбрать',
    seriesNoLabel: 'Серийный номер',
    clinicIdLabel: 'Клиника',
    clinicIdPlaceholder: 'Выбрать',
  },
  EditGadgetForm: {
    table: {
      patientColHeader: 'Пациент',
      startDateColHeader: 'С',
      endDateColHeader: 'По',
    },
    clinicIdLabel: 'Клиника',
    clinicIdPlaceholder: 'Выбрать',
    stateLabel: 'Состояние',
  },
  GadgetList: {
    table: {
      modelColHeader: 'Модель',
      seriesNoColHeader: 'Серийный номер',
      clinicColHeader: 'Клиника',
      patientColHeader: 'Пациент',
      stateColHeader: 'Состояние',
      editColHeader: 'Изменить',
    },
  },
  Notifications: {
    breadcrumb: 'Уведомления',
    table: {
      dateColHeader: 'Дата',
      patientColHeader: 'Пациент',
      doctorColHeader: 'Врач',
      messageColHeader: 'Сообщение',
      statusColHeader: ' ',
      superSearchTab0: 'Все',
      superSearchTab1: 'Неотвеченные',
      superSearchTab2: 'Отложенные',
    },
  },
  AnalysisParametersForm: {
    minError: 'Слишком большое значение',
    maxError: 'Слишком маленькое значение',
    groupIdError: 'Выберите группу',
    title: 'Анализ',
    nameLabel: 'Сокращенное имя',
    groupIdLabel: 'Группа',
    groupIdPlaceholder: '- Выбрать группу -',
    descriptionLabel: 'Описание',
    unitLabel: 'Единица измерения',
    femaleNormHeader: 'Норма (ж)',
    maleNormHeader: 'Норма (м)',
    minLabel: 'Мин.',
    maxLabel: 'Макс.',
  },
  AnalysesList: {
    table: {
      nameColHeader: 'Анализ',
      descriptionColHeader: 'Описание',
      unitColHeader: 'Ед. изм.',
      femaleMinColHeader: 'Норма (ж)',
      maleMinColHeader: 'Норма (м)',
      editColHeader: 'Изменить',
    },
    updateSuccessToast: 'Анализ обновлен',
    createSuccessToast: 'Анализ создан',
    deleteSuccessToast: 'Анализ удален',
  },
  PatientList: {
    table: {
      patientColHeader: 'Пациент',
      ageColHeader: 'Возраст',
      diagnosesColHeader: 'Диагнозы',
      gadgetsColHeader: 'Гаджеты',
      alarmsColHeader: 'Сигналы',
      lastSyncColHeader: 'Последняя синхронизация',
      clinicColHeader: 'Клиника',
      clinicIdColHeader: 'Клиника',
      familyDoctorIdColHeader: 'Врач',
      superSearchTab0: 'Все',
      superSearchGlobalFilterLabel: 'Найти пациента...',
    },
    breadcrumb: 'Пациент',
  },
  PatientAlertsListTab: {
    messages: 'Сигналы',
  },
  AddPatientAnalysisForm: {
    header: 'Добавить анализ',
    dateLabel: 'Дата',
    analysisIdLabel: 'Тип анализа',
    selectAnalysisIdLabel: 'Выбрать',
    parameterIdLabel: 'Анализ',
    selectParameterIdLabel: 'Выбрать',
    valueLabel: 'Значение',
  },
  ParametersDialog: {
    successMessage: 'Параметры обновлены',
    parametersLabel: 'Параметры',
  },
  ParametersDialogForm: {
    dialogHeader: 'Параметры анализа',
  },
  PatientAnalysesTab: {
    header: 'Анализы',
    tableHeader: 'Документы',
    dateHeader: 'Дата',
    startDateHeader: 'Начало мониторинга',
    endDateHeader: 'Конец мониторинга',
    parametersHeader: 'Параметр',
    unitHeader: 'шт.',
    minHeader: 'Норма',
    submitSuccessMessage: 'Анализ сохранен',
    ekgLabel: 'ЭКГ',
  },
  AnamnesisDialog: {
    saveSuccessMessage: 'Анамнез сохранен',
    dialogTitle: 'Анамнез',
  },
  PatientAnamnesisTab: {
    anamnesisTableTitle: 'Анамнез',
    documentsTableTitle: 'Документы',
  },
  AddPatientDiagnosisForm: {
    dialogTitle: 'Внести диагноз',
    diagnosisLabel: 'Диагноз',
    selectDiagnosis: 'Выбрать',
    commentsLabel: 'Комментарии',
  },
  DiagnosesSection: {
    codeHeader: 'Код',
    nameHeader: 'Диагноз',
    commentsHeader: 'Комментарии',
    diagnosesTableTitle: 'Диагнозы',
    successMessage: 'Диагноз {{code}} - {{name}} добавлен',
  },
  EditPatientDiagnosisForm: {
    commentsLabel: 'Комментарии',
  },
  AddPatientDocumentForm: {
    dialogTitle: 'Документы',
    fileLabel: 'Файл',
    commentsLabel: 'Комментарии',
  },
  PatientDocuments: {
    fileNameLabel: 'Документ',
    commentsLabel: 'Комментарии',
    uploadedOnUtcLabel: 'Загружен',
    successMessage: 'Документ загружен',
  },
  ecgCommon: {
    missedPPeak: 'Пропущенный P-пик',
    amount: '{{count}} раз(/а)',
    xScaleTitle: 't, мс',
    yScaleTitle: 'N x 1000',
  },
  EcgDailyBreakdown: {
    cardTitle: 'Информация за 24 часа',
  },
  SummaryTable: {
    pulse: 'Пульс',
    ventricularExtrasystoles: 'Желудочковые экстрасистолы',
    supraventricularExtrasystoles: 'Сверхжелудочковые экстрасистолы',
    beats: 'Сокращения',
    total: 'Всего',
    mean: 'Среднее',
    pairs: 'Пары',
    min: 'Мин.',
    bigeminies: 'Бигеминии',
    max: 'Макс.',
    trigeminies: 'Тригеминии',
    pauses: 'Паузы',
  },
  EcgCommentModal: {
    title: 'Пометить событие',
    submitLabel: 'Сохранить',
    eventTypeLabel: 'Тип события',
    selectEventType: '- Выбрать тип события -',
    commentLabel: 'Комментарий',
  },
  Layers: {
    aligned: 'Выровнено',
    denoised: 'Обезшумлено',
    raw: 'Необработано',
    peaks: 'Пики',
    millimeterPaper: 'Миллиметровая бумага',
    signalsLabel: 'Сигналы:',
  },
  TimePickerPopOver: {
    hours: 'Часы',
    minutes: 'Минуты',
  },
  EcgHourlyBreakdown: {
    cardTitle: 'Почасовой разбор',
    time: 'Время',
    pulse: 'Пульс',
    skExtrasystoles: 'Желудочковые экстрасистолы',
    prExtrasystoles: 'Сверхжелудочковые экстасистолы',
    noise: 'Шум',
    fromHeader: 'От',
    toHeader: 'До',
    beatsHeader: 'Сокращения',
    meanHeader: 'Среднее',
    minHeader: 'Мин.',
    maxHeader: 'Макс.',
    pausesHeader: 'Паузы',
    totalHeader: 'Всего',
    pairsHeader: 'Пары',
    snrHeader: 'Соотношение сигнал-шум',
  },
  PatientEcgComponent: {
    badTimestamp: 'Недопустимый временной штамп',
  },
  PatientEcgLayout: {
    breadcrumbPatients: 'Пациенты',
    breadcrumbAnalyses: 'Анализы',
    breadcrumbECG: 'ЭКГ',
    linkDashboard: 'Обзор',
    linkAccidents: 'Инциденты',
    linkViewer: 'График',
    linkIntervals: 'Интервалы',
  },
  useEcgChunkSections: {
    alignedChunk: 'Выровнено',
    denoisedChunk: 'Обезшумлено',
    rawChunk: 'Необработано',
  },
  EcgIntervalsPage: {
    rrInterval: 'Интервал R-R',
    prInterval: 'Интервал P-R',
    stSegment: 'Сегмент S-T',
    qrsComplex: 'Комплекс QRS',
  },
  EcgAccidentsPage: {
    unknownTypeLabel: 'Неизвестный тип',
  },
  ecgEventGroups: {
    supraventricularBeats_one: 'Сверхжелудочковый сокращение',
    supraventricularBeats_other: 'Сверхжелудочковые сокращения',
    supraventricularBeats_few: 'Сверхжелудочковые сокращения',

    supraventricularCouplets_one: 'Сверхжелудочковая пара',
    supraventricularCouplets_other: 'Сверхжелудочковые пары',
    supraventricularCouplets_few: 'Сверхжелудочковые пары',

    supraventricularBigeminies_one: 'Сверхжелудочковый бигеминизм',
    supraventricularBigeminies_other: 'Сверхжелудочковые бигеминизмы',
    supraventricularBigeminies_few: 'Сверхжелудочковые бигеминизмы',

    supraventricularTrigeminies_one: 'Сверхжелудочковый тригеминизм',
    supraventricularTrigeminies_other: 'Сверхжелудочковые тригеминизмы',
    supraventricularTrigeminies_few: 'Сверхжелудочковые тригеминизмы',

    ventricularBeats_one: 'Желудочковое сокращение',
    ventricularBeats_other: 'Желудочковые сокращения',
    ventricularBeats_few: 'Желудочковые сокращения',

    ventricularCouplets_one: 'Желудочковая пара',
    ventricularCouplets_other: 'Желудочковые пары',
    ventricularCouplets_few: 'Желудочковые пары',

    ventricularBigeminies_one: 'Желудочковый бигеминизм',
    ventricularBigeminies_other: 'Желудочковые бигеминизмы',
    ventricularBigeminies_few: 'Желудочковые бигеминизмы',

    ventricularTrigeminies_one: 'Желудочковый тригеминизм',
    ventricularTrigeminies_other: 'Желудочковые тригеминизмы',
    ventricularTrigeminies_few: 'Желудочковые тригеминизмы',

    sinusBradycardia_one: 'Синусовая брадикардия',
    sinusBradycardia_other: 'Синусовые брадикардии',
    sinusBradycardia_few: 'Синусовые брадикардии',

    sinusTachycardia_one: 'Синусовая тахикардия',
    sinusTachycardia_other: 'Синусовые тахикардии',
    sinusTachycardia_few: 'Синусовые тахикардии',

    all: 'Все',
    comment: 'С комментариями',
    artifact: 'Артефакты',
  },
  PatientFeedRecordForm: {
    dialogTitleEdit: 'Редактировать запись',
    dialogTitleCreate: 'Новая запись',
    createLabel: 'Добавить',
    commentsLabel: 'Комментарии',
    filesLabel: 'Файлы',
  },
  PatientFeedTab: {
    saveSuccessMessage: 'Запись сохранена',
    updateSuccessMessage: 'Запись обновлена',
    deleteSuccessMessage: 'Запись удалена',
  },
  PrintHealthCarePlanHeader: {
    healthcarePlan: 'План здравоохранения',
  },
  HcpDialog: {
    saveSuccessMessage: 'План сохранен',
  },
  PatientHcpTab: {
    updateSuccessMessage: 'Статус обновлен',
    healthcarePlan: 'План здравоохранения',
    completed: 'Завершено',
    print: 'Печать',
  },
  useDynamicTables: {
    dateHeader: 'Дата',
  },
  MetricCell: {
    goalLessThan: 'Цель меньше {{targetMax}}',
    goalMoreThan: 'Цель больше {{targetMin}}',
  },
  MetricHistoryForm: {
    dialogTitle: 'Добавить значение',
    submitLabel: 'Добавить',
    dateLabel: 'Дата',
    valueLabel: 'Значение',
  },
  MetricsHistory: {
    oneWeek: '1 неделя',
    oneMonth: '1 месяц',
    threeMonths: '3 месяца',
    twelveMonths: '12 месяцев',
    header: 'История',
    sleepCellName: 'Сон',
  },
  PatientPoll: {
    pollsBreadcrumb: 'Опросы',
    forPatient: 'Для пациента',
    forDoctor: 'Для врача',
  },
  PatientPollQuestions: {
    unanswered: 'Без ответа',
    unknown: 'Неизвестно',
  },
  PatientPollTab: {
    dateHeader: 'Дата',
    completionPercentHeader: 'Завершение',
    commentsHeader: 'Комментарии',
    pollsTitle: 'Опросы',
    deleteSuccessMessage: 'Опрос удален',
  },
  ScalesDialog: {
    scalesLabel: 'Шкалы',
  },
  ThreadActions: {
    replySuccessMessage: 'Запрос отвечен',
    closeSuccessMessage: 'Запрос закрыт',
    holdSuccessMessage: 'Запрос приостановлен',
    answered: 'Отвечено',
    answer: 'Ответить',
    hold: 'Приостановить',
    close: 'Закрыть',
    threadMessageTitle: 'Ответить на запрос',
    threadMessageTextArea: 'Ответ',
    threadMessageButton: 'Ответить',
  },
  ThreadAssignForm: {
    title: 'Переслать запрос',
  },
  ThreadListTab: {
    updateSuccessMessage: 'Запрос обновлен',
  },
  ThreadMessageForm: {
    selectDoctorValidationMessage: 'Выберите врача',
    dialogTitle: 'Редактировать запрос',
    questionLabel: 'Запрос',
    assigneeLabel: 'Исполнитель',
  },
  PatientGadgetsTab: {
    deviceHeader: 'Устройства',
    seriesNoHeader: 'Серийный номер',
    stateHeader: 'Состояние',
    startDateHeader: 'Выдано',
    endDateHeader: 'Возвращено',
    returnLabel: 'Вернуть',
  },
  PatientMedicationsTab: {
    nameHeader: 'Название',
    usageHeader: 'Использование',
    commentsHeader: 'Комментарии',
    editHeader: 'Редактировать',
  },
  HealthStepBar: {
    targetsMin: 'Мин',
    targetsFrom: 'Оптимально, от',
    targetsTo: 'Оптимально, до',
    targetsMax: 'Макс',
  },
  PatientSettingsTab: {
    targetsTableTitle: 'Цели',
    targetsNameHeader: 'Цель',
    targetsMinHeader: 'Мин',
    targetsFromHeader: 'Оптимально, от',
    targetsToHeader: 'Оптимально, до',
    targetsMaxHeader: 'Макс',
    targetsEditHeader: 'Редактировать',
    devicesTableTitle: 'Аккаунты',
    devicesNameHeader: 'Аккаунт',
    devicesAccountIdHeader: 'ID аккаунта',
    devicesIsAuthorizedHeader: 'Авторизован',
    devicesAuthenticatedLabel: 'Аутентифицирован',
    devicesLoginLabel: 'Войти',
    goalsUpdated: 'Цели обновлены',
  },
  AddPatientForm: {
    invalidWeight: 'Недопустимый вес',
    invalidHeight: 'Недопустимый рост',
    dateOfBirthLabel: 'Дата рождения',
    female: 'Женский',
    male: 'Мужской',
    heightLabel: 'Рост, см',
    weightLabel: 'Вес, кг',
    emailLabel: 'Email',
    phoneNoLabel: 'Номер телефона',
    selectClinic: '- Выберите клинику -',
    selectCaseManager: '- Выберите кейс-менеджера -',
    familyDoctorLabel: 'Семейный врач',
    selectFamilyDoctor: '- Выберите -',
  },
  AddPatientGadgetForm: {
    dialogTitle: 'Назначить устройство',
    serialNoLabel: 'Серийный номер',
  },
  AddPatientMedicationForm: {
    dialogTitle: 'Лекарство',
    drugNameLabel: 'Название',
    drugUsageLabel: 'Использование',
    drugUsageDescription: 'Сколько раз в день, в какое время',
    notesLabel: 'Заметки',
    notesDescription: 'Зачем назначено? На сколько времени?',
  },
  DoctorConsultationForm: {
    selectDoctorValidationMessage: 'Выберите врача',
    doctorLabel: 'Врач',
    selectDoctorLabel: '- Выберите врача -',
    questionLabel: 'Вопрос',
    sendLabel: 'Отправить',
  },
  DoctorConsultationPopup: {
    consultationRequestSent: 'Запрос на консультацию отправлен',
    dialogTitle: 'Консультация врача-врача',
    consultLabel: 'Консультация',
  },
  EditPatientTargetForm: {
    enterGoalsHeader: 'Введите цели',
    minLabel: 'Мин',
    fromLabel: 'Оптимально, от',
    toLabel: 'Оптимально, до',
    maxLabel: 'Макс',
  },
};
