import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import PatientEcgLayout from '../components/PatientEcgLayout';
import { useEcgAccidents } from '../../../../../../hooks/queries/useEcgAccidents';
import EcgViewer from '../components/EcgViewer';
import EcgAccidentsGroupNav from '../components/EcgAccidentsNav/EcgAccidentsGroupNav';
import { EcgEvent, EcgEventGroup, REGULAR_EVENT_GROUPS } from '../utils/types';
import { useEcgEventArtifactCreate } from '../../../../../../hooks/mutations/useEcgEventArtifactCreate';
import { useEcgRecord } from '../../../../../../hooks/queries/useEcgRecord';
import { getEventGroup } from '../utils/utils';
import EcgAccidentsNav from '../components/EcgAccidentsNav';
import { useEcgEventArtifactDelete } from '../../../../../../hooks/mutations/useEcgEventArtifactDelete';
import classNames from 'classnames';
import { useEcgEventCommentCreate } from '../../../../../../hooks/mutations/useEcgEventCommentCreate';
import { useTranslation } from 'react-i18next';

export default function EcgAccidentsPage() {
  const { t } = useTranslation('EcgAccidentsPage');
  const { patientId: patientIdString, ecgRecordId } = useParams<{ patientId: string; ecgRecordId: string }>();
  const [startTimestamp, setStartTimestamp] = useState<number | undefined>(undefined);
  const [selectedEventGroup, setSelectedEventGroup] = useState<EcgEventGroup | null>(null);
  const patientId = Number(patientIdString);
  const recordId = Number(ecgRecordId);
  const { data: record } = useEcgRecord(patientId, recordId);
  const { data: events } = useEcgAccidents(patientId, recordId);
  const artifactCreateMutation = useEcgEventArtifactCreate(patientId, recordId);
  const artifactDeleteMutation = useEcgEventArtifactDelete(patientId, recordId);
  const commentCreateMutation = useEcgEventCommentCreate(patientId, recordId);
  const [groups, setGroups] = useState<EcgEventGroup[]>([]);
  const comment = useMemo(
    () => selectedEventGroup?.events.find(e => e.timestamp === startTimestamp)?.comment,
    [selectedEventGroup, startTimestamp],
  );

  const currentGroupsEvents = useMemo<number[]>(() => {
    // Show only non-deleted events when viewing regular groups. Show all events otherwise.
    if (REGULAR_EVENT_GROUPS.some(e => e.eventGroup === selectedEventGroup?.id)) {
      return selectedEventGroup?.events.filter(e => !e.isDeleted).map(e => e.timestamp) ?? [];
    }
    return selectedEventGroup?.events.map(e => e.timestamp) ?? [];
  }, [selectedEventGroup]);

  const handleArtifactUpdate = (event: EcgEvent) => {
    if (event?.isDeleted) {
      artifactDeleteMutation.mutate({ timestamp: event.timestamp });
    } else {
      artifactCreateMutation.mutate({ timestamp: event.timestamp, eventType: event.eventGroup });
    }
  };

  useEffect(() => {
    if (events && record) {
      const newGroups = getEventGroup(events, record, t);

      setGroups(newGroups);
      setSelectedEventGroup(prevState =>
        !!prevState ? newGroups.find(g => g.id === prevState.id)! : newGroups.find(g => g.id === 'all')!,
      );
    }
  }, [events, record, t]);

  useEffect(() => {
    setStartTimestamp(prevState => prevState ?? selectedEventGroup?.events[0].timestamp);
  }, [selectedEventGroup]);

  const handleEventGroupChange = (eventGroup?: string) => {
    if (!events) {
      return;
    }
    const newSelectedEventGroup = groups.find(group => group.id === eventGroup);
    if (!newSelectedEventGroup) {
      return;
    }

    setSelectedEventGroup(newSelectedEventGroup);
    setStartTimestamp(newSelectedEventGroup.events[0].timestamp);
  };

  return (
    <PatientEcgLayout>
      <div>
        <div className={'flex items-center '}>
          <div className={'mr-4'}>
            {groups && selectedEventGroup && (
              <EcgAccidentsGroupNav
                groups={groups}
                activeEventGroup={selectedEventGroup}
                onGroupClicked={handleEventGroupChange}
              />
            )}
          </div>
          {selectedEventGroup && startTimestamp && (
            <EcgAccidentsNav
              activeGroup={selectedEventGroup}
              activeTimestamp={startTimestamp}
              onActiveTimestampChanged={setStartTimestamp}
              isUpdating={artifactCreateMutation.isLoading}
              onDeleteClicked={handleArtifactUpdate}
            />
          )}
        </div>

        <div
          className={classNames(
            'px-4 py-1 border rounded mt-1 text-sm',
            !!comment ? 'border-gray-300' : 'border-transparent',
          )}
        >
          {!!comment ? <>Komentaras: {comment}</> : <>&nbsp;</>}
        </div>
      </div>
      {startTimestamp && (
        <EcgViewer
          patientId={patientId}
          recordId={recordId}
          timestamp={startTimestamp - 1000}
          temporalResolution={5}
          highlightValues={currentGroupsEvents}
          onCommentCreated={c =>
            commentCreateMutation.mutate({ timestamp: c.timestamp, text: c.comment, eventsGroup: c.eventType })
          }
        />
      )}
    </PatientEcgLayout>
  );
}
