import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ProfileMenu } from './ProfileMenu';
import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { PERMISSIONS, usePermission } from '../../hooks/usePermission';
import { PropsWithChildren, useMemo } from 'react';
import { useThreadInboxCount } from '../../hooks/queries/useThreadInboxCount';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const navigation: Array<{
  id?: string;
  translationKey: string;
  count?: number;
  path: string;
  permissions: Array<PERMISSIONS>;
}> = [
  {
    translationKey: 'patients',
    path: '/patients',
    permissions: [PERMISSIONS.MANAGE_ALL_PATIENTS, PERMISSIONS.MANAGE_LOCAL_PATIENTS],
  },
  {
    translationKey: 'alerts',
    path: '/alerts',
    permissions: [PERMISSIONS.MANAGE_ALL_PATIENTS, PERMISSIONS.MANAGE_LOCAL_PATIENTS],
  },
  {
    id: 'notifications',
    translationKey: 'notifications',
    path: '/notifications',
    permissions: [PERMISSIONS.MANAGE_ALL_PATIENTS, PERMISSIONS.MANAGE_LOCAL_PATIENTS],
  },
  {
    translationKey: 'gadgets',
    path: '/gadgets',
    permissions: [PERMISSIONS.MANAGE_ALL_DEVICES, PERMISSIONS.MANAGE_LOCAL_DEVICES],
  },
  {
    translationKey: 'doctors',
    path: '/doctors',
    permissions: [PERMISSIONS.MANAGE_USERS],
  },
  {
    translationKey: 'analyses',
    path: '/settings/analyses',
    permissions: [PERMISSIONS.MANAGE_ANALYSES],
  },
  { translationKey: 'help', path: '/help', permissions: [] },
];

function MenuItem({ path, children }: PropsWithChildren<{ path: string }>) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames(
          'text-gray-100 tracking-wide hover:bg-primary hover:text-white px-3 py-2 rounded-md text-sm font-medium inline-flex',
          isActive && 'bg-primary text-white',
        )
      }
    >
      {children}
    </NavLink>
  );
}

export const DashboardMainMenu = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { canAny } = usePermission();
  const { data } = useThreadInboxCount();
  const { t } = useTranslation('DashboardMainMenu');

  const menu = useMemo(
    () =>
      navigation.map(n =>
        n.id === 'notifications'
          ? {
              ...n,
              count: data?.count ?? 0,
            }
          : n,
      ),
    [data],
  );

  const isMenuVisible: boolean = !isLoading && isAuthenticated;
  return (
    <Disclosure as="nav" className="bg-header-dark z-50 relative print:hidden">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="shrink-0">
                  <img className="h-8 w-8" src="/logo.svg" alt="Workflow" />
                </div>
                {isMenuVisible && (
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {menu.map(item => {
                        return canAny(item.permissions) ? (
                          <MenuItem path={item.path} key={item.path}>
                            {t(item.translationKey)}
                            {item.count ? (
                              <div className="rounded-full ml-3 px-2 bg-primary text-gray-100 text-xs font-semibold flex justify-center items-center">
                                {item.count}
                              </div>
                            ) : null}
                          </MenuItem>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div>
                <ProfileMenu />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            {isMenuVisible && (
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {menu.map(item => (
                  <MenuItem path={item.path} key={item.path}>
                    {t(item.translationKey)}
                    {item.count ? (
                      <div className="bg-white text-primary rounded-full w-5 h-5 text-sm flex justify-center items-center ml-2">
                        {item.count}
                      </div>
                    ) : null}
                  </MenuItem>
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
