import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';

export function usePatientDocument(patientId: number, documentId: number | null) {
  const request = useRequest<Blob>();
  return useQuery<unknown, unknown, Blob>(
    ['patient-document', patientId, documentId],
    () => request(`/patients/${patientId}/documents/${documentId}`),
    {
      staleTime: 60 * 1000,
      enabled: false,
      onSuccess: blob => {
        let tab = window.open();
        if (tab) {
          tab.location.href = URL.createObjectURL(blob);
        }
      },
    },
  );
}
