import * as React from 'react';
import { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightOnRectangleIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { changeLanguage, supportedLanguagesList } from '../../translations/i18n';

const useMultilanguage = process.env.REACT_APP_USE_MULTILANGUAGE === '1';

export const ProfileMenu = () => {
  const { t, i18n } = useTranslation('ProfileMenu');
  const { isAuthenticated, logout, user, isLoading } = useAuth0();
  const queryClient = useQueryClient();

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return (
    <div className="ml-4 flex items-center md:ml-6">
      <img className="h-8 w-8 rounded-full mr-2" src={user?.picture} alt="" />
      {useMultilanguage && (
        <Menu as="div" className="relative inline-block text-left mx-2">
          <div>
            <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white hover:bg-pink-600">
              {t(`languages.${i18n.language}`)}
              <ChevronDownIcon className="-mr-1 h-4 w-4 text-white" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {supportedLanguagesList.map(language => (
                  <Menu.Item key={language}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full',
                        )}
                        onClick={() => changeLanguage(i18n, language)}
                      >
                        {t(`languages.${language}`)}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      <button
        type="button"
        className="inline-flex items-center px-3 py-2 shadow-sm text-sm font-medium rounded-md text-white hover:bg-primary focus:outline-none"
        onClick={() => {
          queryClient.invalidateQueries(['users-me']);
          logout({
            returnTo: window.location.origin,
          });
        }}
      >
        {t('signOut')}
        <ArrowRightOnRectangleIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};
