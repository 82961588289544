import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientMedicamentUpdateRequest } from '../../contracts/Patient';
import { PatientMedicamentType } from '../../contracts/Patient';

export function usePatientMedicationUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientMedicamentType>();
  return useMutation<PatientMedicamentType, unknown, PatientMedicamentUpdateRequest>(
    ({ patientId, id, ...data }) =>
      request(`/patients/${patientId}/medications/${id}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(
          ['patient-medication', request.patientId],
          (oldData: Array<PatientMedicamentType> = []) =>
            oldData.map(medicament => (medicament.id === data.id ? { ...data } : { ...medicament })),
        );
      },
    },
  );
}
