import H3 from '../typography/H3';
import { PropsWithChildren } from 'react';

interface Props {
  title: string;
  description?: string;
}

export default function SectionTitle({ title, children, description }: PropsWithChildren<Props>) {
  return (
    <div className={'mb-16'}>
      <H3 className={description ? 'sm:pb-0' : ''}>{title}</H3>
      {description ? <p className="mt-1 max-w-2xl text-sm text-gray-500 pb-4">{description}</p> : null}
      {children}
    </div>
  );
}
