import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { DoctorRequestType, DoctorType } from '../../../contracts/Doctors';

export function useDoctorsCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<DoctorType>();
  return useMutation<DoctorType, unknown, DoctorRequestType>(
    data =>
      request('/doctors', {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['doctors'], (oldData: Array<DoctorType> = []) => [...oldData, data]);
      },
    },
  );
}
