import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { EcgAccidents, EcgRecordResponse } from '../../contracts/Ecg';
import { successToast } from '../../utils/successToast';
import { errorToast } from '../../utils/errorToast';
import { useTranslation } from 'react-i18next';

interface Request {
  timestamp: number;
  eventType: string & keyof EcgAccidents;
}

export function useEcgEventArtifactCreate(patientId: number, recordId: number) {
  const { t } = useTranslation('useEcgEventArtifactCreate');
  const queryClient = useQueryClient();
  const request = useRequest<Request>();

  return useMutation<unknown, unknown, Request>(
    (req: Request) =>
      request(`/patients/${patientId}/ecgs/${recordId}/artifacts`, {
        body: JSON.stringify(req),
        method: 'POST',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData<EcgRecordResponse | undefined>(
          ['patient-ecg-record', `${patientId}${recordId}`],
          oldData => {
            if (oldData) {
              return {
                ...oldData,
                artifacts: [...oldData.artifacts, { timestamp: request.timestamp, eventsGroup: request.eventType }],
              };
            } else {
              return undefined;
            }
          },
        );
        successToast(t('successToast'));
      },
      onError: () => {
        errorToast(t('errorToast'));
      },
    },
  );
}
