export const TRANSLATIONS_PL = {
  formats: {
    dateShort: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd HH:mm',
    time: 'HH:mm',
  },
  common: {
    edit: 'Edytuj',
    save: 'Zapisz',
    delete: 'Usuń',
    rename: 'Zmień nazwę',
    yes: 'Tak',
    no: 'Nie',
    cancel: 'Anuluj',
    back: 'Wstecz',
    next: 'Dalej',
    close: 'Zamknij',
    systemError: 'Błąd systemu',
    patient: 'Pacjent',
    name: 'Imię',
    surname: 'Nazwisko',
    gender: 'Płeć',
    clinic: 'Klinika',
    betaFeature: 'BETA',
    caseManager: 'Kierownik przypadku',
  },
  validation: {
    required: 'To pole jest wymagane',
    default: 'Wartość tego pola jest nieprawidłowa',
    email: 'Email jest nieprawidłowy',
    fullDateFormat: 'Data powinna być w formacie: yyyy-MM-dd',
  },
  Breadcrumbs: {
    Home: 'Strona główna',
  },
  Confirmation: {
    title: 'Usunąć rekord?',
    yes: 'Tak, usuń',
    no: 'Anuluj',
  },
  DeleteConfirmationWithInput: {
    title: 'Usunąć rekord?',
    message: 'Aby potwierdzić, wpisz "tak"',
    yes: 'Usuń',
    no: 'Anuluj',
    requiredConfirmationText: 'tak',
  },
  LastSync: {
    updated: 'Synchronizacja: {{date}}',
  },
  ProfileMenu: {
    signOut: 'Wyloguj',
    language: 'Język',
    languages: {
      en: 'EN',
      lt: 'LT',
      pl: 'PL',
      ru: 'РУ',
    },
  },
  DashboardMainMenu: {
    patients: 'Pacjenci',
    alerts: 'Alerts',
    notifications: 'Powiadomienia',
    gadgets: 'Urządzenia',
    doctors: 'Lekarze',
    analyses: 'Analizy',
    help: 'Pomoc',
  },
  AlertLevelValue: {
    info: 'Informacja',
    warning: 'Ostrzeżenie',
    danger: 'Niebezpieczeństwo',
  },
  AlertListComponent: {
    '7days': '7 dni',
    '30days': '30 dni',
    '90days': '90 dni',
    unprocessed: 'Nieprzetworzone',
    processed: 'Przetworzone',
    timestamp: 'Czas',
    metric: 'Metryka',
    target: 'Cel',
    message: 'Wiadomość',
    level: 'Poziom',
    isProcessed: 'Stan',
    edit: 'Edytuj',
    process: 'Przetwórz',
    patient: 'Pacjent',
    alertSubmitted: 'Alert przetworzony',
    all: 'Wszystko',
  },
  MobileSupportBanner: {
    message: 'Zalecamy korzystanie z Chrodis na komputerze stacjonarnym',
  },
  SuperSearch: {
    saveFilter: 'Zapisz filtr',
    renameFilter: 'Zmień nazwę filtra',
    deleteFilter: 'Usunąć filtr?',
    deleteFilterInquiry: 'Czy na pewno chcesz usunąć ten filtr?',
    name: 'Nazwa',
  },
  SuperSearchGlobalFilter: {
    search: 'Szukaj...',
  },
  SuperSearchFilterPopover: {
    header: 'Filtry',
  },
  DropFileInput: {
    invalidFormatToast: 'Plik {{fileName}} ma nieprawidłowy format.',
    selectFile: 'Wybierz plik',
    orDragAndDropHere: 'lub przeciągnij i upuść tutaj',
    supportedFormats: 'PNG, JPG, DOC lub PDF do 10 MB',
    clearFile: 'Usuń plik',
  },
  MultiFileInput: {
    invalidFormatToast: 'Plik {{fileName}} ma nieprawidłowy format.',
    selectFile: 'Wybierz plik',
    orDragAndDropHere: 'lub przeciągnij i upuść tutaj',
    supportedFormats: 'PNG, JPG, DOC lub PDF do 10 MB',
    clearFile: 'Usuń plik',
  },
  RichTextInput: {
    heading1: 'Nagłówek 1',
    heading2: 'Nagłówek 2',
    normal: 'Normalny',
    boldAlt: 'Pogrubienie',
    italicAlt: 'Kursywa',
    underlineAlt: 'Podkreślenie',
    numberedListAlt: 'Lista numerowana',
    bulettedListAlt: 'Lista wypunktowana',
  },
  ScaleDialogForm: {
    title: 'Skale ankiet',
  },
  useSuperSearchPersistence: {
    filterSaved: 'Filtr "{{name}}" zapisany',
  },
  Alert: {
    info: 'Informacja',
    warning: 'Ostrzeżenie',
    danger: 'Niebezpieczeństwo',
  },
  DoctorRoleLabels: {
    familyDoctor: 'Lekarz rodzinny',
    physician: 'Lekarz',
    expert: 'Specjalista',
  },
  GadgetStateLabels: {
    ok: 'Operacyjne',
    broken: 'Uszkodzone',
    lost: 'Utracone',
  },
  MetricsTitleByType: {
    physicalActivity: 'Aktywność fizyczna, min',
    hr: 'HR',
    hrMin: 'HR min',
    hrMax: 'HR max',
    steps: 'Kroki',
    sleep: 'Sen',
    weight: 'Waga, kg',
    bpSys: 'Ciśnienie skurczowe, mmHg',
    bpDia: 'Ciśnienie rozkurczowe, mmHg',
  },
  MetricsSleepCell: {
    dateHeader: 'Data',
    wakeHeader: 'Czas przebudzenia, godz.',
    remHeader: 'REM, godz.',
    lightHeader: 'Światło, godz.',
    deepHeader: 'Głęboki sen, godz.',
    napHeader: 'Drzemka, godz.',
    deep: 'Głęboki',
    light: 'Światło',
    rem: 'REM',
    wake: 'Przebudzenie',
    nap: 'Drzemka',
    chartTooltipHoursFormat: '{{hours}} godz. {{minutes}} min.',
    chartTooltipMinutesFormat: '{{minutes}} min.',
  },
  useEcgEventArtifactCreate: {
    successToast: 'Zdarzenie oznaczone jako artefakt',
    errorToast: 'Nie można oznaczyć zdarzenia jako artefaktu',
  },
  useEcgEventArtifactDelete: {
    successToast: 'Zdarzenie przywrócone z listy artefaktów',
    errorToast: 'Nie można przywrócić zdarzenia z listy artefaktów',
  },
  useEcgEventCommentCreate: {
    successToast: 'Utworzono komentarz',
    errorToast: 'Nie można usunąć komentarza',
  },
  AlertForm: {
    table: {
      timestamp: 'Czas',
      metric: 'Metryka',
      target: 'Cel',
      message: 'Wiadomość',
      level: 'Poziom',
      patient: 'Pacjent',
    },
    title: 'Przetwarzanie alertu',
    commentLabel: 'Komentarze',
    processSimilar: 'Przetwórz podobne alerty',
    submit_zero: 'Przetwórz {{count}} alertów',
    submit_one: 'Przetwórz {{count}} alert',
    submit_other: 'Przetwórz {{count}} alertów',
  },
  AlertList: {
    breadcrumb: 'Alerty',
  },
  NotFound: {
    title: '404',
    heading: 'Strona nie znaleziona',
    message: 'Proszę sprawdzić adres',
    back: 'Powrót do strony głównej',
  },
  DoctorForm: {
    chooseRoleValidationError: 'Proszę wybrać rolę',
    dialogTitle: 'Lekarz',
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: '- Wybierz klinikę -',
    specialtyLabel: 'Specjalizacja',
    roleLabel: 'Rola',
    rolePlaceholder: '- Wybierz rolę -',
    emailLabel: 'Email',
    phoneLabel: 'Nr telefonu',
  },
  DoctorsList: {
    table: {
      nameColHeader: 'Imię i nazwisko',
      specialtyColHeader: 'Specjalizacja',
      clinicColHeader: 'Klinika',
      roleColHeader: 'Rola',
      phoneColHeader: 'Nr telefonu',
      editColHeader: 'Edytuj',
    },
    addDoctorSuccessToast: 'Lekarz utworzony pomyślnie',
    updateDoctorSuccessToast: 'Lekarz zaktualizowany pomyślnie',
  },
  PatientNotAvailable: {
    header: 'Pacjent nie znaleziony',
    message: 'Ten pacjent nie istnieje lub nie uczestniczy w badaniach',
    linkText: 'Lista pacjentów',
  },
  PatientDetails: {
    patientPollTab: 'Karta pacjenta',
    patientMetricsTab: 'Metryki',
    patientAlertsListTab: 'Alerty',
    threadListTab: 'Zapytania',
    patientAnamnesisTab: 'Anamneza',
    patientHcpTab: 'Plan opieki zdrowotnej',
    patientFeedTab: 'Aktywność',
    patientAnalysesTab: 'Analizy',
    patientMedicationsTab: 'Leki',
    patientDocumentsTab: 'Dokumenty',
    patientGadgetsTab: 'Urządzenia',
    patientSettingsTab: 'Ustawienia',
  },
  Faq: {
    header: 'Najczęściej zadawane pytania',
    subHeader: 'Nie możesz znaleźć odpowiedzi? Napisz do nas',
    inquiryEmail: 'pagalba@chrodis.lt',
    faqSection: {
      title: 'Konto Chrodis',
      q1Question: 'Jak utworzyć konto Chrodis?',
      q1Answer:
        'Konta użytkowników pacjentów i lekarzy są tworzone przez administratora systemu. Aby poprosić o nowe konto, proszę napisać na adres <a href="mailto:pagalba@chrodis.lt" class="text-primary">pagalba@chrodis.lt</a>',
      q2Question: 'Jak mogę zmienić hasło?',
      q2Answer:
        'Wyloguj się z Chrodis, a następnie, na ekranie logowania, kliknij <b>Przypomnij hasło?</b> i postępuj zgodnie z instrukcjami.',
    },
  },
  AddGadgetForm: {
    title: 'Dodaj urządzenie',
    modelIdLabel: 'Model',
    modelIdPlaceholder: 'Wybierz',
    seriesNoLabel: 'Numer seryjny',
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: 'Wybierz',
  },
  EditGadgetForm: {
    table: {
      patientColHeader: 'Pacjent',
      startDateColHeader: 'Od',
      endDateColHeader: 'Do',
    },
    clinicIdLabel: 'Klinika',
    clinicIdPlaceholder: 'Wybierz',
    stateLabel: 'Stan',
  },
  GadgetList: {
    table: {
      modelColHeader: 'Model',
      seriesNoColHeader: 'Numer seryjny',
      clinicColHeader: 'Klinika',
      patientColHeader: 'Pacjent',
      stateColHeader: 'Stan',
      editColHeader: 'Edytuj',
    },
  },
  Notifications: {
    breadcrumb: 'Powiadomienia',
    table: {
      dateColHeader: 'Data',
      patientColHeader: 'Pacjent',
      doctorColHeader: 'Lekarz',
      messageColHeader: 'Wiadomość',
      statusColHeader: ' ',
      superSearchTab0: 'Wszystkie',
      superSearchTab1: 'Nieudzielone odpowiedzi',
      superSearchTab2: 'Przełożone',
    },
  },
  AnalysisParametersForm: {
    minError: 'Wartość za mała',
    maxError: 'Wartość za duża',
    groupIdError: 'Wybierz grupę',
    title: 'Analiza',
    nameLabel: 'Skrót',
    groupIdLabel: 'Grupa',
    groupIdPlaceholder: '- Wybierz grupę -',
    descriptionLabel: 'Opis',
    unitLabel: 'Jednostka',
    femaleNormHeader: 'Norma dla kobiet',
    maleNormHeader: 'Norma dla mężczyzn',
    minLabel: 'Min',
    maxLabel: 'Maks',
  },
  AnalysesList: {
    table: {
      nameColHeader: 'Analiza',
      descriptionColHeader: 'Opis',
      unitColHeader: 'Jednostka',
      femaleMinColHeader: 'Norma dla kobiet',
      maleMinColHeader: 'Norma dla mężczyzn',
      editColHeader: 'Edytuj',
    },
    updateSuccessToast: 'Analiza zaktualizowana',
    createSuccessToast: 'Analiza utworzona',
    deleteSuccessToast: 'Analiza usunięta',
  },
  PatientList: {
    table: {
      patientColHeader: 'Pacjent',
      ageColHeader: 'Wiek',
      diagnosesColHeader: 'Diagnozy',
      gadgetsColHeader: 'Urządzenia',
      alarmsColHeader: 'Alerty',
      lastSyncColHeader: 'Ostatnia synchronizacja',
      clinicColHeader: 'Klinika',
      clinicIdColHeader: 'Klinika',
      familyDoctorIdColHeader: 'Lekarz rodziny',
      superSearchTab0: 'Wszystkie',
      superSearchGlobalFilterLabel: 'Znajdź pacjenta...',
    },
    breadcrumb: 'Pacjent',
  },
  PatientAlertsListTab: {
    messages: 'Alerty',
  },
  AddPatientAnalysisForm: {
    header: 'Dodaj analizę',
    dateLabel: 'Data',
    analysisIdLabel: 'Typ analizy',
    selectAnalysisIdLabel: 'Wybierz',
    parameterIdLabel: 'Analiza',
    selectParameterIdLabel: 'Wybierz',
    valueLabel: 'Wartość',
  },
  ParametersDialog: {
    successMessage: 'Parametry zaktualizowane',
    parametersLabel: 'Parametry',
  },
  ParametersDialogForm: {
    dialogHeader: 'Parametry analizy',
  },
  PatientAnalysesTab: {
    header: 'Analizy',
    tableHeader: 'Dokumenty',
    dateHeader: 'Data',
    startDateHeader: 'Początek monitorowania',
    endDateHeader: 'Zakończenie monitorowania',
    parametersHeader: 'Parametr',
    unitHeader: 'szt.',
    minHeader: 'Norma',
    submitSuccessMessage: 'Analiza zapisana',
    ekgLabel: 'EKG',
  },
  AnamnesisDialog: {
    saveSuccessMessage: 'Anamneza zapisana',
    dialogTitle: 'Anamneza',
  },
  PatientAnamnesisTab: {
    anamnesisTableTitle: 'Anamneza',
    documentsTableTitle: 'Dokumenty',
  },
  AddPatientDiagnosisForm: {
    dialogTitle: 'Wprowadź diagnozę',
    diagnosisLabel: 'Diagnoza',
    selectDiagnosis: 'Wybierz',
    commentsLabel: 'Komentarze',
  },
  DiagnosesSection: {
    codeHeader: 'Kod',
    nameHeader: 'Diagnoza',
    commentsHeader: 'Komentarze',
    diagnosesTableTitle: 'Diagnozy',
    successMessage: 'Diagnoza {{code}} - {{name}} dodana',
  },
  EditPatientDiagnosisForm: {
    commentsLabel: 'Komentarze',
  },
  AddPatientDocumentForm: {
    dialogTitle: 'Dokumenty',
    fileLabel: 'Plik',
    commentsLabel: 'Komentarze',
  },
  PatientDocuments: {
    fileNameLabel: 'Dokument',
    commentsLabel: 'Komentarze',
    uploadedOnUtcLabel: 'Przesłane',
    successMessage: 'Dokument został przesłany',
  },
  ecgCommon: {
    missedPPeak: 'Brakujący szczyt P',
    amount: '{{count}} razy(/s)',
    xScaleTitle: 't, ms',
    yScaleTitle: 'N x 1000',
  },
  EcgDailyBreakdown: {
    cardTitle: 'Informacje 24-godzinne',
  },
  SummaryTable: {
    pulse: 'HR',
    ventricularExtrasystoles: 'Ekstrasystole komorowe',
    supraventricularExtrasystoles: 'Ekstrasystole nadkomorowe',
    beats: 'Skoki',
    total: 'Suma',
    mean: 'Średnia',
    pairs: 'Pary',
    min: 'Min.',
    bigeminies: 'Bigeminia',
    max: 'Maks.',
    trigeminies: 'Trigeminia',
    pauses: 'Zakłócenia',
  },
  EcgCommentModal: {
    title: 'Oznacz zdarzenie',
    submitLabel: 'Zapisz',
    eventTypeLabel: 'Typ zdarzenia',
    selectEventType: '- Wybierz typ zdarzenia -',
    commentLabel: 'Komentarz',
  },
  Layers: {
    aligned: 'Wyrównane',
    denoised: 'Odszumione',
    raw: 'Surowe',
    peaks: 'Szczyty',
    millimeterPaper: 'Papier milimetrowy',
    signalsLabel: 'Sygnały:',
  },
  TimePickerPopOver: {
    hours: 'Godziny',
    minutes: 'Minuty',
  },
  EcgHourlyBreakdown: {
    cardTitle: 'Podział godzinowy',
    time: 'Czas',
    pulse: 'Puls',
    skExtrasystoles: 'Ekstrasystole komorowe',
    prExtrasystoles: 'Ekstrasystole nadkomorowe',
    noise: 'Zakłócenia',
    fromHeader: 'Od',
    toHeader: 'Do',
    beatsHeader: 'Skoki',
    meanHeader: 'Średnia',
    minHeader: 'Min.',
    maxHeader: 'Maks.',
    pausesHeader: 'Zakłócenia',
    totalHeader: 'Suma',
    pairsHeader: 'Pary',
    snrHeader: 'Stosunek sygnał-szum',
  },
  PatientEcgComponent: {
    badTimestamp: 'Nieprawidłowy znacznik czasowy',
  },
  PatientEcgLayout: {
    breadcrumbPatients: 'Pacjenci',
    breadcrumbAnalyses: 'Analizy',
    breadcrumbECG: 'ECG',
    linkDashboard: 'Przegląd',
    linkAccidents: 'Incydenty',
    linkViewer: 'Wykres',
    linkIntervals: 'Interwały',
  },
  useEcgChunkSections: {
    alignedChunk: 'Wyrównane',
    denoisedChunk: 'Odszumione',
    rawChunk: 'Surowe',
  },
  EcgIntervalsPage: {
    rrInterval: 'Interwał R-R',
    prInterval: 'Interwał P-R',
    stSegment: 'Segment ST',
    qrsComplex: 'Kompleks QRS',
  },
  EcgAccidentsPage: {
    unknownTypeLabel: 'Nieznany typ',
  },
  ecgEventGroups: {
    supraventricularBeats_one: 'Bicie nadkomorowe',
    supraventricularBeats_other: 'Bicia nadkomorowe',
    supraventricularBeats_few: 'Bicia nadkomorowe',

    supraventricularCouplets_one: 'Para nadkomorowa',
    supraventricularCouplets_other: 'Pary nadkomorowe',
    supraventricularCouplets_few: 'Pary nadkomorowe',

    supraventricularBigeminies_one: 'Bigeminia nadkomorowa',
    supraventricularBigeminies_other: 'Bigeminie nadkomorowe',
    supraventricularBigeminies_few: 'Bigeminie nadkomorowe',

    supraventricularTrigeminies_one: 'Trigeminia nadkomorowa',
    supraventricularTrigeminies_other: 'Trigeminie nadkomorowe',
    supraventricularTrigeminies_few: 'Trigeminie nadkomorowe',

    ventricularBeats_one: 'Bicie komorowe',
    ventricularBeats_other: 'Bicia komorowe',
    ventricularBeats_few: 'Bicia komorowe',

    ventricularCouplets_one: 'Para komorowa',
    ventricularCouplets_other: 'Pary komorowe',
    ventricularCouplets_few: 'Pary komorowe',

    ventricularBigeminies_one: 'Bigeminia komorowa',
    ventricularBigeminies_other: 'Bigeminie komorowe',
    ventricularBigeminies_few: 'Bigeminie komorowe',

    ventricularTrigeminies_one: 'Trigeminia komorowa',
    ventricularTrigeminies_other: 'Trigeminie komorowe',
    ventricularTrigeminies_few: 'Trigeminie komorowe',

    sinusBradycardia_one: 'Bradykardia zatokowa',
    sinusBradycardia_other: 'Bradykardie zatokowe',
    sinusBradycardia_few: 'Bradykardie zatokowe',

    sinusTachycardia_one: 'Tachykardia zatokowa',
    sinusTachycardia_other: 'Tachykardie zatokowe',
    sinusTachycardia_few: 'Tachykardie zatokowe',

    all: 'Wszystko',
    comment: 'Z komentarzami',
    artifact: 'Artefakty',
  },
  PatientFeedRecordForm: {
    dialogTitleEdit: 'Edytuj wpis',
    dialogTitleCreate: 'Nowy wpis',
    createLabel: 'Dodaj',
    commentsLabel: 'Komentarze',
    filesLabel: 'Pliki',
  },
  PatientFeedTab: {
    saveSuccessMessage: 'Wpis zapisany',
    updateSuccessMessage: 'Wpis zaktualizowany',
    deleteSuccessMessage: 'Wpis usunięty',
  },
  PrintHealthCarePlanHeader: {
    healthcarePlan: 'Plan opieki zdrowotnej',
  },
  HcpDialog: {
    saveSuccessMessage: 'Plan zapisany',
  },
  PatientHcpTab: {
    updateSuccessMessage: 'Status zaktualizowany',
    healthcarePlan: 'Plan opieki zdrowotnej',
    completed: 'Zakończony',
    print: 'Drukuj',
  },
  useDynamicTables: {
    dateHeader: 'Data',
  },
  MetricCell: {
    goalLessThan: 'Cel mniej niż {{targetMax}}',
    goalMoreThan: 'Cel więcej niż {{targetMin}}',
  },
  MetricHistoryForm: {
    dialogTitle: 'Dodaj wartość',
    submitLabel: 'Dodaj',
    dateLabel: 'Data',
    valueLabel: 'Wartość',
  },
  MetricsHistory: {
    oneWeek: '1 tydzień',
    oneMonth: '1 miesiąc',
    threeMonths: '3 miesiące',
    twelveMonths: '12 miesięcy',
    header: 'Historia',
    sleepCellName: 'Sen',
  },
  PatientPoll: {
    pollsBreadcrumb: 'Ankiety',
    forPatient: 'Dla pacjenta',
    forDoctor: 'Dla lekarza',
  },
  PatientPollQuestions: {
    unanswered: 'Bez odpowiedzi',
    unknown: 'Nieznane',
  },
  PatientPollTab: {
    dateHeader: 'Data',
    completionPercentHeader: 'Ukończenie',
    commentsHeader: 'Komentarze',
    pollsTitle: 'Ankiety',
    deleteSuccessMessage: 'Ankieta usunięta',
  },
  ScalesDialog: {
    scalesLabel: 'Skale',
  },
  ThreadActions: {
    replySuccessMessage: 'Odpowiedź na zapytanie',
    closeSuccessMessage: 'Zamknięto wątek',
    holdSuccessMessage: 'Wątek wstrzymany',
    answered: 'Odpowiedziano',
    answer: 'Odpowiedź',
    hold: 'Wstrzymaj',
    close: 'Zamknij',
    threadMessageTitle: 'Odpowiedz na zapytanie',
    threadMessageTextArea: 'Odpowiedź',
    threadMessageButton: 'Odpowiedz',
  },
  ThreadAssignForm: {
    title: 'Przekaż zapytanie dalej',
  },
  ThreadListTab: {
    updateSuccessMessage: 'Zapytanie zaktualizowane',
  },
  ThreadMessageForm: {
    selectDoctorValidationMessage: 'Wybierz lekarza',
    dialogTitle: 'Edytuj zapytanie',
    questionLabel: 'Zapytanie',
    assigneeLabel: 'Przydzielone do',
  },
  PatientGadgetsTab: {
    deviceHeader: 'Urządzenia',
    seriesNoHeader: 'Numer seryjny',
    stateHeader: 'Stan',
    startDateHeader: 'Przyznane',
    endDateHeader: 'Zwrócone',
    returnLabel: 'Zwróć',
  },
  PatientMedicationsTab: {
    nameHeader: 'Nazwa',
    usageHeader: 'Użycie',
    commentsHeader: 'Komentarze',
    editHeader: 'Edytuj',
  },
  HealthStepBar: {
    targetsMin: 'Min',
    targetsFrom: 'Optymalne, od',
    targetsTo: 'Optymalne, do',
    targetsMax: 'Maks',
  },
  PatientSettingsTab: {
    targetsTableTitle: 'Cele',
    targetsNameHeader: 'Cel',
    targetsMinHeader: 'Min',
    targetsFromHeader: 'Optymalne, od',
    targetsToHeader: 'Optymalne, do',
    targetsMaxHeader: 'Maks',
    targetsEditHeader: 'Edytuj',
    devicesTableTitle: 'Konta',
    devicesNameHeader: 'Konto',
    devicesAccountIdHeader: 'ID konta',
    devicesIsAuthorizedHeader: 'Zaloguj się',
    devicesAuthenticatedLabel: 'Uwierzytelnione',
    devicesLoginLabel: 'Zaloguj',
    goalsUpdated: 'Cele zaktualizowane',
  },
  AddPatientForm: {
    invalidWeight: 'Nieprawidłowa waga',
    invalidHeight: 'Nieprawidłowy wzrost',
    dateOfBirthLabel: 'Data urodzenia',
    female: 'Kobieta',
    male: 'Mężczyzna',
    heightLabel: 'Wzrost, cm',
    weightLabel: 'Waga, kg',
    emailLabel: 'Email',
    phoneNoLabel: 'Numer telefonu',
    selectClinic: '- Wybierz klinikę -',
    selectCaseManager: '- Wybierz menedżera przypadku -',
    familyDoctorLabel: 'Lekarz rodziny',
    selectFamilyDoctor: '- Wybierz -',
  },
  AddPatientGadgetForm: {
    dialogTitle: 'Przydziel urządzenie',
    serialNoLabel: 'Numer seryjny',
  },
  AddPatientMedicationForm: {
    dialogTitle: 'Lek',
    drugNameLabel: 'Nazwa',
    drugUsageLabel: 'Użycie',
    drugUsageDescription: 'Ile razy dziennie, o której godzinie',
    notesLabel: 'Notatki',
    notesDescription: 'Dlaczego jest przepisany? Na jak długo?',
  },
  DoctorConsultationForm: {
    selectDoctorValidationMessage: 'Wybierz lekarza',
    doctorLabel: 'Lekarz',
    selectDoctorLabel: '- Wybierz lekarza -',
    questionLabel: 'Pytanie',
    sendLabel: 'Wyślij',
  },
  DoctorConsultationPopup: {
    consultationRequestSent: 'Żądanie konsultacji wysłane',
    dialogTitle: 'Konsultacja lekarz-lekarz',
    consultLabel: 'Konsultacja',
  },
  EditPatientTargetForm: {
    enterGoalsHeader: 'Wprowadź cele',
    minLabel: 'Min',
    fromLabel: 'Optymalne, od',
    toLabel: 'Optymalne, do',
    maxLabel: 'Maks',
  },
};
