import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientRequest, PatientType } from '../../contracts/Patient';

export function usePatientUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientType>();
  return useMutation<PatientType, unknown, PatientRequest>(
    ({ id, ...data }) =>
      request(`/patients/${id}`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(['patients'], (oldData: Array<PatientType> = []) =>
          oldData.filter(item => (item.id === request.id ? { ...data } : { ...item })),
        );
        queryClient.setQueryData(['patients', request.id], data);
      },
    },
  );
}
