import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ScalesResponse } from '../../contracts/Poll';

export function useScales(patientId: number, pollId: number) {
  const request = useRequest<ScalesResponse>();
  return useQuery<unknown, unknown, ScalesResponse>(
    ['scales', pollId],
    () => request(`/patients/${patientId}/polls/${pollId}/scales`),
    {
      enabled: false,
    },
  );
}
