import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { NullableType } from '../../contracts/Common';
import { DocumentType, DocumentTypeEnum } from '../../contracts/Document';

type PatientDocumentRequest = {
  patientId: number;
  file: NullableType<File>;
  comments: NullableType<string>;
  documentType: DocumentTypeEnum;
};

export function usePatientDocumentCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<DocumentType>();
  return useMutation<DocumentType, unknown, PatientDocumentRequest>(
    ({ patientId, ...data }) => {
      let body = new FormData();
      if (data.file) {
        body.append('file', data.file);
      }
      if (data.comments) {
        body.append('comments', data.comments);
      }
      if (data.documentType) {
        body.append('documentType', String(data.documentType));
      }
      return request(`/patients/${patientId}/documents`, {
        body,
        method: 'POST',
      });
    },
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(
          ['patient-documents', request.patientId, request.documentType],
          (oldData: Array<DocumentType> = []) => [...oldData, data],
        );
      },
    },
  );
}
