import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DiagnosisType } from '../../contracts/Classifiers';

type PatientDiagnosisRemoveRequest = {
  patientId: number;
  diagnosisId: number;
};

export function usePatientDiagnosisDelete() {
  const queryClient = useQueryClient();
  const request = useRequest();
  return useMutation<unknown, unknown, PatientDiagnosisRemoveRequest>(
    ({ patientId, diagnosisId }) =>
      request(`/patients/${patientId}/diagnoses/${diagnosisId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['patients', request.patientId]);
        queryClient.setQueryData(['patient-diagnoses', request.patientId], (oldData: Array<DiagnosisType> = []) => {
          return oldData.filter(item => item.id !== request.diagnosisId);
        });
      },
    },
  );
}
