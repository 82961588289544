import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../useRequest';

interface Request {
  code: string;
  redirectUrl: string;
}

export const useWithingsCode = (patientId: number) => {
  const request = useRequest<Request>();
  return useMutation<unknown, unknown, Request>(data =>
    request(`/patients/${patientId}/withings`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  );
};
