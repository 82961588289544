import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { ClinicType } from '../../contracts/Clinic';

export function useClinics() {
  const request = useRequest<Array<ClinicType>>();
  return useQuery<unknown, unknown, Array<ClinicType>>(['clinics'], () => request('/classifiers/clinics'), {
    staleTime: 10 * 60 * 1000,
  });
}
