import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { PatientContentResponse } from '../../contracts/Patient';

type PatientAnamnesisUpdateRequest = {
  patientId: number;
  text: string;
};

export function usePatientAnamnesisUpdate() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientContentResponse>();
  return useMutation<PatientContentResponse, unknown, PatientAnamnesisUpdateRequest>(
    ({ patientId, ...data }) =>
      request(`/patients/${patientId}/anamnesis`, {
        body: JSON.stringify(data),
        method: 'PUT',
      }),
    {
      onSuccess: (data, request) => {
        queryClient.setQueryData(['patient-anamnesis', request.patientId], () => {
          return data;
        });
      },
    },
  );
}
