import { Cell } from 'react-table';
import { AnalysisType, PatientAnalysesParameter } from '../../../../../../contracts/Patient';
import * as React from 'react';
import { AnalysisUpdateType } from '../PatientAnalysesTab';

export const EditableCell = ({
  value: initialValue,
  row,
  column,
  handleUpdate,
  allowEdit,
}: Cell<Omit<PatientAnalysesParameter, 'analyses'> & { [_: string]: AnalysisType }, number> & {
  handleUpdate: (data: AnalysisUpdateType) => void;
  allowEdit: boolean;
}) => {
  const [value, setValue] = React.useState(initialValue ? String(initialValue) : '');
  const min = row.original.parameter.min;
  const max = row.original.parameter.max;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value === String(initialValue) || (initialValue === null && value === '')) {
      return;
    }
    // @ts-ignore passing name as additional prop to column
    const columnName: string = column.name; // format date_yyyy-MM-dd
    handleUpdate({
      analysisId: row.original[columnName]?.id ?? null,
      parameterId: row.original[columnName]?.id ? null : row.original.parameter.id,
      value,
      date: row.original[columnName]?.date ?? columnName.replace('date_', ''),
    });
  };

  React.useEffect(() => {
    if (initialValue !== null) {
      setValue(String(initialValue));
    }
  }, [initialValue]);

  const isInvalidText = React.useMemo(() => {
    if (!initialValue) {
      return '';
    }
    if (min && initialValue < min) {
      return 'text-primary';
    }
    if (max && initialValue > max) {
      return 'text-primary';
    }
    return '';
  }, [initialValue, min, max]);

  if (!allowEdit) {
    return <div className={'text-right'}>{value}</div>;
  }
  return (
    <input
      type={'number'}
      value={value}
      onChange={onChange}
      onBlur={handleSubmit}
      onKeyDown={e => e.key === 'Enter' && handleSubmit()}
      className={`-my-2 -mr-6 border-1 border-transparent rounded py-1 px-2 w-full text-right text-sm appearance-none cursor-pointer hover:border-gray-400 focus:border-gray-400 focus:ring-0 ${isInvalidText}`}
    />
  );
};
