import * as React from 'react';
import { ActiveTab, PollResponse, Scale, Section } from '../../../../../contracts/Poll';
import QuestionComponent from '../../../../../components/poll/QuestionComponent';
import { Switch } from '../../../../../components/form/Switch';
import { useTranslation } from 'react-i18next';

interface Props {
  poll: PollResponse;

  activeTab: ActiveTab;

  // section or scale id;
  activeGroup: Section | Scale | undefined;

  handleAnswerSubmit: (requestBody: { questionId: number; answer: any }) => void;
}

export default function PatientPollQuestions(props: Props) {
  const { t } = useTranslation('PatientPollQuestions');
  const [unansweredOnly, setUnansweredOnly] = React.useState<boolean>(false);

  const questions = React.useMemo(() => {
    if (unansweredOnly) {
      return props.poll.questions.filter(question => !question.isAnswered);
    } else {
      return props.poll.questions;
    }
  }, [unansweredOnly, props.poll.questions]);

  return (
    <div className="mb-8">
      <div className="flex justify-end">
        <Switch checked={unansweredOnly} onChange={setUnansweredOnly} />
        <span className="text-sm leading-5 text-gray-700 leading-5 pl-2">{t('unanswered')}</span>
      </div>
      <h3 className="text-xl leading-7 text-black font-semibold mb-8">{props.activeGroup?.name ?? 'unknown'}</h3>
      {questions.map(question => (
        <QuestionComponent
          {...question}
          number={question.number}
          handleAnswerSubmit={props.handleAnswerSubmit}
          key={question.id}
        />
      ))}
    </div>
  );
}
