import { TFunction } from 'i18next';

export type ChartTypes = 'line' | 'bar';
export type ChartAxis = 'left' | 'right';

export enum MetricsStatus {
  Ok = 0,
  Warning,
  Danger,
}

export enum MetricsType { // @todo keep updated
  Undefined = 0,
  ActivityMinutes,
  RestingHeartRate,
  MinHeartRate,
  MaxHeartRate,
  Steps,
  SleepScore,
  SleepDuration,
  SleepDisturbance,
  Weight,
  SystolicBloodPressure,
  DiastolicBloodPressure,
}

export const MetricsImageByType: Array<string> = [
  '',
  '/images/icons/Activity.svg',
  '/images/icons/Bpm.svg',
  '/images/icons/Max.svg',
  '/images/icons/Max.svg',
  '/images/icons/Steps.svg',
  '/images/icons/Sleep.svg',
  '/images/icons/Sleep.svg',
  '/images/icons/Sleep.svg',
  '/images/icons/Activity.svg',
  '/images/icons/Bpm.svg',
  '/images/icons/Bpm.svg',
];

export const getMetricsTitleByType = (t: TFunction): Array<string> => [
  '',
  t('physicalActivity', { ns: 'MetricsTitleByType' }),
  t('hr', { ns: 'MetricsTitleByType' }),
  t('hrMin', { ns: 'MetricsTitleByType' }),
  t('hrMax', { ns: 'MetricsTitleByType' }),
  t('steps', { ns: 'MetricsTitleByType' }),
  t('sleep', { ns: 'MetricsTitleByType' }),
  t('sleep', { ns: 'MetricsTitleByType' }),
  t('sleep', { ns: 'MetricsTitleByType' }),
  t('weight', { ns: 'MetricsTitleByType' }),
  t('bpSys', { ns: 'MetricsTitleByType' }),
  t('bpDia', { ns: 'MetricsTitleByType' }),
];

export type Metric = {
  axis: ChartAxis;
  graphType: ChartTypes;
  type: MetricsType;
  status: MetricsStatus;
  value: number;
  targetMin: number;
  targetMax: number;
};

export type MetricsResponseType = {
  date: Date;
  metrics: Array<Metric>;
};

export type MetricDataPointType = {
  date: Date;
  value: number;
};

export type MetricDataPointRequestType = {
  value: number | '';
  date: string;
  metricType: MetricsType;
};
export type HistoryMetricType = {
  metricType: MetricsType;
  axis: ChartAxis;
  graphType: ChartTypes;

  targetMax: number | null;
  targetMin: number | null;
  name: string;
  unit: string;
};
export type MetricHistoryType = {
  history: Array<MetricDataPointType>;
  metric: HistoryMetricType;
};

export type MetricGroupType = {
  chartType: ChartTypes;
  name: string;
  metrics: Array<MetricHistoryType>;
};
export type MetricsHistoryResponseType = Array<MetricGroupType>;

export type DaysFilterType = 7 | 30 | 90 | 365;

export interface RawMetricDataPointType {
  date: string;
  value: number;
}

export interface SleepMetricsResponse {
  date: string;
  days: number;
  deep: RawMetricDataPointType[];
  light: RawMetricDataPointType[];
  nap: RawMetricDataPointType[];
  rem: RawMetricDataPointType[];
  wake: RawMetricDataPointType[];
}
