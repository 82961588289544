import { CircleInitials } from '../patient/CircleInitials';
import PersonLine from '../common/PersonLine';
import { format } from 'date-fns';
import EditIcon from '../icons/EditIcon';
import RemoveIcon from '../icons/RemoveIcon';
import DocumentLink from '../common/DocumentLink';
import * as React from 'react';
import { DocumentType } from '../../contracts/Document';
import SerializeToHtml from '../richText/SerializeToHtml';

interface Props {
  name: string;
  headline: string;
  date?: Date;
  content?: string;
  documents?: Array<DocumentType>;
  patientId?: number; // for documents
  isLast: boolean;
  isActionsVisible: boolean;
  handleEditClick?: () => void;
  handleDeleteClick?: () => void;
}

interface IDocuments {
  documents?: Array<DocumentType>;
  patientId?: number; // for documents
}

function Documents({ documents, patientId }: IDocuments) {
  if (!patientId) {
    return null;
  }
  if (!documents || documents.length <= 0) {
    return null;
  }
  return (
    <div className={'mt-4 text-sm text-gray-500'}>
      {documents.map(document => (
        <DocumentLink {...document} patientId={patientId} key={document.id} />
      ))}
    </div>
  );
}

export default function FeedItem(props: Props) {
  return (
    <li>
      <div className="relative pb-10">
        {!props.isLast ? (
          <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600" aria-hidden="true" />
        ) : null}
        <div className="relative flex items-start space-x-3 group">
          <>
            <div className="relative">
              <CircleInitials text={props.name} className="ring-8 ring-white dark:ring-gray-700" />
            </div>
            <div className="min-w-0 flex-1">
              <div className="mb-4 flex justify-between">
                <div>
                  <div className="mb-2">
                    <PersonLine headline={props.headline}>{props.name}</PersonLine>
                  </div>
                  {props.date && (
                    <div className="text-sm text-gray-600">{format(new Date(props.date), 'yyyy-MM-dd HH:mm')}</div>
                  )}
                </div>
                {props.isActionsVisible ? (
                  <div className="lg:group-hover:flex self-end flex lg:hidden">
                    {typeof props.handleEditClick === 'function' ? <EditIcon onClick={props.handleEditClick} /> : null}
                    {typeof props.handleDeleteClick === 'function' ? (
                      <RemoveIcon onClick={props.handleDeleteClick} />
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="text-sm text-gray-800 dark:text-gray-300">
                {props.content && (
                  <p className="w-full">
                    {props.content.indexOf('[{') === 0 ? <SerializeToHtml content={props.content} /> : props.content}
                  </p>
                )}
                <Documents documents={props.documents} patientId={props.patientId} />
              </div>
            </div>
          </>
        </div>
      </div>
    </li>
  );
}
