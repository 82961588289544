export function stringToHslColor(str: string, saturation: number = 30, lightness: number = 75): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let h = hash % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

// used for graphs
export const COLORS = [
  {
    backgroundColor: 'rgb(219,39,119)',
    borderColor: 'rgb(219,39,119)',
    pointBorderColor: 'rgb(219,39,119, .5)',
    rangeColor: 'rgb(219,39,119, .1)',
  },
  {
    backgroundColor: 'rgb(56,189,248)',
    borderColor: 'rgb(56,189,248)',
    pointBorderColor: 'rgb(56,189,248, .5)',
    rangeColor: 'rgba(56,189,248, .1)',
  },
  {
    backgroundColor: 'rgb(14,165,233)',
    borderColor: 'rgb(14,165,233)',
    pointBorderColor: 'rgb(14,165,233, .5)',
    rangeColor: 'rgba(14,165,233, .1)',
  },
  {
    backgroundColor: 'rgb(2,132,199)',
    borderColor: 'rgb(2,132,199)',
    rangeColor: 'rgba(2,132,199, .1)',
  },
  {
    backgroundColor: 'rgb(249,168,212)',
    borderColor: 'rgb(249,168,212)',
  },
  {
    backgroundColor: 'rgb(213,39,219)',
    borderColor: 'rgba(213,39,219, .7)',
  },
  {
    backgroundColor: 'rgb(180,219,39)',
    borderColor: 'rgba(180,219,39, .7)',
  },
  {
    backgroundColor: 'rgb(219,81,39)',
    borderColor: 'rgba(219,81,39, .7)',
  },
  {
    backgroundColor: 'rgb(99,39,219)',
    borderColor: 'rgba(99,39,219, .7)',
  },
];
