import { successToast } from '../../../../../utils/successToast';

import { useFiltersQuery } from '../../../../../hooks/queries/useFiltersQuery';
import { useFiltersCreate } from '../../../../../hooks/mutations/useFiltersCreate';
import { useFiltersDelete } from '../../../../../hooks/mutations/useFiltersDelete';
import { useFiltersUpdate } from '../../../../../hooks/mutations/useFiltersUpdate';

import {
  mapFiltersToSuperSearchTabs,
  mapSuperSearchTabToFilterPostRequest,
  mapSuperSearchTabToFilterPutRequest,
  SuperSearchTab,
} from '../SuperSearchUtils';
import { useMemo } from 'react';
import { FilterEntity } from '../../../../../contracts/Filters';
import { useTranslation } from 'react-i18next';

export const useSuperSearchPersistence = (table?: string) => {
  const { t } = useTranslation('useSuperSearchPersistence');
  const filtersGetQuery = useFiltersQuery(table);
  const filtersCreateMutation = useFiltersCreate(table);
  const filtersUpdateMutation = useFiltersUpdate();
  const filtersDeleteMutation = useFiltersDelete();

  const handleSuccessfulSave = useMemo(
    () => (afterSave: (data: FilterEntity) => void) => ({
      onSuccess: (data: FilterEntity) => {
        successToast(t('filterSaved', { name: data.name }));
        afterSave(data);
      },
    }),
    [t],
  );

  const handleTabCreate = useMemo(
    () => (name: string, activeTab: SuperSearchTab, afterSave: (entity: FilterEntity) => void) =>
      filtersCreateMutation.mutate(
        mapSuperSearchTabToFilterPostRequest({ ...activeTab, name }),
        handleSuccessfulSave(afterSave),
      ),
    [filtersCreateMutation, handleSuccessfulSave],
  );

  const handleTabUpdate = useMemo(
    () => (name: string, activeTab: SuperSearchTab, afterSave: (entity: FilterEntity) => void) =>
      filtersUpdateMutation.mutate(
        mapSuperSearchTabToFilterPutRequest({ ...activeTab, name }),
        handleSuccessfulSave(afterSave),
      ),
    [filtersUpdateMutation, handleSuccessfulSave],
  );

  const handleTabDelete = useMemo(
    () => (activeTab: SuperSearchTab, afterDelete: () => void) => {
      if (activeTab.persisted) {
        filtersDeleteMutation.mutate(activeTab.id, { onSuccess: afterDelete });
      }
    },
    [filtersDeleteMutation],
  );

  const handleTabRename = useMemo(
    () => (name: string, originalTab: SuperSearchTab, afterRename: () => void) =>
      filtersUpdateMutation.mutate(
        mapSuperSearchTabToFilterPutRequest({ ...originalTab, name }),
        handleSuccessfulSave(afterRename),
      ),
    [filtersUpdateMutation, handleSuccessfulSave],
  );

  const persistedFilters = useMemo(
    () => (filtersGetQuery.data ? mapFiltersToSuperSearchTabs(filtersGetQuery.data) : []),
    [filtersGetQuery.data],
  );

  return {
    persistedFilters,
    handleTabUpdate,
    handleTabCreate,
    handleTabRename,
    handleTabDelete,
  };
};
