import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { MedicationType } from '../../contracts/Classifiers';

export function useMedications() {
  const request = useRequest<Array<MedicationType>>();
  return useQuery<unknown, unknown, Array<MedicationType>>(['medications'], () => request('/classifiers/medications'), {
    staleTime: 10 * 60 * 1000,
  });
}
