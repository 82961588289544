import { ReactNode } from 'react';
import { DialogFormContainer } from './DialogFormContainer';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import DialogFormControls from './DialogFormControls';

interface Props<T> {
  title: string;
  initialValues: FormikValues & T;
  onSubmit: (values: FormikValues & T) => void;
  onCancel: () => void;
  validationSchema?: any;
  children?: ReactNode | ((formikProps: FormikProps<FormikValues & T>) => React.ReactNode);
  labels?: { submit?: string; cancel?: string };
}

export default function DialogForm<T>({
  title,
  initialValues,
  onSubmit,
  onCancel,
  validationSchema,
  labels,
  children,
}: Props<T>) {
  return (
    <DialogFormContainer title={title}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {formikProps => (
          <Form>
            {typeof children === 'function' ? children(formikProps) : children}
            <DialogFormControls
              isSubmitting={formikProps.isSubmitting}
              submitLabel={labels?.submit}
              cancelLabel={labels?.cancel}
              onCancelClicked={onCancel}
            />
          </Form>
        )}
      </Formik>
    </DialogFormContainer>
  );
}
