import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { AnalysisParameterFormType } from '../../../contracts/Analysis';

export function useAnalysesCreate() {
  const queryClient = useQueryClient();
  const request = useRequest<AnalysisParameterFormType>();
  return useMutation<unknown, unknown, AnalysisParameterFormType>(
    data =>
      request('/analyses/parameters', {
        body: JSON.stringify(data),
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['analyses']);
        queryClient.invalidateQueries(['patient-analyses']);
      },
    },
  );
}
