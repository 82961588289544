import * as React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';

import { FieldProps } from 'formik';
import { BaseInput } from '../BaseInput';
import { PropsWithChildren } from 'react';

interface IListbox {
  label?: string;
  listboxValue: JSX.Element;
}

export const ListboxInput = ({
  field,
  form,
  children,
  listboxValue,
  ...props
}: PropsWithChildren<FieldProps & IListbox>) => {
  return (
    <BaseInput field={field} form={form} label={props.label}>
      <Listbox
        value={field.value}
        onChange={value => {
          form.setFieldValue(field.name, value);
        }}
      >
        {({ open }) => (
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
              {listboxValue}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </BaseInput>
  );
};
