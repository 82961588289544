import * as React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { TextArea } from '../../../../../components/form/TextArea';
import { DropFileInput } from '../../../../../components/form/DropFileInput';
import { NullableType } from '../../../../../contracts/Common';
import { DocumentType } from '../../../../../contracts/Document';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

export type AddPatientDocumentFormType = {
  file: NullableType<File> | string;
  comments: NullableType<string>;
  id: number;
};

interface IAddPatientDocumentForm {
  handleCancel: () => void;
  handleSave: (values: AddPatientDocumentFormType) => void;
  document?: DocumentType | undefined;
}

const DocumentFormSchema = Yup.object().shape({
  file: Yup.mixed().required(),
});

export const AddPatientDocumentForm: React.FunctionComponent<IAddPatientDocumentForm> = props => {
  const { t } = useTranslation('AddPatientDocumentForm');
  const { document } = props;
  const initialValues: AddPatientDocumentFormType = {
    comments: '',
    file: null,
    id: 0,
  };

  return (
    <DialogForm
      title={document ? document.fileName : t('dialogTitle')}
      initialValues={
        document
          ? {
              file: document.fileName,
              comments: document.comments,
              id: document.id,
            }
          : initialValues
      }
      onSubmit={props.handleSave}
      onCancel={props.handleCancel}
      validationSchema={DocumentFormSchema}
    >
      <div className="sm:flex sm:items-start">
        <div className="flex flex-col space-y-6 w-full">
          {!props.document && <Field component={DropFileInput} name={'file'} label={t('fileLabel')} />}
          <Field component={TextArea} name={'comments'} label={t('commentsLabel')} />
        </div>
      </div>
    </DialogForm>
  );
};
