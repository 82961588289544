import * as React from 'react';

import { MetricsCellHeader } from '../MetricsCellHeader';
import { ChartView } from '../MetricsUtils';

interface Props {
  name: string;
  view: ChartView;
  setView: (view: ChartView) => void;
}

export const NoMetricsData = ({ name, view, setView }: Props) => (
  <div className="mb-16 mt-10">
    <MetricsCellHeader name={name} view={view} setView={setView} />
    <div className="bg-gray-100 dark:bg-gray-700 text-gray-600 text-sm dark:text-gray-500 w-full text-center rounded-lg p-2">
      Nėra duomenų
    </div>
  </div>
);

export default NoMetricsData;
