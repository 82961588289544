interface ISmallButton {
  handleClick: () => void;
  children: string;
  icon?: JSX.Element;
  className?: string;
}

export default function SmallButton({ children, className = '', ...props }: ISmallButton) {
  let cx = `print:hidden inline-flex justify-center px-6 py-1.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none  bg-white text-gray-500 hover:bg-gray-50 ${className}`;
  return (
    <button type="button" className={cx} onClick={props.handleClick}>
      {props.icon ?? null}
      <span>{children}</span>
    </button>
  );
}
