import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SelectInput } from '../../../components/form/SelectInput';
import { useClinics } from '../../../hooks/queries/useClinics';
import Table from '../../../components/common/Table/Table';
import { Column } from 'react-table';
import { GadgetHistoryEntity, GadgetType, GadgetUpdateRequest, getGadgetStateArray } from '../../../contracts/Gadget';
import { useGadgetHistory } from '../../../hooks/queries/useGadgetHistory';
import { PatientInline } from '../../../components/patient/PatientInline';
import Button from '../../../components/buttons/Button';
import { getFullYear } from '../../../utils/date';
import { useTranslation } from 'react-i18next';

interface IEditGadgetForm {
  gadgetId: number;
  gadget?: GadgetType;
  handleCancel: () => void;
  handleSave: (value: GadgetUpdateRequest) => void;
}

const GadgetFormSchema = Yup.object().shape({});

export const EditGadgetForm: React.FunctionComponent<IEditGadgetForm> = ({ gadget, ...props }) => {
  const { t } = useTranslation('EditGadgetForm');
  const { data: clinics } = useClinics();
  const { data: gadgetHistory, isLoading } = useGadgetHistory(props.gadgetId);

  const [columns, historyData] = React.useMemo(() => {
    const columns: Array<Column<GadgetHistoryEntity>> = [
      {
        Header: t('table.patientColHeader'),
        accessor: 'patient',
        Cell: ({ value }) => <PatientInline {...value} />,
      },
      {
        Header: t('table.startDateColHeader'),
        accessor: 'startDate',
        Cell: ({ value }) => (value ? <>{getFullYear(value)}</> : null),
      },
      {
        Header: t('table.endDateColHeader'),
        accessor: 'endDate',
        Cell: ({ value }) => (value ? <>{getFullYear(value)}</> : null),
      },
    ];
    return [columns, gadgetHistory ? gadgetHistory : []];
  }, [gadgetHistory, t]);

  if (!gadget) {
    return null;
  }

  let initialValues: GadgetUpdateRequest = {
    id: gadget.id,
    state: gadget.state,
  };

  if (gadget.clinicId) {
    (initialValues as GadgetType).clinicId = gadget.clinicId;
  }

  return (
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{gadget.model}</h3>
      <h4 className="mt-3">{gadget.seriesNo}</h4>
      <div className="mt-6">
        <div className="flex flex-col">
          <Formik initialValues={initialValues} onSubmit={props.handleSave} validationSchema={GadgetFormSchema}>
            <Form>
              <div className="flex items-start space-x-4 mb-8">
                <Field component={SelectInput} name={'clinicId'} label={t('clinicIdLabel')}>
                  <option value={0} key={0}>
                    {t('clinicPlaceholder')}
                  </option>
                  {clinics &&
                    clinics.map(clinic => (
                      <option value={clinic.id} key={clinic.id}>
                        {clinic.name}
                      </option>
                    ))}
                </Field>
                <Field component={SelectInput} name={'state'} label={t('stateLabel')}>
                  {getGadgetStateArray(t).map(gadgetState => (
                    <option value={gadgetState.value} key={gadgetState.value}>
                      {gadgetState.name}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="max-h-64 overflow-x-visible overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 transition duration-300 hover:scrollbar-thumb-gray-500 -m-1 p-1 py-2 ">
                <div className={'mr-7'}>
                  {!isLoading && historyData && <Table data={historyData} columns={columns} />}
                </div>
              </div>
              <div className="mt-6 sm:flex sm:flex-row-reverse">
                <Button buttonType="submit">{t('save', { ns: 'common' })}</Button>
                <Button type="secondary" onClick={props.handleCancel}>
                  {t('cancel', { ns: 'common' })}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
