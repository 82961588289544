import * as React from 'react';
import { useDiagnoses } from '../../hooks/queries/useDiagnoses';

interface Props {
  children: string | number;
  className?: string; //css class
}

export default function DiagnosisBadge({ children, className = '' }: Props) {
  const diagnoses = useDiagnoses();
  const title = diagnoses && diagnoses[children] ? diagnoses[children].name : '';
  let color: string;

  switch (children) {
    case 'E10':
    case 'E11':
    case 'E13':
    case 'E14':
      color = 'bg-blue-200 hover:bg-blue-200 text-blue-800';
      break;
    case 'I11':
    case 'I12':
    case 'I13':
    case 'I15':
    case 'I48':
    case 'I50':
    case 'I69':
      color = 'bg-red-200 hover:bg-red-200 text-red-800';
      break;
    case 'J44':
      color = 'bg-orange-200 hover:bg-orange-200 text-orange-800';
      break;
    case 'J45':
      color = 'bg-emerald-200 hover:bg-emerald-200 text-emerald-800';
      break;
    case 'G20':
      color = 'bg-gray-200 hover:bg-gray-200 text-gray-800';
      break;
    case 'G40':
      color = 'bg-violet-200 hover:bg-violet-200 text-violet-800';
      break;
    case 'M05':
    case 'M06':
      color = 'bg-indigo-200 hover:bg-indigo-200 text-indigo-800';
      break;
    default:
      color = 'bg-gray-200 hover:bg-gray-200 text-gray-800';
  }

  return (
    <span
      className={`relative group min-w-14 py-0.5 justify-center font-mono tracking-wider inline-flex text-sm leading-5 font-semibold rounded-md cursor-pointer ${color} ${className} px-2`}
      title={title}
    >
      {children}
      <div className="-translate-x-1/2 left-1/2 absolute left-0 flex flex-col justify-center items-center -mt-8  hidden group-hover:flex">
        <span className="relative -mt-5 z-10 py-3 px-5 text-sm font-sans font-normal leading-none text-white whitespace-nowrap bg-gray-500 rounded-md shadow-lg">
          {title}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-500" />
      </div>
    </span>
  );
}
