import Table from '../../../../../components/common/Table/Table';
import Dialog from '../../../../../components/dialog/Dialog';
import { EditPatientDiagnosisForm } from './EditPatientDiagnosisForm';
import Confirmation from '../../../../../components/common/Confirmation';
import { TableTitle } from '../../../../../components/common/TableTitle';
import * as React from 'react';
import { Cell, Column } from 'react-table';
import { DiagnosisType } from '../../../../../contracts/Classifiers';
import { NullableType } from '../../../../../contracts/Common';
import { usePatientDiagnosisDelete } from '../../../../../hooks/mutations/usePatientDiagnosisDelete';
import { usePatientDiagnosisUpdate } from '../../../../../hooks/mutations/usePatientDiagnosisUpdate';
import { usePatientDiagnoses } from '../../../../../hooks/queries/usePatientDiagnoses';
import { PERMISSIONS, usePermission } from '../../../../../hooks/usePermission';
import { usePatientDiagnosisCreate } from '../../../../../hooks/mutations/usePatientDiagnosisCreate';
import { AddPatientDiagnosisForm } from './AddPatientDiagnosisForm';
import { useDiagnoses } from '../../../../../hooks/queries/useDiagnoses';
import { usePatient } from '../../../../../hooks/queries/usePatient';
import { successToast } from '../../../../../utils/successToast';
import EditIcon from '../../../../../components/icons/EditIcon';
import RemoveIcon from '../../../../../components/icons/RemoveIcon';
import { useTranslation } from 'react-i18next';

interface IDiagnosesSection {
  patientId: number;
}

export const DiagnosesSection: React.FunctionComponent<IDiagnosesSection> = ({ patientId }) => {
  const { t } = useTranslation('DiagnosesSection');
  const [isOpen, setOpen] = React.useState<NullableType<DiagnosisType | 'add'>>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<NullableType<DiagnosisType>>(null);
  const allDiagnoses = useDiagnoses();

  const diagnosisRemoveMutation = usePatientDiagnosisDelete();
  const diagnosisUpdateMutation = usePatientDiagnosisUpdate();
  const diagnosisCreateMutation = usePatientDiagnosisCreate();
  const { data: _diagnosesData } = usePatientDiagnoses(patientId);
  const { data: patient } = usePatient(patientId);
  const { canAny } = usePermission();
  const canManagePatients = canAny([PERMISSIONS.MANAGE_LOCAL_PATIENTS, PERMISSIONS.MANAGE_ALL_PATIENTS]);

  const [diagnosesColumns, diagnosesData] = React.useMemo(() => {
    const columns: Array<Column> = [
      {
        Header: t('codeHeader'),
        accessor: 'code',
        width: 30,
      },
      {
        Header: t('nameHeader'),
        width: 'auto',
        accessor: 'name',
      },
      {
        Header: t('commentsHeader'),
        width: 'auto',
        accessor: 'comments',
        // @ts-ignore
        className: 'important-whitespace-normal',
      },
    ];

    if (canManagePatients) {
      columns.push({
        Header: t('edit', { ns: 'common' }),
        disableSortBy: true,
        accessor: 'id',
        width: 30,
        // @ts-ignore
        Cell: ({ row }: Cell<DiagnosisType, number>) => {
          return (
            <div className="flex my-1">
              <div>
                <EditIcon onClick={() => setOpen(row.original)} />
              </div>
              <div>
                <RemoveIcon onClick={() => setOpenDeleteConfirmation(row.original)} />
              </div>
            </div>
          );
        },
      });
    }

    return [columns, _diagnosesData];
  }, [_diagnosesData, canManagePatients, t]);

  const diagnoses = React.useMemo(() => {
    if (allDiagnoses && patient) {
      return Object.values(allDiagnoses).filter(diagnosis => !patient.diagnoses.includes(diagnosis.code));
    }
    return [];
  }, [allDiagnoses, patient]);

  return (
    <TableTitle title={t('diagnosesTableTitle')}>
      {diagnosesData && (
        <>
          <Table
            data={diagnosesData}
            columns={diagnosesColumns}
            handleAddClick={canManagePatients ? () => setOpen('add') : undefined}
            pageSize={100}
          />
          <Dialog open={!!isOpen} handleCloseClick={() => setOpen(null)} widthAuto={true}>
            <>
              {isOpen && typeof isOpen === 'object' ? (
                <EditPatientDiagnosisForm
                  handleCancel={() => setOpen(null)}
                  handleSave={data =>
                    diagnosisUpdateMutation.mutate(
                      {
                        patientId: patientId,
                        diagnosisId: data.id,
                        comments: data.comments,
                      },
                      {
                        onSuccess: () => setOpen(null),
                      },
                    )
                  }
                  diagnosis={isOpen}
                />
              ) : null}
              {isOpen === 'add' ? (
                <AddPatientDiagnosisForm
                  handleCancel={() => setOpen(null)}
                  handleSave={data =>
                    diagnosisCreateMutation.mutate(
                      {
                        ...data,
                        patientId: patientId,
                      },
                      {
                        onSuccess: data => {
                          successToast(t('successMessage', { code: data.code, name: data.name }));
                          setOpen(null);
                        },
                      },
                    )
                  }
                  diagnoses={diagnoses}
                />
              ) : null}
            </>
          </Dialog>
          <Confirmation
            id={0}
            open={!!openDeleteConfirmation}
            handleCancel={() => setOpenDeleteConfirmation(null)}
            handleSubmit={() => {
              diagnosisRemoveMutation.mutate(
                {
                  patientId: patientId,
                  diagnosisId: openDeleteConfirmation!.id,
                },
                {
                  onSuccess: () => setOpenDeleteConfirmation(null),
                },
              );
            }}
          />
        </>
      )}
    </TableTitle>
  );
};
