import { useAuth0 } from '@auth0/auth0-react';

export enum PERMISSIONS {
  MANAGE_ALL_DEVICES = 'manage:all-gadgets',
  MANAGE_LOCAL_DEVICES = 'manage:clinic-gadgets',
  MANAGE_ALL_PATIENTS = 'manage:all-patients',
  MANAGE_LOCAL_PATIENTS = 'manage:clinic-patients',
  MANAGE_USERS = 'manage:users',
  MANAGE_ANALYSES = 'manage:analyses-parameters',
}

interface IUser {
  'https://chrodis.lt/permissions': Array<PERMISSIONS>;
}

export function usePermission() {
  const { user } = useAuth0<IUser>();

  function getPermissions(): Array<PERMISSIONS> | false {
    if (!user) {
      return false;
    }
    const permissions = user['https://chrodis.lt/permissions'];
    if (!permissions || permissions.length <= 0) {
      return false;
    }
    return permissions;
  }

  function can(permission: PERMISSIONS): boolean {
    const permissions = getPermissions();
    if (!permissions) {
      return false;
    }
    if (typeof permission === 'string') {
      return permissions.includes(permission);
    }

    return false;
  }

  function canAny(permission: Array<PERMISSIONS>): boolean {
    const permissions = getPermissions();
    if (!permissions) {
      return false;
    }
    if (Array.isArray(permission)) {
      if (permission.length === 0) {
        return true;
      }
      return permission.some(_p => permissions.includes(_p));
    }

    return false;
  }

  return {
    can,
    canAny,
  };
}
