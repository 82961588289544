import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { successToast } from '../../utils/successToast';
import { errorToast } from '../../utils/errorToast';
import { useTranslation } from 'react-i18next';

interface Request {
  timestamp: number;
}

export function useEcgEventArtifactDelete(patientId: number, recordId: number) {
  const { t } = useTranslation('useEcgEventArtifactDelete');
  const request = useRequest<Request>();

  return useMutation<unknown, unknown, Request>(
    ({ timestamp }: Request) =>
      request(`/patients/${patientId}/ecgs/${recordId}/artifacts/${timestamp}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        successToast(t('successToast'));
      },
      onError: () => {
        errorToast(t('errorToast'));
      },
    },
  );
}
