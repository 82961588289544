import { useRequest } from '../useRequest';
import { useQuery } from '@tanstack/react-query';
import { EcgRecord, EcgRecordsResponse } from '../../contracts/Ecg';
import parseISO from 'date-fns/parseISO';

export const useEcgRecords = (patientId: number) => {
  const request = useRequest<EcgRecordsResponse>();
  const mapDtoToResult = (responseData?: EcgRecordsResponse): EcgRecord[] =>
    responseData?.map(datum => {
      const ecgChunks = datum.ecgChunks
        .map(chunk => ({
          ecgRecordId: chunk.ecgRecordId,
          number: chunk.number,
          start: parseISO(chunk.start),
          end: parseISO(chunk.end),
        }))
        .sort((a, b) => a.number - b.number);

      return {
        id: datum.id,
        patientId: datum.patientId,
        date: parseISO(datum.date),
        startDate: ecgChunks.length ? ecgChunks[0].start : null,
        endDate: ecgChunks.length ? ecgChunks[ecgChunks.length - 1].end : null,
        ecgChunks,
        comments: datum.comments.map(({ eventsGroup, text, timestamp }) => ({
          eventType: eventsGroup,
          text,
          timestamp,
        })),
        artifacts: datum.artifacts.map(({ eventsGroup, timestamp }) => ({
          timestamp,
          eventType: eventsGroup,
        })),
      };
    }) ?? [];

  const queryResult = useQuery<EcgRecordsResponse>(
    ['patient-ecg-records', patientId],
    () => request(`/patients/${patientId}/ecgs`),
    { staleTime: 60 * 1000 },
  );

  return {
    ...queryResult,
    data: mapDtoToResult(queryResult.data),
  };
};
