import * as React from 'react';
import { Field } from 'formik';
import Dialog from '../../../../../components/dialog/Dialog';
import { successToast } from '../../../../../utils/successToast';
import { RichTextInput } from '../../../../../components/form/RichTextInput';
import { Descendant } from 'slate';
import { usePatientHcpUpdate } from '../../../../../hooks/mutations/usePatientHcpUpdate';
import { useParams } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/outline';
import SmallButton from '../../../../../components/buttons/SmallButton';
import DialogForm from '../../../../../components/layouts/dialog-form/DialogForm';
import { useTranslation } from 'react-i18next';

interface IHcpDialog {
  initialValue: Descendant[];
  title: string;
  sectionId: number;
}

export const HcpDialog = (props: IHcpDialog) => {
  const { t } = useTranslation('HcpDialog');
  const { id: patientId } = useParams<{ id: string }>();
  const [isOpen, setOpen] = React.useState(false);
  const patientHcpMutation = usePatientHcpUpdate();

  const hasInitialValue = Array.isArray(props.initialValue);

  function handleSave(values: { content: Descendant[] }) {
    patientHcpMutation.mutate(
      {
        sectionId: props.sectionId,
        patientId: Number(patientId),
        text: JSON.stringify(values.content),
      },
      {
        onSuccess: () => {
          successToast(t('saveSuccessMessage'));
          setOpen(false);
        },
      },
    );
  }

  const initialValues: { content: Descendant[] } = {
    content: hasInitialValue
      ? props.initialValue
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
  };

  return (
    <>
      <SmallButton
        handleClick={() => setOpen(true)}
        icon={<PencilIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500" aria-hidden="true" />}
      >
        {t('edit', { ns: 'common' })}
      </SmallButton>
      <Dialog open={isOpen} handleCloseClick={() => setOpen(false)} widthAuto={false}>
        <DialogForm
          title={props.title}
          initialValues={initialValues}
          onSubmit={handleSave}
          onCancel={() => setOpen(false)}
        >
          <div className="flex flex-col space-y-5 sm:items-start w-full">
            <Field component={RichTextInput} name={'content'} />
          </div>
        </DialogForm>
      </Dialog>
    </>
  );
};
