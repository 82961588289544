import * as React from 'react';
import { useFitbitCode } from '../../hooks/mutations/useFitbitCode';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import { FitbitService } from '../../utils/devices/FitbitService';
import { useQueryParams } from '../../hooks/useQueryParams';

const FitBitOAuthPage = () => {
  const navigate = useNavigate();
  const urlParams = useQueryParams();
  const patientId = Number(urlParams.get('state'));
  const code = urlParams.get('code');
  const { mutate } = useFitbitCode(patientId);

  React.useEffect(() => {
    if (patientId && code) {
      mutate(
        {
          code,
          redirectUrl: FitbitService.getReturnUrl(),
        },
        {
          onSettled: () => {
            navigate(`/patients/${patientId}?tab=settings`);
          },
        },
      );
    }
  }, [patientId, code, mutate, navigate]);

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full flex items-center justify-center">
        <Loading />
      </div>
    </div>
  );
};

export default FitBitOAuthPage;
