import { useQuery } from '@tanstack/react-query';
import { useRequest } from '../useRequest';
import { DoctorType } from '../../contracts/Doctors';

export function useDoctors() {
  const request = useRequest<Array<DoctorType>>();
  return useQuery<unknown, unknown, Array<DoctorType>>(['doctors'], () => request('/doctors'), {
    staleTime: 1000 * 30,
  });
}
