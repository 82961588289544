import { getFullTime } from '../../utils/date';
import * as React from 'react';
import { NullableType } from '../../contracts/Common';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  children: NullableType<Date> | undefined;
}

export default function LastSync({ children }: Props) {
  const { t } = useTranslation('LastSync');
  if (!children) {
    return null;
  }
  return (
    <div className={'text-gray-500 flex'}>
      <ClockIcon className="shrink-0 -ml-1 mr-2 h-6 w-6" aria-hidden="true" />
      <span className="text-sm leading-6">{t('updated', { date: getFullTime(children) })}</span>
    </div>
  );
}
