import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_LT } from './lt';
import { TRANSLATIONS_PL } from './pl';
import { TRANSLATIONS_RU } from './ru';
import { FullName } from '../contracts/Generic';

const STORED_LANGUAGE_KEY = 'language';

const resources = {
  en: TRANSLATIONS_EN,
  lt: TRANSLATIONS_LT,
  pl: TRANSLATIONS_PL,
  ru: TRANSLATIONS_RU,
};

export type SupportedLanguage = 'en' | 'lt' | 'pl' | 'ru';

export const supportedLanguagesList: SupportedLanguage[] = ['en', 'lt', 'pl', 'ru'];

const getInitialLanguage = () => {
  const languageFromPreviousSession = localStorage.getItem(STORED_LANGUAGE_KEY);
  const firstSupportedClientBrowsersLanguage = navigator.languages
    .find(navLang => supportedLanguagesList.some(supLang => navLang.toLowerCase().includes(supLang)))
    ?.split('-')?.[0];

  const lastResortLanguage = process.env.REACT_APP_USE_MULTILANGUAGE === '1' ? undefined : 'lt';

  return languageFromPreviousSession || firstSupportedClientBrowsersLanguage || lastResortLanguage;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getInitialLanguage(),
    // lng: 'lt', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export function changeLanguage(i: typeof i18n, lang: SupportedLanguage) {
  i18n.changeLanguage(lang);
  localStorage.setItem(STORED_LANGUAGE_KEY, lang);
}

export const getLocalizedFullName = (name: FullName | undefined) => (!name ? '' : `${name.firstName} ${name.lastName}`);
