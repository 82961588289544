import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../../useRequest';
import { PatientThreadType, ThreadMessageReplyRequest } from '../../../contracts/Threads';

export function useThreadReply() {
  const queryClient = useQueryClient();
  const request = useRequest<PatientThreadType>();
  return useMutation<PatientThreadType, unknown, ThreadMessageReplyRequest>(
    ({ patientId, threadId, ...data }) =>
      request(`/patients/${patientId}/threads/${threadId}`, {
        method: 'POST',
        body: JSON.stringify(data),
      }),
    {
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(['threads-me']);
        queryClient.setQueryData(['patient-threads', request.patientId], (oldData: Array<PatientThreadType> = []) => {
          return oldData.map(oldItem => (oldItem.id === data.id ? data : oldItem));
        });
      },
    },
  );
}
