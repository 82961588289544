import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormikSwitch } from '../../../../../components/form/FormikSwitch';
import Scrollbar from '../../../../../components/common/Scrollbar';
import { AnalysisParameterType } from '../../../../../contracts/Analysis';
import Button from '../../../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';

interface IParametersDialogForm {
  handleCancel: () => void;
  handleSave: (includedParameters: Array<number>) => void;
  parameters: Array<AnalysisParameterType>;
}

export const ParametersDialogForm: React.FunctionComponent<IParametersDialogForm> = ({
  handleSave,
  handleCancel,
  parameters,
}) => {
  const { t } = useTranslation('ParametersDialogForm');

  const initialValues = parameters.map(parameter => {
    return { id: parameter.id, value: parameter.isVisible };
  });

  function handleSubmit({ parameters }: { parameters: Array<{ id: number; value: boolean }> }) {
    const includedParameters = parameters.filter(val => val.value).map(val => val.id);
    handleSave(includedParameters);
  }

  return (
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{t('dialogHeader')}</h3>
      <Formik initialValues={{ parameters: initialValues }} onSubmit={handleSubmit}>
        <Form>
          <div className="mt-6">
            <Scrollbar className="h-72">
              {parameters.map((parameter, index) => {
                return (
                  <div className="mb-6" key={index}>
                    <div className="flex">
                      <Field name={`parameters[${index}]`} component={FormikSwitch} />
                      <div className="ml-4 w-96">
                        <h4 className="text-sm font-medium leading-5 text-black">
                          {parameter.name}, {parameter.unit}
                        </h4>
                        <span className="text-sm leading-5 text-gray-500">{parameter.description}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Scrollbar>
            <div className="mt-6 sm:flex sm:flex-row-reverse">
              <Button buttonType="submit">{t('save', { ns: 'common' })}</Button>
              <Button type="secondary" onClick={handleCancel}>
                {t('cancel', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
