import * as React from 'react';
import { Field } from 'formik';
import { PatientRequest, PatientType } from '../../../contracts/Patient';
import { TextInput } from '../../../components/form/TextInput';
import { RadioInput } from '../../../components/form/RadioInput';
import { SelectInput } from '../../../components/form/SelectInput';
import { useClinics } from '../../../hooks/queries/useClinics';
import * as Yup from 'yup';
import { useDoctors } from '../../../hooks/queries/useDoctors';
import { DoctorRoles } from '../../../contracts/Doctors';
import { NullableType } from '../../../contracts/Common';
import DialogForm from '../../../components/layouts/dialog-form/DialogForm';
import { getValidationMessage } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import { getLocalizedFullName } from '../../../translations/i18n';

interface IAddPatientForm {
  handleCancel: () => void;
  handleSave: (values: PatientRequest) => void;
  patient?: PatientType;
  canAssignClinic: boolean;
}

const PatientFormSchema = Yup.object().shape({
  clinicId: Yup.number().min(1, getValidationMessage('required', 'validation')),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  sex: Yup.string().required(),
  dateOfBirth: Yup.string()
    .matches(/\d{4}-\d{2}-\d{2}/, getValidationMessage('fullDateFormat', 'validation'))
    .required(),
  email: Yup.string().required(),
  weight: Yup.string().matches(
    /^[1-9][0-9]{1,2}([.,][0-9]{1,2})?$/,
    getValidationMessage('invalidWeight', 'AddPatientForm'),
  ),
  height: Yup.string().matches(/^[1-9][0-9]{1,2}$/, getValidationMessage('invalidHeight', 'AddPatientForm')),
  caseManagerId: Yup.number(),
  familyDoctorId: Yup.number(),
});

export const AddPatientForm: React.FunctionComponent<IAddPatientForm> = props => {
  const { t } = useTranslation('AddPatientForm');
  const { data: clinics } = useClinics();
  const { data: fullDoctorsList } = useDoctors();

  const getCaseManagers = React.useCallback(
    (clinicId: NullableType<number> | undefined) => {
      if (!fullDoctorsList) {
        return [];
      }
      if (!Number(clinicId)) {
        return [];
      }
      return fullDoctorsList.filter(
        doctor => doctor.role === DoctorRoles.CaseManager && doctor.clinic?.id === Number(clinicId),
      );
    },
    [fullDoctorsList],
  );

  const getFamilyDoctors = React.useCallback(
    (clinicId: NullableType<number> | undefined) => {
      if (!fullDoctorsList) {
        return [];
      }
      if (!Number(clinicId)) {
        return [];
      }

      return fullDoctorsList.filter(
        doctor => doctor.role === DoctorRoles.FamilyDoctor && doctor.clinic?.id === Number(clinicId),
      );
    },
    [fullDoctorsList],
  );

  let initialValues: PatientRequest = {
    id: 0,
    firstName: '',
    lastName: '',
    sex: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    weight: null,
    height: null,
    caseManagerId: 0,
    familyDoctorId: 0,
  };
  if (props.canAssignClinic) {
    initialValues.clinicId = 0;
  }

  if (props.patient) {
    initialValues = Object.assign(initialValues, {
      ...props.patient,
      dateOfBirth: String(props.patient.dateOfBirth).substr(0, 10),
      clinicId: Number(props.patient?.clinicId),
      familyDoctorId: Number(props.patient?.familyDoctorId),
      weight: props.patient?.weight ?? '',
      height: props.patient?.height ?? '',
      caseManagerId: props.patient.caseManager ? Number(props.patient.caseManager.id) : 0,
    });
  }

  function handleSubmit(values: PatientRequest) {
    if (!Number(values.caseManagerId)) {
      values.caseManagerId = null;
    }
    if (!Number(values.familyDoctorId)) {
      values.familyDoctorId = null;
    }
    if (!Number(values.weight)) {
      values.weight = null;
    }
    if (!Number(values.height)) {
      values.height = null;
    }
    if (!getCaseManagers(Number(values.clinicId)).some(doc => doc.id === Number(values.caseManagerId))) {
      values.caseManagerId = null;
    }
    if (!getFamilyDoctors(Number(values.clinicId)).some(doc => doc.id === Number(values.familyDoctorId))) {
      values.familyDoctorId = null;
    }
    props.handleSave(values);
  }

  return (
    <DialogForm
      title={props.patient ? `${props.patient.firstName} ${props.patient.lastName}` : t('patient', { ns: 'common' })}
      initialValues={initialValues}
      validationSchema={PatientFormSchema}
      onSubmit={handleSubmit}
      onCancel={props.handleCancel}
    >
      {({ values }) => (
        <div className="flex flex-col space-y-5 sm:items-start w-full">
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'firstName'} label={t('name', { ns: 'common' })} />
            <Field component={TextInput} name={'lastName'} label={t('surname', { ns: 'common' })} />
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'dateOfBirth'} label={t('dateOfBirthLabel')} />
            <Field
              component={RadioInput}
              name={'sex'}
              label={t('gender', { ns: 'common' })}
              values={[
                { value: 'F', text: t('female') },
                { value: 'M', text: t('male') },
              ]}
            />
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'height'} label={t('heightLabel')} />
            <Field component={TextInput} name={'weight'} label={t('weightLabel')} />
          </div>
          <div className="flex space-x-10 w-full">
            <Field component={TextInput} name={'email'} label={t('emailLabel')} />
            <Field component={TextInput} name={'phone'} label={t('phoneNoLabel')} />
          </div>
          {props.canAssignClinic && (
            <>
              <div className="flex space-x-10 w-full">
                <Field component={SelectInput} name={'clinicId'} label={t('clinic', { ns: 'common' })}>
                  <option value={0} key={0}>
                    {t('selectClinic')}
                  </option>
                  {clinics &&
                    clinics.map(clinic => (
                      <option value={clinic.id} key={clinic.id}>
                        {clinic.name}
                      </option>
                    ))}
                </Field>
              </div>
              <div className="flex space-x-10 w-full">
                <Field component={SelectInput} name={'caseManagerId'} label={t('caseManager', { ns: 'common' })}>
                  <option value={0} key={0}>
                    {t('selectCaseManager')}
                  </option>
                  {getCaseManagers(values.clinicId).map(doctor => (
                    <option value={doctor.id} key={doctor.id}>
                      {getLocalizedFullName(doctor)}
                    </option>
                  ))}
                </Field>
                <Field component={SelectInput} name={'familyDoctorId'} label={t('familyDoctorLabel')}>
                  <option value={0} key={0}>
                    {t('selectFamilyDoctor')}
                  </option>
                  {getFamilyDoctors(values.clinicId).map(doctor => (
                    <option value={doctor.id} key={doctor.id}>
                      {getLocalizedFullName(doctor)}
                    </option>
                  ))}
                </Field>
              </div>
            </>
          )}
        </div>
      )}
    </DialogForm>
  );
};
