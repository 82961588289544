import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Listbox } from '@headlessui/react';
import React from 'react';
import { SuperSearchFilterOption } from '../../common/Table/SuperSearch/SuperSearchUtils';

interface Props<T extends string | number> {
  options: SuperSearchFilterOption[];
  values: T[];
  open: boolean;
}

const MultiSelectButton = <T extends string | number>({ values, options, open }: Props<T>) => {
  const selectedLabels =
    options
      .filter(o => values.some(v => o.id === v))
      .map(o => o.label)
      .join(', ') || '-';

  return (
    <Listbox.Button
      className={`
                relative w-full cursor-default rounded-md border border-gray-300 dark:border-gray-500 bg-white 
                dark:bg-gray-600 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 
                sm:text-sm
            `}
    >
      <span className="block truncate">{selectedLabels}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        {open && <ChevronUpIcon className="h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />}
        {!open && <ChevronDownIcon className="h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />}
      </span>
    </Listbox.Button>
  );
};

export default MultiSelectButton;
